import React from 'react';
// @material-ui
import { createStyles, Theme, makeStyles } from '@material-ui/core/styles';
import {
  Grid,
  Typography,
  CircularProgress,
  TextField,
  TextFieldProps,
} from '@material-ui/core';
// @logic
import { GOOGLE_MAPS_API_KEY } from 'logic/config';
// @components
import useScript from 'components/utilities/UseScript';
// @local
import './places.module.css';
import { toast } from 'react-toastify';

export interface PlacesResult extends google.maps.places.PlaceResult {
  coordinates: [number, number];
}

interface Props {
  getSelectedLocation?: (location: PlacesResult) => void;
  textfieldProps?: TextFieldProps;
  currentAddress?: string;
  showAddress?: boolean;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    progress: {
      margin: theme.spacing(2),
    },
    loaderContainer: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
    },
  }),
);

const Places = ({
  getSelectedLocation,
  textfieldProps,
  currentAddress,
  showAddress = true,
}: Props) => {
  const status = useScript(
    `https://maps.googleapis.com/maps/api/js?key=${GOOGLE_MAPS_API_KEY}&libraries=places`,
  );
  const classes = useStyles({});

  React.useEffect(() => {
    if (status === 'loading') {
      console.log('Loading Google...');
    }
    if (status === 'ready') {
      console.log('Google has been loaded...');
      loadGoogle();
    }
    if (status === 'error') {
      toast.warning('Could not load Google...');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [status]);

  const loadGoogle = () => {
    const autocomplete = new google.maps.places.Autocomplete(
      document.getElementById('pac-input') as HTMLInputElement,
      {
        types: ['geocode', 'establishment'],
        componentRestrictions: { country: 'za' },
      },
    );

    autocomplete.setFields([
      'address_component',
      'formatted_address',
      'geometry',
      'icon',
      'name',
      'place_id',
      'url',
      'vicinity',
    ]);

    autocomplete.addListener('place_changed', () => {
      const selectedPlace = autocomplete.getPlace();

      if (selectedPlace == null) {
        return;
      }

      if (!selectedPlace.geometry) {
        return;
      }

      const coordinates: [number, number] = [
        selectedPlace.geometry.location.lat(),
        selectedPlace.geometry.location.lng(),
      ];

      if (getSelectedLocation != null && selectedPlace) {
        getSelectedLocation({
          coordinates,
          ...selectedPlace,
        });
      }
    });

    console.log('autocomplete loaded...');
  };

  return (
    <Grid container>
      <Grid item md={12} xs={12}>
        <TextField
          margin="dense"
          color="secondary"
          placeholder="Type to search..."
          type="text"
          className="controls"
          id="pac-input"
          label="Search for Location"
          InputLabelProps={{
            shrink: true,
          }}
          {...textfieldProps}
        />
        {currentAddress != null && showAddress ? (
          <Typography>{currentAddress}</Typography>
        ) : null}

        {status === 'loading' ? (
          <div className={classes.loaderContainer}>
            <Typography>Loading Locations...</Typography>
            <CircularProgress className={classes.progress} />
          </div>
        ) : null}
      </Grid>
    </Grid>
  );
};

export default Places;
