import React from 'react';
import { Link } from 'react-router-dom';
// @material-ui
import { Button, Typography, Grid } from '@material-ui/core';
// @components
import Content from 'components/Layout/Content';
// @local
import { useStore } from 'logic/store';
import useStyles from './styles';

const Terms = () => {
  const store = useStore();
  const classes = useStyles({
    company: store.companies.company,
    whitelabel: store.whitelabel,
  });
  return (
    <div className={classes.cover}>
      <Grid container className={classes.darkBanner}>
        <Grid item xs="auto" className={classes.gridContent}>
          <div className={classes.headTop}>
            <Typography
              variant="h2"
              align="center"
              className={`${classes.btnDark} ${classes.padding10}`}
            >
              Terms
            </Typography>

            <Typography
              variant="h4"
              align="center"
              className={`${classes.contentFaq} ${classes.padding10}`}
            >
              Terms and Conditions
            </Typography>

            <Typography
              variant="body1"
              align="center"
              className={`${classes.typoText} ${classes.padding10}`}
            >
              {/* Sorted uses Peach Payments as their
							payment gateway. --goes before the No sensitive... */}
              Cardholder safety: Your privacy is important to Sorted. It is
              Peach Payments’ policy to respect your privacy regarding any
              information they may collect from you via the Sorted website.
              Sorted and Peach Payments only ask for personal information needed
              to provide a service to you. It is collected by fair and lawful
              means, with your knowledge and consent. We also let you know why
              we’re collecting it and how it will be used. We only retain
              collected information for as long as necessary to provide you with
              your requested service. What data we store, we’ll protect within
              commercially acceptable means to prevent loss and theft, as well
              as unauthorised access, disclosure, copying, use or modification.
              We don’t share any personally identifying information publicly or
              with third-parties, except when given consent by you or required
              to by law. Our website may link to external sites that are not
              operated by us. Please be aware that we have no control over the
              content and practices of these sites, and cannot accept
              responsibility or liability for their respective privacy policies.
              You are free to refuse our request for your personal information,
              with the understanding that we may be unable to provide you with
              some of your desired services. Your continued use of our website
              will be regarded as acceptance of our practices around privacy and
              personal information. If you have any questions about how we
              handle user data and personal information, feel free to contact
              us. This policy is effective as of 1 September 2020.
            </Typography>
            <Typography
              variant="h4"
              align="center"
              className={`${classes.contentFaq} ${classes.padding10}`}
            >
              Refund Policy
            </Typography>
            <Typography
              variant="body1"
              align="center"
              className={`${classes.typoText} ${classes.padding10}`}
            >
              If for whatever reason you&apos;ve paid Sorted and then decide not
              to continue with the transaction, Sorted will refund you the
              department fee, excluding service fees, provided the transaction
              hasn&apos;t already been completed at the relevant department.
            </Typography>

            <Typography
              variant="h4"
              align="center"
              className={`${classes.contentFaq} ${classes.padding10}`}
            >
              Regulation Laws
            </Typography>

            <Typography
              variant="body1"
              align="center"
              className={`${classes.typoText} ${classes.padding10}`}
            >
              Sorted is governed by the laws of the Republic of South Africa,
              and in particular the laws relating to transport compliance.
            </Typography>

            <Typography
              variant="h4"
              align="center"
              className={`${classes.contentFaq} ${classes.padding10}`}
            >
              Delivery Policy
            </Typography>

            <Typography
              variant="body1"
              align="center"
              className={`${classes.typoText} ${classes.padding10}`}
            >
              Sorted will deliver your licence documents to your door. The
              delivery cost is included in your service fee. Sorted makes use of
              third-party delivery providers DSV and Wumdrop. See their websites
              for lists of T’s and C’s.
            </Typography>

            <Typography
              variant="h4"
              align="center"
              className={`${classes.contentFaq} ${classes.padding10}`}
            >
              Transactional Currency (ZAR)
            </Typography>

            <Typography
              variant="body1"
              align="center"
              className={`${classes.typoText} ${classes.padding10}`}
            >
              Sorted transacts in South African Rands.
            </Typography>

            <Typography
              variant="h3"
              align="center"
              className={`${classes.contentFaq} ${classes.padding10}`}
            >
              Privacy Policy
            </Typography>

            <Typography
              variant="body1"
              align="center"
              className={`${classes.typoText} ${classes.padding10}`}
            >
              Sorted (Pty) Ltd (“Sorted”, “we” or “us”) is committed to
              protecting your privacy. We value the trust of our clients,
              subscribers and all others who work with us, and we recognize that
              maintaining your trust requires that we be transparent and
              accountable in how we handle your personal information. In
              performing our services with the Department of Transport and
              Traffic Departments we collect, use and disclose personal
              information. Anyone from whom we collect such information can
              expect that it will be carefully protected and that any use of or
              other dealing with this information is subject to consent or is
              subject to legislation contained within the NCA. Our privacy
              practices are designed to achieve this.
            </Typography>

            <Typography
              variant="h4"
              align="center"
              className={`${classes.contentFaq} ${classes.padding10}`}
            >
              Obtaining Consent
            </Typography>

            <Typography
              variant="body1"
              align="center"
              className={`${classes.typoText} ${classes.padding10}`}
            >
              We do not, except where otherwise permitted by law, collect, use
              or disclose your Personal Information without your consent. In
              most cases where consent is required by law, it is provided to the
              subscriber for access to personal information.
            </Typography>
            <Typography
              variant="h4"
              align="center"
              className={`${classes.contentFaq} ${classes.padding10}`}
            >
              Use and Disclosure of Personal Information
            </Typography>

            <Typography
              variant="body1"
              align="center"
              className={`${classes.typoText} ${classes.padding10}`}
            >
              Sorted conducts its business in accordance with South African
              legislation. Sorted considers it imperative to protect the privacy
              interests of its customers.
            </Typography>
            <Typography
              variant="h4"
              align="center"
              className={`${classes.contentFaq} ${classes.padding10}`}
            >
              Retention of Personal Information
            </Typography>

            <Typography
              variant="body1"
              align="center"
              className={`${classes.typoText} ${classes.padding10}`}
            >
              All Personal Information retained on our database is in accordance
              with the retention provisions set out in the applicable Laws and
              regulations of South Africa.
            </Typography>

            <Typography
              variant="h4"
              align="center"
              className={`${classes.contentFaq} ${classes.padding10}`}
            >
              Ensuring Your Personal Information is Correct and Accurate
            </Typography>

            <Typography
              variant="body1"
              align="center"
              className={`${classes.typoText} ${classes.padding10}`}
            >
              You have a right to ensure that your Personal Information is
              accurate, complete and up-to-date
            </Typography>

            <Typography
              variant="h4"
              align="center"
              className={`${classes.contentFaq} ${classes.padding10}`}
            >
              Updating of Privacy Policy
            </Typography>

            <Typography
              variant="body1"
              align="center"
              className={`${classes.typoText} ${classes.padding10}`}
            >
              We reserve the right, in our sole discretion to update, modify or
              amend (including without limitation, by the addition of new terms
              and conditions) this Privacy Policy from time to time with or
              without notice. You therefore agree to review the Privacy Policy
              whenever you visit our website for any such change. Save as
              expressly provided to the contrary in this Privacy Policy, the
              amended version of the Privacy Policy shall supersede and replace
              all previous versions thereof. Please check our site on an ongoing
              basis or contact info@sorted.co.za for the most up-to-date
              information on our privacy practices.
            </Typography>
          </div>
        </Grid>
      </Grid>
      <Content
        className={`${classes.content} ${classes.headTop}`}
        containerClass={`${classes.containerClass} ${classes.contentMidWhite}`}
      >
        <Grid item sm={12} className={classes.termsMargin}>
          <Typography
            variant="h4"
            align="center"
            className={`${classes.typoHead} ${classes.padding10}`}
          >
            Ready to get Sorted?
          </Typography>
          <div>
            <Button component={Link} to="/signup" className={classes.btnBlue}>
              Sign Up
            </Button>
          </div>
        </Grid>
      </Content>
    </div>
  );
};

export default Terms;
