import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';

export default makeStyles((theme: Theme) =>
  createStyles({
    infoContainer: {
      maxHeight: '200px',
    },
    gridCenter: {
      textAlign: 'center',
    },
    gridCenterMargin: {
      textAlign: 'center',
      marginTop: '15px',
      width: '400px',
      margin: '0 auto',
    },
    fullWidth: {
      width: '100%',
    },
    uploadIcon: {
      fontSize: 60,
    },
    documentContainer: {},
    circleMain: {
      borderRadius: '50%',
      width: '100%',
      background: '#bbb',
      height: '10px',
    },
    img100: {
      height: '100%',
      width: '100%',
    },
    renewalStatus: {
      fontSize: '16px',
      textTransform: 'uppercase',
      marginTop: '15px',
    },
    secondaryHeading: {
      fontSize: theme.typography.pxToRem(15),
      color: theme.palette.text.secondary,
    },
    column: {
      flexBasis: '33.33%',
    },
    defaultDeliveryText: {
      [theme.breakpoints.down('sm')]: {
        width: '100%',
      },
    },
    greenButton: {
      color: theme.palette.secondary.contrastText,
      backgroundColor: theme.palette.secondary.main,
      '&:hover': {
        color: 'black',
      },
    },
    alignGridRight: {
      textAlign: 'right',
    },
    capitalizeText: {
      textTransform: 'capitalize',
    },
    dangerIcon: {
      color: '#f5483b',
    },
    successIcon: {
      color: '#A0E39E',
    },
    defaultDeliveryType: {
      [theme.breakpoints.up('xs')]: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-end',
      },
    },
    contentContainer: {
      background: 'transparent',
      height: '100%',
      overflowY: 'hidden',
      overflowX: 'hidden',
    },
    container: {
      marginTop: '2%',
      paddingBottom: '20px',
      maxWidth: '1000px !important',
      width: '100%',
      padding: '0 10px',
      background: 'transparent',
      margin: '0 auto',
    },
    uppercase: {
      textTransform: 'uppercase',
    },
  }),
);
