import Joi from 'joi';

export interface ICompany {
  id: number;
  name: string;
  reference: string;
  logoUrl: string;
  backgroundUrl?: string;
  skipVerification: boolean;
  showAdmin: boolean;
  contactEmail: string;
  textColor?: string;
  backgroundColor?: string;
  address1?: string;
  address2?: string;
  fullCompanyName?: string;
  primaryColor?: string;
  secondaryColor?: string;
  primaryTextColor?: string;
  secondaryTextColor?: string;
  fromEmail?: string;
  adminEmail?: string;
  createdAt: Date;
  updatedAt: Date;
  parentId?: string;
}

export const CompanySchema = Joi.object<ICompany>({
  id: Joi.number().required(),
  name: Joi.string().required(),
  reference: Joi.string().required(),
  logoUrl: Joi.string().required(),
  backgroundUrl: Joi.string().allow(null),
  skipVerification: Joi.boolean().required(),
  showAdmin: Joi.boolean().required(),
  contactEmail: Joi.string().required(),
  textColor: Joi.string().allow(null),
  backgroundColor: Joi.string().allow(null),
  address1: Joi.string().allow(null),
  address2: Joi.string().allow(null),
  fullCompanyName: Joi.string().allow(null),
  primaryColor: Joi.string().allow(null),
  secondaryColor: Joi.string().allow(null),
  primaryTextColor: Joi.string().allow(null),
  secondaryTextColor: Joi.string().allow(null),
  fromEmail: Joi.string().allow(null),
  adminEmail: Joi.string().allow(null),
  createdAt: Joi.date().required(),
  updatedAt: Joi.date().required(),
  parentId: Joi.string().allow(null),
}).unknown(true);
