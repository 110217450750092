import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';

export default makeStyles((theme: Theme) =>
  createStyles({
    root: {
      maxWidth: 345,
    },
    noPadding: {
      padding: '0px',
    },
    gridCenterMargin: {
      textAlign: 'center',
      marginTop: '15px',
    },
    img100: {
      height: '100%',
      width: '100%',
    },
    downloadBtn: {
      width: '100%',
      padding: '10px',
      textDecoration: 'none',
      textAlign: 'center',
      color: '#64abfb',
      fontSize: '0.875rem',
      fontFamily: 'Titillium Web, sans-serif',
      fontWeight: 500,
      lineHeight: '1.75',
      textTransform: 'uppercase',
      transition:
        'background-color 400ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,border 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
      '&:hover': {
        background: '#f0f0f0',
      },
      '&:selected': {
        opacity: '0.5',
      },
    },
  }),
);
