import { Theme } from '@material-ui/core/styles/createMuiTheme';
import { makeStyles, createStyles } from '@material-ui/core/styles';

const styles = makeStyles((theme: Theme) =>
  createStyles({
    status: {
      textTransform: 'uppercase',
      width: '150px',
      fontWeight: 'bold',
    },
    inProgress: {
      borderColor: theme.palette.primary.main,
      color: theme.palette.primary.main,
    },
    tableScroll: {
      overflowX: 'scroll',
    },
    paper: {
      margin: 'auto',
    },
    root: {
      '& > *': {
        borderBottom: 'unset',
      },
    },
    searchBar: {
      borderBottom: '1px solid rgba(0, 0, 0, 0.12)',
    },
    searchInput: {
      fontSize: theme.typography.fontSize,
    },
    block: {
      display: 'block',
    },
    contentWrapper: {
      margin: '10px',
    },
    progress: {
      padding: theme.spacing(1),
    },
    centerProgress: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },
    rowHover: {
      '&:hover': {
        cursor: 'pointer',
      },
    },
    paginationToolbar: {
      [theme.breakpoints.down('sm')]: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-start',
      },
    },
    paginationActions: {
      [theme.breakpoints.down('sm')]: {
        marginLeft: '0px',
        paddingBottom: '20px',
      },
    },
    blueIcon: {
      color: 'black',
    },
    renewalHead: {
      fontSize: '1.2em',
      color: 'black',
    },

    chevron: {
      transition: 'all 300ms',
      color: 'black',
    },
    chevronRotate: {
      color: 'black',
      transform: 'rotate(180deg)',
      transition: 'all 300ms',
    },
    uppercase: {
      textTransform: 'uppercase',
    },
    collapsePadding: {
      paddingTop: '15px',
      paddingBottom: '15px',
    },
    removeCellSpacing: {
      paddingBottom: 0,
      paddingTop: 0,
    },
  }),
);

export default styles;
