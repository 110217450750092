import React from 'react';
import { observer } from 'mobx-react-lite';
import swal from '@sweetalert/with-react';
// @material-ui
import {
  List,
  ListItem,
  ListItemAvatar,
  Avatar,
  ListItemText,
  ListItemSecondaryAction,
  IconButton,
  DialogTitle,
  Dialog,
  DialogContent,
  Toolbar,
  DialogContentText,
  Grid,
  Paper,
  DialogActions,
  Button,
  Fab,
  TextField,
} from '@material-ui/core';
// @icons
import {
  Delete as DeleteIcon,
  Photo as PhotoIcon,
  Description as DescriptionIcon,
  Add as AddIcon,
  Search as SearchIcon,
} from '@material-ui/icons';
// @logic
import { useStore } from 'logic/store';
// @components
import UploadDocuments from 'components/UploadDocuments';
import Content from 'components/Layout/Content';
// @local
import useCertaintyButtonStyles from 'components/Layout/certaintyButtonStyles';
import { IDocument } from 'logic/stores/document/validation';
import useStyles from './styles';

const ViewDocuments = observer(() => {
  const store = useStore();
  const { userId, permissions } = store.auth;
  const { documents } = store.documents;
  const classes = useStyles();
  const certaintyButtonStyle = useCertaintyButtonStyles({});

  const [open, setOpen] = React.useState(false);
  const [openUpload, setOpenUpload] = React.useState(false);
  const [searchText, setSearchText] = React.useState('');
  const [selectedDocument, setSelectedDocument] =
    React.useState<IDocument | null>(null);
  const [typingTimeout, setTypeTimeout] = React.useState<NodeJS.Timeout | null>(
    null,
  );

  React.useEffect(() => {
    fetchDocuments();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const isAdmin = permissions?.find((n) => n === 'isAdmin');

  const handleUploadOpen = () => {
    setOpenUpload(true);
  };

  const handleUploadClose = () => {
    setOpenUpload(false);
  };

  const fetchDocuments = () => {
    if (userId != null) {
      store.documents.load({ userId });
    }
  };

  const handleNext = () => {
    setOpenUpload(false);
    swal('Success', 'Your file has been uploaded successfully', 'success');
  };

  const handleClickOpen = (document: IDocument) => {
    setOpen(true);
    setSelectedDocument(document);
  };

  const handleClose = () => {
    setSelectedDocument(null);
    setOpen(false);
  };

  const removeDocument = (documentId: number) => {
    swal({
      title: 'Are you sure?',
      text: 'Once deleted, you will not be able to recover this file!',
      icon: 'warning',
      buttons: {
        cancel: true,
        catch: {
          text: 'Yes, I want to delete this.',
          value: 'confirm',
          className: certaintyButtonStyle.dangerRed,
        },
      },
      dangerMode: true,
    }).then(async (willDelete: string) => {
      if (willDelete === 'confirm') {
        try {
          await store.documents.remove(documentId);
          swal('Selected Document has been removed', {
            icon: 'success',
          });
        } catch (error) {
          swal(
            'Error!',
            'Unable to remove document. Please email info@sorted.co.za for help',
            'error',
          );
        }
      }
    });
  };

  const searchFor = (searchEvent: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = searchEvent.target;
    if (typingTimeout != null) {
      clearTimeout(typingTimeout);
    }
    setSearchText(value);
    setTypeTimeout(
      // @ts-ignore To-Do Fix Type
      setTimeout(() => {
        if (userId != null) {
          store.documents.reset();
          store.documents.load({
            userId,
            search: value,
          });
        }
      }, 300),
    );
  };

  return (
    <Content spacing={2} className={classes.container}>
      <Grid item md={12} xs={12}>
        <Toolbar>
          <Grid
            container
            spacing={1}
            alignItems="center"
            className={classes.fullWidth}
          >
            <Grid item xs={2} md={2}>
              <SearchIcon color="inherit" />
            </Grid>
            <Grid item xs={8} md={8}>
              <TextField
                fullWidth
                color="secondary"
                placeholder="Search by Document Name"
                onChange={searchFor}
                value={searchText}
                InputProps={{
                  disableUnderline: true,
                  className: classes.searchInput,
                }}
              />
            </Grid>
            <Grid item xs={2} md={2} className={classes.gridFab}>
              <Fab size="medium" onClick={handleUploadOpen} color="primary">
                <AddIcon />
              </Fab>
            </Grid>
          </Grid>
        </Toolbar>
      </Grid>
      <Grid item xs={12} md={12}>
        <List>
          {documents
            .filter((n) => {
              if (n.access === 'admin') {
                return isAdmin;
              }
              return true;
            })
            .map((element: IDocument) => (
              <ListItem
                key={element.id}
                button
                onClick={() => handleClickOpen(element)}
              >
                <ListItemAvatar>
                  <Avatar
                    className={
                      element.documentType === 'application/pdf'
                        ? classes.blackAvatar
                        : classes.blueAvatar
                    }
                  >
                    {element.documentType === 'application/pdf' ? (
                      <DescriptionIcon />
                    ) : (
                      <PhotoIcon />
                    )}
                  </Avatar>
                </ListItemAvatar>
                <ListItemText
                  primary={element.filename}
                  secondary={element.documentType}
                />
                <ListItemSecondaryAction>
                  <IconButton
                    edge="end"
                    onClick={() => removeDocument(element.id)}
                  >
                    <DeleteIcon />
                  </IconButton>
                </ListItemSecondaryAction>
              </ListItem>
            ))}
        </List>
      </Grid>
      <Dialog
        fullWidth
        maxWidth="sm"
        open={openUpload}
        onClose={handleUploadClose}
      >
        <DialogContent>
          <UploadDocuments next={handleNext} documents={documents} />
        </DialogContent>
      </Dialog>
      {selectedDocument != null ? (
        <Dialog fullWidth maxWidth="sm" open={open} onClose={handleClose}>
          <DialogTitle>Document Name: {selectedDocument.filename}</DialogTitle>

          <DialogContent>
            <DialogContentText>
              Document type: {selectedDocument.documentType}
            </DialogContentText>

            <Paper elevation={0}>
              {selectedDocument != null ? (
                <Grid
                  item
                  md={12}
                  xs={12}
                  className={classes.selectedTopCenter}
                >
                  {selectedDocument.filePath.endsWith('.pdf') ? (
                    <>
                      <embed
                        height="500px"
                        width="500px"
                        title="Vehicle Documents"
                        type="application/pdf"
                        src={selectedDocument.filePath}
                      />
                    </>
                  ) : (
                    <img
                      className={classes.img100}
                      src={selectedDocument.filePath as string}
                      alt="Vehicle Documents"
                    />
                  )}
                </Grid>
              ) : null}
            </Paper>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose} color="primary">
              Close
            </Button>
          </DialogActions>
        </Dialog>
      ) : null}
    </Content>
  );
});

export default ViewDocuments;
