import React, { useState } from 'react';
import { observer } from 'mobx-react-lite';
import swal from '@sweetalert/with-react';
// @material-ui
import { TextField, Button } from '@material-ui/core';
// @form
import * as Yup from 'yup';
import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
// @logic
import { useStore } from 'logic/store';
// @local
import { IRenewal } from 'logic/stores/renewals/validation';
import useStyles from '../styles';

interface FormValues {
  description: string;
}

const validationSchema = Yup.object().shape({
  description: Yup.string().label('user action message').lowercase().required(),
});

interface TemplateProps {
  renewal: IRenewal | null;
}

const UserActionRequired = observer(({ renewal }: TemplateProps) => {
  const classes = useStyles({});
  const store = useStore();
  const [loading, setLoading] = useState(false);

  const { handleSubmit, errors, control } = useForm<FormValues>({
    resolver: yupResolver(validationSchema),
    defaultValues: {
      description: '',
    },
  });

  const sendUserDescription = async (payload: FormValues) => {
    if (renewal != null) {
      setLoading(true);
      const result = await store.events.create({
        eventType: 'renewal',
        status: 'user to action',
        description: payload.description,
        userId: renewal.userId!,
        vehicleId: renewal.vehicleId!,
        renewalId: renewal.id,
      });

      setLoading(false);
      if (result.success === true && result) {
        const res = await store.renewals.update(renewal.id, {
          status: 'user to action',
        });
        if (res.success === true && res) {
          swal(
            'Sorted!',
            'status has been moved to user action required!',
            'success',
          );
        } else {
          swal('Error', res.message, 'error');
        }
      } else {
        swal('Error', result.message, 'error');
      }
    }
  };

  return (
    <form onSubmit={handleSubmit(sendUserDescription)}>
      <Controller
        as={
          <TextField
            margin="dense"
            fullWidth
            color="secondary"
            type="text"
            multiline
            rows={4}
            placeholder="Message for user"
            label="Action required to be taken by the user"
            InputProps={{
              className: classes.textField,
            }}
            error={Boolean(errors.description)}
            helperText={errors.description && errors.description.message}
          />
        }
        name="description"
        control={control}
      />
      <div className={classes.submitContainer}>
        <Button
          variant="contained"
          color="primary"
          disabled={loading}
          type="submit"
          fullWidth
        >
          Send to user
        </Button>
      </div>
    </form>
  );
});

export default UserActionRequired;
