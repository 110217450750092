import React from 'react';
import { Link } from 'react-router-dom';
// @material-ui
import { Grid, Button } from '@material-ui/core';
// @icons
import { Copyright } from '@material-ui/icons';
// @local
import useStyles from './styles';

const Footer = () => {
  const classes = useStyles({});
  return (
    <Grid
      container
      direction="row"
      justify="center"
      className={`${classes.footer} ${classes.footerBackground}`}
    >
      <Grid item container sm={12} md={12}>
        <Grid item sm={12} md={12} className={classes.flexFooter}>
          <nav className={classes.flexLeftContent}>
            <p>
              <i className={classes.footerLogo}>
                <Copyright fontSize="small" />
              </i>
            </p>
            <p className={classes.footSpan}>
              2020, Sorted <span>Pty Ltd</span>
            </p>
          </nav>
          <footer>
            <Button
              component={Link}
              className={`${classes.footerLink}`}
              classes={{ root: classes.removeHoverColor }}
              to="/terms"
            >
              Terms & Privacy Policy
            </Button>
          </footer>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default Footer;
