import Joi from 'joi';
import { makeObservable, observable, runInAction } from 'mobx';
import { DocumentSchema, IDocument } from '../document/validation';

export type Result<Data> =
  | {
      success: true;
      data: Data;
    }
  | {
      success: false;
      message: string;
      code: number;
    };

export type RequestConfig = {
  API_URL: string;
  getHeaders: (header?: Record<string, any>) => Record<string, any>;
};

export const JSON_ERROR = 'Unexpected end of JSON input';

export type RequestOptions<Data> = {
  method: 'POST' | 'GET' | 'PATCH' | 'DELETE';
  path: string;
  validation: Joi.Schema<Data>;
  body?: Record<string, any>;
  params?: Record<string, any>;
  // These headers will override defaults
  headers?: Record<string, any>;
};

export type SuccessfulUploadFileResponse = {
  status: 'success';
  document: IDocument;
};

export type UnsuccessfulUploadFileResponse = {
  status: 'error';
  error: Error;
};

export type UploadFileResponse =
  | {
      success: true;
      document: IDocument;
    }
  | {
      success: false;
      error: Error;
    };

export class BaseStore {
  loading = false;

  constructor(protected readonly config: RequestConfig) {
    makeObservable(this, {
      loading: observable,
    });
  }

  protected async uploadFile(
    path: string,
    file: File,
  ): Promise<UploadFileResponse> {
    try {
      const formData = new FormData();
      formData.append('file', file);

      const headers = this.config.getHeaders();
      headers['Content-length'] = file.size;

      const url = `${this.config.API_URL}/${path}`;
      const response = await fetch(url, {
        method: 'POST',
        headers,
        body: formData,
      });
      const responseBody = await response.json();

      const validDocument = await DocumentSchema.validateAsync(responseBody);

      return { success: true, document: validDocument };
    } catch (error) {
      return { success: false, error: error as Error };
    }
  }

  protected async sendRequest<Data>(
    options: RequestOptions<Data>,
  ): Promise<Result<Data>> {
    const { method, params, validation, body } = options;
    const headers = this.config.getHeaders(options.headers);

    let { path } = options;

    if (method === 'POST' || method === 'PATCH') {
      const contentType = params
        ? 'application/x-www-form-urlencoded'
        : 'application/json';
      headers['Content-Type'] = contentType;
    }

    let bodyContent: BodyInit | undefined;

    if (params && (method === 'POST' || method === 'PATCH')) {
      bodyContent = new URLSearchParams(params);
    } else if (params && method === 'GET') {
      path = `${path}?${new URLSearchParams(params)}`;
    } else if (body) {
      bodyContent = JSON.stringify(body);
    } else if (params) {
      bodyContent = new URLSearchParams(params);
    }

    const url = `${this.config.API_URL}/${path}`;

    const response = await fetch(url, {
      method,
      headers,
      body: bodyContent,
    });

    const responseBody = await response.json();

    try {
      const data = await validation.validateAsync(responseBody);
      return {
        success: true,
        data,
      };
    } catch (e) {
      console.log(`Validation error: `, e);
    }

    return {
      success: false,
      message: responseBody,
      code: response.status,
    };
  }

  protected setLoading(value: boolean) {
    runInAction(() => {
      this.loading = value;
    });
  }

  protected async withState<Data>(
    p: Promise<Result<Data>>,
  ): Promise<Result<Data>> {
    runInAction(() => {
      this.loading = true;
    });

    let result: Result<Data>;

    try {
      const response = await p;

      result = response;
    } catch (e) {
      result = {
        success: false,
        message: (e as Error).message,
        code: 0,
      };
    }

    runInAction(() => {
      this.loading = false;
    });
    return result;
  }
}
