import React from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import { observer } from 'mobx-react-lite';
import moment from 'moment';
import swal from '@sweetalert/with-react';
// @material-ui
import { Autocomplete } from '@material-ui/lab';
import { DatePicker } from '@material-ui/pickers';
import {
  Button,
  Grid,
  TextField,
  Paper,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Typography,
  MenuItem,
  Fab,
} from '@material-ui/core';
// @form
import { useForm, Controller } from 'react-hook-form';
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
// @icons
import { BackupTwoTone, HelpOutline } from '@material-ui/icons';
// @logic
import { useStore } from 'logic/store';

// @components
import Content from 'components/Layout/Content';
import imageDialog, {
  IImageDialog,
  ShowMoreInfo,
} from 'components/ImageDialog';
import OCRUpload from 'components/OCRUpload';
// @local
import {
  Region,
  VehicleType,
  listcarmakes,
  vehicleTypeOptions,
} from 'logic/stores/vehicles/types';
import { IOCRResults } from 'logic/stores/document/validation';
import { regions } from 'logic/stores/renewals/types';
import useStyles from './styles';

// Vehicle form interface here
export interface FormValues {
  make: string;
  model: string;
  year: number;
  licenseNumber: string;
  licenseExpires: Date;
  registrationRegion: Region;
  weightKg: number;
  registerNumber: string;
  // set vehicle type to undefined inside the form for user to select one the menu options
  vehicleType?: VehicleType;
}

Yup.addMethod(Yup.string, 'matchLicense', function () {
  // @ts-ignore
  return this.test(
    'test-1',
    'Validation failure message',
    function (value: any) {
      const valid = /^[A-Za-z0-9_\-. ]{3,}$/;
      // @ts-ignore
      const { path, createError } = this;
      if (valid.test(value)) {
        return true;
      }
      return createError({
        path,
        message:
          'Please make sure that your licence plate is at least 3 digits long.',
      });
    },
  );
});

const AddVehicleForm = observer(() => {
  const history = useHistory();
  const classes = useStyles({});
  const fabStyles = {
    extended: classes.extendedFab,
    label: classes.uploadText,
  };
  const iconSpaceStyles = { root: classes.iconSpaceText };

  const location = useLocation();
  const store = useStore();
  const [loading, setLoading] = React.useState(false);
  const [open, setOpen] = React.useState(false);
  const [currentDialog, setCurrentDialog] = React.useState<IImageDialog>();

  const [selectedDate, setSelectedDate] = React.useState<
    string | moment.Moment | null
  >(moment().format('DD-MMM-YYYY'));

  const validationSchema = Yup.object().shape({
    make: Yup.string().label('Make').lowercase().required(),
    model: Yup.string().label('Model').lowercase().required(),
    year: Yup.number()
      .typeError('Year must be a number')
      .label('Year')
      .min(1900, 'Please enter a valid vehicle Year')
      .max(new Date().getFullYear() + 1, 'Please enter a valid vehicle Year')
      .required(),
    licenseNumber: Yup.string()
      .label('license number')
      .required()
      // @ts-ignore
      .matchLicense()
      .lowercase(),
    registerNumber: Yup.string()
      .label('Vehicle Register Number')
      .lowercase()
      .required(),
    licenseExpires: Yup.date().required().label('Expiry date'),
    registrationRegion: Yup.string().required().label('Province').lowercase(),
    weightKg: Yup.number().required().min(50).label('Vehicle tare weight (kg)'),
    vehicleType: Yup.string().label('Vehicle type').lowercase().required(),
  });

  const handleDateChange = (date: string | moment.Moment | null) => {
    setSelectedDate(date);
  };

  const [file, setFile] = React.useState<File | null>(null);
  const [openDialog, setOpenDialog] = React.useState(false);

  const handleViewUpload = () => {
    setOpenDialog(true);
  };

  const handleClickOpen = (v: ShowMoreInfo) => {
    setCurrentDialog(imageDialog[v]);
    setOpen(true);
  };

  const dialogClick = () => {
    setOpenDialog(!openDialog);
  };

  const [storeOCRResult, setOCRResult] = React.useState<IOCRResults>({
    gvm: undefined,
    discNumber: undefined,
    expiryDate: undefined,
    make: undefined,
    licenseNumber: undefined,
    registerNumber: undefined,
    tare: undefined,
  });

  const updateOCRForm = (update: IOCRResults) => {
    setOCRResult((state) => ({ ...state, ...update }));
  };

  const { handleSubmit, errors, control, reset, setValue, watch } =
    useForm<FormValues>({
      resolver: yupResolver(validationSchema),
      defaultValues: {
        make: '',
        model: '',
        year: undefined,
        licenseNumber: '',
        registerNumber: '',
        licenseExpires: undefined,
        registrationRegion: undefined,
        weightKg: undefined,
        vehicleType: undefined,
      },
    });

  const { vehicleType } = watch();

  const handleClose = () => {
    setOpen(false);
    setCurrentDialog(undefined);
  };

  const addVehicle = async (payload: FormValues) => {
    setLoading(true);
    const result = await store.vehicles.create({
      ...payload,
    });
    if (result.success === true) {
      swal('Sorted!', 'Vehicle has been added!', 'success');
      reset({
        make: '',
        model: '',
        year: undefined,
        licenseNumber: '',
        registerNumber: '',
        licenseExpires: undefined,
        registrationRegion: undefined,
        weightKg: undefined,
        vehicleType: undefined,
      });
      setLoading(false);
      if (location.pathname.includes('admin')) {
        history.push('/admin/manage-my-vehicles');
      } else {
        history.push('/manage-vehicles');
      }
    } else {
      swal(
        'Error!',
        'Unable to add vehicle. Please email info@sorted.co.za for help',
        'error',
      );
      setLoading(false);
    }
  };

  React.useEffect(() => {
    const { discNumber, expiryDate, make, tare, registerNumber } =
      storeOCRResult;

    // licenseNumber already exists, save as different variable
    const ocrLicenseNumber = storeOCRResult.licenseNumber;

    if (
      discNumber &&
      expiryDate &&
      ocrLicenseNumber &&
      registerNumber &&
      tare &&
      registerNumber &&
      make
    ) {
      // remove the kg at end of tare
      const setTare = tare.slice(0, -2);
      setValue('make', make);
      setValue('weightKg', Number(setTare));
      setValue('licenseNumber', ocrLicenseNumber);
      setValue('licenseExpires', expiryDate);
      setValue('registerNumber', registerNumber);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(storeOCRResult)]);

  return (
    <Content>
      <Grid item md={12} xs={12}>
        <Typography
          variant="h4"
          align="center"
          className={classes.headingSpace}
        >
          Add Vehicle
        </Typography>
        <Paper
          className={`${classes.formStyle} ${classes.padding20} ${classes.mobileSpacing}`}
          elevation={2}
        >
          <form
            onSubmit={handleSubmit(addVehicle)}
            className={classes.formMobilePadding}
          >
            <Grid item md={12} xs={12} sm={12} className={classes.fullWidth}>
              <Fab
                variant="extended"
                color="primary"
                size="large"
                classes={fabStyles}
                onClick={dialogClick}
              >
                <BackupTwoTone fontSize="large" classes={iconSpaceStyles} />
                &nbsp;Upload a picture of your licence disc
              </Fab>

              <Typography variant="h5" className={classes.registerText}>
                or register vehicle by entering the following details below
              </Typography>
            </Grid>
            <OCRUpload
              file={file}
              openDialog={openDialog}
              setFile={setFile}
              setOpenDialog={setOpenDialog}
              handleClickOpen={handleViewUpload}
              updateOCRForm={updateOCRForm}
            />
            <Controller
              name="make"
              render={({ onChange, ...props }) => (
                <Autocomplete
                  freeSolo
                  autoSelect
                  className={classes.autocomplete}
                  classes={{
                    listbox: classes.autocomplete,
                    popper: classes.popper,
                  }}
                  options={listcarmakes}
                  {...props}
                  onChange={(e, data) => onChange(data)}
                  renderInput={(params) => (
                    <>
                      <span className={classes.controllerText}>
                        <TextField
                          {...params}
                          label="Vehicle make"
                          margin="normal"
                          color="secondary"
                          className={classes.autoCompleteInput}
                          placeholder="Select vehicle make"
                          error={Boolean(errors.make)}
                          helperText={errors.make && errors.make.message}
                        />
                      </span>
                      <div className={classes.iconBox}>
                        <HelpOutline
                          fontSize="large"
                          onClick={() => handleClickOpen(ShowMoreInfo.makeInfo)}
                        />
                      </div>
                    </>
                  )}
                />
              )}
              defaultValue=""
              control={control}
            />

            <Controller
              className={classes.controllerText}
              as={
                <TextField
                  margin="dense"
                  fullWidth
                  color="secondary"
                  placeholder="Vehicle Model"
                  label="Vehicle Model"
                  InputProps={{
                    className: classes.textField,
                  }}
                  error={Boolean(errors.model)}
                  helperText={errors.model && errors.model.message}
                />
              }
              name="model"
              control={control}
            />
            <div className={classes.iconBox}>
              <HelpOutline
                fontSize="large"
                onClick={() => handleClickOpen(ShowMoreInfo.modelInfo)}
              />
            </div>
            <Controller
              className={classes.controllerText}
              as={
                <TextField
                  margin="dense"
                  fullWidth
                  color="secondary"
                  placeholder="Vehicle Year"
                  label="Vehicle Year"
                  InputProps={{
                    className: classes.textField,
                  }}
                  error={Boolean(errors.year)}
                  helperText={errors.year && errors.year.message}
                />
              }
              name="year"
              control={control}
            />
            <div className={classes.iconBox}>
              <HelpOutline
                fontSize="large"
                onClick={() => handleClickOpen(ShowMoreInfo.yearInfo)}
              />
            </div>
            <Controller
              className={classes.controllerText}
              as={
                <TextField
                  margin="dense"
                  fullWidth
                  color="secondary"
                  placeholder="Vehicle tare weight (kg)"
                  label="Vehicle tare weight (kg)"
                  type="number"
                  error={Boolean(errors.weightKg)}
                  helperText={errors.weightKg && errors.weightKg.message}
                />
              }
              name="weightKg"
              control={control}
              defaultValue=""
            />
            <div className={classes.iconBox}>
              <HelpOutline
                fontSize="large"
                onClick={() => handleClickOpen(ShowMoreInfo.weightInfo)}
              />
            </div>
            <Controller
              className={classes.controllerText}
              as={
                <TextField
                  margin="dense"
                  fullWidth
                  color="secondary"
                  placeholder="License number"
                  label="License number"
                  InputProps={{
                    className: classes.textField,
                  }}
                  error={Boolean(errors.licenseNumber)}
                  helperText={
                    errors.licenseNumber && errors.licenseNumber.message
                  }
                />
              }
              name="licenseNumber"
              control={control}
            />
            <div className={classes.iconBox}>
              <HelpOutline
                fontSize="large"
                onClick={() => handleClickOpen(ShowMoreInfo.licenseNumberInfo)}
              />
            </div>
            <Controller
              name="vehicleType"
              className={classes.controllerText}
              as={
                <TextField
                  select
                  label={vehicleType ? 'Vehicle Type' : ''}
                  color="secondary"
                  margin="dense"
                  InputProps={{
                    className: classes.textField,
                  }}
                  SelectProps={{
                    displayEmpty: true,
                    MenuProps: {
                      anchorOrigin: {
                        vertical: 'bottom',
                        horizontal: 'left',
                      },
                      getContentAnchorEl: null,
                    },
                  }}
                  error={Boolean(errors.vehicleType)}
                  helperText={errors.vehicleType && errors.vehicleType.message}
                >
                  <MenuItem disabled value={undefined}>
                    Please select vehicle type
                  </MenuItem>
                  {vehicleTypeOptions.map((option) => {
                    const { label, type } = option;
                    return (
                      <MenuItem value={type} key={type}>
                        <span className={classes.capitalize}>{label}</span>
                      </MenuItem>
                    );
                  })}
                </TextField>
              }
              onChange={(obj: any) => obj[1].props.value}
              control={control}
            />
            <div className={classes.iconBox}>
              <HelpOutline
                fontSize="large"
                onClick={() => handleClickOpen(ShowMoreInfo.vehicleType)}
              />
            </div>

            <Controller
              className={classes.controllerText}
              as={
                <TextField
                  margin="dense"
                  fullWidth
                  color="secondary"
                  placeholder="Vehicle Register number"
                  label="Vehicle Register number"
                  InputProps={{
                    className: classes.textField,
                  }}
                  error={Boolean(errors.registerNumber)}
                  helperText={
                    errors.registerNumber && errors.registerNumber.message
                  }
                />
              }
              name="registerNumber"
              control={control}
            />
            <div className={classes.iconBox}>
              <HelpOutline
                fontSize="large"
                onClick={() =>
                  handleClickOpen(ShowMoreInfo.vehicleRegisterNumber)
                }
              />
            </div>
            <Controller
              className={classes.controllerText}
              as={
                <DatePicker
                  value={selectedDate}
                  onChange={(date) => handleDateChange(date)}
                  margin="dense"
                  label="Expiry date"
                  format="DD-MM-YYYY"
                  error={Boolean(errors.licenseExpires)}
                  helperText={
                    errors.licenseExpires && errors.licenseExpires.message
                  }
                  emptyLabel="Please select expiration date"
                />
              }
              name="licenseExpires"
              control={control}
              defaultValue={null}
            />
            <div className={classes.iconBox}>
              <HelpOutline
                fontSize="large"
                onClick={() => handleClickOpen(ShowMoreInfo.expiryDateInfo)}
              />
            </div>

            <Controller
              name="registrationRegion"
              render={({ onChange, ...props }) => (
                <Autocomplete
                  className={classes.fullWidth}
                  classes={{
                    input: classes.autoInput,
                    listbox: classes.autoInput,
                    popper: classes.popper,
                  }}
                  {...props}
                  options={regions}
                  onChange={(e, data) => onChange(data)}
                  renderInput={(params) => (
                    <>
                      <span className={classes.controllerText}>
                        <TextField
                          {...params}
                          label="Select a province"
                          color="secondary"
                          margin="normal"
                          className={classes.textField}
                          placeholder="Select a province"
                          error={Boolean(errors.registrationRegion)}
                          helperText={
                            errors.registrationRegion &&
                            errors.registrationRegion.message
                          }
                        />
                      </span>
                      <div className={classes.iconBox}>
                        <HelpOutline
                          fontSize="large"
                          onClick={() =>
                            handleClickOpen(ShowMoreInfo.provinceInfo)
                          }
                        />
                      </div>
                    </>
                  )}
                />
              )}
              control={control}
            />

            <div className={classes.submitContainer}>
              <Button
                variant="contained"
                color="primary"
                size="large"
                type="submit"
                disabled={loading}
              >
                Add vehicle
              </Button>
            </div>
          </form>

          {currentDialog && (
            <Dialog
              open={open}
              keepMounted
              onClose={handleClose}
              aria-labelledby="Show more about selected icon"
              aria-describedby="Dialog will display the relevant image, description, and text on selected icon based on Image dialog data"
              maxWidth="lg"
            >
              <DialogTitle>{currentDialog.text}</DialogTitle>
              <DialogContent>
                <DialogContentText>
                  {currentDialog.description}
                </DialogContentText>
                {currentDialog.image ? (
                  <img
                    src={currentDialog.image}
                    alt={currentDialog.text}
                    className={classes.centerImage}
                  />
                ) : null}
                <DialogContentText className={classes.centerDialogText}>
                  {currentDialog.imageText}
                </DialogContentText>

                <DialogContentText className={classes.centerDialogText}>
                  {currentDialog.lowerText ? currentDialog.lowerText : null}
                </DialogContentText>
              </DialogContent>
            </Dialog>
          )}
        </Paper>
      </Grid>
    </Content>
  );
});

export default AddVehicleForm;
