import Joi from 'joi';
import { makeObservable, observable, runInAction } from 'mobx';
import { BaseStore, RequestConfig } from '../util/baseStore';
import { IPermission, PermissionSchema } from './validation';

class V2PermissionsStore extends BaseStore {
  constructor(config: RequestConfig) {
    super(config);
    makeObservable(this, {
      permissions: observable,
    });
  }

  loading = false;
  permissions: IPermission[] = [];

  async load() {
    this.setLoading(true);

    const result = await this.sendRequest({
      method: 'GET',
      path: 'permissions',
      validation: Joi.array<IPermission[]>().items(PermissionSchema),
    });

    runInAction(() => {
      this.loading = false;
      if (result.success) {
        this.permissions = result.data;
      }
    });

    return result;
  }
}

export default V2PermissionsStore;
