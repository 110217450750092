import React from 'react';
import { observer } from 'mobx-react-lite';
import { Link, useHistory, useLocation } from 'react-router-dom';
// @material-ui
import {
  AppBar,
  Toolbar,
  Drawer,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  useMediaQuery,
  IconButton,
  Hidden,
  Menu,
  MenuItem,
  Collapse,
} from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';
// @icons
import {
  Menu as MenuIcon,
  AccountBox,
  DriveEta,
  Sync,
  AccountCircle,
  ArrowDropDown,
  ExpandLess,
  ExpandMore,
  SupervisorAccount,
} from '@material-ui/icons';
// @logic
import { useStore } from 'logic/store';
// @local
import { IUser } from 'logic/stores/users/validation';
import useStyles from './styles';

interface Props {
  children: React.ReactNode;
}

const UserDashMenu = observer(({ children }: Props) => {
  const store = useStore();
  const classes = useStyles();
  const history = useHistory();

  const { userId, user } = store.auth;

  const permissions = user ? user.permissions.map((n) => n.permission) : [];

  const isAdmin = permissions.includes('isAdmin');

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const location = useLocation();

  const openPaths =
    location.pathname.includes('/profile') ||
    location.pathname.includes('/manage-password')
      ? ['profile']
      : location.pathname.includes('/add-vehicle') ||
        location.pathname.includes('/manage-vehicles')
      ? ['vehicles']
      : [];

  const [drawerOpen, setDrawerOpen] = React.useState(false);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [name, setName] = React.useState('');
  const [openItems, setOpenItems] = React.useState<string[]>(openPaths);

  const logout = () => {
    setAnchorEl(null);
    store.auth.logout();
    history.replace('/');
  };

  const storeUserName = (storeUser: IUser) => {
    setName(storeUser.firstName);
  };

  // Grab the User from User Actions
  React.useEffect(() => {
    if (userId != null) {
      store.users.getById(userId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userId]);

  React.useEffect(() => {
    if (user != null) {
      // grab the user and set the state
      storeUserName(user);
    }
  }, [user]);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleDrawerToggle = () => {
    setDrawerOpen(!drawerOpen);
  };

  const handleOpenMenuItems = (menuItem: string) => (event: any) => {
    const current = openItems.find((n) => n === menuItem);
    if (current) {
      setOpenItems(openItems.filter((n) => n !== menuItem));
    } else {
      setOpenItems([...openItems, menuItem]);
    }
  };

  return (
    <div>
      <Hidden mdUp>
        <AppBar className={classes.whiteBackground} position="static">
          <Toolbar>
            <div className={classes.mobileAppbar}>
              <IconButton className={classes.blackIcon}>
                <MenuIcon onClick={handleDrawerToggle} />
              </IconButton>
              <Link to="/">
                <img
                  src={store.companies.company.logoUrl}
                  className={classes.appbarLogoMobile}
                  alt="APP_LOGO"
                />
              </Link>
              <IconButton onClick={handleClick}>
                <AccountCircle fontSize="large" />
              </IconButton>
            </div>
            <Menu
              anchorEl={anchorEl}
              keepMounted
              open={Boolean(anchorEl)}
              onClose={handleClose}
            >
              <MenuItem>
                <Link to="/profile" className={classes.linkMenu}>
                  Profile
                </Link>
              </MenuItem>
              {!store.whitelabel && (
                <MenuItem>
                  <Link to="faq" className={classes.linkMenu}>
                    FAQ
                  </Link>
                </MenuItem>
              )}
              <ListItem button onClick={logout}>
                <ListItemText
                  primaryTypographyProps={{
                    className: classes.linkMenu,
                  }}
                  primary="Log out"
                />
              </ListItem>
            </Menu>
          </Toolbar>
        </AppBar>
      </Hidden>

      <Hidden smDown>
        <div className={` ${classes.contentContainer}`}>
          <div className={classes.navigationHeadParent}>
            <div className={classes.navigationHead}>
              <AppBar
                className={`${classes.whiteBackground} ${classes.menuMaxWidth}`}
                position="static"
              >
                <Toolbar className={`${classes.alignRight}`}>
                  <div className={classes.mobileAppbar}>
                    <div className={classes.iconBox}>
                      <IconButton
                        disableTouchRipple
                        className={classes.removeHover}
                        onClick={handleClick}
                      >
                        <AccountCircle
                          fontSize="large"
                          classes={{
                            root: classes.iconSpace,
                          }}
                        />
                        <h6 className={classes.profileText}>{name}</h6>
                        <ArrowDropDown
                          fontSize="small"
                          classes={{
                            root: classes.iconSpace,
                          }}
                        />
                      </IconButton>
                    </div>
                    <Menu
                      anchorEl={anchorEl}
                      keepMounted
                      open={Boolean(anchorEl)}
                      onClose={handleClose}
                      elevation={0}
                      PaperProps={{
                        style: {
                          paddingBottom: 'none !important',
                        },
                      }}
                      getContentAnchorEl={null}
                      anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'center',
                      }}
                      transformOrigin={{
                        vertical: 'top',
                        horizontal: 'center',
                      }}
                    >
                      <div className={classes.menuShadow}>
                        <MenuItem className={classes.styledMenuItem}>
                          <Link to="/profile" className={classes.linkMenu}>
                            Profile
                          </Link>
                        </MenuItem>

                        {!store.whitelabel && (
                          <MenuItem className={classes.styledMenuItem}>
                            <Link to="/faq" className={classes.linkMenu}>
                              FAQ
                            </Link>
                          </MenuItem>
                        )}
                        <ListItem button onClick={logout}>
                          <ListItemText
                            primaryTypographyProps={{
                              className: classes.linkMenu,
                            }}
                            primary="Log out"
                          />
                        </ListItem>
                      </div>
                    </Menu>
                  </div>
                </Toolbar>
              </AppBar>
            </div>
          </div>
        </div>
      </Hidden>

      <Drawer
        variant={isMobile ? 'temporary' : 'permanent'}
        open={drawerOpen}
        classes={{
          paper: classes.drawerPaper,
        }}
        onClose={handleDrawerToggle}
        ModalProps={{
          keepMounted: true, // Better open performance on mobile.
        }}
      >
        <div className={classes.toolbar}>
          <div className={classes.menuItems}>
            <Link to="/" className={classes.linkStyles}>
              <span className={classes.spanFlex}>
                <div className={classes.logoContainer}>
                  <img
                    src={store.companies.company.logoUrl}
                    className={classes.appLogo}
                    alt="APP_LOGO"
                  />
                </div>
              </span>
            </Link>
            <div className={classes.menuItem}>
              <IconButton onClick={handleDrawerToggle}>
                <MenuIcon className={classes.whiteText} />
              </IconButton>
            </div>
          </div>
        </div>
        <List className={classes.listOne}>
          <ListItem
            button
            disabled={!permissions.includes('isActivatedUser')}
            onClick={handleOpenMenuItems('profile')}
            className={classes.padding10}
          >
            <ListItemIcon>
              <AccountBox fontSize="large" className={classes.icon} />
            </ListItemIcon>
            <ListItemText
              primaryTypographyProps={{
                className: classes.boldText,
              }}
              primary="My Profile"
            />
            {openItems.includes('profile') ? <ExpandLess /> : <ExpandMore />}
          </ListItem>

          <Collapse
            in={openItems.includes('profile')}
            timeout="auto"
            unmountOnExit
          >
            <List component="div" disablePadding>
              <ListItem
                component={Link}
                to="/profile"
                button
                disabled={!permissions.includes('isActivatedUser')}
                selected={location.pathname.includes('/profile')}
              >
                <ListItemText
                  className={classes.nestedText}
                  primary="Details"
                />
              </ListItem>
              <ListItem
                component={Link}
                to="/view-documents"
                button
                disabled={!permissions.includes('isActivatedUser')}
                selected={location.pathname.includes('/view-documents')}
              >
                <ListItemText
                  className={classes.nestedText}
                  primary="Documents"
                />
              </ListItem>
              <ListItem
                component={Link}
                to="/manage-password"
                button
                disabled={!permissions.includes('isActivatedUser')}
                selected={location.pathname.includes('/manage-password')}
              >
                <ListItemText
                  className={classes.nestedText}
                  primary="Password"
                />
              </ListItem>
            </List>
          </Collapse>

          <ListItem
            button
            disabled={!permissions.includes('isActivatedUser')}
            onClick={handleOpenMenuItems('vehicles')}
            className={classes.padding10}
          >
            <ListItemIcon>
              <DriveEta fontSize="large" className={classes.icon} />
            </ListItemIcon>
            <ListItemText
              primaryTypographyProps={{
                className: classes.boldText,
              }}
              primary="Vehicles"
            />
            {openItems.includes('vehicles') ? <ExpandLess /> : <ExpandMore />}
          </ListItem>

          <Collapse
            in={openItems.includes('vehicles')}
            timeout="auto"
            unmountOnExit
          >
            <List component="div" disablePadding>
              <ListItem
                component={Link}
                to="/manage-vehicles"
                button
                selected={location.pathname.includes('/manage-vehicles')}
              >
                <ListItemText
                  className={classes.nestedText}
                  primary="All Vehicles"
                />
              </ListItem>
              <ListItem
                component={Link}
                to="/add-vehicle"
                button
                selected={location.pathname.includes('/add-vehicle')}
              >
                <ListItemText
                  className={classes.nestedText}
                  primary="Add new"
                />
              </ListItem>
            </List>
          </Collapse>
        </List>

        <List className={classes.listTwo}>
          {/* <ListItem
						disabled={!permissions.includes('isActivatedUser')}
						component={Link}
						to="/"
						button
						className={`${classes.padding10}`}
					>
						<ListItemIcon>
							<History
								fontSize="large"
								className={classes.icon}
							/>
						</ListItemIcon>
						<ListItemText
							primaryTypographyProps={{
								className: classes.boldText,
							}}
							primary="History"
						/>
					</ListItem> */}
          <ListItem
            component={Link}
            to="/manage-renewals"
            disabled={!permissions.includes('isActivatedUser')}
            button
            className={`${classes.padding10}`}
            selected={location.pathname.includes('/manage-renewals')}
          >
            <ListItemIcon>
              <Sync fontSize="large" className={classes.icon} />
            </ListItemIcon>
            <ListItemText
              primaryTypographyProps={{
                className: classes.boldText,
              }}
              primary="Renewals"
            />
          </ListItem>
          {isAdmin ? (
            <ListItem
              component={Link}
              to="/admin"
              disabled={!permissions.includes('isActivatedUser')}
              button
              className={`${classes.padding10}`}
              selected={location.pathname.includes('/admin-manage-renewals')}
            >
              <ListItemIcon>
                <SupervisorAccount fontSize="large" className={classes.icon} />
              </ListItemIcon>
              <ListItemText
                primaryTypographyProps={{
                  className: classes.boldText,
                }}
                primary="Admin Menu"
              />
            </ListItem>
          ) : null}
        </List>
      </Drawer>
      <main className={classes.content}>{children}</main>
    </div>
  );
});

export default UserDashMenu;
