import React from 'react';
// @local
import { useDropzone, DropEvent, FileRejection } from 'react-dropzone';
import { toast } from 'react-toastify';
// @material-ui
import { Typography, Paper, Grid, Button, RootRef } from '@material-ui/core';
// @icons
import { CloudUpload as UploadIcon } from '@material-ui/icons';
// @local
import useStyles from './styles';

interface Props {
  file: File | null;
  setFile: React.Dispatch<React.SetStateAction<File | null>>;
  message: string;
  next: () => void;
  HelpDialog?: React.FC;
}

const UploadDocuments = ({ setFile, message, next, HelpDialog }: Props) => {
  const classes = useStyles();

  const uploadConfig = {
    accept: 'image/jpeg, image/jpg, image/png, application/pdf',
    maxSize: 5 * 1000000,
    onDropRejected: (fileRejections: FileRejection[], event: DropEvent) => {
      fileRejections.forEach((element: FileRejection) => {
        element.errors.forEach((errors: { code: string; message: string }) => {
          toast.error(
            `${errors.code} ${errors.message.replace(
              '5000000 bytes',
              '5 MegaBytes',
            )}`,
          );
        });
      });
    },
    onDropAccepted: (file: File[]) => {
      const reader = new FileReader();
      const newFile = file[0];

      reader.onloadend = () => {
        const binaryStr = reader.result;
        setUploadFile(binaryStr);
        setFile(newFile);
      };
      reader.readAsDataURL(newFile);
      toast.success('File Accepted.');
    },
  };

  const [file, setUploadFile] = React.useState<string | ArrayBuffer | null>(
    null,
  );

  const { getRootProps, getInputProps, isDragActive } =
    useDropzone(uploadConfig);

  const { ref, ...rootProps } = getRootProps();

  const handleNext = () => {
    next();
  };

  const handleReselect = () => {
    if (file) {
      setUploadFile(null);
    }
  };

  return (
    <Grid container alignContent="flex-start" spacing={2} justify="center">
      <Grid item md={12} xs={12} sm={12}>
        {file != null ? (
          <Paper elevation={2} className={classes.paperNoHover}>
            <Grid container alignItems="center" justify="center">
              <Grid item xs={12} md={12} className={classes.gridCenter}>
                <Typography align="center" variant="h6">
                  {message}
                </Typography>
              </Grid>
              <Grid item md={12} xs={12} className={classes.centerWidth}>
                {(file as string).includes('application/pdf') &&
                typeof file === 'string' ? (
                  <embed
                    height="100%"
                    width="100%"
                    title={message}
                    src={file}
                  />
                ) : (
                  <img
                    className={classes.img150}
                    src={file as string}
                    alt={message}
                  />
                )}
              </Grid>
              <Grid item container xs={12} md={12} className={classes.spread}>
                <Grid xs={12} md={6} className={classes.gridCenter}>
                  <Button onClick={handleReselect}>Reselect</Button>
                </Grid>
                <Grid xs={12} md={6} className={classes.gridCenter}>
                  <Button
                    color="primary"
                    variant="contained"
                    onClick={handleNext}
                  >
                    Complete Upload
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </Paper>
        ) : null}

        <Paper elevation={2} hidden={file != null}>
          <Grid container alignItems="center" justify="center">
            {HelpDialog ? (
              <Grid item md={12} className={classes.gridFlexEnd}>
                <HelpDialog />
              </Grid>
            ) : null}
            <Grid container alignItems="center" justify="center">
              <RootRef rootRef={ref}>
                <Paper elevation={0} {...rootProps} className={classes.paper}>
                  <Grid container alignItems="center" justify="center">
                    <Grid item md={1}>
                      <UploadIcon
                        color="primary"
                        className={classes.uploadIcon}
                      />
                    </Grid>
                    <Grid item xs={12} md={12} className={classes.gridCenter}>
                      <Typography align="center" variant="h6">
                        {message}
                      </Typography>
                      <input {...getInputProps()} />
                      {isDragActive ? (
                        <Typography variant="overline" align="center">
                          Drop the files here ...
                        </Typography>
                      ) : (
                        <Typography variant="overline" align="center">
                          Drag and drop a copy of your document here, or click
                          to select it
                        </Typography>
                      )}
                    </Grid>
                    <Grid item xs={12} md={12} className={classes.center}>
                      <Button color="primary">Select Document</Button>
                    </Grid>
                  </Grid>
                </Paper>
              </RootRef>
            </Grid>
          </Grid>
        </Paper>
      </Grid>
    </Grid>
  );
};

export default UploadDocuments;
