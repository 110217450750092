import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';

export default makeStyles((theme: Theme) =>
  createStyles({
    contentContainer: {
      background: 'transparent',
      height: '100%',
    },
    alertSpaceBelow: {
      marginBelow: '20px !important',
    },
    textInline: {
      display: 'inline',
      marginLeft: '5px',
      color: theme.palette.text.primary,
    },
    actionMargin: {
      margin: '10px',
    },
    btnMarginButton: {
      marginBottom: '10px',
    },
    textMarginTop: {
      marginTop: '15px',
    },
    renewalDialog: {
      paddingTop: '0',
      height: '100vh',
    },
    mobileSpacingTop: {
      [theme.breakpoints.down('sm')]: {
        // spacing on mobile
        marginTop: '20px',
      },
    },
    inline: {
      display: 'inline',
      marginLeft: '1px',
    },
    container: {
      paddingLeft: '40px',
      paddingRight: '40px',
      width: '100%',
      background: 'transparent',
      margin: '0 auto',
    },
    gridLeft: {
      padding: '2em',
      paddingLeft: '0',
    },
    textField: {
      fontSize: '1.2em',
      background: 'transparent',
    },
    renewalText: {
      color: '#757575',
      fontWeight: 'bold',
    },
    controllerText: {
      width: '90%',
      margin: '1.2em',
      display: 'inline-flex',
      [theme.breakpoints.down('md')]: {
        margin: '0.5em',
        width: '70%',
      },
    },
    gridNoPadding: {
      padding: '0',
    },
    gridNoVertPadding: {
      paddingTop: '0 !important',
      paddingBottom: '0 !important',
    },
    tabsContainer: {},
    cardsSection: {
      paddingBottom: '40px !important',
    },
    grid40Padding: {
      paddingTop: '40px',
      paddingLeft: '40px',
      paddingRight: '40px',
    },
    submitContainer: {
      display: 'flex',
      justifyContent: 'center',
      marginTop: '10px',
    },
    marginRight: {
      marginRight: '2px',
    },
    paper: {
      padding: '6px 16px',
    },
    leftAlign: {
      textAlign: 'left',
      display: 'block',
    },
    renewalStatus: {
      textTransform: 'capitalize',
    },
    centerIcon: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    bold: {
      fontStyle: 'bold',
    },
    paperGrid: {
      padding: theme.spacing(1),
      paddingTop: '15px',
      paddingBottom: '15px',
      backgroundColor: '#fafafa',
      textAlign: 'center',
      color: theme.palette.text.secondary,
    },
    secondaryTail: {
      backgroundColor: theme.palette.secondary.main,
    },
    upperCase: {
      textTransform: 'uppercase',
    },
    spaceBottom: {
      marginBottom: '30px',
    },
    spaceBottom10: {
      marginBottom: '5px',
    },
    gridLessPadding: {
      padding: '20px !important',
    },
    centerDocuments: {
      margin: '0 auto',
    },
    root: {
      padding: '20px 0px 0px',
    },
    gridBackground: {
      backgroundColor: '#fafafa',
    },
    tabHeight: {
      maxHeight: '50vh',
      overflowY: 'scroll',
    },
    gridBox: {
      borderRadius: '3px',
      color: '#757575',
    },
    btnMarginBottom: {
      marginBottom: '20px',
    },
    gridFlex: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      marginBottom: '20px',
    },
    adminMapBottom: {
      marginBottom: '10px',
    },
    status: {
      textTransform: 'uppercase',
      fontWeight: 'bold',
    },
    danger: {
      backgroundColor: '#f5483b',
      '& span': {
        color: 'white',
      },
      '&:hover': {
        backgroundColor: '#fc5347',
      },
    },
    success: {
      backgroundColor: '#4BB543',
      '& span': {
        color: 'white',
      },
      '&:hover': {
        backgroundColor: '#54bf4d',
      },
    },
    greenChip: {
      color: '#51cb8e',
    },
    full100: {
      width: '100%',
    },
    userSecondaryText: {
      color: theme.palette.text.secondary,
    },
    alignRight: {
      textAlign: 'right',
      [theme.breakpoints.down('sm')]: {
        textAlign: 'left',
      },
    },
    headerMargin: {
      marginBottom: '1%',
    },
    blueText: {
      color: '#81BCFF',
    },
    blueAvatar: {
      background: theme.palette.primary.main,
    },
    large: {
      width: theme.spacing(7),
      height: theme.spacing(7),
    },
    meduim: {
      width: theme.spacing(5),
      height: theme.spacing(5),
    },
    margin10: {
      margin: '10px',
    },
    avatarClick: {
      '&:hover': {
        cursor: 'pointer',
      },
    },
    actionsContainer: {
      border: '1px solid #eee',
      background: '#fafafa',
      marginBottom: '20px',
    },
    buttonMarginBottom: {
      marginBottom: '10px',
    },
  }),
);
