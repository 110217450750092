import React from 'react';
import moment from 'moment';
import { pathToRegexp } from 'path-to-regexp';
import { useLocation, useRouteMatch, useHistory } from 'react-router-dom';
import { observer } from 'mobx-react-lite';
// @material-ui
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
  List,
  ListItem,
  ListItemAvatar,
  Avatar,
  ListItemText,
  ListItemSecondaryAction,
  Grid,
} from '@material-ui/core';
// @icons
import { Description as DescriptionIcon } from '@material-ui/icons';
// @logic
import { useStore } from 'logic/store';
// @local
import { IVehicle } from 'logic/stores/vehicles/validation';
import useStyles from './styles';

const ExpiringReminder = observer(() => {
  const history = useHistory();
  const location = useLocation();
  const disallowedRoutes = [
    '/manage-renewals',
    '/manage-vehicles',
    '/',
    '/add-vehicle',
    '/view-renewal',
    '/verify-account',
  ];

  const pathName = location.pathname;
  const match = useRouteMatch(pathName);

  const store = useStore();

  const { auth } = store;
  const { vehicles } = store.vehicles;
  const { userId } = auth;
  const [open, setOpen] = React.useState(false);
  const classes = useStyles({});

  const adminRoutes = ['/admin', '/admin/:nextRoute*'].map((p) =>
    pathToRegexp(p),
  );

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  const filter = (result: IVehicle[]) => {
    return result.filter((element: IVehicle) => {
      const expiry = moment(element.licenseExpires);
      const currentDate = moment();
      const diff = expiry.diff(currentDate, 'days');
      return diff <= 35 && !element.inRenewal;
    });
  };

  const checkExpiry = filter(vehicles.results);

  const historyPushVehicle = (vehicleId: number) => {
    history.push(`/start-renewal/collection/${vehicleId}`);
    handleClose();
  };

  const historyPushManage = () => {
    history.push('/manage-vehicles');
    handleClose();
  };

  React.useEffect(() => {
    if (userId != null) {
      store.vehicles.getVehicles();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userId]);

  React.useEffect(() => {
    const path = match ? match.path : '';
    const check = disallowedRoutes.some((route) => route === path);
    const adminAccess = adminRoutes.some((checkRoute) => checkRoute.test(path));
    const midRenewalRegex = /\/start-renewal\/.*/;
    const hasVehicles = checkExpiry.length > 0;
    if (hasVehicles && !check && !adminAccess && !midRenewalRegex.test(path)) {
      handleClickOpen();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [vehicles.results.length]);

  return (
    <Dialog
      fullWidth
      maxWidth="md"
      open={open}
      onClose={handleClose}
      aria-labelledby="Expiry Reminer"
    >
      <DialogTitle id="Expiry Reminder Display">
        Some of your vehicles are expiring soon and need a renewal
      </DialogTitle>
      <DialogContent>
        <DialogContentText>
          Don&apos;t worry we have you Sorted!
        </DialogContentText>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <List dense>
              {checkExpiry.map((car) => (
                <>
                  <ListItem button onClick={() => historyPushVehicle(car.id)}>
                    <ListItemAvatar>
                      <Avatar>
                        <DescriptionIcon />
                      </Avatar>
                    </ListItemAvatar>
                    <ListItemText
                      className={classes.upperCase}
                      primary={car.licenseNumber}
                      secondary={car.make}
                    />
                    <ListItemSecondaryAction>
                      <Button
                        color="primary"
                        onClick={() => historyPushVehicle(car.id)}
                      >
                        Renew Vehicle
                      </Button>
                    </ListItemSecondaryAction>
                  </ListItem>
                </>
              ))}
            </List>
          </Grid>
          <Grid item xs={12}>
            <Button
              variant="contained"
              color="primary"
              fullWidth
              onClick={historyPushManage}
            >
              View All Vehicles
            </Button>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="primary" variant="outlined">
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
});

export default ExpiringReminder;
