import React from 'react';
import MaskedInput from 'react-text-mask';
// @material-ui
import { TextField, TextFieldProps } from '@material-ui/core';

interface TextMaskCustomProps {
  inputRef: (ref: HTMLInputElement | null) => void;
}

const phoneNumberMask = [
  '+',
  '2',
  '7',
  /\d/,
  /\d/,
  ' ',
  /\d/,
  /\d/,
  /\d/,
  ' ',
  /\d/,
  /\d/,
  /\d/,
  /\d/,
];

function TextMaskCustom(props: TextMaskCustomProps) {
  const { inputRef, ...other } = props;

  return (
    <MaskedInput
      {...other}
      ref={(ref: any) => {
        inputRef(ref ? ref.inputElement : null);
      }}
      guide
      keepCharPositions={false}
      mask={phoneNumberMask}
      showMask
    />
  );
}

export const transformForValidation = (inputValue: string) => {
  const numericValue = inputValue.replace(/\D/g, '');
  const formatted = numericValue.startsWith('0')
    ? `+27${numericValue.slice(1)}`
    : `+${numericValue}`;
  return formatted;
};

export const formatNumberOut = (inputValue: string) => {
  const numericValue = inputValue.replace(/\D/g, '');
  return numericValue.replace('+27', '0').replace('27', '0');
};

const PhoneInput = React.forwardRef((props: TextFieldProps, ref) => {
  const { value, onChange, ...rest } = props;

  const formatNumberIn = (inputValue: string) => {
    return inputValue.startsWith('0') ? inputValue.slice(1) : inputValue;
  };

  return (
    <div>
      <MaskedInput
        ref={ref}
        guide
        showMask
        mask={phoneNumberMask}
        keepCharPositions={false}
        value={formatNumberIn(value as string)}
        onChange={onChange}
        render={(r, p) => (
          <TextField
            fullWidth
            inputRef={r}
            color="secondary"
            variant="outlined"
            margin="normal"
            label="Contact Number"
            error={p.error}
            helperText={p.helperText}
            {...p}
          />
        )}
        {...(rest as any)}
      />
    </div>
  );
});

export default PhoneInput;
