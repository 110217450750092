import * as React from 'react';
import { Switch, withRouter, RouteComponentProps } from 'react-router-dom';
// @material-ui
import { CssBaseline } from '@material-ui/core';
import { ThemeProvider } from '@material-ui/core/styles';
// @components
import Home from 'components/Home';
import Profile from 'components/Profile';
import Contact from 'components/Contact';
import About from 'components/About';
// General
import FAQ from 'components/FAQ';
import Terms from 'components/Terms';
import ManageUsers from 'components/ManageUsers';
// General User
import ManagePayments from 'components/ManagePayments';
import ManagePassword from 'components/ManagePassword';
import AddVehicle from 'components/AddVehicle';
import ManageVehicles from 'components/ManageVehicles';
import AccountSetup from 'components/AccountSetup';
import StartRenewal, { Steps } from 'components/StartRenewal';
import RenewalResult from 'components/StartRenewal/RenewalResult';
import ViewVehicle from 'components/ViewVehicle';
import ViewDocuments from 'components/ViewDocuments';
// AUTH
import Signin from 'components/Auth/Signin';
import Signup from 'components/Auth/Signup';
import ForgotPassword from 'components/Auth/ForgotPassword';
import ResetPassword from 'components/Auth/ResetPassword';
import AccountCreated from 'components/Auth/AccountCreated';
// User Verification
import ResendVerification from 'components/Auth/ResendVerification';
// Layouts
import WithLayout from 'components/Layout/WithLayout';
import WithFooter from 'components/Layout/Main/WithFooter';
import AdminDashboard from 'components/Layout/AdminDash';
import UserDashMenu from 'components/Layout/UserDash';
// Admin
import LicenseFees from 'components/LicenseFees';
import Renewals from 'components/Renewals';
import ManagedVariables from 'components/ManagedVariables';
import ViewUser from 'components/ViewUser';
import ViewRenewal from 'components/ViewRenewal';
// UI UTILS
import ScrollToTop from 'components/utilities/ScrollToTop';
import NotFound from 'components/NotFound';
import ExpiryReminder from 'components/ExpiryReminder';

// Themes
import { useStore } from 'logic/store';
import createTheme from 'theme';
import Validate from './Validate';

const initialTheme = createTheme();

const App = ({ location }: RouteComponentProps) => {
  const store = useStore();

  const [theme, setTheme] = React.useState(initialTheme);

  React.useEffect(() => {
    setTheme(createTheme(store.companies.company));
  }, [store.companies.company]);

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <ExpiryReminder />
      <ScrollToTop />
      <Validate>
        <Switch location={location}>
          <WithLayout exact path="/" layout={WithFooter} component={Home} />
          <WithLayout path="/contact" component={Contact} layout={WithFooter} />
          <WithLayout path="/faq" layout={WithFooter} component={FAQ} />
          <WithLayout path="/signup" layout={WithFooter} component={Signup} />
          <WithLayout path="/login" layout={WithFooter} component={Signin} />
          <WithLayout
            path="/recover-account"
            layout={WithFooter}
            component={ForgotPassword}
          />
          <WithLayout
            path="/resend-verification"
            component={ResendVerification}
            layout={WithFooter}
          />
          <WithLayout
            path="/verify-account/:token?"
            component={AccountCreated}
            layout={WithFooter}
          />
          <WithLayout
            path="/password-reset/:token"
            component={ResetPassword}
            layout={WithFooter}
          />
          <WithLayout path="/about" component={About} layout={WithFooter} />
          <WithLayout path="/terms" component={Terms} layout={WithFooter} />
          <WithLayout
            path="/payments"
            component={ManagePayments}
            layout={WithFooter}
          />
          {/* Logged In User */}
          <WithLayout
            path="/profile"
            view="authenticated"
            layout={UserDashMenu}
            component={Profile}
          />
          <WithLayout
            path="/manage-password"
            view="authenticated"
            layout={UserDashMenu}
            component={ManagePassword}
          />
          <WithLayout
            path="/account-setup"
            view="authenticated"
            layout={UserDashMenu}
            component={AccountSetup}
          />
          <WithLayout
            path="/view-documents"
            view="authenticated"
            layout={UserDashMenu}
            component={ViewDocuments}
          />
          {/* Activated User */}
          <WithLayout
            path="/add-vehicle"
            view="authenticated"
            layout={UserDashMenu}
            component={AddVehicle}
          />
          <WithLayout
            path="/manage-vehicles"
            view="authenticated"
            layout={UserDashMenu}
            component={ManageVehicles}
          />
          <WithLayout
            view="authenticated"
            path="/manage-renewals"
            layout={UserDashMenu}
            component={Renewals}
          />
          <WithLayout
            path="/start-renewal/collection/:vehicleId"
            view="authenticated"
            component={StartRenewal}
            layout={UserDashMenu}
            childProps={{ step: Steps.DeliveryAddressConfirmation }}
          />
          <WithLayout
            path="/start-renewal/renewal-quote/:vehicleId"
            view="authenticated"
            component={StartRenewal}
            layout={UserDashMenu}
            childProps={{ step: Steps.LicenceRenewalQuoteConfimation }}
          />
          <WithLayout
            path="/start-renewal/payment/:vehicleId"
            view="authenticated"
            component={StartRenewal}
            layout={UserDashMenu}
            childProps={{ step: Steps.Payment }}
          />
          <WithLayout
            path="/start-renewal/result/:vehicleId"
            view="authenticated"
            component={RenewalResult}
            layout={UserDashMenu}
          />
          <WithLayout
            path="/view-vehicle/:id"
            view="authenticated"
            layout={UserDashMenu}
            component={ViewVehicle}
          />
          <WithLayout
            exact
            view="authenticated"
            path="/view-renewal/:id"
            layout={UserDashMenu}
            component={ViewRenewal}
          />
          {/* Admin */}
          <WithLayout
            exact
            path="/admin"
            view="admin"
            layout={AdminDashboard}
            component={ManageUsers}
          />
          <WithLayout
            exact
            path="/admin/manage-users/:id?"
            view="admin"
            layout={AdminDashboard}
            component={ManageUsers}
          />
          <WithLayout
            exact
            path="/admin/payments"
            view="admin"
            layout={AdminDashboard}
            component={ManagePayments}
          />
          <WithLayout
            exact
            path="/admin/tare-pricing"
            view="admin"
            layout={AdminDashboard}
            component={LicenseFees}
          />
          <WithLayout
            exact
            path="/admin/manage-vehicles"
            view="admin"
            layout={AdminDashboard}
            component={ManageVehicles}
          />
          <WithLayout
            exact
            path="/admin/manage-my-vehicles"
            view="admin"
            layout={AdminDashboard}
            component={ManageVehicles}
          />
          <WithLayout
            exact
            view="admin"
            path="/admin/manage-my-renewals"
            layout={AdminDashboard}
            component={Renewals}
          />
          <WithLayout
            exact
            view="admin"
            path="/admin/user-renewals"
            layout={AdminDashboard}
            component={Renewals}
          />
          <WithLayout
            exact
            view="admin"
            path="/admin/user-active-renewals"
            layout={AdminDashboard}
            component={Renewals}
          />
          <WithLayout
            exact
            view="admin"
            path="/admin/manage-variables"
            layout={AdminDashboard}
            component={ManagedVariables}
          />
          <WithLayout
            path="/admin/add-vehicle"
            view="admin"
            layout={AdminDashboard}
            component={AddVehicle}
          />
          <WithLayout
            exact
            view="admin"
            path="/admin/profile"
            layout={AdminDashboard}
            component={Profile}
          />
          <WithLayout
            exact
            view="admin"
            path="/admin/view-user/:userId"
            layout={AdminDashboard}
            component={ViewUser}
          />
          <WithLayout
            exact
            view="admin"
            path="/admin/view-renewal/:id"
            layout={AdminDashboard}
            component={ViewRenewal}
          />
          <WithLayout
            exact
            view="admin"
            path="/admin/start-renewal/:id"
            layout={AdminDashboard}
            component={StartRenewal}
          />
          <WithLayout
            exact
            view="admin"
            path="/admin/view-vehicle/:id"
            layout={AdminDashboard}
            component={ViewVehicle}
          />

          {/* Not Found */}
          <WithLayout path="*" layout={WithFooter} component={NotFound} />
        </Switch>
      </Validate>
    </ThemeProvider>
  );
};

export default withRouter(App);
