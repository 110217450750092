import React, { useState } from 'react';
import { observer } from 'mobx-react-lite';
// @material-ui
import { Button, TextField, CircularProgress } from '@material-ui/core';
// @form
import { useForm, Controller } from 'react-hook-form';
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
// @logic
import { useStore } from 'logic/store';
import { emailValidation } from 'logic/validation';
// @local
import useStyles from './style';

interface IPasswordEmailProps {
  action(values: FormValues): Promise<void>;
}

interface FormValues {
  email: string;
}

const validationSchema = Yup.object().shape({
  email: emailValidation.required(),
});

const PasswordEmail = observer<IPasswordEmailProps>((props) => {
  const store = useStore();
  const classes = useStyles({});

  const { handleSubmit, errors, control } = useForm<FormValues>({
    resolver: yupResolver(validationSchema),
  });

  return (
    <form onSubmit={handleSubmit(props.action)}>
      <Controller
        as={
          <TextField
            fullWidth
            margin="dense"
            label="Email"
            color="secondary"
            InputProps={{ className: classes.textDark }}
            placeholder="Email"
            type="email"
            error={Boolean(errors.email)}
            helperText={errors.email && errors.email.message}
          />
        }
        name="email"
        control={control}
      />
      <div className={classes.submitContainer}>
        <Button
          fullWidth
          disabled={store.auth.loading}
          className={classes.btnBlue}
          size="large"
          type="submit"
        >
          {store.auth.loading ? (
            <CircularProgress color="secondary" />
          ) : (
            `Let's Go`
          )}
        </Button>
      </div>
    </form>
  );
});

export default PasswordEmail;
