import React from 'react';
import swal from '@sweetalert/with-react';
// @material-ui
import {
  Grid,
  List,
  ListItem,
  ListItemAvatar,
  Avatar,
  ListItemText,
  ListItemSecondaryAction,
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  Paper,
  Button,
  DialogActions,
} from '@material-ui/core';
// @icons
import {
  Description as DocumentIcon,
  Delete as DeleteIcon,
  Photo as PhotoIcon,
} from '@material-ui/icons';
// @logic
import { useStore } from 'logic/store';
// @components
import useCertaintyButtonStyles from 'components/Layout/certaintyButtonStyles';
// @local
import { IDocument } from 'logic/stores/document/validation';
import useStyles from './styles';

interface TemplateProps {
  documents: IDocument[];
  vehicleId: number;
}

const ViewDocuments = ({ documents, vehicleId }: TemplateProps) => {
  const store = useStore();
  const [open, setOpen] = React.useState(false);
  const classes = useStyles({});
  const certaintyButtonStyle = useCertaintyButtonStyles({});

  const [selectedDocument, setSelectedDocument] =
    React.useState<IDocument | null>(null);

  const handleClickOpen = (document: IDocument) => {
    setOpen(true);
    setSelectedDocument(document);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const removeDocument = (documentId: number) => {
    swal({
      title: 'Are you sure?',
      text: 'Once deleted, you will not be able to recover this file!',
      icon: 'warning',
      buttons: {
        cancel: true,
        catch: {
          text: 'Yes, I want to delete this.',
          value: 'confirm',
          className: certaintyButtonStyle.dangerRed,
        },
      },
      dangerMode: true,
    }).then(async (willDelete: string) => {
      if (willDelete === 'confirm') {
        try {
          await store.documents.remove(documentId);
          await store.vehicles.loadVehicle(vehicleId);
          swal('Selected Document has been removed', {
            icon: 'success',
          });
        } catch (err) {
          swal('error', 'Failed Remove Document', 'error');
        }
      }
    });
  };

  return (
    <Grid container>
      <Grid item xs={12}>
        <div>
          <List>
            {documents.map((element: IDocument) => (
              <ListItem
                button
                key={element.filePath}
                onClick={() => handleClickOpen(element)}
              >
                <ListItemAvatar>
                  <Avatar
                    className={
                      element.documentType === 'application/pdf'
                        ? classes.blackAvatar
                        : classes.blueAvatar
                    }
                  >
                    {element.fileType === '.pdf' ? (
                      <DocumentIcon />
                    ) : (
                      <PhotoIcon />
                    )}
                  </Avatar>
                </ListItemAvatar>
                <ListItemText
                  primary={element.filename}
                  secondary={element.fileType}
                />
                <ListItemSecondaryAction>
                  <IconButton
                    edge="end"
                    onClick={() => removeDocument(element.id)}
                  >
                    <DeleteIcon />
                  </IconButton>
                </ListItemSecondaryAction>
              </ListItem>
            ))}
          </List>
        </div>
      </Grid>
      {selectedDocument != null ? (
        <Dialog fullWidth maxWidth="lg" open={open} onClose={handleClose}>
          <DialogTitle>Document Name: {selectedDocument.filename}</DialogTitle>

          <DialogContent>
            <DialogContentText>
              Document type: {selectedDocument.documentType}
            </DialogContentText>

            <Paper elevation={0}>
              {selectedDocument != null ? (
                <Grid item md={12} xs={12} className={classes.documentText}>
                  {selectedDocument.filePath.endsWith('.pdf') ? (
                    <>
                      <embed
                        height="400px"
                        width="100%"
                        title="Vehicle Documents"
                        src={selectedDocument.filePath}
                      />
                    </>
                  ) : (
                    <img
                      className={classes.imageFullWidth}
                      src={selectedDocument.filePath as string}
                      alt="Vehicle Documents"
                    />
                  )}
                </Grid>
              ) : null}
            </Paper>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose} color="primary">
              Close
            </Button>
          </DialogActions>
        </Dialog>
      ) : null}
    </Grid>
  );
};

export default ViewDocuments;
