import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';

const drawerWidth = 300;

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      display: 'flex',
    },
    containerMain: {
      textAlign: 'center',
      display: 'flex',
      [theme.breakpoints.down('sm')]: {
        margin: 'auto',
        marginTop: '0',
      },
    },
    alignRight: {
      marginTop: '-14px',
    },
    contentContainer: {
      [theme.breakpoints.up('lg')]: {
        maxWidth: '1476px',
        margin: '0 auto',
      },
    },
    nestedText: {
      paddingLeft: '5.5em',
    },
    selectedGrey: {
      backgroundColor: '#EBEBEB',
    },
    nestedMenu: {
      display: 'flex',
      flexDirection: 'column',
      paddingTop: '0',
    },
    removePaddingBottom: {
      paddingBottom: '0',
    },
    header: {
      position: 'absolute',
      top: 0,
      width: '100%',
      zIndex: 21,
      [theme.breakpoints.down('sm')]: {
        position: 'relative',
        top: 0,
      },
    },
    siteLink: {
      color: 'white',
      paddingBottom: '0',
      textDecoration: 'none',
    },
    siteNotice: {
      background: theme.palette.secondary.main,
      color: '#fff',
      textAlign: 'center',
      padding: '10px',
      position: 'fixed',
      width: '100%',
      top: '0',
      zIndex: 20,
      [theme.breakpoints.down('sm')]: {
        position: 'relative !important',
      },
    },
    boldText: {
      fontWeight: 500,
      color: '#444',
    },
    nestedItemLink: {
      textDecoration: 'none',
      width: '100%',
      '&:hover': {
        opacity: '1',
      },
    },
    siteNoticeHead: {
      textAlign: 'center',
      [theme.breakpoints.up('lg')]: {
        marginTop: '50px',
      },
    },
    padding10: {
      padding: '1em',
      paddingLeft: '2.2em',
    },
    linkStyles: {
      textDecoration: 'none',
      width: '100%',
    },
    spanFlex: {
      display: 'flex',
      justifyContent: 'center',
      flexDirection: 'row',
      alignItems: 'center',
    },
    nestedItemParent: {
      paddingLeft: '5.5em',
    },
    selectedBlue: {
      backgroundColor: '#64abfb',
    },
    mobileAppbar: {
      width: '500px',
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-around',
      alignItems: 'center',
      overflow: 'none',

      [theme.breakpoints.down('lg')]: {
        overflow: 'none',
        width: '100%',
      },

      [theme.breakpoints.up('lg')]: {
        width: 'auto',
      },
    },
    iconBox: {
      transition: 'ease 0.4s',
      '&:hover': {
        background: '#F5F5F5',
      },
    },
    iconSpace: {
      marginRight: '10px',
    },
    profileText: {
      margin: '0',
      color: 'rgba(0, 0, 0, 0.87)',
      fontWeight: 500,
    },

    removeHover: {
      textAlign: 'left',
      '&:hover': {
        backgroundColor: 'transparent',
      },
      '&:focus': {
        backgroundColor: 'transparent',
        transition: 'none',
      },
    },
    styledMenuItem: {
      padding: 'none !important',
      backgroundColor: 'transparent',
      '& .MuiListItemIcon-root, & .MuiListItemText-primary': {
        color: theme.palette.common.white,
      },
    },
    whiteBackground: {
      background: 'white',
      boxShadow: 'none',
    },
    logoContainer: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      height: '100px',
      width: '100%',
      margin: '20px 20px 0px 20px',
      [theme.breakpoints.down('md')]: {
        overflow: 'hidden',
        margin: '5px',
      },
    },
    appLogo: {
      display: 'block',
      maxWidth: '100%',
      maxHeight: '100%',
      width: 'auto',
      height: 'auto',
    },
    appbarLogo: {
      marginLeft: '-58px',
      height: '40px',
    },
    appbarLogoMobile: {
      marginLeft: 'auto',
      height: '40px',
    },
    blackIcon: {
      color: 'black',
    },

    appBar: {
      zIndex: theme.zIndex.drawer + 1,
      padding: 'unset',
      transition: theme.transitions.create(['width', 'margin'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
    },
    appBarShift: {
      marginLeft: drawerWidth,
      width: `calc(100% - 205px)`,
      transition: theme.transitions.create(['width', 'margin'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
    },
    menuButton: {
      marginRight: 36,
    },

    listOne: {
      flexGrow: 0,
      marginBottom: '0',
      paddingBottom: '0',
    },
    listTwo: {
      flexGrow: 9,
      paddingTop: '0',
    },
    active: {
      'text-decoration': 'none',
      'font-weight': 100,
      'font-family': '"Roboto", "Helvetica", "Arial", sans-serif',
      '&:after': {
        content: '""',
        'border-bottom': `solid 2px ${theme.palette.secondary.main}`,
      },
    },
    link: {
      textDecoration: 'none',
      width: '100%',
    },
    linkMenu: {
      textDecoration: 'none',
      color: '#64abfb',
      width: '160px',
    },
    editEvent: {
      padding: '3% 10% 0% 10%',
    },
    hide: {
      display: 'none',
    },
    drawer: {
      width: drawerWidth,
      flexShrink: 0,
      whiteSpace: 'nowrap',
    },
    drawerPaper: {
      backgroundColor: '#FFFFFF',
      width: drawerWidth,
      boxShadow: '0 2px 5px 0 rgba(0,0,0,0.16), 0 2px 10px 0 rgba(0,0,0,0.12)',
      overFlow: 'none',
      [theme.breakpoints.down('sm')]: {
        overflow: 'none',
      },

      [theme.breakpoints.down('md')]: {
        overflow: 'none',
        width: drawerWidth - 60,
      },
    },
    menuShadow: {
      boxShadow: '0 2px 5px 0 rgba(0,0,0,0.16), 0 2px 10px 0 rgba(0,0,0,0.12)',
    },
    drawerOpen: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
      maxWidth: 'min-content',
      top: 0,
      width: drawerWidth,
      transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
    },
    drawerClose: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
      transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      overflowX: 'hidden',
      width: theme.spacing(7) + 1,
      [theme.breakpoints.up('sm')]: {
        width: theme.spacing(9) + 1,
      },
    },
    toolbar: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      padding: theme.spacing(0, 1),
      overFlow: 'none',
      ...theme.mixins.toolbar,
    },
    content: {
      maxWidth: '100%',
      // hide any flowing content
      overflow: 'hidden',
      [theme.breakpoints.down('sm')]: {
        // some spacing on top of the views
        marginTop: '5px',
      },
      flexGrow: 1,
      [theme.breakpoints.up('md')]: {
        marginLeft: drawerWidth - 10,
      },
    },
    listItem: {
      color: '#444',
    },
    whiteText: {
      color: 'white',
    },
    divBorder: {
      borderRight: '1px solid rgba(0, 0, 0, 0.12)',
    },
    icon: {
      color: 'grey',
      marginRight: '1px',
    },
    menuItems: {
      width: '100%',
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      alignItems: 'center',
    },
    menuItem: {
      [theme.breakpoints.down('sm')]: {
        display: 'none',
        overflow: 'hidden',
      },
      [theme.breakpoints.up('md')]: {
        display: 'none',
      },
    },
    maxHead: {
      background: 'red',
    },
    navigationHead: {
      maxWidh: '3500px;',
    },
    menuMaxWidth: {
      alignItems: 'flex-end',
    },
    navigationHeadParent: {
      width: '28px',
      marginLeft: 'auto',
    },
  }),
);

export default useStyles;
