import { makeStyles, createStyles } from '@material-ui/core/styles';
import { Theme } from '@material-ui/core/styles/createMuiTheme';
import { gradient } from '../../theme';

export default makeStyles((theme: Theme) =>
  createStyles({
    contentContainer: {
      background: gradient,
      height: '100vh',
      overflowY: 'hidden',
    },
    container: {
      marginTop: '70px',
      paddingBottom: '20px',
      maxWidth: '500px',
    },
    paperPadding: {
      padding: '20px',
    },
    submitButton: {
      alignSelf: 'flex-end',
    },
    submitContainer: {
      display: 'flex',
      justifyContent: 'center',
      flexDirection: 'column',
    },
    link: {
      marginTop: theme.spacing(2),
      textDecoration: 'none',
      color: '#777',
      fontWeight: 100,
      fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
      '&:hover': {
        cursor: 'pointer',
      },
      '&:after': {
        content: '""',
        borderBottom: `solid 2px ${theme.palette.secondary.main}`,
      },
    },
    textLink: {
      textDecoration: 'none',
      color: '#777',
      fontWeight: 100,
      fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
      '&:hover': {
        cursor: 'pointer',
      },
    },
    linksGroup: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      flexDirection: 'row',
    },
    logoLink: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      marginBottom: theme.spacing(2),
      textDecoration: 'none',
      color: '#777',
      fontWeight: 100,
      fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
      '&:after': {
        content: '""',
        borderBottom: `solid 2px ${theme.palette.secondary.main}`,
      },
    },
    logo: {
      height: '60px',
    },
  }),
);
