import { makeObservable, observable, runInAction } from 'mobx';
import Joi from 'joi';
import { BaseStore, RequestConfig } from '../util/baseStore';
import {
  EventSchema,
  EventStatus,
  EventType,
  IEvent,
  PaginatedEventSchema,
} from './validation';

export type CreateEventInput = {
  eventType: EventType;
  status: EventStatus;
  description: string;
  userId: number;
  vehicleId: number;
  renewalId: number;
};

type LoadEventsInput = {
  renewalId: number;
};

class V2EventStore extends BaseStore {
  constructor(config: RequestConfig) {
    super(config);
    makeObservable(this, {
      events: observable,
    });
  }

  // State
  loading = false;
  events: IEvent[] = [];

  // Actions
  reset = () => {
    runInAction(() => {
      this.events = [];
    });
  };

  async create(input: CreateEventInput) {
    return this.withState(
      this.sendRequest({
        method: 'POST',
        path: 'events',
        validation: EventSchema,
        body: input,
      }),
    );
  }

  async load(input: LoadEventsInput) {
    return this.withState(
      this.sendRequest({
        method: 'GET',
        path: 'events',
        validation: PaginatedEventSchema,
        params: input,
      }),
    );
  }
}

export default V2EventStore;
