import React, { useState } from 'react';
import moment from 'moment';
import { observer } from 'mobx-react-lite';
import { useParams, useHistory } from 'react-router-dom';
// @material-ui
import { Typography, Grid, Button, Fade, Chip } from '@material-ui/core';
import { ArrowDownward, ArrowForwardIos } from '@material-ui/icons';
import { Alert } from '@material-ui/lab';
// @logic
import { useStore } from 'logic/store';
// @components
import Content from 'components/Layout/Content';
// @local
import EditVehicle from 'components/ManageVehicles/EditVehicle';
import { IRenewal } from 'logic/stores/renewals/validation';
import { IVehicle } from 'logic/stores/vehicles/validation';
import { IDocument } from 'logic/stores/document/validation';
import { DefaultViewProps } from 'components/Layout/WithLayout';
import ViewDocuments from './ViewDocuments';
import Dialog from './Dialog';
import useStyles from './styles';

const ViewVehicle = observer<DefaultViewProps>(({ view }) => {
  const classes = useStyles();
  const history = useHistory();
  const { id } = useParams<{ id: string }>();
  const store = useStore();

  const [openDialog, setOpenDialog] = React.useState(false);

  const [renewal, setRenewal] = React.useState<IRenewal | null>(null);
  const [documents, setDocuments] = useState<IDocument[]>([]);

  const [file, setFile] = React.useState<File | null>(null);
  const [vehicle, setVehicle] = useState<IVehicle | null>(null);
  const [viewEditDialog, setViewEditDialog] = React.useState(false);

  const editVehicleClick = () => {
    setViewEditDialog(true);
  };

  const closeEditVehicle = (v?: IVehicle) => {
    if (v) {
      setVehicle(v);
    }
    setViewEditDialog(false);
  };

  const { permissions: permissionTypes } = store.auth;

  const isAdmin = permissionTypes?.includes('isAdmin');

  const handleClickOpen = () => {
    setOpenDialog(true);
  };

  const handleDialogOpen = () => {
    setOpenDialog(!openDialog);
  };

  const startRenewal = () => {
    if (vehicle !== null) {
      if (isAdmin) {
        history.push(`/admin/start-renewal/collection/${vehicle.id}`);
      } else {
        history.push(`/start-renewal/collection/${vehicle.id}`);
      }
    }
  };

  const viewRenewal = () => {
    if (renewal) {
      if (isAdmin) {
        history.push(`/admin/view-renewal/${renewal.id}`);
      } else {
        history.push(`/view-renewal/${renewal.id}`);
      }
    }
  };

  const reload = async () => {
    if (id == null) {
      return;
    }

    const idNum = parseInt(id, 10);

    if (view === 'admin') {
      const result = await store.admin.getVehicle(idNum);
      if (result.success) {
        setVehicle(result.data);
      }

      store.documents.reset();
      const docResult = await store.documents.load({ vehicleId: idNum });
      if (docResult.success) {
        setDocuments(docResult.data);
      }

      const renewalsResult = await store.admin.getRenewal({ vehicleId: idNum });
      if (renewalsResult.success) {
        setRenewal(renewalsResult.data);
      }
    } else {
      const result = await store.vehicles.loadVehicle(idNum);
      if (result.success && result.data.count === 1) {
        setVehicle(result.data.entries[0]);
      }

      store.documents.reset();
      const docResult = await store.documents.load({ vehicleId: idNum });
      if (docResult.success) {
        setDocuments(docResult.data);
      }

      const renewalsResult = await store.renewals.getRenewals({
        vehicleId: idNum,
        skip: 0,
        take: 1,
      });
      if (renewalsResult.success) {
        setRenewal(renewalsResult.data.entries[0]);
      }
    }
  };

  React.useEffect(() => {
    reload();
  }, [id]);

  React.useEffect(() => {
    reload();
  }, [JSON.stringify(documents), id]);

  const vehicleMakeHeading = vehicle
    ? vehicle.model
      ? `${vehicle.make} - ${vehicle.model} - ${vehicle.year}`
      : vehicle.make
    : null;

  return (
    <Content className={classes.container}>
      <Grid container spacing={5}>
        <Grid item md={12} xs={12} sm={12}>
          <Grid container className={classes.infoContainer} spacing={3}>
            <Grid item md={8} xs={12} sm={7}>
              <Typography
                variant="h4"
                color="primary"
                className={classes.uppercase}
              >
                {vehicle != null ? vehicle.licenseNumber : null}
              </Typography>
              <Typography variant="h6" className={`${classes.uppercase}`}>
                {vehicleMakeHeading}
              </Typography>
            </Grid>
            <Grid item md={4} className={classes.alignGridRight}>
              <Typography variant="h6">Expiry Date</Typography>
              <Typography variant="body1">
                {vehicle
                  ? moment(vehicle.licenseExpires).format('MMMM Do YYYY')
                  : null}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
        <Grid item md={12} xs={12} sm={12}>
          <Grid container spacing={1}>
            <Grid item md={12}>
              <Typography variant="h4" color="primary">
                Details
              </Typography>
            </Grid>
            <Grid item md={6} xs={12} sm={12}>
              <Typography variant="h6">Region</Typography>
              <Typography variant="body1" className={classes.capitalizeText}>
                {vehicle != null ? vehicle.registrationRegion : null}
              </Typography>
            </Grid>
            <Grid item md={6} className={classes.defaultDeliveryType}>
              <Typography variant="h6" align="left">
                Default delivery type
              </Typography>
              <Typography
                variant="body1"
                className={classes.defaultDeliveryText}
              >
                {/* Todo: add a delivery Type */}
                Delivery
              </Typography>
            </Grid>
            <Grid item container md={12}>
              <Grid item md={12}>
                <Typography variant="h4" color="primary">
                  Current Status
                </Typography>
              </Grid>
              <Grid item md={6}>
                <Chip
                  className={classes.renewalStatus}
                  label={renewal ? renewal.status : 'not in renewal yet'}
                  color="primary"
                />
              </Grid>
              <Grid item md={6} sm={12} className={classes.defaultDeliveryType}>
                <div>
                  {vehicle != null && vehicle.inRenewal ? (
                    <Button
                      onClick={viewRenewal}
                      variant="contained"
                      endIcon={<ArrowForwardIos />}
                      className={classes.greenButton}
                    >
                      View Renewal
                    </Button>
                  ) : (
                    <Button
                      onClick={startRenewal}
                      variant="contained"
                      endIcon={<ArrowDownward />}
                      className={classes.greenButton}
                    >
                      Start Renewal
                    </Button>
                  )}
                </div>
              </Grid>
            </Grid>

            <Grid item container md={12}>
              <Grid item md={12}>
                <Typography variant="h4" color="primary">
                  Edit Vehicle
                </Typography>
              </Grid>
              <Button
                onClick={editVehicleClick}
                variant="contained"
                color="primary"
                disabled={vehicle?.archived}
                fullWidth
              >
                Click to Edit Vehicle
              </Button>
            </Grid>
          </Grid>
        </Grid>
        <Grid
          item
          xs={12}
          md={12}
          sm={12}
          className={classes.documentContainer}
        >
          <Grid container spacing={3}>
            <Grid item md={12} xs={12} sm={12}>
              <Typography variant="h4">Vehicle documents</Typography>
            </Grid>
            {documents == null || documents.length <= 0 ? (
              <Grid item xs={12} md={12} sm={12}>
                <Alert severity="info">
                  You have not uploaded custom documents for this vehicle. No
                  problem! By default we’ll use the documents associated with
                  your user account. You’ll only need to upload custom documents
                  if the vehicle you want to renew is registered in someone
                  else&apos;s name
                </Alert>
              </Grid>
            ) : null}

            <Fade in={documents != null}>
              <>
                {documents != null ? (
                  <Grid md={12} sm={12} xs={12}>
                    <ViewDocuments
                      vehicleId={parseInt(id, 10)}
                      documents={documents}
                    />
                  </Grid>
                ) : null}
              </>
            </Fade>

            <Grid item md={12} xs={12} sm={12} className={classes.fullWidth}>
              <Button
                variant="contained"
                fullWidth
                color="primary"
                onClick={handleDialogOpen}
              >
                Upload Vehicle Documents
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Dialog
        openDialog={openDialog}
        setFile={setFile}
        setOpenDialog={setOpenDialog}
        vehicle={vehicle}
        handleClickOpen={handleClickOpen}
        file={file}
      />
      {viewEditDialog && vehicle ? (
        <EditVehicle
          vehicle={vehicle}
          editId={parseInt(id, 10)}
          handleClose={closeEditVehicle}
          open={viewEditDialog}
        />
      ) : null}
    </Content>
  );
});

export default ViewVehicle;
