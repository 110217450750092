export const vehicleTypes = [
  'trailers and semi-trailers',
  'public tractors',
  'breakdown vehicles',
  'rigid vehicles',
  'motorcycles',
  'caravans',
  'other',
] as VehicleType[];

export type Region =
  | 'gauteng'
  | 'western cape'
  | 'eastern cape'
  | 'free state'
  | 'kwazulu-natal'
  | 'limpopo'
  | 'northern cape'
  | 'north-west'
  | 'mpumalanga'
  | 'unknown';

export type VehicleType =
  | 'trailers and semi-trailers'
  | 'public tractors'
  | 'breakdown vehicles'
  | 'rigid vehicles'
  | 'motorcycles'
  | 'caravans'
  | 'other';

export const vehicleTypeOptions = [
  {
    type: 'trailers and semi-trailers',
    label: 'trailer',
  },
  {
    type: 'rigid vehicles',
    label: 'car',
  },
  {
    type: 'motorcycles',
    label: 'motorcycle',
  },
  {
    type: 'caravans',
    label: 'caravan',
  },
];

export const listcarmakes = [
  'AC',
  'AC Propulsion',
  'Acura',
  'A.D. Tramontana',
  'Alfa Romeo',
  'Almac',
  'Alternative cars',
  'Amuza',
  'Anteros',
  'Arash',
  'Ariel',
  'Arrinera',
  'ASL',
  'Asterio',
  'Aston Martin',
  'Audi',
  'BAC',
  'Bajaj',
  'Beijing Automobile Works (BAW)',
  'Bentley',
  'BMW',
  'Bolloré',
  'Bolwell',
  'Brilliance / Huachen',
  'Bristol',
  'British Leyland',
  'BRM Buggy',
  'Brooke',
  'Buddy',
  'Bufori',
  'Bugatti',
  'Buick',
  'BYD',
  'Cadillac',
  'Caparo',
  'Carbontech',
  'Carice',
  "Chang'an",
  'Changhe',
  'Chery',
  'Chevrolet',
  'Chevron',
  'Citroën',
  'Chrysler',
  'Commuter cars',
  'Connaught',
  'Covini',
  'Dacia',
  'Daihatsu',
  'Datsun',
  'De la Chapelle',
  'DMC',
  'Diardi',
  'Dodge',
  'Donkervoort',
  'Dongfeng',
  'Donto',
  'DS Automobiles',
  'Dynasti Electric Car Corp.',
  'E Vade',
  'Effedi',
  'Egy Tech Engineering',
  'Electric Raceabout',
  'Elfin',
  'Emgrand',
  'Englon',
  'Eterniti',
  'Etox',
  'Equus',
  'Exagon',
  'Faralli & Mazzanti',
  'FAW',
  'Ferrari',
  'Fiat',
  'Fisker',
  'Foday',
  'Force',
  'Ford',
  'Ford Australia',
  'Ford Germany',
  'Fornasari',
  'Fraser',
  'GAC Group',
  'Galpin',
  'Geely',
  'Genesis',
  'Gibbs',
  'Gillet',
  'Ginetta',
  'GMC',
  'Gonow',
  'Great Wall / Changcheng',
  'Greentech automotive',
  'Grinnall',
  'GTA Motor',
  'Gumpert',
  'Gurgel',
  'Hennessey',
  'Hindustan',
  'Holden',
  'Honda',
  'Hongqi',
  'Hradyesh',
  'HTT Technologies',
  'Hulme',
  'Hyundai',
  'Icml',
  'IFR',
  'Iran Khodro',
  'Ikco',
  'Imperia',
  'Infiniti',
  'IVM',
  'Invicta',
  'Isdera',
  'Isuzu',
  'JAC',
  'Jaguar',
  'Jeep',
  'Jensen Motors',
  'Jetcar',
  'Jonway',
  'Joss',
  'Kaipan',
  'Kantanka',
  'Karma',
  'Koenigsegg',
  'Korres',
  'Kia',
  'Kiat',
  'Kish Khodro',
  'KTM',
  'Lada',
  'Lamborghini',
  'Lancia',
  'Land rover',
  'Landwind',
  'Laraki',
  'Leblanc',
  'Leitch',
  'Leopard',
  'Lexus',
  'li Ion',
  'Lifan',
  'Lightning',
  'Lincoln',
  'Lister',
  'Local Motors',
  'Lobini',
  'Lotec',
  'Lotus Cars',
  'Lucra Cars',
  'Luxgen',
  'Mahindra',
  'Marussia',
  'Maruti Suzuki',
  'Maserati',
  'Mastretta',
  'Mazda',
  'Mclaren',
  'Mercedes Benz',
  'MG',
  'Micro',
  'Mini',
  'Mitsubishi',
  'Mitsuoka',
  'Morgan',
  'Mullen',
  'Mycar',
  'Myvi Perodua',
  'Nissan',
  'Noble',
  'Nota',
  'Oldsmobile',
  'Opel',
  'Optimal Energy',
  'Orca',
  'Oltcit',
  'Oullim',
  'Pagani',
  'Panhard',
  'Panoz',
  'Perana',
  'Perodua',
  'Peugeot',
  'P.G.O.',
  'Polarsun',
  'Plymouth',
  'Porsche',
  'Proto',
  'Proton',
  'Puritalia',
  'Qoros',
  'Qvale',
  'Radical',
  'Reliant',
  'Renault',
  'Reva',
  'Rimac',
  'Rinspeed',
  'Roding',
  'Roewe',
  'Rolls Royce',
  'Rossin Bertin',
  'Rossion',
  'Rover',
  'Saab',
  'Saleen',
  'Saic GM Wuling',
  'Saipa',
  'Saker',
  'Samsung',
  'San',
  'Sbarro',
  'Scion',
  'Seat',
  'Shanghai Maple',
  'Sin',
  'Škoda',
  'Smart',
  'Spada Vetture Sport',
  'Spyker',
  'Ssangyong',
  'SSC North America',
  'Street & Racing Technology',
  'Subaru',
  'Suzuki',
  'Tanom',
  'Tata',
  'Tauro',
  'Tawon Car',
  'TD Cars',
  'Tesla',
  'Thai Rung',
  'Toyota',
  'Trekka',
  'Trident',
  'Triumph',
  'Troller',
  'Trumpchi',
  'Tushek',
  'TVR',
  'TVS',
  'Ultima',
  'Umm',
  'Uev',
  'Uri',
  'uUz',
  'Vauxhall Motors',
  'Vector',
  'Vencer',
  'Venirauto',
  'Venturi',
  'Vepr',
  'Vespa',
  'Volkswagen',
  'Volvo',
  'Vinfast',
  'W Motors',
  'Wallyscar',
  'Westfield',
  'Wheego',
  'Wiesmann',
  'Xenia',
  'Yes!',
  'Youabian Puma',
  'Zastava Automobiles',
  'Zender Cars',
  'Zenos Cars',
  'Zenvo',
  'Zil',
  'ZX Auto',
];
