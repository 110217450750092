import { Theme } from '@material-ui/core/styles/createMuiTheme';
import { makeStyles, createStyles } from '@material-ui/core/styles';

const styles = makeStyles((theme: Theme) =>
  createStyles({
    paper: {
      margin: 'auto',
    },
    searchBar: {
      borderBottom: '1px solid rgba(0, 0, 0, 0.12)',
    },
    searchInput: {
      fontSize: theme.typography.fontSize,
    },
    greenButton: {
      color: 'white',
      backgroundColor: '#51cb8e',
      '&:hover': {
        color: 'black',
      },
    },
    flexGrow: {
      flexGrow: 1,
    },
    block: {
      display: 'block',
    },
    contentWrapper: {
      margin: '10px',
    },
    scrollTable: {
      overflowX: 'scroll',
    },
    progress: {
      padding: theme.spacing(1),
    },
    centerProgress: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },
    rowHover: {
      '&:hover': {
        cursor: 'pointer',
      },
    },
    paginationToolbar: {
      [theme.breakpoints.down('sm')]: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-start',
      },
    },
    positionIcon: {
      position: 'absolute',
      right: '10px',
      top: '10px',
    },
    dialogBorder: {
      borderTop: 'solid 1.3px rgba(0,0,0,0.25)',
    },
    paginationActions: {
      [theme.breakpoints.down('sm')]: {
        marginLeft: '0px',
        paddingBottom: '20px',
      },
    },
  }),
);

export default styles;
