import { Theme } from '@material-ui/core/styles/createMuiTheme';
import { makeStyles, createStyles } from '@material-ui/core/styles';

const useStyle = makeStyles((theme: Theme) =>
  createStyles({
    contentContainer: {
      background: 'transparent',
      height: '100%',
      overflowY: 'hidden',
    },
    container: {
      maxWidth: '1200px !important',
      width: '100%',
      padding: '0 10px',
      background: 'transparent',
      margin: '0 auto',
      paddingBottom: '6.4rem',
      [theme.breakpoints.up('lg')]: {
        paddingBottom: '5rem',
      },
    },
    submitButton: {
      alignSelf: 'flex-end',
    },
    signUpHead: {
      textAlign: 'center',
      padding: '0 2%',
    },
    headFirst: {
      fontSize: '3.0rem',
      lineHeight: '110%',
      margin: '1.78rem 0 1.424rem 0',
    },
    headSecond: {
      fontSize: '1.6rem',
      lineHeight: '110%',
      margin: '0.82rem 0 1.656rem 0',
      fontWeight: 300,
    },
    pageTitle: {
      textAlign: 'left',
      fontSize: '2.2rem',
      fontWeight: 400,
      margin: '0.5rem 0 1.424rem 0',
    },
    removePadding: {
      paddingTop: '0 !important',
    },
    submitContainer: {
      display: 'flex',
      justifyContent: 'center',
      flexDirection: 'column',
      marginTop: '10px',
    },
    formStyle: {
      background: '#f5f5f5',
    },
    marginTop: {
      marginTop: '10px',
    },
    link: {
      marginTop: theme.spacing(2),
      textDecoration: 'none',
      color: '#777',
      fontWeight: 100,
      fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
      '&:after': {
        content: '""',
        borderBottom: `solid 2px ${theme.palette.secondary.main}`,
      },
    },
    textLink: {
      textDecoration: 'none',
      color: '#64abfb',
      fontSize: '1.2em',
      fontWeight: 100,
      fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
      '&:hover': {
        cursor: 'pointer',
      },
    },
    linksGroup: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      flexDirection: 'row',
    },
    logoLink: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      marginBottom: theme.spacing(1),
      textDecoration: 'none',
      color: '#777',
      fontWeight: 100,
      fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
      '&:after': {
        content: '""',
        borderBottom: `solid 2px ${theme.palette.secondary.main}`,
      },
    },
    logo: {
      height: '60px',
    },
    padding20: {
      padding: '20px',
    },
    inputField: {
      display: 'flex',
      padding: '20px',
      background: 'transparent',
      [theme.breakpoints.down('sm')]: {
        flexDirection: 'column',
      },
    },
    btnBlue: {
      background: theme.palette.primary.main,
      color: theme.palette.primary.contrastText,
      padding: '1.1em',
      margin: '12px auto',
      borderRadius: '3px',
      fontSize: '1.0em',
      width: '9em',
      '&:hover': {
        background: theme.palette.primary.main,
      },
    },
    textField: {
      fontSize: '16px',
      magin: '10px',
      background: 'transparent',
    },
  }),
);

export default useStyle;
