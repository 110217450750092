import React from 'react';
import { Link } from 'react-router-dom';
// @material-ui
import { Button, Typography, Grid } from '@material-ui/core';
// @components
import Content from 'components/Layout/Content';
// @local
import { useStore } from 'logic/store';
import useStyles from './styles';
import BaseFooterTopStyles from '../Layout/BaseFooterTopStyles';

const About = () => {
  const store = useStore();
  const classes = useStyles({
    company: store.companies.company,
    whitelabel: store.whitelabel,
  });
  const footerStyles = BaseFooterTopStyles({});

  const aboutHeadingStyles = [classes.centerDiv, footerStyles.headTop].join(
    ' ',
  );

  return (
    <div className={classes.cover}>
      <Grid container className={classes.darkBanner}>
        <Grid item xs="auto" className={classes.gridContent}>
          <div className={aboutHeadingStyles}>
            <Typography
              variant="h3"
              align="center"
              className={`${classes.btnDark} ${classes.padding10}`}
            >
              About us
            </Typography>

            <Typography
              variant="body1"
              align="center"
              className={`${classes.typoText} ${classes.padding10} `}
            >
              Sorted was started by founders Daniela Morris and Loyiso Bikitsha.
              They were inspired to digitise the tedious, manual licencing
              process after experiencing the frustration of dealing with these
              outdated systems themselves. Sorted offers solutions for
              individuals, dealerships and fleets to manage their systems
              online, nationwide.
            </Typography>
          </div>
        </Grid>
      </Grid>
      <Content
        className={`${footerStyles.headTop}`}
        containerClass={`${classes.containerClass} ${footerStyles.contentMidWhite}`}
      >
        <Grid item sm={12}>
          <Typography
            variant="h4"
            align="center"
            className={`${classes.typoHead} ${classes.padding10}`}
          >
            Ready to get Sorted?
          </Typography>
          <div>
            <Button component={Link} to="/signup" className={classes.btnBlue}>
              Sign Up
            </Button>
          </div>
        </Grid>
      </Content>
    </div>
  );
};

export default About;
