import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';

export default makeStyles((theme: Theme) =>
  createStyles({
    blueAvatar: {
      background: '#64abfb',
    },
    blackAvatar: {
      background: '#000',
    },
    imageFullWidth: {
      height: '100%',
      width: '100%',
    },
    documentText: {
      textAlign: 'center',
      marginTop: '15px',
    },
  }),
);
