import React, { useState } from 'react';
import swal from '@sweetalert/with-react';
import { observer } from 'mobx-react-lite';
// @material-ui
import { Grid, Paper } from '@material-ui/core';

// @logic
import { useStore } from 'logic/store';
// @components
import Content from 'components/Layout/Content';
// @local
import { useHistory } from 'react-router-dom';
import useStyles from './style';
import PasswordEmail from './PasswordEmail';
import PasswordCode from './PasswordCode';

const ForgotPassTemplate = observer(() => {
  const history = useHistory();
  const store = useStore();
  const classes = useStyles({});

  const [email, setEmail] = useState<string>();

  const sendRecoverMail = async (values: { email: string }) => {
    const res = await store.auth.requestPasswordReset(values.email);
    if (res.success === true) {
      swal('Sorted!', 'Recovery email has been sent.', 'success');
      setEmail(values.email);
    } else {
      swal(
        'Error!',
        'Unable to recover account. Please email info@sorted.co.za for help',
        'error',
      );
    }
  };

  const recover = async (values: { code: string }) => {
    if (!email) {
      return;
    }
    const res = await store.auth.recoverAccount(email, values.code);

    if (res.success === true) {
      history.push(`/password-reset/${res.data.resetToken}`);
      swal('Sorted!', 'Successful Reset Code.', 'success');
    } else {
      swal(
        'Error!',
        'Unable to recover account. Please email info@sorted.co.za for help',
        'error',
      );
    }
  };

  return (
    <Content
      className={classes.container}
      containerClass={classes.contentContainer}
      spacing={3}
    >
      <Grid className={classes.signUpHead}>
        <p className={classes.headSecond}>
          Enter your email and we&apos;ll send you reset instructions.
        </p>
      </Grid>

      <Grid item md={12} xs={12}>
        <Paper
          elevation={2}
          className={`${classes.formStyle} ${classes.padding20}`}
        >
          {!email ? (
            <PasswordEmail action={sendRecoverMail} />
          ) : (
            <PasswordCode action={recover} />
          )}
        </Paper>
      </Grid>
    </Content>
  );
});

export default ForgotPassTemplate;
