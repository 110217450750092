import React from 'react';
import moment from 'moment';
// @material-ui
import { Grid, Paper, Typography, Avatar } from '@material-ui/core';
// @icons
import { Sync, EventAvailable, DateRange } from '@material-ui/icons';
// @local
import { IRenewal } from 'logic/stores/renewals/validation';
import { StatusDict } from 'logic/stores/renewals/types';
import useStyles from '../styles';
import RenewalSteps from './RenewalSteps';

interface TemplateProps {
  renewal: IRenewal | null;
}

const RenewalCards = ({ renewal }: TemplateProps) => {
  const classes = useStyles({});
  const renewalStatusStyles = [classes.leftAlign, classes.status].join(' ');
  const renewalAvatarStyles = [classes.meduim, classes.blueAvatar].join(' ');

  return (
    <Grid
      item
      container
      md={12}
      spacing={2}
      className={classes.mobileSpacingTop}
    >
      <Grid item xs={12} md={4} className={classes.gridBox}>
        <Paper className={classes.paperGrid}>
          <Grid container>
            <Grid item md={8}>
              <Typography variant="overline" className={classes.leftAlign}>
                Expired At
              </Typography>
              <Typography variant="body1" className={renewalStatusStyles}>
                <b>
                  {renewal
                    ? moment(renewal.originalExpiry).format('DD-MM-YYYY')
                    : null}
                </b>
              </Typography>
            </Grid>
            <Grid item md={4} className={classes.centerIcon}>
              <Avatar className={renewalAvatarStyles}>
                <EventAvailable fontSize="default" />
              </Avatar>
            </Grid>
          </Grid>
        </Paper>
      </Grid>
      <Grid item xs={12} md={4} className={classes.gridBox}>
        <Paper className={classes.paperGrid}>
          <Grid container>
            <Grid item md={8}>
              <Typography variant="overline" className={classes.leftAlign}>
                Renewal Status
              </Typography>
              <Typography variant="body1" className={renewalStatusStyles}>
                <b>
                  {renewal ? (
                    <>
                      {StatusDict[renewal.status]}
                      <RenewalSteps renewal={renewal} />
                    </>
                  ) : null}
                </b>
              </Typography>
            </Grid>
            <Grid item md={4} className={classes.centerIcon}>
              <Avatar className={renewalAvatarStyles}>
                <Sync fontSize="default" />
              </Avatar>
            </Grid>
          </Grid>
        </Paper>
      </Grid>
      <Grid item xs={12} md={4} className={classes.gridBox}>
        <Paper className={classes.paperGrid}>
          <Grid container>
            <Grid item md={8}>
              <Typography variant="overline" className={classes.leftAlign}>
                Ordered on
              </Typography>
              <Typography variant="body1" className={renewalStatusStyles}>
                <b>
                  {renewal
                    ? moment(renewal.dateOrdered).format('DD-MM-YYYY')
                    : null}
                </b>
              </Typography>
            </Grid>
            <Grid item md={4} className={classes.centerIcon}>
              <Avatar className={renewalAvatarStyles}>
                <DateRange fontSize="default" />
              </Avatar>
            </Grid>
          </Grid>
        </Paper>
      </Grid>
    </Grid>
  );
};

export default RenewalCards;
