import { Theme } from '@material-ui/core/styles/createMuiTheme';
import { makeStyles, createStyles } from '@material-ui/core/styles';

const useStyle = makeStyles((theme: Theme) =>
  createStyles({
    contentContainer: {
      background: 'transparent',
      height: '100%',
      overflowY: 'hidden',
    },
    container: {
      maxWidth: '1200px !important',
      width: '100%',
      background: 'transparent',
      margin: '2em auto 0',
      paddingBottom: '6.4rem',
      [theme.breakpoints.up('lg')]: {
        paddingBottom: '5rem',
      },
      [theme.breakpoints.down('sm')]: {
        // spacing at the bottom of form for mobile
        marginBottom: '9em',
      },
    },
    removePadding: {
      [theme.breakpoints.down('sm')]: {
        paddingBottom: '2em',
      },
      [theme.breakpoints.up('lg')]: {
        paddingTop: '4em !important',
      },
      [theme.breakpoints.only('xl')]: {
        textAlign: 'left',
        width: '100%',
        paddingTop: '10em !important',
      },
    },
    submitButton: {
      alignSelf: 'flex-end',
    },
    loginButton: {
      backgroundColor: '#64abfb',
      color: 'rgba(0, 0, 0, 0.87)',
    },
    submitContainer: {
      display: 'flex',
      justifyContent: 'center',
      flexDirection: 'column',
      marginTop: '10px',
    },
    formStyle: {
      background: '#f5f5f5',
      padding: '20px',
      [theme.breakpoints.down('sm')]: {
        padding: '10px',
      },
    },
    marginTop: {
      marginTop: '10px',
    },
    link: {
      marginTop: theme.spacing(2),
      textDecoration: 'none',
      color: '#777',
      fontWeight: 100,
      fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
      '&:after': {
        content: '""',
        borderBottom: `solid 2px ${theme.palette.secondary.main}`,
      },
    },
    textField: {
      fontSize: '16px',
      magin: '10px',
      background: 'transparent',
    },
    textLink: {
      textDecoration: 'none',
      color: '#64abfb',
      fontSize: '1.5em',
      fontWeight: 100,
      fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
      '&:hover': {
        cursor: 'pointer',
      },
    },
    pageTitle: {
      textAlign: 'left',
      fontSize: '2.2rem',
      fontWeight: 400,
      margin: '0rem 0 1.424rem 0',
    },
    linksGroup: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      flexDirection: 'row',
    },
    logoLink: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      marginBottom: theme.spacing(1),
      textDecoration: 'none',
      color: '#777',
      fontWeight: 100,
      fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
      '&:after': {
        content: '""',
        borderBottom: `solid 2px ${theme.palette.secondary.main}`,
      },
    },
    logo: {
      height: '60px',
    },
    padding20: {
      padding: '20px',
    },
    btnBlue: {
      background: theme.palette.primary.main,
      color: theme.palette.primary.contrastText,
      padding: '1.1em',
      margin: '0 auto',
      borderRadius: '3px',
      fontSize: '1.0em',
      width: '9em',
    },
  }),
);

export default useStyle;
