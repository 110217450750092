import Joi from 'joi';

export type IManagedVariable = {
  id: number;
  readableName: string;
  variable: string;
  value: number;
  measuredIn: string;
  createdAt: Date;
  updatedAt: Date;
};

export const ManagedVariableSchema = Joi.object<IManagedVariable>({
  id: Joi.number().required(),
  readableName: Joi.string().required(),
  variable: Joi.string().required(),
  value: Joi.number().required(),
  measuredIn: Joi.string().required(),
  createdAt: Joi.date().required(),
  updatedAt: Joi.date().required(),
});

export type Measurements = 'zar' | 'days' | 'months' | 'kg';

export type MeasurementOptionTypes = 'days' | 'months' | 'ZAR' | 'kg';

export type ManagedVariablesTypes =
  | 'grace period'
  | 'service fee'
  | 'transaction fee'
  | 'update license fees reminder'
  | 'maximum weight'
  | 'maximum tier weight'
  | 'western cape tier fee'
  | 'eastern cape tier fee'
  | 'northern cape tier fee'
  | 'limpopo tier fee'
  | 'north west tier fee'
  | 'gauteng tier fee'
  | 'kwazulu natal tier fee'
  | 'free state tier fee'
  | 'mpumalanga tier fee';

export const managedVariables = [
  'service fee',
  'update license fees reminder',
  'grace period',
  'transaction fee',
  'maximum weight',
  'maximum tier weight',
  'western cape tier fee',
  'eastern cape tier fee',
  'northern cape tier fee',
  'limpopo tier fee',
  'north west tier fee',
  'gauteng tier fee',
  'kwazulu natal tier fee',
  'free state tier fee',
  'mpumalanga tier fee',
] as ManagedVariablesTypes[];

export const measurementOptions = [
  'days',
  'months',
  'zar',
  'kg',
] as MeasurementOptionTypes[];
