import { Theme } from '@material-ui/core/styles/createMuiTheme';
import { makeStyles, createStyles } from '@material-ui/core/styles';

import { ICompany } from 'logic/stores/company/validation';

interface Props {
  whitelabel: boolean;
  company: ICompany;
  dragActive: boolean;
}

export default makeStyles<Theme, Props>((theme: Theme) =>
  createStyles({
    bannerText: {
      fontSize: '1.4rem',
      lineHeight: '140%',
      margin: '16px',
    },
    container: {
      textAlign: 'center',
      background: 'transparent',
      height: `calc(100vh - ${theme.mixins.toolbar.minHeight}px)`,
    },
    homeOverlay: {
      height: '100%',
      width: '100%',
      opacity: 2,
    },
    homeLanding: {
      maxWidth: '2100px',
      textAlign: 'center',
      overflow: 'hidden',
      position: 'relative',
      margin: '0 auto',
      width: '100%',
      [theme.breakpoints.down('sm')]: {
        // decrease any spacing on landing page
        padding: '0 !important',
      },
    },
    homeMainImage: {
      width: '650px',
      '&:hover': {
        cursor: 'pointer',
      },
      [theme.breakpoints.down('sm')]: {
        // make width of home images fit 100% of content on paper for mobile
        width: '100%',
      },
    },
    homeLandingImg: {
      [theme.breakpoints.down('md')]: {
        maxWidth: '100%',
      },
    },
    homeDialogImage: {
      width: '650px',
      [theme.breakpoints.down('md')]: {
        maxWidth: '100%',
      },
    },

    GridImageHover: {
      '&:hover': {
        cursor: 'pointer',
      },
    },
    heroImageBack: {
      position: 'absolute',
      zIndex: 0,
    },
    heroImageBackRight: {
      marginLeft: '10%',
    },
    heroImagBbackLeft: {
      marginLeft: '-10%',
    },
    heroImage: {
      maxWidth: '67%',
      display: 'inline-block',
      position: 'relative',
    },
    cover: {
      width: '100%',
      textAlign: 'center',
      backgroundPosition: 'center',
      overflow: 'hidden',
    },
    padding10: {
      padding: '10px',
    },
    darkBanner: {
      textAlign: 'center',
      backgroundPosition: (props) => `50% ${props.whitelabel ? '75%' : '50%'}`,
      backgroundImage: (props) => `url(${props.company.backgroundUrl})`,
      backgroundRepeat: 'no-repeat',
      position: 'relative',
      padding: '10px 0px 0px',
      minHeight: 'calc(88vh)',
      backgroundAttachment: 'fixed',
      zIndex: 5,
    },
    titleP: {
      color: 'white',
      position: 'relative',
    },
    containerClass: {
      // margin: '-10px',
    },
    marginMidTop: {
      paddingTop: '21em',
    },
    btnDark: {
      color: 'black',
    },
    companyFont: {
      color: (props) => props.company.textColor,
    },
    homeMid: {
      fontSize: '2.1rem',
      lineHeight: '130%',
      margin: '1.46rem 0 1.68rem 0',
      color: 'black',
    },
    homeHead: {
      fontSize: '3.0rem',
      lineHeight: '110%',
      margin: '0 0 1.424rem 0',
    },
    homeText: {
      fontSize: '1.6rem',
      lineHeight: '140%',
      margin: '1.78rem 0 1.424rem 0',
    },
    gridMaxCenterMore: {
      width: '500px',
      margin: '0 auto',
    },
    bottomRemove: {
      margin: '0px !important',
    },
    infoGrid: {
      maxWidth: '600px',
      margin: '0 auto',
    },
    infoGridDiv: {
      maxWidth: '700px',
      margin: '0 auto',
    },
    padding7: {
      padding: '7px',
    },
    margin0: {
      margin: '0',
    },
    featureText: {
      fontWeight: 300,
      fontSize: '1.15rem',
      letterSpacing: '0.02rem',
      lineHeight: '1.72rem',
      margin: '0px 0px 1.72rem 0px',
      color: 'rgba(0,0,0,0.95)',
    },
    textSecond: {
      maxWidth: '37rem',
      marginLeft: 'auto',
      marginRight: 'auto',
    },
    btnLight: {
      color: '#000',
    },
    homeFaqText: {
      marginBottom: '1.2rem',
      color: '#000',
    },
    infoLight: {
      opacity: '0.87',
      fontWeight: 400,
      letterSpacing: '0.02rem',
      fontSize: '1.42rem',
      lineHeight: '2.14rem',
      margin: '1.5rem 0px 1.4rem 0px',
      color: 'black',
    },
    typoText: {
      opacity: 0.64,
      fontWeight: 300,
      fontSize: '1.15rem',
      letterSpacing: '0.02rem',
      lineHeight: '1.72rem',
      margin: '0px 0px 1.72rem 0px',
      color: 'rgba(0,0,0,0.95)',
    },
    width200: {
      width: '200px',
    },
    typoTextLeft: {
      opacity: 0.64,
      fontWeight: 300,
      fontSize: '1.15rem',
      letterSpacing: '0.02rem',
      lineHeight: '1.72rem',
      margin: '0px 0px 1.72rem 0px',
      color: 'rgba(0,0,0,0.95)',
      textAlign: 'left',
    },
    typoHead: {
      fontSize: '2.1rem',
      lineHeight: '130%',
      margin: '1.46rem 0 1.168rem 0',
    },
    typoHeadLeft: {
      fontSize: '2.1rem',
      lineHeight: '130%',
      margin: '1.46rem 0 1.168rem 0',
      textAlign: 'left',
      [theme.breakpoints.down('sm')]: {
        // spacing between headings and sections
        padding: '20px',
      },
    },
    spaceLeft: {
      paddingLeft: '12px',
      [theme.breakpoints.up('lg')]: {
        paddingLeft: '5.5rem !important',
      },
    },
    btnFaq: {
      width: '140px',
      color: '#fff',
      background: '#81bcff !important',
    },
    headTop: {
      zIndex: 'auto',
      color: 'black !important',
      margin: '1.5rem',
      padding: '1em',
      marginTop: '10em',
      [theme.breakpoints.down('sm')]: {
        // decrease top spacing for mobile
        marginTop: '5em',
      },
    },
    paperRemove: {
      background: 'none',
      boxShadow: 'none',
    },
    homeImage: {
      width: '400px',
      postiion: 'absolute',
      maxWidth: '100%',
      height: '100%',
      '&:hover': {
        cursor: 'pointer',
      },
      [theme.breakpoints.down('md')]: {
        width: '100%',
      },
    },
    homeBackLeft: {
      zIndex: 1,
      marginLeft: '-12%',
      left: '0',
      [theme.breakpoints.down('md')]: {
        margin: '0 auto',
      },
    },
    homeBackRight: {
      zIndex: 1,
      marginRight: '11%',
      right: '-2%',
      left: 'auto',
      [theme.breakpoints.down('md')]: {
        margin: '0 auto',
      },
    },
    homeBackCenter: {
      zIndex: 2,
      margin: '0 auto',
    },
    modal: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    paper: {
      backgroundColor: theme.palette.background.paper,
      border: '2px solid #000',
      boxShadow: theme.shadows[5],
      padding: theme.spacing(2, 4, 3),
    },
    infoHome: {
      fontSize: '1.2em',
      color: 'rgb(123, 127, 133)',
    },
    contentMid: {
      background: '#F5F5F5',
      padding: '70px',
      textAlign: 'center',
      paddingBottom: '6rem',
      position: 'relative',
      zIndex: 20,
    },

    clickNow: {
      '&:hover': {
        cursor: 'pointer',
      },
    },
    buttonStyle: {
      transition: 'all 300ms ease',
      backgroundColor: (props) => props.company.secondaryColor,
      borderRadius: '10px',
      fontSize: '2.2rem',
      height: '',
      lineHeight: '100%',
      color: (props) => props.company.secondaryTextColor,
      marginLeft: '15px',
      minHeight: '66px',
      transform: 'translateY(-0.1em)',
      '&:hover': {
        transform: 'translateY(-0.3em)',
      },
      [theme.breakpoints.down('md')]: {
        // add some spacing between renew now button and text on mobile
        marginTop: '20px',
      },
    },
    blackIcon: (props) => ({
      color: 'white',
      '&:hover': {
        color: 'white',
      },
    }),
    chevron: {
      transition: 'all 300ms',
      color: '#64abfb',
    },
    chevronRotate: {
      color: '#64abfb',
      transform: 'rotate(180deg)',
      transition: 'all 300ms',
    },
    contentMidWhite: {
      background: '#FFFFFF',
      padding: '60px',
      textAlign: 'center',
      [theme.breakpoints.down('sm')]: {
        padding: '10px',
      },
    },
    contentFaq: {
      background: '#F5F5F5',
      padding: '60px',
      textAlign: 'center',
    },
    centerImage: {
      margin: 'auto 0',
    },
    icon: {
      fontSize: '3.5rem',
    },
    iconText: {
      maxWidth: '300px',
      margin: '0 auto',
    },
    faqFooterSection: {
      margin: '2rem',
    },
    footer: {
      magin: '0 auto',
      background: '#eee',
      display: 'flex',
      justify: 'center',
      justifyContent: 'space-between',
      width: '500px',
    },
    footerInner: {
      width: '500px',
      margin: '0 auto',
      padding: '20px',
    },
    iconSpace: {
      padding: '2px',
    },
    footerLink: {
      marginLeft: '8px',
      color: 'black',
    },
    footerBackground: {
      background: '#eee',
      width: '100% !important',
    },
  }),
);
