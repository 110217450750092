import React, { useState } from 'react';
import swal from '@sweetalert/with-react';
// @material-ui
import { Grid, Button, TextField, Typography } from '@material-ui/core';
// @forms
import { useForm, Controller } from 'react-hook-form';
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
// @logic
import { useStore } from 'logic/store';
// @components
import Content from 'components/Layout/Content';
// @local
import { emailValidation } from 'logic/validation';
import useStyles from './styles';

interface FormData {
  email: string;
  password: string;
  newPassword: string;
  retypePassword: string;
}

const validationSchema = Yup.object().shape({
  email: emailValidation.required(),
  password: Yup.string().label('password').required(),
  newPassword: Yup.string().label('newPassword').required(),
  retypePassword: Yup.string()
    .label('retypePassword')
    .oneOf([Yup.ref('newPassword'), ''], 'Passwords must match')
    .required(),
});

const PasswordReset = () => {
  const classes = useStyles({});
  const store = useStore();
  const { userId, user } = store.auth;

  const [loading, setLoading] = useState(false);

  const { handleSubmit, errors, reset, control } = useForm<FormData>({
    resolver: yupResolver(validationSchema),
    defaultValues: {
      email: user != null ? user.email : '',
    },
  });

  React.useEffect(() => {
    if (user != null) {
      reset({
        email: user.email,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(user)]);

  const update = async (payload: FormData) => {
    if (!userId) {
      return;
    }

    setLoading(true);
    const res = await store.auth.changePassword(
      payload.password,
      payload.newPassword,
    );
    setLoading(false);
    if (res.success === true) {
      swal('Sorted!', 'Password has been updated.', 'success');

      reset({
        password: '',
        newPassword: '',
        retypePassword: '',
      });
    } else {
      swal(
        'Error!',
        'Unable to update password. Please email info@sorted.co.za for help',
        'error',
      );
    }
  };

  return (
    <Content
      className={classes.container}
      containerClass={classes.contentContainer}
    >
      <Grid item container justify="center" className={classes.container}>
        <Grid item md={12}>
          <Typography variant="h4" align="center">
            Manage Password
          </Typography>
          <form onSubmit={handleSubmit(update)}>
            <Controller
              as={
                <TextField
                  fullWidth
                  color="secondary"
                  margin="normal"
                  variant="outlined"
                  placeholder="Email"
                  type="email"
                  error={Boolean(errors.email)}
                  helperText={errors.email && errors.email.message}
                />
              }
              name="email"
              control={control}
            />

            <Controller
              as={
                <TextField
                  fullWidth
                  color="secondary"
                  margin="normal"
                  placeholder="Current Password"
                  variant="outlined"
                  type="password"
                  error={Boolean(errors.password)}
                  helperText={errors.password && errors.password.message}
                />
              }
              name="password"
              control={control}
            />

            <Controller
              as={
                <TextField
                  fullWidth
                  color="secondary"
                  margin="normal"
                  placeholder="New Password"
                  variant="outlined"
                  type="password"
                  error={Boolean(errors.newPassword)}
                  helperText={errors.newPassword && errors.newPassword.message}
                />
              }
              name="newPassword"
              control={control}
            />

            <Controller
              as={
                <TextField
                  fullWidth
                  color="secondary"
                  margin="normal"
                  placeholder="Confirm New Password"
                  variant="outlined"
                  type="password"
                  error={Boolean(errors.retypePassword)}
                  helperText={
                    errors.retypePassword && errors.retypePassword.message
                  }
                />
              }
              name="retypePassword"
              control={control}
            />

            <Button
              fullWidth
              disabled={loading}
              variant="contained"
              size="large"
              color="primary"
              type="submit"
              className={classes.submitButton}
            >
              Submit
            </Button>
          </form>
        </Grid>
      </Grid>
    </Content>
  );
};

export default PasswordReset;
