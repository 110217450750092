import { Theme } from '@material-ui/core/styles/createMuiTheme';
import { makeStyles, createStyles } from '@material-ui/core/styles';

const useStyle = makeStyles((theme: Theme) =>
  createStyles({
    contentContainer: {
      background: 'transparent',
      height: '100%',
      overflowY: 'hidden',
    },
    container: {
      maxWidth: '1200px !important',
      paddingBottom: '20px',
      width: '100%',
      padding: '0 10px',
      background: 'transparent',
      margin: '0 auto',
    },
    submitButton: {
      alignSelf: 'flex-end',
    },
    signUpHead: {
      textAlign: 'center',
      padding: '0 1%',
      margin: '0 auto',
    },
    headFirst: {
      fontSize: '3.2rem',
      lineHeight: '110%',
      margin: '1.78rem 0 1.424rem 0',
    },
    headSecond: {
      fontSize: '1.5rem',
      margin: '0.82rem 0 1rem 0',
      fontWeight: 300,
      textAlign: 'center',
      padding: '1em',
    },
    removePadding: {
      paddingTop: '0 !important',
    },
    btnBlue: {
      background: theme.palette.primary.main,
      color: theme.palette.primary.contrastText,
      padding: '1.1em',
      margin: '0 auto',
      borderRadius: '3px',
      fontSize: '1.0em',
      width: '9em',
    },
    formStyle: {
      background: '#f5f5f5',
      padding: '20px',
      color: 'black !important',
    },
    textDark: {
      color: 'black',
    },
    pageTitle: {
      textAlign: 'left',
      fontSize: '2.2rem',
      fontWeight: 400,
      margin: '0',
    },
    submitContainer: {
      display: 'flex',
      justifyContent: 'center',
      flexDirection: 'column',
      marginTop: '10px',
    },
    marginTop: {
      marginTop: '10px',
    },
    link: {
      marginTop: theme.spacing(2),
      textDecoration: 'none',
      color: '#777',
      fontWeight: 100,
      fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
      '&:after': {
        content: '""',
        borderBottom: `solid 2px ${theme.palette.secondary.main}`,
      },
    },
    textLink: {
      textDecoration: 'none',
      color: '#64abfb',
      fontSize: '1.2em',
      fontWeight: 100,
      fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
      '&:hover': {
        cursor: 'pointer',
      },
    },
    linksGroup: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      flexDirection: 'row',
    },
    centerProgress: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },
    logoLink: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      marginBottom: theme.spacing(1),
      textDecoration: 'none',
      color: '#777',
      fontWeight: 100,
      fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
      '&:after': {
        content: '""',
        borderBottom: `solid 2px ${theme.palette.secondary.main}`,
      },
    },
    logo: {
      height: '60px',
    },
    progress: {
      marginRight: theme.spacing(1),
    },
    padding20: {
      padding: '20px',
    },
  }),
);

export default useStyle;
