// Stores
// Entities
import V2CompanyStore from 'logic/stores/company/company';
import V2VehiclesStore from 'logic/stores/vehicles/vehicles';
import V2RenewalsStore from 'logic/stores/renewals/renewals';
import V2UsersStore from 'logic/stores/users/users';
import V2ManagedVehicleStore from 'logic/stores/managedVariables';
import V2LicenseFeeStore from 'logic/stores/licenseFees';
import V2PaymentsStore from 'logic/stores/payments';
import V2PermissionsStore from 'logic/stores/permissions';
import V2DeliveriesStore from 'logic/stores/deliveries';
import V2EventStore from 'logic/stores/events';
import V2DocumentStore from 'logic/stores/document';

// Non Entities
import V2MailStore from 'logic/stores/mail';
import V2SubscriberStore from 'logic/stores/subscribers';
import AdminStore from 'logic/stores/admin/admin';
import V2AuthStore from '../../stores/auth/auth';
import { RequestConfig } from '../../stores/util/baseStore';

export type IRootStore = RootStore;
class RootStore {
  initialState: Partial<RootStore> | undefined;
  // Entities

  documents: V2DocumentStore;
  permissions: V2PermissionsStore;
  payments: V2PaymentsStore;
  licenseFees: V2LicenseFeeStore;
  managedVariables: V2ManagedVehicleStore;
  vehicles: V2VehiclesStore;
  renewals: V2RenewalsStore;
  users: V2UsersStore;
  deliveries: V2DeliveriesStore;
  companies: V2CompanyStore;
  events: V2EventStore;
  subscribers: V2SubscriberStore;
  // Non-Entities
  auth: V2AuthStore;
  admin: AdminStore;
  mail: V2MailStore;

  get whitelabel(): boolean {
    const { company } = this.companies;
    const isWhitelabel = company.reference !== 'sorted';
    return isWhitelabel;
  }

  private getHeaders = (
    headers?: Record<string, string>,
  ): Record<string, any> => {
    // Load the access token when available at the start of each request...
    const defaultHeaders: Record<string, string> = {};

    if (this.auth && this.auth.accessToken) {
      defaultHeaders.Authorization = `Bearer ${this.auth.accessToken}`;
    }
    return {
      ...defaultHeaders,
      ...headers,
    };
  };

  constructor(initialState: Partial<RootStore> | undefined) {
    const API_URL = process.env.REACT_APP_API_URL;
    if (!API_URL) {
      throw new Error(`Cannot instantiate stores without API_URL env variable`);
    }

    const config: RequestConfig = {
      API_URL,
      getHeaders: this.getHeaders,
    };

    this.initialState = initialState;
    // Non-Entities
    this.auth = new V2AuthStore(config);
    this.admin = new AdminStore(config);
    this.mail = new V2MailStore(config);
    this.subscribers = new V2SubscriberStore(config);
    // Entities
    this.documents = new V2DocumentStore(config);
    this.payments = new V2PaymentsStore(config);
    this.permissions = new V2PermissionsStore(config);
    this.users = new V2UsersStore(config, this.auth);
    this.licenseFees = new V2LicenseFeeStore(config);
    this.managedVariables = new V2ManagedVehicleStore(config);
    this.renewals = new V2RenewalsStore(config);
    this.vehicles = new V2VehiclesStore(config);
    this.companies = new V2CompanyStore(config);
    this.deliveries = new V2DeliveriesStore(config);
    this.events = new V2EventStore(config);
  }
}

export default RootStore;
