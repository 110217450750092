import React, { useState } from 'react';
import { observer } from 'mobx-react-lite';
import { toast } from 'react-toastify';
import { useHistory } from 'react-router-dom';
// @material-ui
import { Button } from '@material-ui/core';
// @icons
import { ArrowForward } from '@material-ui/icons';
// @logic
import { useStore } from 'logic/store';
import { IVehicle } from 'logic/stores/vehicles/validation';
import { IRenewal } from 'logic/stores/renewals/validation';
import { delay } from 'bluebird';
import { JSON_ERROR } from 'logic/stores/util/baseStore';

interface Props {
  vehicle: IVehicle;
}

const ViewRenewalButton = observer(({ vehicle }: Props) => {
  const store = useStore();
  const history = useHistory();
  const [renewal, setRenewal] = useState<IRenewal>();
  const { permissions: permissionTypes } = store.auth;

  const isAdmin = permissionTypes?.includes('isAdmin');

  const viewRenewal = () => {
    if (renewal) {
      if (isAdmin) {
        history.push(`/admin/view-renewal/${renewal.id}`);
      } else {
        history.push(`/view-renewal/${renewal.id}`);
      }
    } else {
      toast.error('Renewal does not exist');
    }
  };

  const loadRenewal = async () => {
    if (isAdmin) {
      const result = await store.admin.getRenewal({ vehicleId: vehicle.id });
      if (!result.success) {
        if (result.message !== JSON_ERROR) {
          toast.error(`Error loading renewal: ${result.message}`);
        }
        return;
      }
      await delay(500);
      setRenewal(result.data);
      return;
    }

    const result = await store.renewals.getRenewals({
      vehicleId: vehicle.id,
      skip: 0,
      take: 10,
    });
    if (!result.success) {
      if (result.message !== JSON_ERROR) {
        toast.error(`Error loading renewal: ${result.message}`);
      }
      return;
    }
    const rn = result.data.entries.find((r) => r.vehicleId === vehicle.id);
    if (!rn) {
      toast.error(`Renewal does not exist`);
      return;
    }
    await delay(500);
    setRenewal(rn);
  };

  React.useEffect(() => {
    if (vehicle == null) {
      return;
    }

    if (!vehicle.renewals || vehicle.renewals.length === 0) {
      loadRenewal();
      return;
    }

    const renewals = vehicle.renewals.sort(
      (a, b) => b.dateOrdered.getTime() - a.dateOrdered.getTime(),
    );
    setRenewal(renewals[0]);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(vehicle)]);

  return (
    <Button
      color="primary"
      variant="contained"
      onClick={viewRenewal}
      endIcon={<ArrowForward />}
      disabled={!renewal}
    >
      <b>View Renewal</b>{' '}
    </Button>
  );
});

export default ViewRenewalButton;
