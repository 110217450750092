import Joi from 'joi';
import { PaginatedResponse } from '../vehicles/validation';

export type ILicenseFee = {
  id: number;
  region: string;
  kgFrom: number;
  kgTo: number;
  feeInCents: number;
  currency: string;
  vehicleType: string;
  vehicleTypeCode: string;
  createdAt: Date;
  updatedAt: Date;
};

export const LicenseFeeSchema = Joi.object<ILicenseFee>({
  id: Joi.number().required(),
  region: Joi.string().required(),
  kgFrom: Joi.number().required(),
  kgTo: Joi.number().required(),
  feeInCents: Joi.number().required(),
  currency: Joi.string().required(),
  vehicleType: Joi.string().required(),
  vehicleTypeCode: Joi.string().required(),
  createdAt: Joi.date().required(),
  updatedAt: Joi.date().required(),
});

export const PaginatedLicenseFeeSchema = Joi.object<
  PaginatedResponse<ILicenseFee>
>({
  count: Joi.number().required(),
  entries: Joi.array<ILicenseFee[]>().items(LicenseFeeSchema),
});
