import { makeObservable, observable, runInAction } from 'mobx';
import { BaseStore, RequestConfig } from '../util/baseStore';
import {
  LoginResponse,
  LoginSchema,
  RecoverAccountSchema,
  SetNewPasswordInput,
  VerifyAccountInput,
  VerifyAccountResponse,
} from './validation';
import { MessageSchema } from '../validation';
import { IUser, UserSchema } from '../users/validation';

export type UserType = 'individual' | 'business';

type RegisterInput = {
  firstName: string;
  lastName: string;
  email: string;
  password: string;
  companyId: number;
  contactNumber: string;
  userType: UserType;
};

class V2AuthStore extends BaseStore {
  constructor(config: RequestConfig) {
    super(config);
    makeObservable(this, {
      userId: observable,
      user: observable,
      accessToken: observable,
      permissions: observable,
    });
  }

  // State
  loading = false;
  userId?: number;
  user?: IUser;
  accessToken?: string;
  permissions?: string[];

  get isAdmin(): boolean {
    return Boolean(this.permissions?.some((p) => p === 'isAdmin'));
  }

  get isAuthed(): boolean {
    return Boolean(this.permissions?.some((p) => p === 'isActivatedUser'));
  }

  // Actions
  async setPermissions(permissions: string[]) {
    runInAction(() => {
      this.permissions = permissions;
    });
  }

  async set(user: IUser) {
    this.userId = user.id;
    this.user = user;
    this.permissions = user.permissions.map((p) => p.permission);
  }

  async rehydrate() {
    const token = localStorage.getItem('token');
    const userId = localStorage.getItem('userId');

    if (!token || !userId) {
      return;
    }

    const auth = `Bearer ${token}`;

    try {
      const result = await this.sendRequest({
        method: 'GET',
        path: `users/${userId}`,
        validation: UserSchema,
        headers: { Authorization: auth },
      });

      if (!result.success) {
        throw new Error(result.message);
      }

      runInAction(() => {
        this.accessToken = token;
        this.userId = parseInt(userId, 10);
        if (result.success) {
          this.user = result.data;
          this.permissions = result.data.permissions.map((p) => p.permission);
        }
      });
    } catch (e) {
      console.log(`Unable to rehydrate. Err: ${e}`);
      localStorage.clear();
      this.accessToken = undefined;
      this.userId = undefined;
      this.user = undefined;
      this.permissions = undefined;
    }
  }

  async login(
    email: string,
    password: string,
  ): Promise<
    { success: true; result: LoginResponse } | { success: false; result: Error }
  > {
    this.setLoading(true);

    try {
      const result = await this.sendRequest({
        method: 'POST',
        path: 'auth/login',
        validation: LoginSchema,
        body: { email, password },
      });

      if (!result.success) {
        throw new Error(result.message);
      }

      const permissions = result.data.user.permissions.map((p) => p.permission);

      runInAction(() => {
        this.loading = false;
        this.accessToken = result.data.token;
        this.userId = result.data.user.id;
        this.user = result.data.user;
        this.permissions = permissions;
      });

      localStorage.setItem('token', result.data.token);
      localStorage.setItem('userId', `${result.data.user.id}`);
      localStorage.setItem('permissions', JSON.stringify(permissions));

      return {
        success: true,
        result: {
          token: result.data.token,
          user: result.data.user,
        },
      };
    } catch (e) {
      this.setLoading(false);
      return {
        success: false,
        result: e as Error,
      };
    }
  }

  async register(input: RegisterInput) {
    this.setLoading(true);

    const result = await this.sendRequest({
      method: 'POST',
      path: 'auth/signup',
      validation: LoginSchema,
      body: input,
    });

    runInAction(() => {
      this.loading = false;
      if (result.success) {
        this.accessToken = result.data.token;
        this.userId = result.data.user.id;
        this.user = result.data.user;
      }
    });

    if (!result.success) {
      return result;
    }

    return {
      success: result.success,
      accessToken: result.data.token,
      user: result.data.user,
    };
  }

  async logout() {
    localStorage.clear();

    runInAction(() => {
      this.accessToken = undefined;
      this.userId = undefined;
      this.user = undefined;
      this.permissions = undefined;
    });

    return { success: true };
  }

  async requestPasswordReset(email: string) {
    return this.withState(
      this.sendRequest({
        method: 'POST',
        path: 'auth/request-new-password',
        body: { email },
        validation: MessageSchema,
      }),
    );
  }

  async changePassword(currentPassword: string, newPassword: string) {
    return this.withState(
      this.sendRequest({
        method: 'POST',
        path: 'auth/update-password',
        body: {
          id: this.userId,
          currentPassword,
          newPassword,
        },
        validation: MessageSchema,
      }),
    );
  }

  sendVerificationRequest(email: string) {
    return this.withState(
      this.sendRequest({
        method: 'POST',
        path: 'auth/verify',
        body: { email },
        validation: MessageSchema,
      }),
    );
  }

  setNewPassword(input: SetNewPasswordInput) {
    return this.withState(
      this.sendRequest({
        method: 'POST',
        path: 'auth/set-new-password',
        body: input,
        validation: MessageSchema,
      }),
    );
  }

  async verifyAccount(input: VerifyAccountInput) {
    return this.withState(
      input.type === 'token'
        ? this.sendRequest({
            method: 'POST',
            path: 'auth/verify-email-token',
            body: { token: input.token },
            validation: VerifyAccountResponse,
          })
        : this.sendRequest({
            method: 'POST',
            path: 'auth/verify-code',
            body: {
              email: input.email,
              code: input.code,
            },
            validation: VerifyAccountResponse,
          }),
    );
  }

  recoverAccount(email: string, code: string) {
    return this.withState(
      this.sendRequest({
        method: 'POST',
        path: 'auth/recover-with-code',
        body: { email, code },
        validation: RecoverAccountSchema,
      }),
    );
  }
}

export default V2AuthStore;
