import { Region } from '../vehicles/types';

export type RenewalStatus =
  | 'submitted by user'
  | 'scheduled'
  | 'processing'
  | 'ready to collect'
  | 'delivery requested'
  | 'delivered'
  | 'license updated'
  | 'admin to action'
  | 'user to action'
  | 'cancelled';

export const StatusDict = {
  'submitted by user': 'Submitted',
  scheduled: 'Scheduled',
  processing: 'Processing',
  'ready to collect': 'Can collect',
  'delivery requested': 'delivery requested',
  delivered: 'Delivered',
  'license updated': 'License updated',
  'admin to action': 'Admin action required',
  'user to action': 'User action required',
  cancelled: 'cancelled',
};

export const regions = [
  'gauteng',
  'western cape',
  'eastern cape',
  'free state',
  'kwazulu-natal',
  'limpopo',
  'northern cape',
  'north-west',
  'mpumalanga',
] as Region[];
