import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    lightBtnBackground: {
      backgroundColor: '#eee !important',
      color: 'black',
      border: 'none !important',
    },
    dangerRed: {
      backgroundColor: '#F32013 !important',
      '&:hover': {
        opacity: '0.9',
      },
    },
  }),
);

export default useStyles;
