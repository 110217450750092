import React from 'react';
import moment from 'moment';
import { observer } from 'mobx-react-lite';
import swal from '@sweetalert/with-react';
// @material-ui
import { Autocomplete } from '@material-ui/lab';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  Button,
  TextField,
  MenuItem,
  Select,
  FormHelperText,
  InputLabel,
} from '@material-ui/core';
import { DatePicker } from '@material-ui/pickers';
// @form
import { useForm, Controller } from 'react-hook-form';
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
// @logic
import { useStore } from 'logic/store';
// @components
import useStyles from 'components/AddVehicle/styles';
import { IVehicle } from 'logic/stores/vehicles/validation';
import {
  Region,
  VehicleType,
  listcarmakes,
  vehicleTypeOptions,
} from 'logic/stores/vehicles/types';
import { capitalizeWords } from 'logic/stores/renewals/validation';
import { regions } from 'logic/stores/renewals/types';

interface TemplateProps {
  editId: number;
  vehicle: IVehicle;
  handleClose: (vehicle: IVehicle) => void;
  open: boolean;
}

export interface FormValues {
  make: string;
  model: string;
  year: number;
  licenseNumber: string;
  licenseExpires: Date;
  registrationRegion: Region;
  weightKg: number;
  registerNumber: string;
  vehicleType?: VehicleType;
}

Yup.addMethod(Yup.string, 'matchLicense', function () {
  // @ts-ignore
  return this.test(
    'test-1',
    'Validation failure message',
    function (value: any) {
      const valid = /^[A-Za-z0-9_\-. ]{3,}$/;
      // @ts-ignore
      const { path, createError } = this;
      if (valid.test(value)) {
        return true;
      }
      return createError({
        path,
        message: 'Please use the correct format',
      });
    },
  );
});

const validationSchema = Yup.object().shape({
  make: Yup.string().label('Make').lowercase().required(),
  model: Yup.string().label('Model').lowercase().required(),
  year: Yup.number()
    .typeError('Year must be a number')
    .label('Year')
    .min(1900, 'Please enter a valid vehicle Year')
    .max(new Date().getFullYear() + 1, 'Please enter a valid vehicle Year')
    .required(),
  licenseNumber: Yup.string()
    .label('license Number')
    .required()
    // @ts-ignore
    .matchLicense()
    .lowercase(),
  registerNumber: Yup.string()
    .label('Vehicle Register Number')
    .lowercase()
    .required(),
  vehicleType: Yup.string().label('Vehicle Type').lowercase().required(),
  licenseExpires: Yup.date().required().label('expiry Date'),
  registrationRegion: Yup.string().required().label('province').lowercase(),
  weightKg: Yup.number().required().min(50).label('Vehicle Weight'),
});

const EditVehicle = observer(
  ({ open, editId, handleClose, vehicle }: TemplateProps) => {
    const classes = useStyles();
    const store = useStore();

    const [selectedDate, setSelectedDate] = React.useState<
      string | moment.Moment | null
    >(moment().format('DD-MMM-YYYY'));

    const handleDateChange = (date: string | moment.Moment | null) => {
      setSelectedDate(date);
    };

    const { handleSubmit, errors, control, reset, watch } = useForm<FormValues>(
      {
        resolver: yupResolver(validationSchema),
        defaultValues: {
          make: vehicle ? vehicle.make : '',
          model: vehicle ? vehicle.model : '',
          year: vehicle ? vehicle.year : undefined,
          licenseNumber: vehicle ? vehicle.licenseNumber : '',
          registerNumber: vehicle ? vehicle.registerNumber : '',
          licenseExpires: vehicle ? vehicle.licenseExpires : new Date(),
          registrationRegion: vehicle ? vehicle.registrationRegion : 'gauteng',
          weightKg: vehicle ? vehicle.weightKg : 0,
          vehicleType: vehicle ? vehicle.vehicleType : 'rigid vehicles',
        },
      },
    );

    const { registrationRegion, make } = watch();

    React.useEffect(() => {
      if (editId != null && vehicle != null) {
        reset({
          make: (vehicle.make || '').toLowerCase(),
          model: (vehicle.model || '').toLowerCase(),
          year: vehicle.year,
          licenseNumber: vehicle.licenseNumber,
          licenseExpires: new Date(vehicle.licenseExpires),
          registerNumber: vehicle.registerNumber,
          registrationRegion: vehicle.registrationRegion,
          weightKg: vehicle.weightKg,
          vehicleType: vehicle.vehicleType,
        });
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [editId, vehicle]);

    const updateVehicle = async (payload: FormValues) => {
      const result = await store.vehicles.update(editId, payload);
      if (result.success === true) {
        swal('Sorted!', 'Vehicle has been Updated!', 'success');
        handleClose(result.data);
      } else {
        swal(
          'Error!',
          'Unable to update vehicle. Please email info@sorted.co.za for help',
          'error',
        );
      }
    };

    return (
      <Dialog
        fullWidth
        maxWidth="sm"
        open={open}
        onClose={handleClose}
        aria-labelledby="Update Vehicle Details"
      >
        <DialogContent className={classes.centerContent}>
          <DialogContentText>Update Vehicle Information</DialogContentText>
          <form onSubmit={handleSubmit(updateVehicle)}>
            <Controller
              name="make"
              as={
                <Autocomplete
                  autoSelect
                  freeSolo
                  className={classes.fullWidth}
                  options={listcarmakes}
                  classes={{
                    popper: classes.popper,
                  }}
                  getOptionSelected={(option, value) => {
                    return option === value;
                  }}
                  renderInput={(params) => (
                    <span>
                      <TextField
                        {...params}
                        label="Make"
                        color="secondary"
                        margin="normal"
                        className={classes.textField}
                        placeholder="Vehicle make"
                        error={Boolean(errors.make)}
                        helperText={errors.make && errors.make.message}
                      />
                    </span>
                  )}
                />
              }
              onChange={([, obj]: any) => obj}
              control={control}
              defaultValue={make}
            />

            <Controller
              as={
                <TextField
                  margin="dense"
                  color="secondary"
                  fullWidth
                  placeholder="Vehicle Model"
                  label="Vehicle Model"
                  InputProps={{
                    className: classes.textField,
                  }}
                  error={Boolean(errors.model)}
                  helperText={errors.model && errors.model.message}
                />
              }
              name="model"
              control={control}
            />
            <Controller
              as={
                <TextField
                  margin="dense"
                  color="secondary"
                  fullWidth
                  placeholder="Vehicle Year"
                  label="Vehicle Year"
                  InputProps={{
                    className: classes.textField,
                  }}
                  error={Boolean(errors.year)}
                  helperText={errors.year && errors.year.message}
                />
              }
              name="year"
              control={control}
            />

            <Controller
              as={
                <TextField
                  margin="dense"
                  color="secondary"
                  fullWidth
                  type="number"
                  placeholder="Vehicle Weight (kg)"
                  label="Vehicle Weight (kg)"
                  InputProps={{
                    className: classes.textField,
                  }}
                  error={Boolean(errors.weightKg)}
                  helperText={errors.weightKg && errors.weightKg.message}
                />
              }
              name="weightKg"
              control={control}
            />

            <Controller
              as={
                <TextField
                  margin="dense"
                  color="secondary"
                  fullWidth
                  placeholder="License Number"
                  label="License number"
                  InputProps={{
                    className: classes.textField,
                  }}
                  error={Boolean(errors.licenseNumber)}
                  helperText={
                    errors.licenseNumber && errors.licenseNumber.message
                  }
                />
              }
              name="licenseNumber"
              control={control}
            />
            <Controller
              name="vehicleType"
              as={
                <TextField
                  select
                  color="secondary"
                  label="Vehicle Type"
                  margin="dense"
                  InputProps={{
                    className: classes.textField,
                  }}
                  SelectProps={{
                    displayEmpty: true,
                    MenuProps: {
                      anchorOrigin: {
                        vertical: 'bottom',
                        horizontal: 'left',
                      },
                      getContentAnchorEl: null,
                    },
                  }}
                  error={Boolean(errors.vehicleType)}
                  helperText={errors.vehicleType && errors.vehicleType.message}
                >
                  {vehicleTypeOptions.map((option) => {
                    const { label, type } = option;
                    return (
                      <MenuItem value={type} key={type}>
                        <span className={classes.capitalize}>{label}</span>
                      </MenuItem>
                    );
                  })}
                </TextField>
              }
              onChange={(obj: any) => obj[1].props.value}
              control={control}
            />
            <Controller
              as={
                <TextField
                  margin="dense"
                  color="secondary"
                  fullWidth
                  placeholder="Vehicle Register Number"
                  label="Vehicle Register Number"
                  InputProps={{
                    className: classes.textField,
                  }}
                  error={Boolean(errors.registerNumber)}
                  helperText={
                    errors.registerNumber && errors.registerNumber.message
                  }
                />
              }
              name="registerNumber"
              control={control}
            />
            <Controller
              as={
                <DatePicker
                  value={selectedDate}
                  onChange={(date) => handleDateChange(date)}
                  margin="dense"
                  label="Expiry Date"
                  format="DD-MM-YYYY"
                  helperText={
                    errors.licenseExpires && errors.licenseExpires.message
                  }
                />
              }
              name="licenseExpires"
              control={control}
            />

            <Controller
              name="registrationRegion"
              control={control}
              defaultValue={registrationRegion}
              render={({ onChange, value, ...props }) => {
                return (
                  <>
                    <InputLabel
                      style={{ fontSize: '12px' }}
                      id="province-label-id"
                    >
                      Province
                    </InputLabel>
                    <Select
                      className={classes.fullWidth}
                      color="secondary"
                      label="Province"
                      labelId="province-label-id"
                      placeholder="Province Select"
                      error={Boolean(errors.registrationRegion)}
                      value={value}
                      onChange={onChange}
                      {...props}
                    >
                      {regions.map((region) => (
                        <MenuItem key={region} value={region}>
                          {capitalizeWords(region)}
                        </MenuItem>
                      ))}
                    </Select>
                    {errors.registrationRegion?.message ? (
                      <FormHelperText>
                        {errors.registrationRegion?.message}
                      </FormHelperText>
                    ) : null}
                  </>
                );
              }}
            />

            <div className={classes.submitContainer}>
              <Button
                variant="contained"
                color="primary"
                size="large"
                type="submit"
                disabled={registrationRegion === 'unknown'}
              >
                Save Changes
              </Button>
            </div>
          </form>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => handleClose(vehicle)} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>
    );
  },
);

export default EditVehicle;
