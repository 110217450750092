import * as React from 'react';
import { observer } from 'mobx-react-lite';
import { Link, NavLink } from 'react-router-dom';
// @material-ui
import {
  AppBar,
  Toolbar,
  MenuItem,
  Menu,
  IconButton,
  Grid,
  Divider,
  Button,
  Typography,
} from '@material-ui/core';
// @icons
import { Menu as MenuIcon, AccountCircle } from '@material-ui/icons/';
// @logic
import { useStore } from 'logic/store';
// @components
import Content from 'components/Layout/Content';
// @local
import useStyles from './styles';
import './index.css';

// eslint-disable-next-line no-use-before-define
interface TemplateProps {
  state: string;
  logout: () => void;
}

const retrieveMenuItems = (authState: string, whitelabel: boolean) => {
  let menuItems = [] as Array<any>;
  if (!whitelabel) {
    menuItems = [
      { link: '/about', label: 'About', type: 'link' },
      { link: '/contact', label: 'Contact', type: 'link' },
      { link: '/faq', label: 'FAQ', type: 'link' },
    ];
  }
  if (authState === 'authorized') {
    menuItems.push({ link: '/profile', label: 'Profile', type: 'button' });
  } else {
    menuItems.push({ link: '/signup', label: 'Sign up', type: 'link' });
    menuItems.push({ link: '/login', label: 'Login', type: 'link' });
  }
  return menuItems;
};

const Navbar = observer(({ state, logout }: TemplateProps) => {
  const store = useStore();

  const classes = useStyles({ company: store.companies.company });

  const [mobileMenu, setMobileMenu] = React.useState(null);
  const [menu, setMenu] = React.useState(null);
  const handleMenuOpen = React.useCallback(
    (setState) => (event: React.MouseEvent<HTMLButtonElement>) => {
      handleMenuClose(setState)();
      setState(event.currentTarget);
    },
    [],
  );

  const { user } = store.auth;

  const handleMenuClose = (setState: (state: null) => void) => () => {
    setState(null);
  };

  const isMobileMenuOpen = Boolean(mobileMenu);

  const renderMobileMenu = (
    <Menu
      anchorEl={mobileMenu}
      open={isMobileMenuOpen}
      onClose={handleMenuClose(setMobileMenu)}
    >
      {retrieveMenuItems(state, store.whitelabel).map((menuItem) =>
        menuItem.type === 'link' ? (
          <MenuItem key={menuItem.link}>
            <Link to={menuItem.link} className={classes.linkType2}>
              {menuItem.label}
            </Link>
          </MenuItem>
        ) : menuItem.type === 'button' ? (
          <MenuItem key={menuItem.link}>
            <Link to={menuItem.link} className={classes.buttonLink}>
              <Button fullWidth className={classes.button}>
                {menuItem.label}
              </Button>
            </Link>
          </MenuItem>
        ) : null,
      )}
    </Menu>
  );

  const RenderGeneralMenu = () => {
    const isGeneralMenuOpen = Boolean(menu);

    return (
      <Menu
        anchorEl={menu}
        open={isGeneralMenuOpen}
        onClose={handleMenuClose(setMenu)}
        className={classes.profileMenu}
        classes={{ paper: classes.profileMenu }}
      >
        <Grid item md={12}>
          <MenuItem>
            <Link className={classes.link} to="/profile">
              Profile
            </Link>
          </MenuItem>
        </Grid>

        <Grid item md={12}>
          <Divider />
          <Button onClick={logout} fullWidth className={classes.button}>
            <Typography className={classes.text}>Logout</Typography>
          </Button>
        </Grid>
      </Menu>
    );
  };

  return (
    <div className={classes.siteNoticeHead}>
      <AppBar position="static" className={classes.appbar}>
        <Content>
          <Grid item md={12} sm={12} className={classes.noHorizontalPadding}>
            <Toolbar classes={{ root: classes.toolbar }}>
              <Link className={classes.logoLink} to="/">
                <img
                  src={store.companies.company.logoUrl}
                  alt="logo"
                  className={classes.logo}
                />
              </Link>
              <div className={classes.sectionDesktop}>
                {retrieveMenuItems(state, store.whitelabel).map((menuItem) =>
                  menuItem.type === 'link' ? (
                    <NavLink
                      key={menuItem.link}
                      to={menuItem.link}
                      activeClassName={classes.selected}
                      className={[classes.buttonLink].join(' ')}
                    >
                      <Button
                        className={[
                          classes.marginRight,
                          classes.buttonActive,
                        ].join(' ')}
                        variant="text"
                      >
                        <Typography className={classes.text}>
                          {menuItem.label}
                        </Typography>
                      </Button>
                    </NavLink>
                  ) : menuItem.type === 'button' ? (
                    <React.Fragment key={menuItem.link}>
                      <Button
                        onClick={handleMenuOpen(setMenu)}
                        color="primary"
                        className={classes.button}
                      >
                        <AccountCircle className={classes.marginRight} />
                        <Typography className={classes.text}>
                          {user ? `${user.firstName} ${user.lastName}` : null}
                        </Typography>
                      </Button>
                      <RenderGeneralMenu />
                    </React.Fragment>
                  ) : null,
                )}
              </div>
              <div className={classes.sectionMobile}>
                <IconButton
                  aria-haspopup="true"
                  onClick={handleMenuOpen(setMobileMenu)}
                  color="inherit"
                >
                  <MenuIcon className={classes.icon} />
                </IconButton>
              </div>
            </Toolbar>
            {renderMobileMenu}
          </Grid>
        </Content>
      </AppBar>
    </div>
  );
});

export default Navbar;
