import React from 'react';
import { observer } from 'mobx-react-lite';
// @material-ui
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  TextField,
  DialogTitle,
  IconButton,
  Typography,
  MenuItem,
} from '@material-ui/core';
// @form
import { useForm, Controller } from 'react-hook-form';
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
// @icons
import { Close as CloseIcon } from '@material-ui/icons';
// @logic
import { useStore } from 'logic/store';
// @local
import { vehicleTypes } from 'logic/stores/vehicles/types';
import { regions } from 'logic/stores/renewals/types';
import useStyles from '../styles';

interface FormValues {
  region: string;
  kgFrom: number;
  kgTo: number;
  feeInCents: number;
  vehicleType: string;
}

const validationSchema = Yup.object().shape({
  region: Yup.string().lowercase().label('Region').required(),
  kgFrom: Yup.number().required().label('kg from'),
  kgTo: Yup.number().required().label('kg to'),
  feeInCents: Yup.number().label('Fee').required(),
  vehicleType: Yup.string().lowercase().label('Vehicle Type').required(),
});

const AddForm = observer(() => {
  const store = useStore();
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const [loading, setLoading] = React.useState(false);

  const { handleSubmit, errors, control, reset } = useForm<FormValues>({
    resolver: yupResolver(validationSchema),
  });

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    reset();
  };

  const addItem = async (payload: FormValues) => {
    setLoading(true);
    const res = await store.licenseFees.create({
      ...payload,
      feeInCents: payload.feeInCents * 100,
      currency: 'ZAR',
    });
    setLoading(false);
    if (res.success === true) {
      handleClose();
    }
  };

  return (
    <>
      <Button onClick={handleClickOpen} variant="contained" color="primary">
        <b>Add</b>
      </Button>
      <Dialog
        fullWidth
        maxWidth="sm"
        open={open}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle>
          <b>Add</b>
          <IconButton
            aria-label="close"
            onClick={handleClose}
            className={classes.positionIcon}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <form>
            <br />
            <Typography>
              <b>General Details</b>
            </Typography>
            <Controller
              as={
                <TextField
                  select
                  color="secondary"
                  variant="outlined"
                  margin="dense"
                  label="Select Region"
                  error={Boolean(errors.region)}
                  helperText={errors.region && errors.region.message}
                  className={classes.capitalText}
                >
                  {regions.map((n) => (
                    <MenuItem key={n} value={n} className={classes.capitalText}>
                      {n}
                    </MenuItem>
                  ))}
                </TextField>
              }
              name="region"
              control={control}
            />

            <Controller
              as={
                <TextField
                  select
                  color="secondary"
                  variant="outlined"
                  margin="dense"
                  label="Select Vehicle Type"
                  error={Boolean(errors.vehicleType)}
                  helperText={errors.vehicleType && errors.vehicleType.message}
                  className={classes.capitalText}
                >
                  {vehicleTypes.map((n) => (
                    <MenuItem key={n} value={n} className={classes.capitalText}>
                      {n}
                    </MenuItem>
                  ))}
                </TextField>
              }
              name="vehicleType"
              control={control}
            />

            <Controller
              as={
                <TextField
                  fullWidth
                  color="secondary"
                  type="number"
                  margin="dense"
                  variant="outlined"
                  placeholder="From (kg)"
                  error={Boolean(errors.kgFrom)}
                  helperText={errors.kgFrom && errors.kgFrom.message}
                />
              }
              name="kgFrom"
              control={control}
            />

            <Controller
              as={
                <TextField
                  fullWidth
                  color="secondary"
                  type="number"
                  margin="dense"
                  variant="outlined"
                  placeholder="To (kg)"
                  error={Boolean(errors.kgTo)}
                  helperText={errors.kgTo && errors.kgTo.message}
                />
              }
              name="kgTo"
              control={control}
            />

            <Controller
              as={
                <TextField
                  fullWidth
                  color="secondary"
                  type="number"
                  margin="dense"
                  variant="outlined"
                  placeholder="Fee"
                  error={Boolean(errors.feeInCents)}
                  helperText={errors.feeInCents && errors.feeInCents.message}
                />
              }
              name="feeInCents"
              control={control}
            />
          </form>
        </DialogContent>
        <DialogActions className={classes.dialogBorder}>
          <Button onClick={handleClose} color="default">
            Cancel
          </Button>
          <Button
            disabled={loading}
            variant="contained"
            color="primary"
            onClick={handleSubmit(addItem)}
          >
            Add
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
});

export default AddForm;
