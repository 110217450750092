import AuthStore from './auth/auth';
import { RequestConfig } from './util/baseStore';

export const API_URL = process.env.REACT_APP_API_URL;

class GRootStore {
  auth: AuthStore;

  private getHeaders(): Record<string, any> {
    // Load the access token when available at the start of each request...
    if (this.auth && this.auth.accessToken) {
      return { Authorization: `Bearer ${this.auth.accessToken}` };
    }
    return {};
  }

  constructor() {
    const url = process.env.REACT_APP_API_URL;
    if (!url) {
      throw new Error(`Cannot instantiate stores without API_URL env variable`);
    }

    const config: RequestConfig = {
      API_URL: url,
      getHeaders: this.getHeaders,
    };
    this.auth = new AuthStore(config);
  }
}

export default GRootStore;
