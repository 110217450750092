import React, { useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { observer } from 'mobx-react-lite';
// @material-ui
import {
  Button,
  Grid,
  TextField,
  Typography,
  Paper,
  Checkbox,
  FormControlLabel,
} from '@material-ui/core';
// @form
import { useForm, Controller } from 'react-hook-form';
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
// @logic
import { useStore } from 'logic/store';
import { emailValidation } from 'logic/validation';
// @components
import Content from 'components/Layout/Content';
// @local
import { IUser } from 'logic/stores/users/validation';
import useStyles from './styles';

interface FormData {
  email: string;
  password: string;
}

const validationSchema = Yup.object().shape({
  password: Yup.string().label('Password').required(),
  email: emailValidation.required(),
});

const Signin = observer(() => {
  const history = useHistory();
  const store = useStore();
  const classes = useStyles({});

  const [loginError, setLoginError] = useState<string>();

  const { handleSubmit, errors, control } = useForm<FormData>({
    resolver: yupResolver(validationSchema),
  });

  const [checked, setChecked] = React.useState(true);
  const [loading, setLoading] = React.useState(false);

  const handleChange = (event: any) => {
    setLoginError(undefined);
    setChecked(event.target.checked);
  };
  const login = async ({ email, password }: FormData) => {
    let user: IUser | null = null;
    setLoading(true);

    try {
      const result = await store.auth.login(email, password);
      if (!result.success) {
        throw result.result;
      }
      user = result.result.user;
    } catch (e) {
      console.error(e);
      setLoginError('Incorrect username and password');
    }

    setLoading(false);

    if (user) {
      const permissionNames = user.permissions.map((n) => n.permission);
      if (permissionNames.includes('isAdmin')) {
        history.push('/admin');
      } else if (user.verified === true) {
        // TODO: fix this flow, check when isActivatedUser is being set
        if (!permissionNames.includes('isActivatedUser')) {
          history.push('/account-setup');
        } else {
          history.push('/manage-vehicles');
        }
      } else if (!user.verified) {
        history.push('/verify-account');
      }
    }
  };

  return (
    <Content
      className={classes.container}
      containerClass={classes.contentContainer}
    >
      <Grid item md={12} xs={12} className={classes.removePadding}>
        <h2 className={classes.pageTitle}>Login</h2>
        <Paper className={`${classes.formStyle}`} elevation={2}>
          <form onSubmit={handleSubmit(login)} autoComplete="off">
            <Grid item xs={12}>
              <Controller
                as={
                  <TextField
                    fullWidth
                    margin="dense"
                    placeholder="Email"
                    color="secondary"
                    label="Email"
                    InputProps={{
                      className: classes.textField,
                    }}
                    type="email"
                    error={Boolean(errors.email)}
                    helperText={errors.email && errors.email.message}
                  />
                }
                name="email"
                control={control}
              />
            </Grid>

            <Grid item xs={12}>
              <Controller
                as={
                  <TextField
                    fullWidth
                    margin="dense"
                    placeholder="password"
                    color="secondary"
                    label="Password"
                    InputProps={{
                      className: classes.textField,
                    }}
                    type="password"
                    error={Boolean(errors.password)}
                    helperText={errors.password && errors.password.message}
                  />
                }
                name="password"
                control={control}
              />
            </Grid>

            <div className={classes.submitContainer}>
              <Button
                fullWidth
                disabled={loading}
                className={classes.btnBlue}
                size="large"
                type="submit"
              >
                Login
              </Button>
            </div>
            <br />

            <div className={classes.linksGroup}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={checked}
                    onChange={handleChange}
                    name="checkedB"
                    color="primary"
                  />
                }
                label="Remember Me"
              />
              {loginError ? (
                <Typography color="error">{loginError}</Typography>
              ) : null}
              <Link to="/recover-account" className={classes.link}>
                <Typography
                  variant="body1"
                  align="center"
                  className={classes.textLink}
                >
                  Forgot Password?
                </Typography>
              </Link>
            </div>
          </form>
        </Paper>
      </Grid>
    </Content>
  );
});

export default Signin;
