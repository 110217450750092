import { BaseStore, RequestConfig } from '../util/baseStore';
import { MessageSchema } from '../validation';

class V2SubscriberStore extends BaseStore {
  constructor(config: RequestConfig) {
    super(config);
  }

  // State
  loading = false;

  // Actions
  async create(input: { email: string }) {
    return this.withState(
      this.sendRequest({
        method: 'POST',
        path: 'subscribers',
        body: input,
        validation: MessageSchema,
      }),
    );
  }
}

export default V2SubscriberStore;
