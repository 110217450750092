import React, { useCallback } from 'react';
import { observer } from 'mobx-react-lite';
import Numeral from 'numeral';
// @material-ui
import { TableRow, TableCell } from '@material-ui/core';
// @components
import { formatCurrency } from 'utilities/handleCurrency';
// @local
import { ILicenseFee } from 'logic/stores/licenseFees/validation';
import useStyles from './styles';

interface IProps {
  key: string;
  licenseFee: ILicenseFee;
  onClick: (id: number | void) => void;
}

const LicenseFee: React.FC<IProps> = observer(
  ({ licenseFee, onClick, key }) => {
    const classes = useStyles();

    const handleClick = useCallback(() => {
      onClick(licenseFee.id);
    }, []);

    return (
      <TableRow
        onClick={handleClick}
        key={key}
        hover
        role="checkbox"
        classes={{
          hover: classes.rowHover,
        }}
      >
        <TableCell className={classes.capitalize}>
          {licenseFee.region}
        </TableCell>
        <TableCell>{Numeral(licenseFee.kgFrom).format('0,0')}</TableCell>
        <TableCell>{Numeral(licenseFee.kgTo).format('0,0')}</TableCell>
        <TableCell>{formatCurrency(licenseFee.feeInCents)}</TableCell>
        <TableCell className={classes.capitalize}>
          {licenseFee.vehicleType}
        </TableCell>
      </TableRow>
    );
  },
);

export default LicenseFee;
