import * as React from 'react';
// @material-ui
import { makeStyles, createStyles } from '@material-ui/core/styles';
import Grid, {
  GridSpacing,
  GridJustification,
  GridDirection,
  GridItemsAlignment,
} from '@material-ui/core/Grid';
import { Theme } from '@material-ui/core/styles/createMuiTheme';

interface Props {
  children: React.ReactNode;
  className?: string;
  containerClass?: string;
  spacing?: GridSpacing;
  direction?: GridDirection;
  justify?: GridJustification;
  alignItems?: GridItemsAlignment;
  container?: boolean;
}

const Content = ({
  children,
  className = undefined,
  containerClass = undefined,
  spacing = 10,
  direction = undefined,
  justify = undefined,
  alignItems = undefined,
  container = true,
  ...props
}: Props) => {
  const classes = useStyles(props);
  return (
    <div className={[classes.center, containerClass].join(' ')}>
      <Grid
        container={container}
        spacing={spacing}
        className={[classes.content, className].join(' ')}
        {...props}
      >
        {children}
      </Grid>
    </div>
  );
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    center: {
      display: 'flex',
      justifyContent: 'center',
    },
    content: {
      width: '100%',
      [theme.breakpoints.up('md')]: {
        maxWidth: '1140px',
      },
      [theme.breakpoints.down('sm')]: {
        maxWidth: '960px',
      },
      [theme.breakpoints.down('xs')]: {
        maxWidth: '540px',
      },
    },
    noPadding: {
      padding: '0px',
    },
  }),
);

export default Content;
