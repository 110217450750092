import React from 'react';
import moment from 'moment';
import { observer } from 'mobx-react-lite';
import { v4 as uuidv4 } from 'uuid';
// @material-ui
import {
  Card,
  Grid,
  Typography,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  CircularProgress,
} from '@material-ui/core';
// @logic
import { useStore } from 'logic/store';
// @components
import { formatCurrency } from 'utilities/handleCurrency';
// @local
import { LicenceRenewalQuote } from 'logic/stores/renewals/validation';
import useStyles from './styles';

interface Props {
  elevation?: number;
  licenceRenewalQuote: LicenceRenewalQuote;
}

const RenewalInvoice = observer(
  ({ licenceRenewalQuote, elevation = 1 }: Props) => {
    const classes = useStyles();
    const flexColumnEndStyles = [classes.flexColumn, classes.flexEnd].join(' ');
    const flexColumnStartStyles = [classes.flexColumn, classes.flexStart].join(
      ' ',
    );
    const flexCenterStyles = [classes.flexColumn, classes.flexCenter].join(' ');

    const store = useStore();
    const quoteCode = uuidv4();
    const [paymentReference] = React.useState(quoteCode);
    const [renewalTotal, setRenewalTotal] = React.useState<number | null>(null);
    const [serviceFeeValue, setServiceFeeValue] = React.useState<number | null>(
      null,
    );

    const { managedVariables } = store.managedVariables;

    const [total, setTotal] = React.useState<number | null>(null);
    const [loading, setLoading] = React.useState(true);

    React.useEffect(() => {
      setLoading(true);
      if (licenceRenewalQuote != null) {
        setRenewalTotal(licenceRenewalQuote.total);
        setLoading(false);
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [JSON.stringify(licenceRenewalQuote)]);

    React.useEffect(() => {
      if (renewalTotal != null && serviceFeeValue != null) {
        const calcTotal = Number(renewalTotal) + Number(serviceFeeValue);
        // make sure its a number
        setTotal(calcTotal);
      }
    }, [renewalTotal, serviceFeeValue]);

    React.useEffect(() => {
      async function loadServicefee() {
        if (managedVariables === null || managedVariables.length === 0) {
          await store.managedVariables.get();
        }

        const fee = store.managedVariables.byName('serviceFee');

        if (fee) {
          setServiceFeeValue(fee.value);
        }
      }

      loadServicefee();
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
      <div>
        <br />
        <Card elevation={elevation}>
          <div className={classes.cardMargin}>
            <div className={classes.addressMarginBelow}>
              <Grid container>
                <Grid item md={6} xs={12}>
                  <img
                    src={store.companies.company.logoUrl}
                    className={classes.img200}
                    alt="logo"
                  />
                </Grid>
                <Grid item md={6} xs={12} className={flexColumnEndStyles}>
                  <Typography variant="overline" align="right">
                    Quote: #{paymentReference}
                  </Typography>
                  <Typography variant="overline" align="right">
                    Created: {moment().format('MMMM D, YYYY')}
                  </Typography>
                </Grid>
                <Grid item md={12} xs={12} className={flexColumnStartStyles}>
                  <Typography variant="overline">
                    {store.companies.company.fullCompanyName}
                  </Typography>
                  <Typography variant="overline">
                    {store.companies.company.address1}
                  </Typography>
                  <Typography variant="overline">
                    {store.companies.company.address2}
                  </Typography>
                </Grid>
              </Grid>
            </div>
            {loading ? (
              <div className={flexCenterStyles}>
                <Typography gutterBottom>Loading Fees...</Typography>
                <CircularProgress />
              </div>
            ) : renewalTotal ? (
              <Table>
                <TableHead className={classes.tableBackground}>
                  <TableRow>
                    <TableCell>
                      <Typography variant="h6">
                        <b>Item</b>
                      </Typography>
                    </TableCell>
                    <TableCell />
                    <TableCell align="right">
                      <Typography variant="h6">
                        <b>Price</b>
                      </Typography>
                    </TableCell>
                  </TableRow>
                </TableHead>

                <TableBody>
                  <TableRow hover>
                    <TableCell>Renewal fee</TableCell>
                    <TableCell />
                    <TableCell align="right">
                      {formatCurrency(renewalTotal)}
                    </TableCell>
                  </TableRow>
                  <TableRow hover>
                    <TableCell>Service charge</TableCell>
                    <TableCell />
                    <TableCell align="right">
                      {serviceFeeValue ? formatCurrency(serviceFeeValue) : null}
                    </TableCell>
                  </TableRow>

                  {/* Total */}
                  <TableRow hover>
                    <TableCell className={classes.tableCellBottomNone} />
                    <TableCell className={classes.tableCellBottomNone} />
                    <TableCell align="right">
                      <Typography variant="button">
                        <strong>
                          Total: {`  `}
                          {total ? formatCurrency(total) : null}
                        </strong>
                      </Typography>
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            ) : (
              <Typography color="primary" variant="h5">
                No license fees could be matched
              </Typography>
            )}
          </div>
        </Card>
      </div>
    );
  },
);

export default RenewalInvoice;
