import React, { useState } from 'react';
import { observer } from 'mobx-react-lite';
import { toast } from 'react-toastify';
// @material-ui
import { Grid, Button } from '@material-ui/core';
// @form
import { useForm } from 'react-hook-form';
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
// @logic
import { useStore } from 'logic/store';
// @components
import Content from 'components/Layout/Content';
import GooglePlaces, { PlacesResult } from 'components/utilities/Google/Places';
import GoogleMap from 'components/utilities/Google/Map/Iframe';
// @local
import useStyles from './styles';

interface Form {
  deliveryAddress: string;
  deliveryCoordinates: [number, number];
}

interface Props {
  next?: () => void;
}

const validationSchema = Yup.object().shape({
  deliveryAddress: Yup.string().label('delivery address').required(),
});

const Profile = observer(({ next }: Props) => {
  const store = useStore();
  const classes = useStyles({});
  const { userId } = store.auth;

  const [loading, setLoading] = useState(false);

  const { handleSubmit, setValue, register, watch } = useForm<Form>({
    resolver: yupResolver(validationSchema),
  });

  React.useEffect(() => {
    register({ name: 'deliveryAddress' });
    register({ name: 'deliveryCoordinates' });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [register]);

  const updateAddress = async (payload: Form) => {
    if (!userId) {
      return;
    }

    setLoading(true);
    const result = await store.users.update(userId, payload);
    setLoading(false);
    if (result) {
      toast.success('Address has been updated');
      if (next) {
        next();
      }
    }
  };

  const getSelectedLocation = (location: PlacesResult) => {
    if (location.formatted_address) {
      setValue('deliveryAddress', location.formatted_address);
    }
    setValue('deliveryCoordinates', location.coordinates);
  };

  const { deliveryAddress } = watch();

  return (
    <Content
      className={classes.container}
      containerClass={classes.contentContainer}
    >
      <Grid container justify="center" className={classes.container}>
        <Grid item md={11} xs={12}>
          <form onSubmit={handleSubmit(updateAddress)}>
            <GooglePlaces
              getSelectedLocation={getSelectedLocation}
              textfieldProps={{
                variant: 'outlined',
                label: 'Search for Location',
                margin: 'normal',
                fullWidth: true,
              }}
            />

            <GoogleMap title="Delivery Address" address={deliveryAddress} />

            <div className={classes.buttons}>
              <Button
                fullWidth
                variant="contained"
                size="large"
                color="primary"
                disabled={loading}
                type="submit"
              >
                Save
              </Button>
            </div>
          </form>
        </Grid>
      </Grid>
    </Content>
  );
});

export default Profile;
