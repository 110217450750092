import React, { useState } from 'react';
import { observer } from 'mobx-react-lite';
import { useParams } from 'react-router-dom';
import swal from '@sweetalert/with-react';
// @material-ui
import {
  Grid,
  Typography,
  List,
  ListItemAvatar,
  Avatar,
  ListItem,
  ListItemText,
  ListItemSecondaryAction,
  IconButton,
  DialogTitle,
  DialogContentText,
  DialogContent,
  DialogActions,
  Button,
  Dialog,
} from '@material-ui/core';
// @icons
import {
  Description as DescriptionIcons,
  Delete as DeleteIcon,
  Photo as PhotoIcon,
} from '@material-ui/icons';
// @logic
import { useStore } from 'logic/store';
// @components
import Content from 'components/Layout/Content';
import MapIframe from 'components/utilities/Google/Map/Iframe';
import useCertaintyButtonStyles from 'components/Layout/certaintyButtonStyles';
// @local
import { IDocument } from 'logic/stores/document/validation';
import { IUser } from 'logic/stores/users/validation';
import ViewUserDetails from './ViewUserVehicles';
import useStyles from './styles';

interface OptionalProp {
  renewalUserId: number;
}

const ViewUser = observer(({ renewalUserId }: OptionalProp) => {
  const classes = useStyles();
  const certaintyButtonStyles = useCertaintyButtonStyles({});

  const store = useStore();
  const { userId } = useParams<{ userId: string }>();
  const { documents } = store.documents;

  const [user, setUser] = useState<IUser>();

  const [selectedDocument, setSelectedDocument] =
    React.useState<IDocument | null>(null);
  const [open, setOpen] = React.useState(false);

  React.useEffect(() => {
    async function loadUser() {
      const result = await store.users.getById(parseInt(userId, 10));
      if (result.success) {
        setUser(result.data);
      } else {
        // TODO: handle Error
        console.log(`Error loading user: ${result.message}`);
        setUser(undefined);
      }
    }

    if (user) {
      return;
    }
    loadUser();
  }, [user]);

  React.useEffect(() => {
    const id = (renewalUserId || userId) as number;
    if (id != null) {
      store.users.getById(id);
      store.documents.reset();
      store.documents.load({ userId: id });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [renewalUserId, userId]);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setSelectedDocument(null);
  };

  const removeDocument = (documentId: number) => {
    swal({
      title: 'Are you sure?',
      text: 'Once deleted, you will not be able to recover this file!',
      icon: 'warning',
      buttons: {
        cancel: true,

        catch: {
          text: 'Yes, I want to delete this',
          value: 'confirm',
          className: certaintyButtonStyles.dangerRed,
        },
      },
      dangerMode: true,
    }).then(async (willDelete: string) => {
      if (willDelete === 'confirm') {
        try {
          await store.documents.remove(documentId);
          swal('Selected Document has been removed', {
            icon: 'success',
          });
        } catch (err) {
          swal('error', 'Failed Remove Document', 'error');
        }
      }
    });
  };

  const selectDocument = (document: IDocument) => {
    setSelectedDocument(document);
    handleClickOpen();
  };

  return (
    <Content
      className={classes.container}
      containerClass={classes.contentContainer}
    >
      <Grid item md={12} className={classes.gridLessPadding}>
        <Typography variant="h4" className={classes.blueText}>
          Details
          {user?.testUser ? (
            <Typography variant="h5" className={classes.redText}>
              (Test Account)
            </Typography>
          ) : null}
        </Typography>
        <Grid container>
          <Grid item md={6} xs={12} sm={12}>
            <Typography variant="h5">
              Name: {user != null ? user.firstName : null}
            </Typography>
          </Grid>
          <Grid item md={6} xs={12} sm={12}>
            <Typography variant="h5" className={classes.alignRight}>
              Email: {user != null ? user.email : null}
            </Typography>
          </Grid>
          <Grid item md={6} xs={12} sm={12}>
            <Typography variant="h5">
              Surname: {user != null ? user.lastName : null}
            </Typography>
          </Grid>
          <Grid item md={6} xs={12} sm={12}>
            <Typography variant="h5" className={classes.alignRight}>
              Number: {user != null ? user.contactNumber : null}
            </Typography>
          </Grid>
        </Grid>
      </Grid>

      <Grid item sm={12} className={classes.gridLessPadding}>
        <Typography variant="h4" className={classes.blueText}>
          Delivery Address
        </Typography>
        {user != null ? (
          <>
            {user.deliveryAddressAdditional && (
              <Typography variant="h5">
                {user.deliveryAddressAdditional}
              </Typography>
            )}
            <Typography variant="h5">{user.deliveryAddress}</Typography>
            {user.deliveryAddress && (
              <MapIframe
                title={user.deliveryAddress}
                address={user.deliveryAddress}
              />
            )}
          </>
        ) : null}
      </Grid>

      <Grid item sm={12} className={classes.gridLessPadding}>
        <Typography variant="h4">Documents</Typography>
        <div>
          <List dense>
            {documents != null
              ? documents.map((element) => {
                  return (
                    <ListItem
                      key={element.id}
                      button
                      onClick={() => selectDocument(element)}
                    >
                      <ListItemAvatar>
                        <Avatar
                          className={[classes.blueAvatar, classes.large].join(
                            ' ',
                          )}
                        >
                          {element.documentType === 'application/pdf' ? (
                            <DescriptionIcons fontSize="large" />
                          ) : (
                            <PhotoIcon fontSize="large" />
                          )}
                        </Avatar>
                      </ListItemAvatar>
                      <ListItemText
                        className={classes.margin10}
                        primary={
                          <Typography variant="h6">
                            {element.filename}
                          </Typography>
                        }
                        secondary={
                          <Typography variant="body1">
                            {element.documentType}
                          </Typography>
                        }
                      />
                      <ListItemSecondaryAction>
                        <IconButton edge="end">
                          <DeleteIcon
                            onClick={() => removeDocument(element.id)}
                          />
                        </IconButton>
                      </ListItemSecondaryAction>
                    </ListItem>
                  );
                })
              : null}
          </List>
        </div>
      </Grid>

      <Grid item md={12} className={classes.gridLessPadding}>
        <Typography variant="h4">Vehicles</Typography>
        {user ? <ViewUserDetails userId={user.id} /> : null}
      </Grid>
      <Dialog fullWidth maxWidth="lg" open={open} onClose={handleClose}>
        <DialogTitle>User Document</DialogTitle>
        <DialogContent>
          {selectedDocument != null ? (
            <>
              <DialogContentText>
                Document name: {selectedDocument.filename}
              </DialogContentText>
              <iframe
                className={classes.centerDocuments}
                frameBorder="10"
                height="350px"
                width="100%"
                title="User Document"
                src={selectedDocument.filePath}
              />
            </>
          ) : null}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </Content>
  );
});

export default ViewUser;
