import { makeObservable, observable, runInAction } from 'mobx';
import uniqBy from 'lodash/uniqBy';
import { BaseStore, RequestConfig } from '../util/baseStore';
import { IRenewal, PaginatedRenewalSchema, RenewalSchema } from './validation';
import { IPaginated } from '../vehicles/vehicles';
import { PaginatedInput } from '../vehicles/validation';

class V2RenewalsStore extends BaseStore {
  constructor(config: RequestConfig) {
    super(config);
    makeObservable(this, {
      renewals: observable,
    });
  }

  // State
  loading = false;
  renewals: IPaginated<IRenewal> = {
    total: 0,
    results: [],
    skip: 0,
    take: 0,
  };

  // Actions
  async addRenewal(input: Partial<IRenewal>) {
    return this.withState(
      this.sendRequest({
        method: 'POST',
        path: 'renewals',
        validation: RenewalSchema,
        body: input,
      }),
    );
  }

  async update(id: number, payload: Partial<IRenewal>) {
    return this.withState(
      this.sendRequest({
        method: 'PATCH',
        path: 'renewals',
        validation: RenewalSchema,
        body: { id, ...payload },
      }),
    );
  }

  async getRenewals({ renewalId, ...input }: PaginatedInput) {
    this.setLoading(true);

    const params: Record<string, any> = {
      ...input,
    };

    if (renewalId) {
      params.id = renewalId;
    }

    const result = await this.sendRequest({
      method: 'GET',
      path: 'renewals',
      validation: PaginatedRenewalSchema,
      params,
    });

    runInAction(() => {
      this.loading = false;
      if (result.success) {
        this.renewals = {
          total: result.data.count,
          results: uniqBy(
            [...this.renewals.results, ...result.data.entries],
            (v) => v.id,
          ),
          skip: input.skip,
          take: input.take,
        };
      }
    });

    return result;
  }

  async getRenewal(id: number): Promise<IRenewal | null> {
    const result = await this.getRenewals({
      renewalId: id,
      skip: 0,
      take: 1,
    });

    if (!result.success) {
      return null;
    }

    if (result.data.count > 1) {
      throw new Error(`Invalid data: Multiple renewals with id ${id}`);
    }
    if (result.data.count === 0) {
      return null;
    }
    return result.data.entries[0];
  }

  getById(id: number) {
    const renewal = this.renewals.results.find((v) => v.id === id);
    if (!renewal) {
      throw new Error('No renewal'); // TODO: actual check
    }

    return renewal;
  }
}

export default V2RenewalsStore;
