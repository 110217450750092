import React from 'react';
// @material-ui
import { Typography } from '@material-ui/core';
// @local
import { IRenewal } from 'logic/stores/renewals/validation';
import useStyles from '../styles';

interface Props {
  renewal: IRenewal;
}

const RenewalState = ({ renewal }: Props) => {
  const classes = useStyles({});

  switch (renewal.status) {
    case 'submitted by user':
      return (
        <Typography color="primary" className={classes.textInline}>
          (1/7)
        </Typography>
      );
    case 'scheduled':
      return (
        <Typography color="primary" className={classes.textInline}>
          (2/7)
        </Typography>
      );
    case 'processing':
      return (
        <Typography color="primary" className={classes.textInline}>
          (3/7)
        </Typography>
      );
    case 'delivery requested':
      return (
        <Typography color="primary" className={classes.textInline}>
          (4/7)
        </Typography>
      );
    case 'delivered':
      return (
        <Typography color="primary" className={classes.textInline}>
          (6/7)
        </Typography>
      );
    case 'license updated':
      return (
        <Typography color="primary" className={classes.textInline}>
          (7/7)
        </Typography>
      );
    default:
      return null;
  }
};
export default RenewalState;
