import { Theme } from '@material-ui/core/styles/createMuiTheme';

import { makeStyles, createStyles } from '@material-ui/core/styles';

export default makeStyles((theme: Theme) =>
  createStyles({
    footerLogo: {
      bottom: '65px',
      right: '15px',
      color: '#777 !important',
      lineHeight: 'inherit',
      display: 'inline-block',
      padding: '10px',
      width: '2.5em',
    },
    footer: {
      textAlign: 'left',
      width: '100%',
      color: 'white',
      position: 'absolute',
      bottom: '0',
      // remove white space with height for footer
      height: '7.4rem',
      [theme.breakpoints.up('lg')]: {
        height: '5rem',
      },
    },
    footerBackground: {
      background: '#eee !important',
      width: '100% !important',
    },
    removeHoverColor: {
      // remove button color on hover in footer
      '&:hover': {
        background: 'none',
      },
    },
    flexFooter: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      margin: '0 auto',
      [theme.breakpoints.up('md')]: {
        maxWidth: '1140px',
      },
      [theme.breakpoints.down('md')]: {
        flexDirection: 'column',
      },
      flexShrink: 0,
    },
    footSpan: {
      fontSize: '20px',
      color: '#777',
      fontWeight: 200,
      marginBottom: '4px',
    },
    flexLeftContent: {
      display: 'flex',
    },
    footerLink: {
      marginRight: '10px',
      color: 'black',
      fontSize: '18px',
      textDecoration: 'none',
      fontWeight: 100,
      justifyContent: 'flex-end',
    },
  }),
);
