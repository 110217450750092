import React, { useState } from 'react';
import { observer } from 'mobx-react-lite';
import { useLocation, useHistory } from 'react-router-dom';
// @material-ui
import {
  List,
  ListItem,
  ListItemAvatar,
  Avatar,
  ListItemText,
  Typography,
  Grid,
  Button,
  ListItemSecondaryAction,
} from '@material-ui/core';
// @icons
import { Commute } from '@material-ui/icons';
// @logic
import { useStore } from 'logic/store';
// @local
import { IVehicle } from 'logic/stores/vehicles/validation';
import useStyles from './styles';

interface ViewDetails {
  userId: number;
}

const ViewUserDetails = observer(({ userId }: ViewDetails) => {
  const history = useHistory();
  const classes = useStyles({});
  const store = useStore();
  const [vehicles, setVehicles] = useState<IVehicle[]>([]);
  const location = useLocation();

  React.useEffect(() => {
    fetchVehicles();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const fetchVehicles = async () => {
    if (location.pathname.includes('/admin')) {
      const result = await store.admin.getVehicles({
        userId,
        skip: 0,
        take: 10,
      });
      if (result.success) {
        setVehicles(result.data.entries);
      }
    } else {
      // TODO: check
      const result = await store.vehicles.getVehicles({
        userId,
        skip: 0,
        take: 10,
      });
      if (result.success) {
        setVehicles(result.data.entries);
      }
    }
  };

  const matchUrlParam = (id: number) => {
    const match = location.pathname;
    if (match.includes('/admin')) {
      history.push(`/admin/view-vehicle/${id}`);
    } else {
      history.push(`/view-vehicle/${id}`);
    }
  };

  return (
    <List>
      {vehicles.map((car) => (
        <>
          <ListItem key={car.id}>
            <ListItemAvatar>
              <Avatar className={`${classes.blueAvatar} ${classes.large}`}>
                <Commute fontSize="large" />
              </Avatar>
            </ListItemAvatar>
            <ListItemText
              className={`${classes.divSpace} ${classes.uppercase}`}
              primary={car.model ? `${car.make} - ${car.model}` : car.make}
              secondary={
                <>
                  <div className={classes.secondaryItem}>
                    <Grid>
                      <Typography
                        variant="body2"
                        color="textPrimary"
                        className={classes.uppercase}
                      >
                        {car.licenseNumber}
                      </Typography>
                    </Grid>
                    <Grid>
                      <Typography
                        component="span"
                        variant="body1"
                        color="textPrimary"
                        className={classes.uppercase}
                      >
                        {car.registrationRegion}
                      </Typography>
                    </Grid>
                  </div>
                </>
              }
            />
            <ListItemSecondaryAction>
              <Button
                color="primary"
                variant="outlined"
                onClick={() => matchUrlParam(car.id)}
              >
                View Vehicle
              </Button>
            </ListItemSecondaryAction>
          </ListItem>
        </>
      ))}
    </List>
  );
});
export default ViewUserDetails;
