import Joi from 'joi';
import { IUser, UserSchema } from '../users/validation';

export type LoginResponse = {
  token: string;
  user: IUser;
};
export const LoginSchema = Joi.object<LoginResponse>({
  token: Joi.string().required(),
  user: UserSchema.required(),
});

export type RecoverAccountResponse = {
  message: string;
  resetToken: string;
};
export const RecoverAccountSchema = Joi.object<RecoverAccountResponse>({
  resetToken: Joi.string().required(),
  message: Joi.string().required(),
});

export type VerifyAccountInput =
  | {
      type: 'code';
      email: string;
      code: string;
    }
  | {
      type: 'token';
      token: string;
    };

export type VerifyAccountResponse = {
  message: string;
  user: IUser;
};

export const VerifyAccountResponse = Joi.object<VerifyAccountResponse>({
  message: Joi.string().required(),
  user: UserSchema.required(),
});

export type SetNewPasswordInput = {
  email: string;
  password: string;
  resetToken: string;
};
