import React from 'react';
import { observer } from 'mobx-react-lite';
import { toast } from 'react-toastify';
// @material-ui
import { useTheme } from '@material-ui/core/styles';
import {
  IconButton,
  Dialog,
  Grid,
  Typography,
  Button,
  useMediaQuery,
  TextField,
} from '@material-ui/core';
// @form
import * as Yup from 'yup';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
// @icons
import { Close as CloseIcon } from '@material-ui/icons';
// @logic
import { useStore } from 'logic/store';
// @components
import GooglePlaces, { PlacesResult } from 'components/utilities/Google/Places';
import GoogleMap from 'components/utilities/Google/Map/Iframe';
// @local
import { IUser } from 'logic/stores/users/validation';
import useStyles from './styles';

interface Props {
  open: boolean;
  onClose: (user?: IUser) => void;
}

interface UserAddress {
  deliveryAddress: string;
  deliveryCoordinates: [number, number];
  deliveryAddressAdditional?: string;
}
const validationSchema = Yup.object().shape({
  deliveryAddress: Yup.string().label('delivery address').required(),
  deliveryAddressAdditional: Yup.string()
    .label('Additional Address Information')
    .nullable(true),
});

const UpdateRenewalDelivery = observer(({ open, onClose }: Props) => {
  const theme = useTheme();
  const classes = useStyles();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));

  const store = useStore();
  const { userId, user } = store.auth;

  // Default to sorted address
  const defaultCoords = [-26.0406575, 28.0596701] as [number, number];

  const [loading, setLoading] = React.useState(false);
  const [title, setTitle] = React.useState('');
  const [address, setAddress] = React.useState('');
  const [, setAddressCoords] = React.useState(defaultCoords);

  const { control, errors, handleSubmit, watch, setValue, register } =
    useForm<UserAddress>({
      resolver: yupResolver(validationSchema),
      defaultValues: user || {
        deliveryAddress: '',
      },
    });
  const { deliveryAddress } = watch();

  const updateUser = async (payload: UserAddress) => {
    setLoading(true);
    const result = await store.users.update(userId!, payload);
    setLoading(false);
    if (result && result.success) {
      toast.success('Profile has been updated');
      onClose(result.data);
    }
  };

  const getSelectedLocation = (location: PlacesResult) => {
    setTitle(location.name);
    if (location.formatted_address) {
      setAddress(location.formatted_address);
      setValue('deliveryAddress', location.formatted_address);
    }
    setAddressCoords(location.coordinates);
    setValue('deliveryCoordinates', location.coordinates);
  };

  React.useEffect(() => {
    if (user != null) {
      if (user.deliveryCoordinates) {
        setAddressCoords(user.deliveryCoordinates);
      }
      if (user.deliveryAddress) {
        setAddress(user.deliveryAddress);
      }
    }
  }, [user]);

  React.useEffect(() => {
    register({ name: 'deliveryAddress' });
    register({ name: 'deliveryCoordinates' });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [register]);

  return (
    <Dialog
      fullWidth
      fullScreen={fullScreen}
      maxWidth="md"
      open={open}
      onClose={() => onClose()}
      aria-labelledby="Update Delivery Address"
      className={classes.renewalDialog}
    >
      <Grid container justify="center" className={classes.gridBackground}>
        <Grid item md={11} xs={12}>
          <form onSubmit={handleSubmit(updateUser)}>
            <div>
              <div className={classes.gridFlex}>
                <Typography
                  variant="h4"
                  align="center"
                  className={classes.textMarginTop}
                  color="primary"
                >
                  Delivery Address
                </Typography>
                <IconButton onClick={() => onClose()}>
                  <CloseIcon />
                </IconButton>
              </div>
              {deliveryAddress != null || !!address ? (
                <Controller
                  as={
                    <TextField
                      fullWidth
                      color="secondary"
                      margin="normal"
                      variant="outlined"
                      label="Complex / Building (Optional)"
                      placeholder="Complex or Building Name, unit number or floor"
                      error={Boolean(errors.deliveryAddressAdditional)}
                      helperText={
                        errors.deliveryAddressAdditional &&
                        errors.deliveryAddressAdditional.message
                      }
                    />
                  }
                  name="deliveryAddressAdditional"
                  control={control}
                />
              ) : null}
              <GooglePlaces
                getSelectedLocation={getSelectedLocation}
                textfieldProps={{
                  placeholder: address,
                  variant: 'outlined',
                  label: 'Updated Location',
                  margin: 'normal',
                  fullWidth: true,
                }}
              />
              {deliveryAddress == null ? (
                <GoogleMap title={title} address={address} />
              ) : (
                <GoogleMap title={title} address={deliveryAddress} />
              )}
            </div>
            <div className={classes.btnMarginButton}>
              <Button
                fullWidth
                variant="contained"
                size="large"
                disabled={loading}
                color="primary"
                type="submit"
              >
                Update Address
              </Button>
            </div>
          </form>
        </Grid>
      </Grid>
    </Dialog>
  );
});

export default UpdateRenewalDelivery;
