import React from 'react';
import { observer } from 'mobx-react-lite';
import Numeral from 'numeral';
import moment from 'moment';
// @material-ui
import {
  AppBar,
  TableFooter,
  Hidden,
  TablePagination,
  TableRow,
  TableHead,
  TableCell,
  Table,
  TableBody,
  TextField,
  Grid,
  Paper,
  CircularProgress,
  Toolbar,
  Typography,
} from '@material-ui/core';
// @icons
import { Search as SearchIcon } from '@material-ui/icons';
// @logic
import { useStore } from 'logic/store';
// @components
import { formatCurrency } from 'utilities/handleCurrency';
// @local
import useStyles from './styles';
import Update from './Update';

type Order = 'asc' | 'desc';

function descendingComparator<T>(a: T, b: T, orderBy: keyof T) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator<Key extends keyof any>(
  order: Order,
  orderBy: Key,
): (a: { [key in Key]: any }, b: { [key in Key]: any }) => number {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort<T>(array: T[], comparator: (a: T, b: T) => number) {
  const stabilizedThis = array.map((el, index) => [el, index] as [T, number]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

const LicenseFees = observer(() => {
  const store = useStore();
  const { managedVariables } = store.managedVariables;
  const classes = useStyles();
  const [searchText, setSearchText] = React.useState('');
  const [variableId, setVariableId] = React.useState<number | null>(null);
  const [typingTimeout, setTypeTimeout] = React.useState<NodeJS.Timeout | null>(
    null,
  );
  const [order] = React.useState<Order>('asc');
  const [orderBy] = React.useState<'updatedAt' | 'readableName'>(
    'readableName',
  );
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  const [page, setPage] = React.useState(0);

  React.useEffect(() => {
    store.managedVariables.get();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  React.useEffect(() => {
    store.managedVariables.get();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rowsPerPage, page, orderBy, order]);

  const searchFor = (searchEvent: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = searchEvent.target;
    if (typingTimeout != null) {
      clearTimeout(typingTimeout);
    }
    setSearchText(value);
    setTypeTimeout(
      // @ts-ignore To-Do Fix Type
      setTimeout(() => {
        store.managedVariables.search(value);
      }, 300),
    );
  };

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const clearItem = () => {
    setVariableId(null);
    store.managedVariables.get();
  };

  const viewItem = (id: number | void) => () => {
    if (id != null) {
      setVariableId(id);
    }
  };

  const emptyRows =
    rowsPerPage -
    Math.min(rowsPerPage, managedVariables.length - page * rowsPerPage);

  const rowStyles = 49 * emptyRows;
  const heightWithRow = [{ height: rowStyles }].join(' ');

  return (
    <Paper className={classes.paper} elevation={0}>
      <Typography variant="h4" align="center">
        Manage Variables
      </Typography>
      <AppBar
        className={classes.searchBar}
        position="static"
        color="default"
        elevation={0}
      >
        <Toolbar>
          <Grid container spacing={2} alignItems="center">
            <Hidden smDown>
              <Grid item>
                <SearchIcon className={classes.block} color="inherit" />
              </Grid>
            </Hidden>
            <Grid item xs>
              <TextField
                fullWidth
                color="secondary"
                placeholder="Search by region or vehicle type"
                onChange={searchFor}
                value={searchText}
                InputProps={{
                  disableUnderline: true,
                  className: classes.searchInput,
                }}
              />
            </Grid>
            <Grid item>
              {variableId != null ? (
                <Update variableId={variableId} closeModal={clearItem} />
              ) : null}
            </Grid>
          </Grid>
        </Toolbar>
      </AppBar>
      <div className={classes.contentWrapper}>
        {managedVariables.length < 1 ? (
          <Typography color="textSecondary" align="center">
            No variables found...
          </Typography>
        ) : store.managedVariables.loading === true ? (
          <div className={classes.centerProgress}>
            Fetching managed variables...
            <CircularProgress className={classes.progress} />
          </div>
        ) : (
          <div>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Variable</TableCell>
                  <TableCell>Value</TableCell>
                  <TableCell>Measured in</TableCell>
                  <TableCell>Date</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {stableSort(managedVariables, getComparator(order, orderBy))
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((variable) => (
                    <TableRow
                      onClick={viewItem(variable.id)}
                      key={variable.id}
                      hover
                      role="checkbox"
                      classes={{
                        hover: classes.rowHover,
                      }}
                    >
                      <TableCell className={classes.capitalize}>
                        {variable.readableName}
                      </TableCell>
                      <TableCell>
                        {variable.measuredIn === 'zar'
                          ? formatCurrency(variable.value)
                          : Numeral(variable.value).format('0,0')}
                      </TableCell>
                      <TableCell className={classes.uppercase}>
                        {variable.measuredIn}
                      </TableCell>
                      <TableCell>
                        {moment(variable.updatedAt).format('DD-MM-YYYY')}
                      </TableCell>
                    </TableRow>
                  ))}
                <Hidden smDown>
                  {emptyRows > 0 && (
                    <TableRow className={heightWithRow}>
                      <TableCell colSpan={6} />
                    </TableRow>
                  )}
                </Hidden>
              </TableBody>
              <TableFooter>
                <TableRow>
                  <TablePagination
                    rowsPerPageOptions={[5, 10, 25]}
                    count={managedVariables.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    backIconButtonProps={{
                      'aria-label': 'Previous Page',
                    }}
                    nextIconButtonProps={{
                      'aria-label': 'Next Page',
                    }}
                    classes={{
                      toolbar: classes.paginationToolbar,
                      actions: classes.paginationActions,
                    }}
                    onChangePage={handleChangePage}
                    onChangeRowsPerPage={handleChangeRowsPerPage}
                  />
                </TableRow>
              </TableFooter>
            </Table>
          </div>
        )}
      </div>
    </Paper>
  );
});

export default LicenseFees;
