import React, { useState } from 'react';
import { observer } from 'mobx-react-lite';
// @material-ui
import {
  Grid,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  Paper,
  DialogActions,
  Button,
  Card,
  CardActionArea,
  CardMedia,
  CardContent,
  Typography,
  CardActions,
  Divider,
} from '@material-ui/core';
// @logic
import { useStore } from 'logic/store';
// @components
import { IDocument } from 'logic/stores/document/validation';
import pdfImage from './img/pdf.png';
// @local
import useStyles from './styles';

interface TemplateProps {
  documents: IDocument[];
}

const ViewRenewalDocuments = observer(({ documents }: TemplateProps) => {
  const store = useStore();
  const classes = useStyles();
  const { permissions } = store.auth;

  const [open, setOpen] = React.useState(false);
  const handleDocumentClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    handleClickOpen(parseInt(event.currentTarget.id, 10));
  };

  const isAdmin = permissions?.find((n) => n === 'isAdmin');

  const [selectedDocument, setSelectedDocument] =
    React.useState<IDocument | null>(null);

  const handleClickOpen = (documentId: number) => {
    setOpen(true);
    const clickedDocument = documents.find((d) => d.id === documentId);
    setSelectedDocument(clickedDocument ?? null);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12} container spacing={3}>
          {documents
            ? documents
                .filter((n) => {
                  if (n.access === 'admin') {
                    return isAdmin;
                  }
                  return true;
                })
                .map((document: IDocument) => (
                  <>
                    <Grid item md={4} xs={12}>
                      <Card>
                        <CardActionArea
                          id={`${document.id}`}
                          onClick={handleDocumentClick}
                        >
                          <CardMedia
                            component="img"
                            alt={document.filename}
                            height="200"
                            image={
                              document.filePath.endsWith('.pdf')
                                ? pdfImage
                                : document.filePath
                            }
                            title={document.filename}
                          />
                        </CardActionArea>
                        <CardContent>
                          <Typography
                            variant="body2"
                            color="textSecondary"
                            component="p"
                          >
                            {document.filename}
                          </Typography>
                          <Typography
                            variant="body1"
                            color="textSecondary"
                            component="h1"
                          >
                            {`${(document.kbSize / 1000000).toFixed(3)} MB`}
                          </Typography>
                        </CardContent>
                        <Divider />
                        <CardActions className={classes.noPadding}>
                          <a
                            className={classes.downloadBtn}
                            href={document.filePath}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            View
                          </a>
                        </CardActions>
                      </Card>
                    </Grid>
                  </>
                ))
            : null}
        </Grid>
      </Grid>
      {selectedDocument != null ? (
        <Dialog fullWidth maxWidth="lg" open={open} onClose={handleClose}>
          <DialogTitle>Document Name: {selectedDocument.filename}</DialogTitle>

          <DialogContent>
            <DialogContentText>
              Document type: {selectedDocument.documentType}
            </DialogContentText>

            <Paper elevation={0}>
              {selectedDocument != null ? (
                <Grid item md={12} xs={12} className={classes.gridCenterMargin}>
                  {selectedDocument.filePath.endsWith('.pdf') ? (
                    <>
                      <embed
                        height="400px"
                        width="100%"
                        title="Vehicle Documents"
                        src={selectedDocument.filePath}
                      />
                    </>
                  ) : (
                    <>
                      <img
                        className={classes.img100}
                        src={selectedDocument.filePath as string}
                        alt="Vehicle Documents"
                      />
                    </>
                  )}
                </Grid>
              ) : null}
            </Paper>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose} color="primary">
              Close
            </Button>
          </DialogActions>
        </Dialog>
      ) : null}
    </>
  );
});

export default ViewRenewalDocuments;
