import Joi from 'joi';
import {
  IVehicle,
  PaginatedResponse,
  VehicleSchema,
} from '../vehicles/validation';
import { IUser, UserSchema } from '../users/validation';
import { RenewalStatus } from './types';

export function capitalizeWords(inputString: string) {
  return inputString.replace(/(^|\s|-)\S/g, function (match) {
    return match.toUpperCase();
  });
}

export const matchLicenseToProvince = {
  'western cape': ['CA', 'CAA', 'CY', 'CF', 'CYM'],
  'eastern cape': ['FMJ'],
  'kwazulu-natal': ['NN'],
  mpumalanga: ['CDS'],
  limpopo: ['CHZ'],
  gauteng: ['GP', 'BBC', 'BC'],
};

export interface LicenceRenewalQuote {
  tareAmount: number;
  arrears: number;
  penalties: number;
  transactionFee: number;
  total: number;
}

export const LicenseRenewalQuoteSchema = Joi.object<LicenceRenewalQuote>({
  tareAmount: Joi.number().required(),
  arrears: Joi.number().required(),
  penalties: Joi.number().required(),
  transactionFee: Joi.number().required(),
  total: Joi.number().required(),
});

export interface IRenewal {
  id: number;
  dateOrdered: Date;
  originalExpiry: Date;
  paymentReference: string;
  paymentInCents: string;
  currency: string;
  status: RenewalStatus;
  webhookSuccessful?: boolean;
  webhookResponse?: string;
  createdAt: Date;
  updatedAt: Date;
  user?: IUser;
  userId?: number;
  vehicle?: IVehicle;
  vehicleId?: number;
}

export const RenewalSchema = Joi.object<IRenewal>({
  id: Joi.number().required(),
  dateOrdered: Joi.date().required(),
  originalExpiry: Joi.date().required(),
  paymentReference: Joi.string().required(),
  paymentInCents: Joi.any().required(),
  currency: Joi.string().required(),
  status: Joi.string().required(),
  webhookSuccessful: Joi.boolean().allow(null),
  webhookResponse: Joi.string().allow(null),
  createdAt: Joi.date().required(),
  updatedAt: Joi.date().required(),
  user: UserSchema.allow(null),
  userId: Joi.number().allow(null),
  vehicle: VehicleSchema.allow(null),
  vehicleId: Joi.number().allow(null),
});

export const PaginatedRenewalSchema = Joi.object<PaginatedResponse<IRenewal>>({
  count: Joi.number().required(),
  entries: Joi.array<IRenewal[]>().items(RenewalSchema),
});
