import { makeObservable, observable, runInAction } from 'mobx';
import { BaseStore, RequestConfig } from '../util/baseStore';
import { IUser, UserSchema } from './validation';
import { IPaginated } from '../vehicles/vehicles';
import { MessageSchema } from '../validation';
import V2AuthStore from '../auth/auth';

export type IQuery = {
  skip?: number;
  take?: number;
  order?: 'desc' | 'asc';
  orderBy?: 'createdAt';
  search?: string;
  status?: string;
  from?: Date;
  to?: Date;
  userId?: number;
};

class V2UsersStore extends BaseStore {
  constructor(config: RequestConfig, private auth: V2AuthStore) {
    super(config);
    makeObservable(this, {
      users: observable,
    });
  }

  loading = false;
  users: IPaginated<IUser> = {
    total: 0,
    results: [],
    skip: 0,
    take: 0,
  };

  async getById(id: number) {
    return this.withState(
      this.sendRequest({
        method: 'GET',
        path: `users/${id}`,
        validation: UserSchema,
      }),
    );
  }

  async update(id: number, payload: Partial<IUser>, permissions?: number[]) {
    return this.withState(
      (async () => {
        const result = await this.sendRequest({
          method: 'PATCH',
          path: 'users',
          validation: UserSchema,
          body: { id, ...payload },
        });
        if (result.success) {
          await this.auth.rehydrate();
        }
        return result;
      })(),
    );
  }

  // TODO: admin only
  remove(id: number) {
    return this.withState(
      this.sendRequest({
        method: 'DELETE',
        path: 'users',
        validation: UserSchema,
        params: { id },
      }),
    );
  }

  async uploadPassport(
    file: File,
    userId: number,
  ): Promise<{ success: boolean }> {
    this.sendRequest({
      method: 'POST',
      path: 'users/upload',
      validation: MessageSchema,
    });
    return { success: true };
  }

  async uploadId(file: File) {
    return this.uploadFile('uploads/id', file);
  }

  async uploadBusinessRegister(
    file: File,
    userId: number,
  ): Promise<{ success: boolean }> {
    this.sendRequest({
      method: 'POST',
      path: 'users/upload',
      validation: MessageSchema,
    });
    return { success: true };
  }

  async uploadProxyIdRegister(
    file: File,
    userId: number,
  ): Promise<{ success: boolean }> {
    this.sendRequest({
      method: 'POST',
      path: 'users/upload',
      validation: MessageSchema,
    });
    return { success: true };
  }
}
export default V2UsersStore;
