import Joi from 'joi';
import { DeliverySchema, IDelivery } from '../deliveries/validations';
import { DocumentSchema, IDocument } from '../document/validation';
import { IRenewal, RenewalSchema } from '../renewals/validation';
import { IUser, UserSchema } from '../users/validation';
import {
  IVehicle,
  PaginatedResponse,
  VehicleSchema,
} from '../vehicles/validation';

export type EventType = 'vehicle' | 'renewal' | 'delivery' | 'document';

export type EventStatus =
  | 'user joined'
  | 'vehicle created'
  // Renewal Events
  | 'submitted by user'
  | 'scheduled'
  | 'processing'
  | 'ready to collect'
  | 'license updated'
  | 'admin to action'
  | 'user to action'
  | 'cancelled'
  // Delivery Events
  | 'delivery requested'
  | 'delivered'
  | 'pending drop-off'
  | 'pending pick-up'
  | 'ready'
  | 'collected'
  | 'pending quote'
  | 'pick-up exception'
  | 'cancelled quote'
  | 'drop-off exception'
  | 'returned to sender'
  | 'cancelled with fee'
  | 'refunded';

export type IEvent = {
  id: number;
  eventType: EventType;
  status: EventStatus;
  description: string;
  createdAt: Date;
  updatedAt: Date;
  userId?: number;
  user?: IUser;
  vehicleId?: number;
  vehicle?: IVehicle;
  renewalId?: number;
  renewal?: IRenewal;
  deliveryId?: number;
  delivery?: IDelivery;
  documentId?: number;
  document?: IDocument;
};

export const EventSchema = Joi.object<IEvent>({
  id: Joi.number().required(),
  eventType: Joi.string().required(),
  status: Joi.string().required(),
  description: Joi.string().required(),
  createdAt: Joi.date().required(),
  updatedAt: Joi.date().required(),
  userId: Joi.number().allow(null),
  user: UserSchema.allow(null),
  vehicleId: Joi.number().allow(null),
  vehicle: VehicleSchema.allow(null),
  renewalId: Joi.number().allow(null),
  renewal: RenewalSchema.allow(null),
  deliveryId: Joi.number().allow(null),
  delivery: DeliverySchema.allow(null),
  documentId: Joi.number().allow(null),
  document: DocumentSchema.allow(null),
});

export const PaginatedEventSchema = Joi.object<PaginatedResponse<IEvent>>({
  count: Joi.number().required(),
  entries: Joi.array<IEvent[]>().items(EventSchema),
});
