import React from 'react';
import { observer } from 'mobx-react-lite';
import { Link } from 'react-router-dom';
// @material-ui
import { Button, Typography, Grid } from '@material-ui/core';
// @logic
import { useStore } from 'logic/store';
// @components
import { formatCurrency } from 'utilities/handleCurrency';
import Content from 'components/Layout/Content';
// @local
import useStyles from './styles';
import BaseFooterTopStyles from '../Layout/BaseFooterTopStyles';
import FAQTableStyles from '../Layout/FAQTableStyles';

const FAQ = observer(() => {
  const store = useStore();
  const classes = useStyles({
    company: store.companies.company,
    whitelabel: store.whitelabel,
  });
  const footerStyles = BaseFooterTopStyles({});
  const faqTableStyles = FAQTableStyles({});
  const { managedVariables } = store.managedVariables;
  const [serviceFeeValue, setServiceFeeValue] = React.useState(0);

  React.useEffect(() => {
    if (managedVariables == null || managedVariables.length == 0) {
      return;
    }

    const serviceFee = managedVariables.find(
      (mv) => mv.readableName === 'serviceFee',
    );
    setServiceFeeValue(serviceFee?.value ?? 0);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(managedVariables)]);

  React.useEffect(() => {
    if (!managedVariables || managedVariables.length === 0) {
      store.managedVariables.get();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [managedVariables]);

  const featureTextWithWidth = [classes.featureText, classes.width200].join(
    ' ',
  );
  const tableRightTextStyles = {
    root: faqTableStyles.tableTextRight,
  };

  return (
    <div className={classes.cover}>
      <Grid container className={classes.darkBanner}>
        <Grid item xs="auto" className={classes.gridMaxCenter}>
          <div className={footerStyles.headTop}>
            <Typography
              variant="h2"
              align="center"
              className={`${classes.btnDark} ${classes.homeHead}`}
            >
              Frequently Asked Questions
            </Typography>
            <Typography
              variant="h5"
              align="center"
              className={`${classes.heroP2} ${classes.padding10}`}
            >
              A few other things you might want to know.
            </Typography>
            <Typography
              variant="h5"
              align="center"
              className={`${classes.contentFaq}`}
            >
              What documents do I need?
            </Typography>

            <Typography
              variant="body1"
              align="center"
              className={`${classes.featureText}`}
            >
              All you need is to upload a picture of your ID and a picture of
              your licence disc. That’s it! No need to fill out any forms –
              we’ll sort out the rest.
            </Typography>

            <Typography
              variant="h5"
              align="center"
              className={`${classes.homeFaqText} ${classes.padding10}`}
            >
              What are the costs?
            </Typography>
            <p className={classes.featureText}>
              This is what your invoice will look like
            </p>
            <table>
              <tbody>
                <tr>
                  <td>
                    <Typography
                      variant="body1"
                      align="left"
                      className={featureTextWithWidth}
                    >
                      <b>Your licence disc fee</b>
                    </Typography>
                  </td>
                  <td>
                    <Typography
                      variant="body2"
                      align="center"
                      className={classes.featureText}
                      classes={tableRightTextStyles}
                    >
                      This differs for everyone, and is calculated according to
                      car weight.
                    </Typography>
                  </td>
                </tr>
                <tr>
                  <td>
                    <Typography className={featureTextWithWidth} align="left">
                      <b>Sorted’s service fee</b>
                    </Typography>
                  </td>
                  <td>
                    <Typography
                      variant="body2"
                      className={classes.featureText}
                      align="center"
                      classes={tableRightTextStyles}
                    >
                      {serviceFeeValue ? formatCurrency(serviceFeeValue) : null}
                    </Typography>
                  </td>
                </tr>
              </tbody>
            </table>

            <Typography
              variant="h5"
              align="center"
              className={`${classes.contentFaq} ${classes.padding10}`}
            >
              What happens if I have fines to pay?
            </Typography>

            <Typography
              variant="body1"
              align="center"
              className={`${classes.featureText} ${classes.padding10}`}
            >
              If you have fines to pay, you can still get a new disc. The only
              time you’ll be prevented from renewing your disc is if your fines
              are so overdue that they’ve turned into enforcements orders or
              warrants of arrest, which must then be settled.
            </Typography>

            <Typography
              variant="body1"
              align="center"
              className={`${classes.featureText} ${classes.padding10}`}
            >
              These sounds scary, but don’t panic just yet. It&apos;s as simple
              as letting us settle the outstanding amount for you. There may be
              a slight delay with processing your application,but we can still
              do so! And then you can breathe a sigh of relief that you’ve found
              out about it through Sorted, versus at a roadblock.
            </Typography>

            <Typography
              variant="h5"
              align="center"
              className={`${classes.contentFaq} ${classes.padding10}`}
            >
              What happens if my disc has expired already?
            </Typography>

            <Typography
              variant="body1"
              align="center"
              className={`${classes.featureText} ${classes.padding10}`}
            >
              All that happens in this case is that there will be a penalty
              added each month it’s overdue, which is 10% of the total price of
              your disc. Compound interest can be a sneaky thing though, so try
              renewing your disc as soon as possible to avoid exponential leaps
              in price.
            </Typography>

            <Typography
              variant="body1"
              align="center"
              className={`${classes.featureText} ${classes.padding10}`}
            >
              On a positive note, you do get a 21-day grace period from the time
              that your disc has expired till penalties kick in.
            </Typography>

            <Typography
              variant="h5"
              align="center"
              className={`${classes.contentFaq} ${classes.padding10}`}
            >
              How long does it usually take?
            </Typography>

            <Typography
              variant="body1"
              align="center"
              className={`${classes.featureText} ${classes.padding10}`}
            >
              From the time payment is received, you can expect to get your disc
              in about 2 – 7 working days. Certain delays we cant always
              pre-empt (such as the settling of warrants or inefficient
              government systems), but you can be assured that speediness is top
              of mind for us, and we&apos;ll keep you updated every step of the
              way.
            </Typography>
          </div>
        </Grid>
      </Grid>
      <Content
        className={` ${footerStyles.headTop}`}
        containerClass={`${footerStyles.contentMidWhite}`}
      >
        <Grid item sm={12}>
          <Typography
            variant="h4"
            align="center"
            className={`${classes.typoHead} ${classes.padding10}`}
          >
            Ready to get Sorted?
          </Typography>
          <div>
            <Button component={Link} to="/signup" className={classes.btnBlue}>
              Sign Up
            </Button>
          </div>
        </Grid>
      </Content>
    </div>
  );
});

export default FAQ;
