import { makeObservable, observable } from 'mobx';
import { BaseStore, RequestConfig } from '../util/baseStore';
import { DeliverySchema } from './validations';

export type CreateDeliveryInput = {
  renewalId: number;
  userId?: number;
  destinationAddress?: string;
  destinationContactName: string;
  destinationPhoneNumber?: string;
  registrationRegion?: string;
};

class V2DeliveriesStore extends BaseStore {
  constructor(config: RequestConfig) {
    super(config);
    makeObservable(this, {});
  }

  // State
  loading = false;

  async create(input: CreateDeliveryInput) {
    return this.withState(
      this.sendRequest({
        method: 'POST',
        path: 'deliveries',
        validation: DeliverySchema,
        body: input,
      }),
    );
  }
}

export default V2DeliveriesStore;
