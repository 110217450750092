import { useStore } from 'logic/store';
import * as React from 'react';
import { Route, useHistory } from 'react-router-dom';

export type ViewType = 'public' | 'admin' | 'onboarding' | 'authenticated';

interface Props {
  component: React.ElementType;
  layout: React.ElementType;
  path: string;
  exact?: boolean;
  childProps?: any;
  view?: ViewType; // TODO: pass this in
}

const AppRoute = ({
  component: Component,
  layout: Layout,
  exact = false,
  childProps,
  view = 'public',
  ...rest
}: Props) => {
  const store = useStore();
  const history = useHistory();

  const { isAdmin } = store.auth;
  const { isAuthed } = store.auth;

  React.useEffect(() => {
    if (!isAdmin && view === 'admin') {
      history.push('/');
    }

    if (!isAuthed && view === 'authenticated') {
      history.push('/login');
    }
  }, [isAdmin, view]);

  return (
    <Route
      {...rest}
      render={(props) => (
        <Layout>
          <div style={{ background: store.companies.company.backgroundColor }}>
            <Component {...props} {...childProps} view={view} />
          </div>
        </Layout>
      )}
    />
  );
};

export default AppRoute;

export type DefaultViewProps = {
  view?: ViewType;
};
