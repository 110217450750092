import createMuiTheme from '@material-ui/core/styles/createMuiTheme';
import { ICompany } from 'logic/stores/company/validation';

export const gradient =
  'linear-gradient(to right, #fbb464, #ffa726, #ffb74d, #ffcc80)';

const defaults = {
  primaryColor: '#64abfb',
  secondaryColor: '#78909c',
  primaryTextColor: '#fff',
  secondaryTextColor: '#fff',
  textColor: '#000',
};

export default function createTheme(company?: ICompany) {
  const primaryColor = company?.primaryColor ?? defaults.primaryColor;
  const secondaryColor = company?.secondaryColor ?? defaults.secondaryColor;
  const primaryTextColor =
    company?.primaryTextColor ?? defaults.primaryTextColor;
  const secondaTextryColor =
    company?.secondaryTextColor ?? defaults.secondaryTextColor;

  const textColor = company?.textColor ?? defaults.textColor;

  return createMuiTheme({
    typography: {
      fontFamily: "'Lato', sans-serif",
      h1: {
        color: '#3F3D56',
      },
      h2: {
        color: '#3F3D56',
      },
      h3: {
        color: '#3F3D56',
        marginBottom: '15px',
      },
      h4: {
        color: '#3F3D56',
        marginBottom: '10px',
      },
    },
    palette: {
      text: {
        primary: textColor,
      },
      primary: {
        light: primaryColor,
        main: primaryColor,
        contrastText: primaryTextColor,
        dark: '#3F3D56',
      },
      secondary: {
        light: '#cfd8dc',
        main: secondaryColor,
        contrastText: secondaTextryColor,
      },
    },

    overrides: {
      MuiFormControl: {
        root: {
          width: '100%',
        },
      },

      MuiButton: {
        label: {
          color: 'black',
        },
      },
    },
  });
}
