import React from 'react';
// @local
import { useStore } from 'logic/store';
import Navbar from './Navbar';
import Footer from './Footer';

// eslint-disable-next-line
interface Props {
  children: React.ReactNode;
}

const WithFooter = ({ children }: Props) => {
  const store = useStore();
  return (
    <div>
      <Navbar />
      {children}
      {!store.whitelabel && <Footer />}
    </div>
  );
};

export default WithFooter;
