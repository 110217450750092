import React from 'react';
import { observer } from 'mobx-react-lite';
// @material-ui
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  TextField,
  DialogTitle,
  IconButton,
  FormGroup,
  FormControlLabel,
  Checkbox,
  Typography,
} from '@material-ui/core';
// @icons
import { Close as CloseIcon } from '@material-ui/icons';
// @form
import { useForm, Controller } from 'react-hook-form';
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
// @logic
import { useStore } from 'logic/store';
import { emailValidation } from 'logic/validation';
// @components
import PhoneInput, {
  formatNumberOut,
  transformForValidation,
} from 'components/utilities/PhoneInput';
// @local
import { IPermission } from 'logic/stores/permissions/validation';
import useStyles from '../styles';

interface FormValues {
  email: string;
  firstName: string;
  lastName: string;
  contactNumber: string;
  permissions: string[];
}

interface CheckBoxState {
  [id: string]: boolean;
}

const phoneRegExp = /^((\+27|27)|0)(\d{2})-?(\d{3})-?(\d{4})$/;

const validationSchema = Yup.object().shape({
  firstName: Yup.string().label('First Name').required(),
  lastName: Yup.string().required().label('Last Name'),
  email: emailValidation.required(),
  contactNumber: Yup.string()
    .transform((value, originalValue) => transformForValidation(value))
    .matches(phoneRegExp, 'Phone number is not valid, i.e. 27824567890')
    .label('Contact Number')
    .required(),
});

type AddUserFormProps = {
  onClose: () => Promise<void>;
};

const AddUserForm = observer<AddUserFormProps>((props) => {
  const store = useStore();
  const classes = useStyles();

  const [open, setOpen] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [testUser, setTestUser] = React.useState(false);
  const [permissions, setPermissions] = React.useState<IPermission[]>([]);

  const { handleSubmit, errors, control, reset } = useForm<FormValues>({
    resolver: yupResolver(validationSchema),
  });

  const [checkboxState, setState] = React.useState<CheckBoxState>({});

  React.useEffect(() => {
    fetchPermissions();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const fetchPermissions = async () => {
    try {
      const response = await store.permissions.load();
      if (response.success && response.data.length > 0) {
        setPermissions(response.data);
        const newState = response.data.reduce((acc, val) => {
          acc[val.id] = false;
          return acc;
        }, {} as CheckBoxState);
        setState(newState);
      }
    } catch (err) {
      // eslint-disable-next-line no-console
      console.log('Could not fetch permissions', err);
    }
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    reset();
    props.onClose();
  };

  const handleChange =
    (id: number) => (event: React.ChangeEvent<HTMLInputElement>) => {
      const newState = { ...checkboxState, [id]: event.target.checked };
      setState(newState);
    };

  const addUser = async (payload: FormValues) => {
    setLoading(true);

    const { contactNumber, ...rest } = payload;

    const permissionIds = Object.entries(checkboxState)
      .filter(([permId, checked]) => {
        return checked === true;
      })
      .map((n) => n[0]);

    const result = await store.admin.addUser({
      ...rest,
      contactNumber: formatNumberOut(contactNumber),
      permissionIds,
      testUser,
      companyId: store.companies.company.id,
    });

    setLoading(false);
    if (result.success) {
      handleClose();
    }
  };

  const toggleTestUser = (e: any, checked: boolean) => {
    setTestUser(checked);
  };

  const permissionsChecks = (
    <FormGroup row>
      {permissions
        .sort((a, b) => a.risk - b.risk)
        .map((n) => (
          <FormControlLabel
            key={n.id}
            control={
              <Checkbox
                checked={checkboxState[n.id]}
                onChange={handleChange(n.id)}
                value={n.id}
              />
            }
            label={n.name}
          />
        ))}
      <FormControlLabel
        control={
          <Checkbox
            checked={testUser}
            onChange={toggleTestUser}
            value={testUser}
          />
        }
        label="Test User"
      />
    </FormGroup>
  );

  return (
    <>
      <Button onClick={handleClickOpen} variant="contained" color="primary">
        <b>Add user</b>
      </Button>
      <Dialog
        fullWidth
        maxWidth="sm"
        open={open}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle>
          <b>Add User</b>
          <IconButton
            aria-label="close"
            onClick={handleClose}
            className={classes.positionIcon}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <form>
            <br />
            <Typography>
              <b>General Details</b>
            </Typography>
            <Controller
              as={
                <TextField
                  fullWidth
                  color="secondary"
                  margin="dense"
                  variant="outlined"
                  placeholder="First Name"
                  error={Boolean(errors.firstName)}
                  helperText={errors.firstName && errors.firstName.message}
                />
              }
              name="firstName"
              control={control}
            />

            <Controller
              as={
                <TextField
                  fullWidth
                  color="secondary"
                  margin="dense"
                  variant="outlined"
                  placeholder="Last Name"
                  error={Boolean(errors.lastName)}
                  helperText={errors.lastName && errors.lastName.message}
                />
              }
              name="lastName"
              control={control}
            />

            <Controller
              as={
                <TextField
                  fullWidth
                  color="secondary"
                  margin="dense"
                  variant="outlined"
                  placeholder="Email"
                  type="email"
                  error={Boolean(errors.email)}
                  helperText={errors.email && errors.email.message}
                />
              }
              name="email"
              control={control}
            />

            <Controller
              render={({ onChange, value, ...props }) => {
                return (
                  <PhoneInput
                    error={Boolean(errors.contactNumber)}
                    helperText={
                      errors.contactNumber && errors.contactNumber.message
                    }
                    value={value}
                    onChange={onChange}
                    {...props}
                  />
                );
              }}
              defaultValue=""
              name="contactNumber"
              control={control}
            />
            <br />
            <Typography>
              <b>User Permissions</b>
            </Typography>
            {permissionsChecks}
          </form>
        </DialogContent>
        <DialogActions className={classes.dialogBorder}>
          <Button onClick={handleClose} color="default">
            Cancel
          </Button>
          <Button
            disabled={loading}
            variant="contained"
            color="primary"
            onClick={handleSubmit(addUser)}
          >
            Add
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
});

export default AddUserForm;
