import { makeObservable, observable, runInAction } from 'mobx';
import uniqBy from 'lodash/uniqBy';
import { BaseStore, RequestConfig } from '../util/baseStore';
import {
  IVehicle,
  LoadVehiclesSchema,
  PaginatedInput,
  PaginatedVehiclesSchema,
  VehicleSchema,
} from './validation';
import { MessageSchema } from '../validation';
import { Region, VehicleType } from './types';

export type AddVehicleInput = {
  make: string;
  model: string;
  year: number;
  licenseNumber: string;
  licenseExpires: Date;
  registrationRegion: Region;
  weightKg: number;
  registerNumber: string;
  vehicleType?: VehicleType;
};

export type IPaginated<T> = {
  total: number;
  results: T[];
  skip: number;
  take: number;
};

class V2VehiclesStore extends BaseStore {
  constructor(config: RequestConfig) {
    super(config);
    makeObservable(this, {
      vehicles: observable,
    });
  }

  // State
  loading = false;
  vehicles: IPaginated<IVehicle> = {
    total: 0,
    results: [],
    skip: 0,
    take: 0,
  };

  // Actions
  async create(input: AddVehicleInput) {
    return this.withState(
      this.sendRequest({
        method: 'POST',
        path: 'vehicles',
        validation: VehicleSchema,
        body: input,
      }),
    );
  }

  async getVehicles(input: PaginatedInput = { skip: 0, take: 10 }) {
    this.setLoading(true);
    const result = await this.sendRequest({
      method: 'GET',
      path: 'vehicles',
      validation: PaginatedVehiclesSchema,
      params: input,
    });

    runInAction(() => {
      this.loading = false;
      if (result.success) {
        this.vehicles = {
          total: result.data.count,
          results: uniqBy(
            [...this.vehicles.results, ...result.data.entries],
            (v) => v.id,
          ),
          skip: input.skip,
          take: input.take,
        };
      }
    });

    return result;
  }

  remove(id: number) {
    return this.withState(
      this.sendRequest({
        method: 'PATCH',
        path: 'vehicles/remove',
        validation: VehicleSchema,
        body: { id },
      }),
    );
  }

  update(id: number, payload: Partial<IVehicle>) {
    return this.withState(
      this.sendRequest({
        method: 'PATCH',
        path: 'vehicles',
        validation: VehicleSchema,
        body: { id, payload },
      }),
    );
  }

  async uploadDocument(file: File, vehicleId: number, userId: number) {
    return this.withState(
      this.sendRequest({
        method: 'POST',
        path: 'vehicle/upload',
        validation: MessageSchema,
        body: { method: 'eeeeek, actually upload the file' },
      }),
    );

    // try {
    //   this.loading = true;

    //   const { success, url } = await apiActions.uploadFile({
    //     file: uploadObject,
    //     fileName: `vehicle-${vehicleId}`,
    //   });
    //   if (success === true && url != null) {
    //     toast.success(`Vehicle document has been uploaded.`);
    //     const res = await this.update(vehicleId, {
    //       userId,
    //       document: url,
    //       documentIdType: uploadObject.type,
    //     });
    //     return res;
    //   }
    //   throw new Error('Vehicle document could not be uploaded.');
    // } catch (err) {
    //   runInAction(() => {
    //     handleStoreError(err, {
    //       id: 'vehicle-uploadDocument',
    //       store: this.store,
    //     });
    //   });

    //   return { success: false, message: err.message };
    // } finally {
    //   runInAction(() => {
    //     this.loading = false;
    //   });
    // }
  }

  async loadVehicle(id: number) {
    return this.withState(
      this.sendRequest({
        method: 'GET',
        path: 'vehicles',
        validation: LoadVehiclesSchema,
        params: { id },
      }),
    );
  }

  getById(id: number) {
    const vehicle = this.vehicles.results.find((v) => v.id === id);
    return vehicle;
  }
}

export default V2VehiclesStore;
