import React from 'react';
import { toast } from 'react-toastify';
import { observer } from 'mobx-react-lite';
// @material-ui
import { Grid, LinearProgress, Typography } from '@material-ui/core';
// @logic
import { useStore } from 'logic/store';
// @components
import Upload from 'components/UploadDetails/Upload';

interface Props {
  next?: () => void;
}

const SetBusinessDocumentSelect = observer(({ next }: Props) => {
  const store = useStore();
  const { userId } = store.auth;

  const [businessRegister, setBusinessRegister] = React.useState<File | null>(
    null,
  );
  const [proxyIdRegister, setProxyIdRegister] = React.useState<File | null>(
    null,
  );
  const [businessProgress, setBusinessProgress] = React.useState(0);
  const [proxyProgress, setProxyProgress] = React.useState(0);
  const [businessStep, setBusinessStep] = React.useState<
    'certificate' | 'proxyId'
  >('certificate');

  const uploadBusinessDocs = async () => {
    if (userId != null && businessRegister != null && proxyIdRegister != null) {
      const businessRegisterResult = await store.users.uploadBusinessRegister(
        businessRegister,
        userId,
      );
      const proxyIdRegisterResult = await store.users.uploadProxyIdRegister(
        proxyIdRegister,
        userId,
      );

      if (businessRegisterResult.success && proxyIdRegisterResult) {
        if (next) {
          next();
        }
      } else {
        toast.error('Business documents could not be uploaded');
      }
    } else {
      toast.error(
        'Unable to identify the user and files intended to be uploaded',
      );
    }
  };

  const updateBusinessStep = () => {
    setBusinessStep('proxyId');
  };

  return (
    <>
      {businessStep === 'certificate' ? (
        <Grid item xs={12}>
          <Upload
            file={businessRegister}
            setFile={setBusinessRegister}
            message="Copy of Business Registration Certificate (BRNC)"
            next={updateBusinessStep}
          />
          {businessProgress > 0 ? (
            <LinearProgress variant="determinate" value={businessProgress} />
          ) : null}
        </Grid>
      ) : null}
      {businessStep === 'proxyId' ? (
        <Grid item xs={12}>
          <Upload
            file={proxyIdRegister}
            setFile={setProxyIdRegister}
            message="Copy of Proxy Id"
            next={uploadBusinessDocs}
          />
          {proxyProgress > 0 ? (
            <LinearProgress variant="determinate" value={proxyProgress} />
          ) : null}
        </Grid>
      ) : null}

      {proxyIdRegister && businessRegister ? (
        <Typography color="primary">
          2/2 files uploaded! Complete upload to proceed
        </Typography>
      ) : proxyIdRegister || businessRegister ? (
        <Typography color="primary"> 1/2 files uploaded</Typography>
      ) : (
        <Typography color="primary"> 0/2 files uploaded</Typography>
      )}
    </>
  );
});

export default SetBusinessDocumentSelect;
