import { Theme } from '@material-ui/core/styles/createMuiTheme';
import { makeStyles, createStyles } from '@material-ui/core/styles';

const styles = makeStyles((theme: Theme) =>
  createStyles({
    status: {
      textTransform: 'uppercase',
      width: '150px',
      fontWeight: 'bold',
    },
    statusExpired: {
      borderColor: '#e57373',
      color: '#e57373',
    },
    inProgress: {
      borderColor: '#51cb8e',
      color: '#51cb8e',
    },
    statusExpiringSoon: {
      borderColor: '#ffb74d',
      color: '#ffb74d',
    },
    statusSorted: {
      borderColor: '#81c784',
      color: '#81c784',
    },
    paper: {
      margin: 'auto',
    },
    scrollX: {
      overflowX: 'scroll',
    },
    greenButton: {
      color: theme.palette.secondary.contrastText,
      backgroundColor: theme.palette.secondary.main,
    },
    addVehicleSpace: {
      marginTop: '10px',
      marginBottom: '10px',
    },
    mobileLabel: {
      // for mobile view make button thinner
      [theme.breakpoints.down('sm')]: {
        width: '90px !important',
      },
    },
    searchBar: {
      borderBottom: '1px solid rgba(0, 0, 0, 0.12)',
    },
    searchInput: {
      fontSize: theme.typography.fontSize,
    },
    popper: {
      marginTop: '-50px',
    },
    block: {
      display: 'block',
    },
    contentWrapper: {
      margin: '10px',
      textAlign: 'center',
    },
    progress: {
      padding: theme.spacing(1),
    },
    centerProgress: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },
    rowHover: {
      '&:hover': {
        cursor: 'pointer',
      },
    },
    paginationToolbar: {
      [theme.breakpoints.down('sm')]: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-start',
      },
    },
    paginationActions: {
      [theme.breakpoints.down('sm')]: {
        marginLeft: '0px',
        paddingBottom: '20px',
      },
    },
    uppercase: {
      textTransform: 'uppercase',
    },
  }),
);

export default styles;
