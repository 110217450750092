import React, { useState } from 'react';
import { observer } from 'mobx-react-lite';
import { useParams, useHistory } from 'react-router-dom';
// @material-ui
import { Grid, Tabs, Tab, Button } from '@material-ui/core';
// @icons
import { Commute, NoteAdd, Timeline } from '@material-ui/icons';
// @logic
import { useStore } from 'logic/store';
// @local
import { IRenewal } from 'logic/stores/renewals/validation';
import { IUser } from 'logic/stores/users/validation';
import { IVehicle } from 'logic/stores/vehicles/validation';
import { DefaultViewProps } from 'components/Layout/WithLayout';
import { IEvent } from 'logic/stores/events/validation';
import { IDocument } from 'logic/stores/document/validation';
import RenewalCards from './RenewalCard';
import RenewalActionsContainer from './RenewalActionsContainer';
import Vehicle from './Vehicle';
import ViewDocuments from './ViewDocuments';
import ViewEvents from './ViewEvents';
import useStyles from './styles';
import UpdateRenewalDelivery from './UpdateRenewalDelivery';
import AdminUpdateAddress from './AdminUpdateAddress';

enum TabViews {
  VehicleTab = 0,
  DocumentsTab = 1,
  EventsTab = 2,
}

const ViewRenewal = observer<DefaultViewProps>(({ view }) => {
  const store = useStore();
  const { id } = useParams<{ id: string }>();
  const classes = useStyles();
  const { permissions: permissionTypes } = store.auth;

  const [renewal, setRenewal] = useState<IRenewal | null>(null);
  const [user, setUser] = useState<IUser | null>(null);
  const [vehicle, setVehicle] = useState<IVehicle | null>(null);
  const [events, setEvents] = useState<IEvent[]>([]);
  const [documents, setDocuments] = useState<IDocument[]>([]);

  const isAdmin = permissionTypes?.includes('isAdmin');

  const [value, setValue] = React.useState(TabViews.EventsTab);

  const [openUpdateAddress, setOpenUpdateAddress] = React.useState(false);
  const history = useHistory();

  const handleClick = () => {
    setOpenUpdateAddress(true);
  };
  const handleClose = (u?: IUser) => {
    if (u) {
      setUser(u);
    }
    setOpenUpdateAddress(false);
  };

  const renewAgain = () => {
    history.push(`/start-renewal/collection/${renewal?.vehicleId}`);
  };

  const refresh = (renewalId: number) => {
    if (view === 'admin') {
      fetchAllAdmin(renewalId);
    } else {
      fetchAll(renewalId);
    }
  };

  React.useEffect(() => {
    if (id == null) {
      return;
    }

    const idNum = parseInt(id, 10);
    refresh(idNum);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  const handleChange = (event: unknown, newValue: number) => {
    setValue(newValue);
  };

  const fetchAll = async (renewalId: number) => {
    const renewalResult = await store.renewals.getRenewal(renewalId);
    if (!renewalResult) {
      return;
    }
    setRenewal(renewalResult);

    if (renewalResult.vehicleId) {
      const vehicleResult = await store.vehicles.loadVehicle(
        renewalResult.vehicleId,
      );
      if (!vehicleResult.success) {
        console.error(`Error: ${vehicleResult.code}: ${vehicleResult.message}`);
        return;
      }
      setVehicle(vehicleResult.data.entries[0]);
    }

    await fetchEvents(renewalId);
    if (renewalResult.userId) {
      fetchUser(renewalResult.userId);
      fetchDocuments(renewalResult.userId);
    }
  };

  const fetchAllAdmin = async (renewalId: number) => {
    const renewalResult = await store.admin.getRenewal({ id: renewalId });
    if (!renewalResult.success) {
      console.error(`Error: ${renewalResult.code}: ${renewalResult.message}`);
      return;
    }
    setRenewal(renewalResult.data);

    if (renewalResult.data.vehicleId) {
      const vehicleResult = await store.admin.getVehicle(
        renewalResult.data.vehicleId,
      );
      if (!vehicleResult.success) {
        console.error(`Error: ${vehicleResult.code}: ${vehicleResult.message}`);
        return;
      }
      setVehicle(vehicleResult.data);
    }

    await fetchEvents(renewalId);

    if (renewalResult.data.userId) {
      fetchUser(renewalResult.data.userId);
      fetchDocuments(renewalResult.data.userId);
    }
  };

  const fetchUser = async (userId: number) => {
    const userResult = await store.users.getById(userId);
    if (!userResult.success) {
      console.error(`Error: ${userResult.code}: ${userResult.message}`);
      return;
    }
    setUser(userResult.data);
  };

  const fetchEvents = async (renewalId: number) => {
    store.events.reset();
    const result = await store.events.load({ renewalId });
    if (result.success) {
      setEvents(result.data.entries);
    }
  };

  const fetchDocuments = async (userId: number) => {
    const result = await store.documents.load({ userId });
    if (result.success) {
      setDocuments(result.data);
    }
  };

  return (
    <Grid container className={classes.container} spacing={0}>
      <Grid item xs={12} md={12} className={classes.cardsSection}>
        <RenewalCards renewal={renewal} />
      </Grid>
      <Grid item xs={12} md={12} className={classes.tabsContainer}>
        <Tabs
          value={value}
          onChange={handleChange}
          indicatorColor="primary"
          textColor="secondary"
          variant="fullWidth"
        >
          <Tab fullWidth icon={<Commute />} label="Vehicle Details" />
          <Tab fullWidth icon={<NoteAdd />} label="Documents" />
          <Tab fullWidth icon={<Timeline />} label="Events" />
        </Tabs>
      </Grid>
      <Grid item xs={12} md={12} className={classes.grid40Padding}>
        <Grid container spacing={3}>
          <Grid item md={9} lg={9}>
            {value === TabViews.VehicleTab && user ? (
              <Vehicle vehicle={vehicle} user={user} />
            ) : null}
            {value === TabViews.DocumentsTab && documents ? (
              <ViewDocuments documents={documents} />
            ) : null}
            {value === TabViews.EventsTab && events ? (
              <ViewEvents events={events} />
            ) : null}
          </Grid>
          <Grid item md={3} lg={3} className={classes.actionsContainer}>
            {isAdmin ? (
              <RenewalActionsContainer
                renewal={renewal}
                user={user}
                onAction={refresh}
              />
            ) : (
              <>
                <Button
                  onClick={handleClick}
                  color="primary"
                  fullWidth
                  variant="contained"
                  size="large"
                >
                  Update Address
                </Button>
                {vehicle?.inRenewal === false ? (
                  <Button
                    onClick={renewAgain}
                    variant="contained"
                    color="secondary"
                    fullWidth
                    className={[
                      classes.status,
                      classes.full100,
                      classes.buttonMarginBottom,
                      classes.textMarginTop,
                    ].join(' ')}
                  >
                    Renew Vehicle Again
                  </Button>
                ) : null}
              </>
            )}
          </Grid>
        </Grid>
      </Grid>
      {openUpdateAddress ? (
        <UpdateRenewalDelivery open={openUpdateAddress} onClose={handleClose} />
      ) : null}
      {isAdmin && user ? <AdminUpdateAddress user={user} /> : null}
    </Grid>
  );
});

export default ViewRenewal;
