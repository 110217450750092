import { Theme } from '@material-ui/core/styles/createMuiTheme';
import { makeStyles, createStyles } from '@material-ui/core/styles';

const styles = makeStyles((theme: Theme) =>
  createStyles({
    cardMargin: {
      margin: 20,
      marginLeft: '0',
      marginBottom: '24px',
      marginRight: '0',
      padding: '20px',
    },
    spaceHeading: {
      marginBottom: '24px',
    },
    img200: {
      width: '200px',
    },
    addressMarginBelow: {
      marginBottom: '24px',
    },
    flexColumn: {
      display: 'flex',
      flexDirection: 'column',
    },
    flexEnd: {
      alignItems: 'flex-end',
    },
    flexCenter: {
      justifyContent: 'center',
      alignItems: 'center',
    },
    flexStart: {
      alignItems: 'flex-start',
    },
    tableBackground: {
      backgroundColor: 'rgba(0,0,0,0.1)',
    },
    tableCellBottomNone: {
      borderBottom: 'none',
    },
  }),
);

export default styles;
