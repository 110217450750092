import Joi from 'joi';
import { IPermission, PermissionSchema } from '../permissions/validation';
import { PaginatedResponse } from '../vehicles/validation';
import { UserType } from '../auth/auth';

export interface IUser {
  id: number;
  firstName: string;
  lastName: string;
  email: string;
  password: string;
  contactNumber?: string;
  picture?: string;
  verified: boolean;
  accountCompletion: number;
  paymentOptions?: string[];
  archived: boolean;
  address?: string;
  resetToken?: string;
  verificationCode?: string;
  resetCode?: string;
  coordinates?: [number, number];
  deliveryType?: string;
  deliveryAddress?: string;
  deliveryCoordinates?: [number, number];
  emailNotifications: string[];
  userType: UserType;
  createdAt: Date;
  updatedAt: Date;
  deliveryAddressAdditional?: string;
  source?: string;
  sourceDetails?: string;
  testUser: boolean;
  company?: any;
  permissions: IPermission[];
}

export const UserSchema = Joi.object<IUser>({
  id: Joi.number().required(),
  firstName: Joi.string().required(),
  lastName: Joi.string().required(),
  email: Joi.string().required(),
  password: Joi.string().required(),
  contactNumber: Joi.string().allow(null),
  picture: Joi.string().allow(null),
  verified: Joi.boolean().required(),
  accountCompletion: Joi.number().required(),
  paymentOptions: Joi.array<string[]>().items(Joi.string()).allow(null),
  archived: Joi.boolean().required(),
  address: Joi.string().allow(null),
  resetToken: Joi.string().allow(null),
  verificationCode: Joi.string().allow(null),
  resetCode: Joi.string().allow(null),
  coordinates: Joi.any(), // GEOMETRY
  deliveryType: Joi.string().allow(null),
  deliveryAddress: Joi.string().allow(null),
  deliveryCoordinates: Joi.any(), // GEOMETRY
  emailNotifications: Joi.array<string[]>().items(Joi.string()).required(),
  userType: Joi.string().required(),
  createdAt: Joi.date().required(),
  updatedAt: Joi.date().required(),
  deliveryAddressAdditional: Joi.string().allow(null),
  source: Joi.string().allow(null),
  sourceDetails: Joi.string().allow(null),
  testUser: Joi.boolean().required(),
  company: Joi.any(), // Company
  permissions: Joi.array<IPermission[]>().items(PermissionSchema),
});

export const PaginatedUsersSchema = Joi.object<PaginatedResponse<IUser>>({
  count: Joi.number().required(),
  entries: Joi.array<IUser[]>().items(UserSchema),
});
