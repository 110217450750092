import React from 'react';
import { observer } from 'mobx-react-lite';
// @material-ui
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  TextField,
  DialogTitle,
  IconButton,
  MenuItem,
  Typography,
} from '@material-ui/core';
// @form
import { useForm, Controller } from 'react-hook-form';
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
// @icons
import { Close as CloseIcon } from '@material-ui/icons';
// @logic
import { useStore } from 'logic/store';
// @components
import handleCurrency from 'utilities/handleCurrency';
import Confirmation from 'components/utilities/Confirm';
// @local
import {
  Measurements,
  managedVariables,
  measurementOptions,
} from 'logic/stores/managedVariables/validation';
import useStyles from '../styles';

interface FormValues {
  readableName: string;
  value: number;
  measuredIn: Measurements;
}

const validationSchema = Yup.object().shape({
  readableName: Yup.string().lowercase().label('Variable').required(),
  value: Yup.number().required().label('Value'),
  measuredIn: Yup.string().lowercase().label('Measured in').required(),
});

interface Props {
  variableId: number;
  closeModal: () => void;
}

const UpdateForm = observer(({ variableId, closeModal }: Props) => {
  const classes = useStyles();
  const store = useStore();
  const variable = store.managedVariables.getById(variableId);
  const [open, setOpen] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [viewConfirm, setViewConfirm] = React.useState(false);

  const defaultTo = variable
    ? {
        ...variable,
        value:
          variable.measuredIn === 'zar'
            ? handleCurrency({ amount: variable.value }).toObject().amount / 100
            : variable.value,
      }
    : {};

  const { handleSubmit, errors, control, reset } = useForm<FormValues>({
    resolver: yupResolver(validationSchema),
    defaultValues: defaultTo,
  });

  React.useEffect(() => {
    if (variable != null) {
      reset(defaultTo);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(variable)]);

  React.useEffect(() => {
    if (variableId != null) {
      setOpen(true);
    } else {
      setOpen(false);
    }
  }, [variableId]);

  const updateData = async (data: FormValues) => {
    if (variableId != null) {
      setLoading(true);
      const value = data.measuredIn === 'zar' ? data.value * 100 : data.value;
      const res = await store.managedVariables.update(variableId, {
        ...data,
        value,
      });
      setLoading(false);
      if (res.success === true) {
        closeModal();
      }
    }
  };

  const confirm = async () => {
    if (variableId != null) {
      const res = await store.managedVariables.remove(variableId);
      if (res.success === true) {
        setViewConfirm(false);
        closeModal();
      }
    }
  };

  return (
    <>
      <Confirmation
        title={`Remove ${variable != null ? `#${variable.id}` : ''}`}
        confirm={confirm}
        close={() => setViewConfirm(false)}
        description="Click okay to remove the selected variable"
        open={viewConfirm}
      />
      <Dialog fullWidth maxWidth="sm" open={open} onClose={closeModal}>
        <DialogTitle>
          <b>Update</b>
          <IconButton
            aria-label="close"
            onClick={closeModal}
            className={classes.positionIcon}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <form>
            <br />
            <Typography>
              <b>General Details</b>
            </Typography>
            <Controller
              as={
                <TextField
                  select
                  color="secondary"
                  variant="outlined"
                  margin="dense"
                  label="Select Variable"
                  error={Boolean(errors.readableName)}
                  helperText={
                    errors.readableName && errors.readableName.message
                  }
                  className={classes.uppercase}
                >
                  {managedVariables.map((n) => (
                    <MenuItem key={n} value={n} className={classes.uppercase}>
                      {n}
                    </MenuItem>
                  ))}
                </TextField>
              }
              name="readableName"
              control={control}
            />

            <Controller
              as={
                <TextField
                  fullWidth
                  color="secondary"
                  type="number"
                  margin="dense"
                  variant="outlined"
                  placeholder="Value"
                  error={Boolean(errors.value)}
                  helperText={errors.value && errors.value.message}
                />
              }
              name="value"
              control={control}
            />

            <Controller
              as={
                <TextField
                  select
                  color="secondary"
                  variant="outlined"
                  margin="dense"
                  label="Select Measurement Option"
                  error={Boolean(errors.measuredIn)}
                  helperText={errors.measuredIn && errors.measuredIn.message}
                  className={classes.uppercase}
                >
                  {measurementOptions.map((n) => (
                    <MenuItem key={n} value={n} className={classes.uppercase}>
                      {n}
                    </MenuItem>
                  ))}
                </TextField>
              }
              name="measuredIn"
              control={control}
            />
          </form>
        </DialogContent>
        <DialogActions className={classes.dialogBorder}>
          <div className={classes.flexGrow} />
          <Button onClick={closeModal} color="default">
            Cancel
          </Button>
          <Button
            disabled={loading}
            variant="contained"
            color="primary"
            onClick={handleSubmit(updateData)}
          >
            Save
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
});

export default UpdateForm;
