import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';

const styles = makeStyles((theme: Theme) =>
  createStyles({
    contentWrapper: {
      margin: '10px 16px',
    },
    tableHeader: {
      '& .MuiTableCell-head': {
        fontWeight: 'bold',
      },
    },
    tableFooter: {
      width: '100%',
      display: 'flex',
      justifyContent: 'flex-end',
      paddingTop: '15px',
      [theme.breakpoints.down('sm')]: {
        justifyContent: 'center',
      },
    },
    searchBar: {
      flexGrow: 1,
      flexShrink: 1,
    },
    block: {
      display: 'block',
    },
    sortCell: {
      '&:hover': {
        cursor: 'pointer',
      },
    },
    chip: {
      '&successful': {
        borderColor: '#00C853',
        borderWidth: '2px',
        color: '#00C853',
        textTransform: 'uppercase',
        width: '150px',
      },
      '&processing': {
        borderColor: '#448aff',
        borderWidth: '2px',
        color: '#448aff',
        textTransform: 'uppercase',
        width: '150px',
      },
      '&cancelled': {
        borderColor: '#ff9100',
        borderWidth: '2px',
        color: '#ff9100',
        textTransform: 'uppercase',
        width: '150px',
      },
      '&failed': {
        borderColor: '#f44336',
        borderWidth: '2px',
        color: '#f44336',
        textTransform: 'uppercase',
        width: '150px',
      },
    },
    paginationToolbar: {
      [theme.breakpoints.down('sm')]: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-start',
      },
    },
    paginationActions: {
      [theme.breakpoints.down('sm')]: {
        marginLeft: '0px',
        paddingBottom: '20px',
      },
    },
    progress: {
      padding: theme.spacing(1),
    },
    centerProgress: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },
  }),
);

export default styles;
