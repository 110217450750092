import Dinero from 'dinero.js';

Dinero.globalLocale = 'en-ZA';
Dinero.defaultCurrency = 'ZAR';

export const VAT_RATE = 0.15;

export const withVAT = (amountInCents: number) => {
  const priceWithVAT = Dinero({
    amount: amountInCents,
    precision: 2,
  })
    .multiply(1 + Number(VAT_RATE))
    .toObject();

  return priceWithVAT.amount;
};

export const formatCurrency = (amount: number) => {
  if (amount != null) {
    return Dinero({
      amount: Number(amount),
    }).toFormat('$0,0.00'); // Depending on the currency specified the $ dollar will adapt to the currencies symbol
  }
  return '';
};

export const toStandardUnit = (amount: number) => {
  if (amount != null) {
    return Dinero({
      amount: Number(amount),
      precision: 2,
    }).toUnit();
  }
  return 0;
};

export default Dinero;
