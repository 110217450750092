import * as React from 'react';
import { observer } from 'mobx-react-lite';
// @logic
import { useStore } from 'logic/store';
// @local
import Template from './Template';

const TemplateWithMachine = observer(() => {
  const store = useStore();
  const { accessToken: token } = store.auth;
  return (
    <Template
      state={token != null ? 'authorized' : 'unauthorized'}
      logout={() => store.auth.logout()}
    />
  );
});

export default TemplateWithMachine;
