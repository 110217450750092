import React, { useState } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import swal from '@sweetalert/with-react';
import { observer } from 'mobx-react-lite';
// @material-ui
import {
  CircularProgress,
  Button,
  Grid,
  TextField,
  Paper,
} from '@material-ui/core';
// @form
import { useForm, Controller } from 'react-hook-form';
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
// @logic
import { useStore } from 'logic/store';
import { emailValidation } from 'logic/validation';
// @components
import Content from 'components/Layout/Content';
// @local
import useStyles from './styles';

interface FormValues {
  email: string;
  password: string;
  confirmPassword: string;
}

const validationSchema = Yup.object().shape({
  email: emailValidation.required(),
  password: Yup.string().required().label('Password').min(7),
  confirmPassword: Yup.string()
    .label('Confirm password')
    .oneOf([Yup.ref('password')], 'Passwords do not match')
    .required(),
});

const VerifyTemplate = observer(() => {
  const store = useStore();
  const history = useHistory();
  const classes = useStyles({});
  const { token } = useParams<{ token: string }>();
  const [loading, setLoading] = useState(false);

  const { handleSubmit, errors, control } = useForm<FormValues>({
    resolver: yupResolver(validationSchema),
  });

  const updatePassword = async ({ email, password }: FormValues) => {
    if (token) {
      setLoading(true);
      const res = await store.auth.setNewPassword({
        email,
        password,
        resetToken: token,
      });
      setLoading(false);
      if (res.success === true) {
        swal('Sorted!', res.data.message, 'success');
      } else {
        swal(
          'Error!',
          'Unable to set new password. Please email info@sorted.co.za for help',
          'error',
        );
      }
      history.push('/login');
    }
  };

  return (
    <Content
      className={classes.container}
      containerClass={classes.contentContainer}
      spacing={3}
    >
      <Grid className={classes.signUpHead}>
        <p className={classes.headSecond}>
          Enter the following details below to complete your password recovery.
        </p>
      </Grid>

      <Grid item md={12} xs={12}>
        <Paper
          elevation={2}
          className={`${classes.formStyle} ${classes.padding20}`}
        >
          {store.auth.loading ? (
            <div className={classes.centerProgress}>
              Updating Password
              <CircularProgress className={classes.progress} />
            </div>
          ) : null}
          {!store.auth.loading ? (
            <form onSubmit={handleSubmit(updatePassword)}>
              <Controller
                as={
                  <TextField
                    margin="dense"
                    label="Email"
                    InputProps={{
                      className: classes.textDark,
                    }}
                    color="secondary"
                    placeholder="Email"
                    type="email"
                    error={Boolean(errors.email)}
                    helperText={errors.email && errors.email.message}
                  />
                }
                name="email"
                control={control}
              />
              <Controller
                as={
                  <TextField
                    margin="dense"
                    label="Password"
                    color="secondary"
                    placeholder="Password"
                    type="password"
                    InputProps={{
                      className: classes.textDark,
                    }}
                    error={Boolean(errors.password)}
                    helperText={errors.password && errors.password.message}
                  />
                }
                name="password"
                control={control}
              />
              <Controller
                as={
                  <TextField
                    margin="dense"
                    color="secondary"
                    label="Confirm password"
                    placeholder="Confirm password"
                    InputProps={{
                      className: classes.textDark,
                    }}
                    type="password"
                    error={Boolean(errors.confirmPassword)}
                    helperText={
                      errors.confirmPassword && errors.confirmPassword.message
                    }
                  />
                }
                name="confirmPassword"
                control={control}
              />
              <div className={classes.submitContainer}>
                <Button
                  fullWidth
                  disabled={loading}
                  className={classes.btnBlue}
                  size="large"
                  type="submit"
                >
                  Update Password
                </Button>
              </div>
              <br />
            </form>
          ) : null}
        </Paper>
      </Grid>
    </Content>
  );
});

export default VerifyTemplate;
