import { Theme } from '@material-ui/core/styles/createMuiTheme';
import { makeStyles, createStyles } from '@material-ui/core/styles';

export default makeStyles((theme: Theme) =>
  createStyles({
    popper: {
      marginTop: '-50px',
    },
    linksGroup: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      flexDirection: 'row',
    },
    mobileSpacing: {
      // space around form and on top for mobile
      padding: '50px',
      [theme.breakpoints.down('sm')]: {
        marginTop: '5px',
        marginBottom: '50px',
      },
    },
    formMobilePadding: {
      [theme.breakpoints.down('sm')]: {
        // space inside form for mobile
        padding: '35px',
        margin: '0 auto',
      },
    },
    centerImage: {
      margin: '0 auto',
      display: 'block',
    },
    centerDialogText: {
      textAlign: 'center',
    },
    menuButton: {
      marginRight: theme.spacing(2),
      [theme.breakpoints.up('sm')]: {
        display: 'none',
      },
    },
    formStyle: {
      background: '#f5f5f5',
      borderRadius: '1px',
    },
    submitContainer: {
      display: 'flex',
      justifyContent: 'center',
      marginTop: '10px',
    },
    pageTitle: {
      textAlign: 'left',
      fontSize: '2.2rem',
      fontWeight: 400,
    },
    capitalize: {
      textTransform: 'capitalize',
    },
    padding20: {
      padding: '20px',
      [theme.breakpoints.down('sm')]: {
        padding: '0',
      },
    },
    registerText: {
      textAlign: 'center',
      marginTop: '10px',
    },
    textField: {
      fontSize: '1.2em',
      background: 'transparent',
    },
    autoSelect: {
      marginBottom: '-20px',
    },
    autocomplete: {
      width: '100%',
    },
    autoCompleteInput: {
      fontSize: '18px',
      borderRadius: '2px',
      color: '#333333',
      lineHeight: '1.2',
      padding: '5px 0 ',
      ' & input': {
        textTransform: 'capitalize',
      },
    },
    gridIconFlex: {
      display: 'flex',
    },
    iconBox: {
      margin: '27px 0px auto',
      display: 'inline-flex',
      '&:hover': {
        cursor: 'pointer',
      },
    },
    iconBoxBottom: {
      margin: '27px 0px auto',
      display: 'inline-flex',
      [theme.breakpoints.down('md')]: {
        padding: '20px',
      },
    },
    controllerText: {
      width: '90%',
      margin: '1.2em',
      display: 'inline-flex',
      [theme.breakpoints.down('md')]: {
        margin: '0.5em',
        width: '70%',
      },
      // center the  controller text field for mobile
      [theme.breakpoints.down('sm')]: {
        margin: '14px',
      },
    },
    headingSpace: {
      marginBottom: '20px',
    },
    controlledTextLast: {
      [theme.breakpoints.down('md')]: {
        margin: '0.5em',
        width: '50%',
      },
    },
    autoInput: {
      textTransform: 'capitalize',
    },
    centerContent: {
      margin: '0 auto',
    },
    fullWidth: {
      width: '100%',
    },
    progress: {
      marginRight: theme.spacing(1),
      margin: '0 auto',
      textAlgn: 'center',
    },
    progressColor: {
      color: 'rgba(255,255,255,0.8)',
    },
    centerProgress: {
      display: 'block',
    },
    iconSpaceText: {
      margin: '-8px',
      marginRight: '1px',
    },
    uploadText: {
      fontSize: '20px',
      fontWeight: 'bold',
    },
    extendedFab: {
      width: '600px',
      margin: '0 auto',
      display: 'block',
      marginTop: '20px',
      marginBottom: '20px',
      [theme.breakpoints.down('sm')]: {
        maxWidth: '100%',
      },
    },
  }),
);
