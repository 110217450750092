import { Theme } from '@material-ui/core/styles/createMuiTheme';
import { makeStyles, createStyles } from '@material-ui/core/styles';

export default makeStyles((theme: Theme) =>
  createStyles({
    iframeContainer: {
      position: 'relative',
      // overflow: 'hidden',
      paddingTop: '56.25%',
      height: '100%',
      width: '100%',
      [theme.breakpoints.down('sm')]: {
        paddingTop: '130%',
      },
    },
    iframe: {
      overflowY: 'scroll',
      position: 'absolute',
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
      border: 0,
    },
    resultText: {
      textTransform: 'capitalize',
    },
    resultIcon: {
      marginRight: theme.spacing(1),
    },
    resultContainer: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      marginTop: '15px',
    },
    errorBar: {
      backgroundColor: '#ef5350',
    },
    loader: {
      zIndex: 1000,
      position: 'fixed',
      backgroundColor: 'white',
      height: '100vh',
      width: '100vw',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
    },
  }),
);
