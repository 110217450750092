import { makeObservable, observable, runInAction } from 'mobx';
import { BaseStore, RequestConfig } from '../util/baseStore';
import { CompanySchema, ICompany } from './validation';

class V2CompanyStore extends BaseStore {
  constructor(config: RequestConfig) {
    super(config);
    makeObservable(this, {
      company: observable,
    });
  }

  // State
  loading = false;
  company!: ICompany;

  // Actions
  async loadBranding(reference: string) {
    this.setLoading(true);

    const result = await this.sendRequest({
      method: 'GET',
      path: 'companies',
      validation: CompanySchema,
      params: { id: reference },
    });

    runInAction(() => {
      this.loading = false;

      if (result.success) {
        this.company = result.data;
      }
    });

    return result;
  }
}

export default V2CompanyStore;
