import Joi from 'joi';
import { IRenewal, RenewalSchema } from '../renewals/validation';
import { IUser, UserSchema } from '../users/validation';
import { IVehicle, VehicleSchema } from '../vehicles/validation';

export type IDocument = {
  id: number;
  filename: string;
  filePath: string;
  fileType: string;
  documentType: string;
  kbSize: number;
  access: string;
  createdAt: Date;
  updatedAt: Date;
  userId?: number;
  user?: IUser;
  vehicle?: IVehicle;
  vehicleId?: number;
  renewal?: IRenewal;
  renewalId?: number;
};

export const DocumentSchema = Joi.object<IDocument>({
  id: Joi.number().required(),
  filename: Joi.string().required(),
  filePath: Joi.string().required(),
  fileType: Joi.string().required(),
  documentType: Joi.string().required(),
  kbSize: Joi.number().required(),
  access: Joi.string().required(),
  createdAt: Joi.date().required(),
  updatedAt: Joi.date().required(),
  userId: Joi.number().allow(null),
  user: UserSchema.allow(null),
  vehicleId: Joi.number().allow(null),
  vehicle: VehicleSchema.allow(null),
  renewalId: Joi.number().allow(null),
  renewal: RenewalSchema.allow(null),
});

export interface IOCRResults {
  gvm?: string;
  gvmConfidence?: number;
  discNumber?: string;
  discNumberConfidence?: number;
  expiryDate?: Date;
  expiryDateConfidence?: number;
  licenseNumber?: string;
  licenseNumberConfidence?: number;
  tare?: string;
  tareConfidence?: number;
  make?: string;
  makeConfidence?: number;
  registerNumber?: string;
  registerNumberConfidence?: number;
}

export const IOCRResultsSchema = Joi.object<IOCRResults>({
  gvm: Joi.string().allow(null, ''),
  gvmConfidence: Joi.number().allow(null),
  discNumber: Joi.string().allow(null, ''),
  discNumberConfidence: Joi.number().allow(null),
  expiryDate: Joi.date().allow(null),
  expiryDateConfidence: Joi.number().allow(null),
  licenseNumber: Joi.string().allow(null, ''),
  licenseNumberConfidence: Joi.number().allow(null),
  tare: Joi.string().allow(null, ''),
  tareConfidence: Joi.number().allow(null),
  make: Joi.string().allow(null, ''),
  makeConfidence: Joi.number().allow(null),
  registerNumber: Joi.string().allow(null, ''),
  registerNumberConfidence: Joi.number().allow(null),
});
