import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';

export default makeStyles((theme: Theme) =>
  createStyles({
    center: {
      display: 'contents',
    },
    uploadIcon: {
      fontSize: 60,
    },
    gridCenter: {
      textAlign: 'center',
    },
    spread: {
      display: 'flex',
      justifyContent: 'space-between',
    },
    gridFlexEnd: {
      display: 'flex',
      justifyContent: 'flex-end',
      zIndex: 10,
    },
    img150: {
      height: '150px',
      width: '150px',
    },
    centerWidth: {
      textAlign: 'center',
      marginTop: '15px',
      width: '500px',
    },
    paper: {
      padding: '10px',
      '&:hover': {
        cursor: 'pointer',
        backgroundColor: 'rgba(0,0,0,0.05)',
      },
    },
    paperNoHover: {
      padding: '10px',
    },
  }),
);
