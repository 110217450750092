import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    tableTextRight: {
      [theme.breakpoints.down('md')]: {
        width: '100%',
        // center table text on mobile to fit screen
        textAlign: 'center',
      },
      [theme.breakpoints.up('md')]: {
        width: '565px',
      },
    },
  }),
);

export default useStyles;
