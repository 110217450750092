import * as React from 'react';
import { observer } from 'mobx-react-lite';
import { Link, useHistory, useParams } from 'react-router-dom';
import swal from '@sweetalert/with-react';
import ReactGA from 'react-ga'; // Google Analytics
// @material-ui
import { Button, Grid, Paper, TextField, Typography } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
// @form
import { useForm, Controller } from 'react-hook-form';
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
// @logic
import { useStore } from 'logic/store';
import { emailValidation } from 'logic/validation';
// @components
import Content from 'components/Layout/Content';
// @local
import { delay } from 'bluebird';
import useStyles from './styles';

interface FormValues {
  code: string;
  email: string;
}

const validationSchema = Yup.object().shape({
  code: Yup.string().lowercase().required().label('Code'),
  email: emailValidation.required(),
});

const AccountCreated = observer(() => {
  const classes = useStyles({});
  const { token } = useParams<{ token: string }>();
  const store = useStore();

  const [loading, setLoading] = React.useState(false);

  const history = useHistory();

  const { user } = store.auth;

  const { handleSubmit, errors, control } = useForm<FormValues>({
    resolver: yupResolver(validationSchema),
  });

  const verifyUserWithCode = async ({ code, email }: FormValues) => {
    const res = await store.auth.verifyAccount({
      type: 'code',
      code,
      email,
    });

    if (res.success === true) {
      swal('Sorted!', 'Your account has been verified!', 'success');
      await onVerified();
    } else {
      swal(
        'Error!',
        'Unable to verify with code. Please email info@sorted.co.za for help',
        'error',
      );
    }
  };

  React.useEffect(() => {
    if (token != null) {
      verifyUserAccount(token);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [token]);

  React.useEffect(() => {
    if (user != null && !user.verified) {
      // if user is not verified inform them to verify their account
      informSignUp();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(user)]);

  React.useEffect(() => {
    // Record the conversion
    ReactGA.event({ category: 'conversion', action: 'signup' });
  }, []);

  const informSignUp = () => {
    swal({
      title: "You're Signed Up",
      content: (
        <Alert severity="info">
          <b>Please Note</b> <br />
          All that is required from here is to verify your account by checking
          your email for the verification code. If you have already copied the
          verification code from your email, close this and paste it in the form
          below to proceed.
        </Alert>
      ),
      icon: 'success',
    });
  };

  const verifyUserAccount = async (newToken: string) => {
    setLoading(true);
    const res = await store.auth.verifyAccount({
      token: newToken,
      type: 'token',
    });
    if (res.success === true) {
      swal('Sorted!', 'Your account has been verified!', 'success');
      await onVerified();
    } else {
      swal(
        'Error!',
        'Unable to verify account. Please email info@sorted.co.za for help',
        'error',
      );
    }
    setLoading(false);
  };

  const onVerified = async () => {
    if (user) {
      const reloadedUser = await store.users.getById(user?.id);
      if (reloadedUser.success) {
        await store.auth.set(reloadedUser.data);
      }
    }
    await delay(2000);

    history.push('/account-setup');
  };

  return (
    <Content
      className={classes.container}
      containerClass={classes.contentContainer}
    >
      <Grid item md={12} xs={12}>
        <Typography variant="h4">Account Verification Page</Typography>
        <Typography className={classes.text} align="left">
          Please check your email for the verification email. Once done you can
          then complete your verification process.
        </Typography>

        <Paper
          elevation={2}
          className={`${classes.formStyle} ${classes.padding20}`}
        >
          <form onSubmit={handleSubmit(verifyUserWithCode)}>
            <Controller
              as={
                <TextField
                  fullWidth
                  margin="dense"
                  label="Code"
                  color="secondary"
                  InputProps={{ className: classes.textDark }}
                  placeholder="code"
                  type="text"
                  error={Boolean(errors.code)}
                  helperText={errors.code && errors.code.message}
                />
              }
              name="code"
              control={control}
            />
            <Controller
              as={
                <TextField
                  fullWidth
                  margin="dense"
                  label="Email"
                  color="secondary"
                  InputProps={{ className: classes.textDark }}
                  placeholder="email"
                  type="email"
                  error={Boolean(errors.email)}
                  helperText={errors.email && errors.email.message}
                />
              }
              name="email"
              control={control}
            />
            <div className={classes.submitContainer}>
              <Button
                fullWidth
                disabled={loading}
                className={classes.btnBlue}
                size="large"
                type="submit"
              >
                Let&apos;s Go
              </Button>
            </div>
            <br />
          </form>
          <Typography className={classes.text} align="center">
            If you did not receive a verification email, you can try to{' '}
            <Link to="/resend-verification" className={classes.blueLink}>
              resend it
            </Link>
          </Typography>
        </Paper>
      </Grid>
    </Content>
  );
});

export default AccountCreated;
