import React, { useState } from 'react';
import { toast } from 'react-toastify';
import { useDropzone, DropEvent, FileRejection } from 'react-dropzone';
import swal from '@sweetalert/with-react';
// @material-ui
import {
  Typography,
  Grid,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  RootRef,
  Paper,
} from '@material-ui/core';
// @icons
import { CloudUpload as UploadIcon } from '@material-ui/icons';
// @logic
import { useStore } from 'logic/store';
// @local
import { IVehicle } from 'logic/stores/vehicles/validation';
import useStyles from '../styles';

interface TemplateProps {
  file: File | null;
  setOpenDialog: React.Dispatch<React.SetStateAction<boolean>>;
  setFile: React.Dispatch<React.SetStateAction<File | null>>;
  vehicle: IVehicle | null;
  openDialog: boolean;
  handleClickOpen: () => void;
}

const UploadDialog = (props: TemplateProps) => {
  const store = useStore();
  const { file, setOpenDialog, setFile, vehicle, openDialog, handleClickOpen } =
    props;
  const classes = useStyles();
  const { userId } = store.auth;

  const [loading, setLoading] = useState(false);
  const [uploadFile, setUploadFile] = React.useState<
    string | ArrayBuffer | null
  >(null);

  const handleClose = () => {
    setOpenDialog(false);
  };

  const uploadConfig = {
    accept: 'image/jpeg, image/jpg, image/png, application/pdf',
    maxSize: 5 * 1000000,
    onDropRejected: (fileRejections: FileRejection[], event: DropEvent) => {
      fileRejections.forEach((element: FileRejection) => {
        element.errors.forEach((errors: { code: string; message: string }) => {
          swal(
            `${errors.code} ${errors.message.replace(
              '5000000 bytes',
              '5 MegaBytes',
            )}`,
          );
        });
      });
    },
    onDropAccepted: (selectedFile: File[]) => {
      const reader = new FileReader();
      const newFile = selectedFile[0];

      reader.onloadend = () => {
        const binaryStr = reader.result;
        setUploadFile(binaryStr);
        setFile(newFile);
      };

      reader.readAsDataURL(newFile);
      toast.success('File Accepted');
    },
  };

  const { getRootProps, getInputProps, isDragActive } =
    useDropzone(uploadConfig);

  const { ref, ...rootProps } = getRootProps();

  const uploadDocument = async () => {
    if (userId != null && vehicle != null && file != null) {
      setLoading(true);
      const result = await store.vehicles.uploadDocument(
        file,
        vehicle.id,
        userId,
      );
      setLoading(false);
      if (result != null && result.success != null) {
        swal('Sorted!', 'File has be uploaded successfully.', 'success');
        handleClose();
      }
    }
  };

  return (
    <Dialog fullWidth maxWidth="md" open={openDialog} onClose={handleClose}>
      <DialogTitle>Upload Vehicle Documents</DialogTitle>
      <DialogContent>
        {file != null ? (
          <Button
            onClick={uploadDocument}
            variant="contained"
            color="primary"
            disabled={loading}
            fullWidth
          >
            Submit
          </Button>
        ) : null}
        <RootRef rootRef={ref}>
          <Paper elevation={0} {...rootProps}>
            <Grid container alignItems="center" justify="center">
              <Grid item md={1}>
                <UploadIcon color="primary" className={classes.uploadIcon} />
              </Grid>
              <Grid item xs={12} md={12} sm={12} className={classes.gridCenter}>
                <input {...getInputProps()} />
                {isDragActive ? (
                  <Typography variant="overline" align="center">
                    Drop the files here ...
                  </Typography>
                ) : (
                  <Typography variant="overline" align="center">
                    Drag and drop a copy of your document here, or click to
                    select it
                  </Typography>
                )}
              </Grid>
              <Grid item xs={12} md={12} sm={12} className={classes.fullWidth}>
                <Button
                  onClick={handleClickOpen}
                  color="primary"
                  variant="outlined"
                  fullWidth
                >
                  Select Vehicle Document
                </Button>
              </Grid>
            </Grid>
            {uploadFile != null ? (
              <Grid item md={12} xs={12} className={classes.gridCenterMargin}>
                {(uploadFile as string).includes('application/pdf') &&
                typeof uploadFile === 'string' ? (
                  <>
                    <embed
                      height="400px"
                      width="400px"
                      title="Vehicle Documents"
                      src={uploadFile}
                    />
                  </>
                ) : (
                  <img
                    className={classes.img100}
                    src={uploadFile as string}
                    alt="Vehicle Documents"
                  />
                )}
              </Grid>
            ) : null}
          </Paper>
        </RootRef>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="primary">
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default UploadDialog;
