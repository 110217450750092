import { makeObservable, observable, runInAction } from 'mobx';
import Joi from 'joi';
import { BaseStore, RequestConfig } from '../util/baseStore';

import { IManagedVariable, ManagedVariableSchema } from './validation';

class V2ManagedVehicleStore extends BaseStore {
  constructor(config: RequestConfig) {
    super(config);
    makeObservable(this, {
      managedVariables: observable,
    });
  }

  // State
  loading = false;
  managedVariables: IManagedVariable[] = [];

  byName(name: string) {
    return this.managedVariables.find((mv) => mv.variable === name);
  }

  // Actions
  async get() {
    this.setLoading(true);

    const result = await this.sendRequest({
      method: 'GET',
      path: 'managed-variables',
      validation: Joi.array<IManagedVariable[]>().items(ManagedVariableSchema),
    });

    runInAction(() => {
      this.loading = false;
      if (result.success) {
        this.managedVariables = result.data;
      }
    });

    return {
      success: true,
      managedVariables: result,
    };
  }

  async search(value: string) {
    this.setLoading(true);

    const result = await this.sendRequest({
      method: 'GET',
      path: 'managed-variables/search',
      params: {
        search: value,
        skip: 0,
        take: 300,
      },
      validation: Joi.array<IManagedVariable[]>().items(ManagedVariableSchema),
    });

    runInAction(() => {
      this.loading = false;
      if (result.success) {
        this.managedVariables = result.data;
      }
    });

    return result;
  }

  getById(id: number) {
    return this.managedVariables.find((mv) => mv.id === id);
  }

  update(id: number, payload: Partial<IManagedVariable>) {
    return this.withState(
      this.sendRequest({
        method: 'PATCH',
        path: 'managed-variables',
        validation: ManagedVariableSchema,
        body: { id, ...payload },
      }),
    );
  }

  remove(id: number) {
    return this.withState(
      this.sendRequest({
        method: 'DELETE',
        path: 'managed-variables',
        validation: ManagedVariableSchema,
        params: { id },
      }),
    );
  }
}

export default V2ManagedVehicleStore;
