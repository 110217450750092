import * as React from 'react';
import * as R from 'ramda';
// @material-ui
import { useTheme } from '@material-ui/core/styles';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  LinearProgress,
  Typography,
  useMediaQuery,
  Slide,
} from '@material-ui/core/';
// @icons
import { Error, Check } from '@material-ui/icons';
// @local
import { IUser } from 'logic/stores/users/validation';
import useStyles from './styles';
import PaymentForm from './PaymentForm';

export interface Update {
  status: string | null;
  step: number;
  result?: {
    code: string;
    description: string;
    success: boolean;
  } | null;
}

interface ContainerProps {
  openModel?: boolean;
  close?: () => void;
  title?: string;
  description: string;
  reference: string;
  amountInCents: number;
  user: IUser;
  vehicleId: number;
  successCallback: () => void;
  noDialog?: boolean;
}

const PaymentModal = ({
  amountInCents,
  description,
  reference,
  vehicleId,
  user,
  openModel = false,
  successCallback,
  noDialog,
}: ContainerProps) => {
  const [open, setOpen] = React.useState(openModel);
  const [timer, setTimer] = React.useState<any>(undefined);
  const [payStatus, setPayStatus] = React.useState<Update | null>(null);
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));

  const status = payStatus != null ? payStatus.status : null;
  const result = payStatus != null ? payStatus.result : null;
  const step = payStatus != null ? payStatus.step : 0;

  const success = R.pathOr(null, ['success'], result);

  function handleClickOpen() {
    setOpen(true);
  }

  const memoizedStatusUpdate = React.useCallback(
    (update) => {
      if (update.status !== status) {
        setPayStatus(update);
      }
    },
    [status],
  );

  React.useEffect(() => {
    if (success === true) {
      successCallback();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [success]);

  React.useEffect(() => {
    clearTimeout(timer);
    if (step > 0 && step < 7) {
      const timeoutId = setTimeout(() => {
        setPayStatus({
          status: '',
          step: 8,
          result: {
            success: false,
            description: 'Payment gateway timed out.',
            code: '504',
          },
        });
      }, 300_000);
      setTimer(timeoutId);
    }
    // eslint-disable-next-line
  }, [step]);

  // React.useEffect(() => {
  //   payments.on('status', memoizedStatusUpdate);
  //   return () => payments.removeListener('status', memoizedStatusUpdate);
  // }, [memoizedStatusUpdate]);

  const reset = () => {
    setPayStatus({
      step: 0,
      status: '',
      result: null,
    });
  };

  const close = () => {
    reset();
    setOpen(false);
  };

  const classes = useStyles({});
  const progress = (step / 7) * 100;
  const verificationProgess = ((step - 7) / 2) * 100;

  const errorClass =
    result != null && success === false ? classes.errorBar : '';

  const DialogMode = (component: any) => (
    <div>
      <Button
        variant="outlined"
        color="primary"
        onClick={handleClickOpen}
        hidden={open}
      >
        Open Payment Dialog
      </Button>
      <Dialog
        fullScreen={fullScreen}
        open={open}
        onClose={close}
        fullWidth
        maxWidth="sm"
        aria-labelledby="payment-dialog-title"
        aria-describedby="payment-dialog-description"
      >
        {component()}
      </Dialog>
    </div>
  );

  const Payment = () => (
    <>
      <Slide direction="down" in={progress !== 100} mountOnEnter unmountOnExit>
        <DialogTitle id="payment-dialog-title">{status || ''}</DialogTitle>
      </Slide>
      <DialogContent style={noDialog ? { padding: '0px' } : {}}>
        {step < 8 ? (
          <>
            <LinearProgress
              variant="determinate"
              value={progress}
              classes={{ root: errorClass, bar: errorClass }}
              style={
                progress !== 100 ? { marginTop: '0px' } : { marginTop: '20px' }
              }
            />
            <div className={classes.iframeContainer}>
              <PaymentForm
                amountInCents={amountInCents}
                user={user}
                vehicleId={vehicleId}
                description={description}
                reference={reference}
              />
            </div>
          </>
        ) : (
          <>
            <LinearProgress
              variant="determinate"
              value={verificationProgess}
              classes={{ root: errorClass, bar: errorClass }}
            />
            {result != null ? (
              result.success !== true ? (
                <div className={classes.resultContainer}>
                  <Error color="error" className={classes.resultIcon} />
                  <Typography className={classes.resultText} color="error">
                    {result.description}
                  </Typography>
                </div>
              ) : (
                <div className={classes.resultContainer}>
                  <Check color="primary" className={classes.resultIcon} />
                  <Typography className={classes.resultText} color="primary">
                    {result.description}
                  </Typography>
                </div>
              )
            ) : null}
          </>
        )}
      </DialogContent>
      <DialogActions>
        {result != null ? (
          result.success !== true ? (
            <Button onClick={reset} variant="outlined" color="primary">
              Try Again
            </Button>
          ) : null
        ) : null}
        {noDialog ? null : (
          <Button onClick={close} color="default">
            Close
          </Button>
        )}
      </DialogActions>
    </>
  );

  return noDialog ? Payment() : DialogMode(Payment);
};

export default PaymentModal;
