import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';

export default makeStyles((theme: Theme) =>
  createStyles({
    paper: {
      padding: '6px 16px',
    },
    eventIcon: {
      height: '20px',
      width: '20px',
    },
    secondaryTail: {
      backgroundColor: theme.palette.secondary.main,
    },
    seperateHeight: {
      height: '150px',
    },
    marginListItemLeft: {
      marginLeft: '150px',
    },
    marginListItemRight: {
      marginLeft: '-200px',
    },
    listMarginReduce: {
      marginTop: '-16px',
    },
    uppercase: {
      textTransform: 'uppercase',
    },
    blueText: {
      color: '#64ABFC',
    },
    eventDescription: {
      textTransform: 'capitalize',
    },
  }),
);
