import * as React from 'react';
import { observer } from 'mobx-react-lite';
// @logic
import { useStore } from 'logic/store';
import { delay } from 'bluebird';
import { Loader } from '../Rehydrater';

interface WhitelabelProps {
  Component: React.ElementType;
}

const Whitelabel: React.FC<WhitelabelProps> = observer(({ Component }) => {
  const store = useStore();
  const [loading, setLoading] = React.useState(true);

  const loadCompanyData = async () => {
    setLoading(true);
    const { host } = window.location;
    const prodWhiteLabel = host.endsWith('.wl.sorted.co.za');
    const stagingWhiteLabel = host.endsWith('.wl.staging.sorted.co.za');

    let reference = 'sorted';
    // let reference = 'assurity';

    if (prodWhiteLabel) {
      reference = host.replace('.wl.sorted.co.za', '');
    } else if (stagingWhiteLabel) {
      reference = host.replace('.wl.staging.sorted.co.za', '');
    }

    await store.companies.loadBranding(reference);
    await delay(500);
    setLoading(false);
  };

  React.useEffect(() => {
    loadCompanyData();
  }, []);

  return loading ? <Loader /> : <Component />;
});

export default Whitelabel;
