import React from 'react';
import { observer } from 'mobx-react-lite';
// @material-ui
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  TextField,
  DialogTitle,
  IconButton,
  MenuItem,
  Typography,
} from '@material-ui/core';
// @form
import { useForm, Controller } from 'react-hook-form';
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
// @icons
import { Close as CloseIcon } from '@material-ui/icons';
// @logic
import { useStore } from 'logic/store';
// @components
import Confirmation from 'components/utilities/Confirm';
// @local
import { vehicleTypes } from 'logic/stores/vehicles/types';
import { regions } from 'logic/stores/renewals/types';
import useStyles from '../styles';

interface FormValues {
  region: string;
  kgFrom: number;
  kgTo: number;
  feeInCents: number;
  vehicleType: string;
}

const validationSchema = Yup.object().shape({
  region: Yup.string().lowercase().label('Region').required(),
  kgFrom: Yup.number().required().label('kg from'),
  kgTo: Yup.number().required().label('kg to'),
  feeInCents: Yup.number().label('Fee').required(),
  vehicleType: Yup.string().lowercase().label('Vehicle Type').required(),
});

interface Props {
  licenseFeeId: number;
  closeModal: () => void;
}

const UpdateForm = observer(({ licenseFeeId, closeModal }: Props) => {
  const classes = useStyles();
  const store = useStore();
  const licenseFee = store.licenseFees.getById(licenseFeeId);
  const [open, setOpen] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [viewConfirm, setViewConfirm] = React.useState(false);

  const defaultTo = licenseFee
    ? {
        ...licenseFee,
        feeInCents: licenseFee.feeInCents / 100,
      }
    : {};

  const { handleSubmit, errors, control, reset } = useForm<FormValues>({
    resolver: yupResolver(validationSchema),
    defaultValues: defaultTo,
  });

  React.useEffect(() => {
    if (licenseFee != null) {
      reset(defaultTo);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [licenseFee]);

  React.useEffect(() => {
    if (licenseFeeId != null) {
      setOpen(true);
    } else {
      setOpen(false);
    }
  }, [licenseFeeId]);

  const updateData = async (data: FormValues) => {
    if (licenseFeeId != null) {
      setLoading(true);
      const feeInCents = data.feeInCents * 100;
      const res = await store.licenseFees.update(licenseFeeId, {
        ...data,
        feeInCents,
      });
      setLoading(false);
      if (res.success === true) {
        closeModal();
      }
    }
  };

  const confirm = async () => {
    if (licenseFeeId != null) {
      const res = await store.licenseFees.remove(licenseFeeId);
      if (res.success === true) {
        setViewConfirm(false);
        closeModal();
      }
    }
  };

  return (
    <>
      <Confirmation
        title={`Remove ${licenseFee != null ? `#${licenseFee.id}` : ''}`}
        confirm={confirm}
        close={() => setViewConfirm(false)}
        description="Click okay to remove the selected license fee"
        open={viewConfirm}
      />
      <Dialog fullWidth maxWidth="sm" open={open} onClose={closeModal}>
        <DialogTitle>
          <b>Update</b>
          <IconButton
            aria-label="close"
            onClick={closeModal}
            className={classes.positionIcon}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <form>
            <br />
            <Typography>
              <b>General Details</b>
            </Typography>
            <Controller
              as={
                <TextField
                  select
                  color="secondary"
                  variant="outlined"
                  margin="dense"
                  label="Select Region"
                  error={Boolean(errors.region)}
                  helperText={errors.region && errors.region.message}
                  className={classes.capitalText}
                >
                  {regions.map((n) => (
                    <MenuItem key={n} value={n} className={classes.capitalText}>
                      {n}
                    </MenuItem>
                  ))}
                </TextField>
              }
              name="region"
              control={control}
            />

            <Controller
              as={
                <TextField
                  select
                  color="secondary"
                  variant="outlined"
                  margin="dense"
                  label="Select Vehicle Type"
                  error={Boolean(errors.vehicleType)}
                  helperText={errors.vehicleType && errors.vehicleType.message}
                  className={classes.capitalText}
                >
                  {vehicleTypes.map((n) => (
                    <MenuItem key={n} value={n} className={classes.capitalText}>
                      {n}
                    </MenuItem>
                  ))}
                </TextField>
              }
              name="vehicleType"
              control={control}
            />

            <Controller
              as={
                <TextField
                  fullWidth
                  color="secondary"
                  type="number"
                  margin="dense"
                  variant="outlined"
                  placeholder="From (kg)"
                  error={Boolean(errors.kgFrom)}
                  helperText={errors.kgFrom && errors.kgFrom.message}
                />
              }
              name="kgFrom"
              control={control}
            />

            <Controller
              as={
                <TextField
                  fullWidth
                  color="secondary"
                  type="number"
                  margin="dense"
                  variant="outlined"
                  placeholder="To (kg)"
                  error={Boolean(errors.kgTo)}
                  helperText={errors.kgTo && errors.kgTo.message}
                />
              }
              name="kgTo"
              control={control}
            />

            <Controller
              as={
                <TextField
                  fullWidth
                  type="number"
                  color="secondary"
                  margin="dense"
                  variant="outlined"
                  placeholder="Fee"
                  error={Boolean(errors.feeInCents)}
                  helperText={errors.feeInCents && errors.feeInCents.message}
                />
              }
              name="feeInCents"
              control={control}
            />
          </form>
        </DialogContent>
        <DialogActions className={classes.dialogBorder}>
          <Button
            onClick={() => setViewConfirm(true)}
            variant="contained"
            color="default"
          >
            Delete
          </Button>
          <div className={classes.flexGrow} />
          <Button onClick={closeModal} color="default">
            Cancel
          </Button>
          <Button
            disabled={loading}
            variant="contained"
            color="primary"
            onClick={handleSubmit(updateData)}
          >
            Save
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
});

export default UpdateForm;
