import { Theme } from '@material-ui/core/styles/createMuiTheme';
import { makeStyles, createStyles } from '@material-ui/core/styles';

const styles = makeStyles((theme: Theme) =>
  createStyles({
    fullWidth: {
      width: '100%',
      marginBottom: '10px',
    },
    progress: {
      marginRight: theme.spacing(1),
      margin: '0 auto',
      textAlgn: 'center',
    },
    alertSpaceBottom: {
      marginBottom: '10px',
    },
    progressColor: {
      color: 'rgba(255,255,255,0.8)',
    },
    gridCenter: {
      textAlign: 'center',
    },
    centerProgress: {
      display: 'block',
    },
    uploadFont60: {
      fontSize: 60,
    },
    uploadSpaceTop: {
      marginTop: '15px',
    },
    img100: {
      height: '100%',
      width: '100%',
    },
  }),
);

export default styles;
