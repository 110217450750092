import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';

export default makeStyles((theme: Theme) =>
  createStyles({
    lightText: {
      fontWeight: 200,
    },
    boldText: {
      fontWeight: 400,
    },
    progress: {
      padding: theme.spacing(1),
    },
    gridPaddingBottom: {
      paddingBottom: '20px',
    },
    select: {
      padding: '15px',
      margin: '0',
    },
    btnSpace: {
      marginTop: '10px',
    },
    gridWidthBackground: {
      backgroundColor: 'rgba(0,0,0,0.05)',
      width: '100%',
    },
    alertSpaceBottom: {
      marginTop: '10px',
      marginBottom: '20px',
    },
    centerProgress: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },
  }),
);
