import { makeObservable, observable, runInAction } from 'mobx';
import { BaseStore, RequestConfig } from '../util/baseStore';
import {
  ILicenseFee,
  LicenseFeeSchema,
  PaginatedLicenseFeeSchema,
} from './validation';
import { PaginatedInput } from '../vehicles/validation';
import {
  LicenceRenewalQuote,
  LicenseRenewalQuoteSchema,
} from '../renewals/validation';
import { Region, VehicleType } from '../vehicles/types';

export type CreateLicenseFeeInput = {
  region: string;
  kgFrom: number;
  kgTo: number;
  feeInCents: number;
  vehicleType: string;
  currency: string;
};

export type GetLicenceRenewalQuoteInput = {
  region: Region;
  vehicleWeight: number;
  vehicleType: VehicleType;
  licenseExpiryDate: Date;
};

class V2LicenseFeeStore extends BaseStore {
  constructor(config: RequestConfig) {
    super(config);
    makeObservable(this, {
      fees: observable,
    });
  }

  fees: ILicenseFee[] = [];

  clear() {
    runInAction(() => {
      this.fees = [];
    });
  }

  async get(input: PaginatedInput) {
    this.setLoading(true);

    const result = await this.sendRequest({
      method: 'GET',
      path: 'license-fees',
      params: input,
      validation: PaginatedLicenseFeeSchema,
    });

    runInAction(() => {
      this.loading = false;
      if (result.success) {
        this.fees = result.data.entries;
      }
    });

    return result;
  }

  async search(value: string) {
    this.setLoading(true);

    const result = await this.sendRequest({
      method: 'GET',
      path: 'license-fees/search',
      params: {
        search: value,
        skip: 0,
        take: 300,
      },
      validation: PaginatedLicenseFeeSchema,
    });

    runInAction(() => {
      this.loading = false;
      if (result.success) {
        this.fees = result.data.entries;
      }
    });

    return result;
  }

  async getLicenceRenewalQuote(input: GetLicenceRenewalQuoteInput) {
    return this.withState(
      this.sendRequest({
        method: 'GET',
        path: 'license-fees/quote',
        params: input,
        validation: LicenseRenewalQuoteSchema,
      }),
    );
  }

  async create(input: CreateLicenseFeeInput) {
    return this.withState(
      this.sendRequest({
        method: 'POST',
        path: 'license-fees',
        body: input,
        validation: LicenseFeeSchema,
      }),
    );
  }

  async loadById(id: number) {
    const cached = this.fees.find((f) => f.id === id);
    if (cached) {
      return cached;
    }

    this.setLoading(true);

    const result = await this.sendRequest({
      method: 'GET',
      path: 'license-fees/id',
      body: { id },
      validation: LicenseFeeSchema,
    });

    runInAction(() => {
      this.loading = false;
    });

    return result;
  }

  getById(id: number) {
    return this.fees.find((f) => f.id === id);
  }

  update(id: number, payload: Partial<ILicenseFee>) {
    return this.withState(
      this.sendRequest({
        method: 'PATCH',
        path: 'license-fees',
        validation: LicenseFeeSchema,
        body: { id, ...payload },
      }),
    );
  }

  remove(id: number) {
    return this.withState(
      this.sendRequest({
        method: 'DELETE',
        path: 'license-fees',
        validation: LicenseFeeSchema,
        params: { id },
      }),
    );
  }
}

export default V2LicenseFeeStore;
