import React, { useState } from 'react';
import swal from '@sweetalert/with-react';
import { observer } from 'mobx-react-lite';
// @material-ui
import { Button, Grid, TextField, Paper } from '@material-ui/core';
// @form
import { useForm, Controller } from 'react-hook-form';
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
// @logic
import { useStore } from 'logic/store';
import { emailValidation } from 'logic/validation';
// @components
import Content from 'components/Layout/Content';
// @local
import useStyles from './styles';

interface FormValues {
  email: string;
}

const validationSchema = Yup.object().shape({
  email: emailValidation.required(),
});

const ResendVerification = observer(() => {
  const store = useStore();
  const classes = useStyles({});

  const [loading, setLoading] = useState(false);

  const { handleSubmit, errors, control } = useForm<FormValues>({
    resolver: yupResolver(validationSchema),
  });

  const verify = async ({ email }: FormValues) => {
    setLoading(true);
    const res = await store.auth.sendVerificationRequest(email);
    if (res.success === true) {
      swal('Sorted!', 'New verification request has been sent.', 'success');
    } else {
      swal(
        'Error!',
        'Unable to send verification email. Please email info@sorted.co.za for help',
        'error',
      );
    }
    setLoading(false);
  };

  return (
    <Content
      className={classes.container}
      containerClass={classes.contentContainer}
    >
      <Grid className={classes.signUpHead}>
        <p className={classes.headSecond}>
          Enter your email address and we&apos;ll resend the verification.
        </p>
      </Grid>

      <Grid item md={12} xs={12}>
        <h2 className={classes.pageTitle}>Resend Verification </h2>
        <Paper
          elevation={2}
          className={`${classes.formStyle} ${classes.padding20}`}
        >
          <form onSubmit={handleSubmit(verify)}>
            <Controller
              as={
                <TextField
                  fullWidth
                  margin="dense"
                  label="Email"
                  InputProps={{ className: classes.textDark }}
                  placeholder="Email"
                  color="secondary"
                  type="email"
                  error={Boolean(errors.email)}
                  helperText={errors.email && errors.email.message}
                />
              }
              name="email"
              control={control}
            />

            <div className={classes.submitContainer}>
              <Button
                fullWidth
                disabled={loading}
                className={classes.btnBlue}
                size="large"
                type="submit"
              >
                Let&apos;s Go
              </Button>
            </div>
            <br />
          </form>
        </Paper>
      </Grid>
    </Content>
  );
});

export default ResendVerification;
