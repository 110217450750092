import React from 'react';
import moment from 'moment';
// @material-ui
import {
  Grid,
  ListItem,
  ListItemAvatar,
  Avatar,
  ListItemText,
} from '@material-ui/core';
// @icons
import {
  FitnessCenter,
  ConfirmationNumber,
  Event,
  Info,
  DriveEta,
  Room,
  Payment,
  Description,
} from '@material-ui/icons';
import { BsPersonDashFill, BsFillPersonLinesFill } from 'react-icons/all';
// @components
import { formatCurrency } from 'utilities/handleCurrency';
// @local
import { IVehicle } from 'logic/stores/vehicles/validation';
import { IUser } from 'logic/stores/users/validation';
import useStyles from './styles';

interface TemplateProps {
  vehicle: IVehicle | null;
  user: IUser;
}

const Vehicle = ({ vehicle, user }: TemplateProps) => {
  const classes = useStyles();

  const largeBlueAvatarStyles = [classes.large, classes.blueAvatar].join(' ');
  const upperDivSpaceStyles = [classes.uppercase, classes.divSpace].join(' ');
  const secondaryBlueTextStyles = [
    classes.secondaryLarge,
    classes.blueText,
  ].join(' ');

  return (
    <div>
      <Grid container spacing={2}>
        <Grid item xs={12} container>
          <Grid md={6} xs={12}>
            <ListItem>
              <ListItemAvatar>
                <Avatar className={largeBlueAvatarStyles}>
                  <DriveEta fontSize="large" />
                </Avatar>
              </ListItemAvatar>
              <ListItemText
                className={upperDivSpaceStyles}
                primary="Make"
                secondary={
                  vehicle ? (
                    <span className={secondaryBlueTextStyles}>
                      {vehicle.make}
                    </span>
                  ) : null
                }
              />
            </ListItem>
          </Grid>

          <Grid md={6} xs={12}>
            <ListItem>
              <ListItemAvatar>
                <Avatar className={largeBlueAvatarStyles}>
                  <ConfirmationNumber fontSize="large" />
                </Avatar>
              </ListItemAvatar>
              <ListItemText
                className={upperDivSpaceStyles}
                primary="License Number"
                secondary={
                  vehicle ? (
                    <span className={secondaryBlueTextStyles}>
                      {vehicle.licenseNumber}
                    </span>
                  ) : null
                }
              />
            </ListItem>
          </Grid>

          <Grid md={6} xs={12}>
            <ListItem>
              <ListItemAvatar>
                <Avatar className={largeBlueAvatarStyles}>
                  <FitnessCenter fontSize="large" />
                </Avatar>
              </ListItemAvatar>
              <ListItemText
                className={upperDivSpaceStyles}
                primary="Vehicle Weight"
                secondary={
                  vehicle ? (
                    <span className={secondaryBlueTextStyles}>
                      {`${vehicle.weightKg} KG`}
                    </span>
                  ) : null
                }
              />
            </ListItem>
          </Grid>

          <Grid md={6} xs={12}>
            <ListItem>
              <ListItemAvatar>
                <Avatar className={largeBlueAvatarStyles}>
                  <Info fontSize="large" />
                </Avatar>
              </ListItemAvatar>
              <ListItemText
                className={upperDivSpaceStyles}
                primary="Vehicle Type"
                secondary={
                  vehicle ? (
                    <span className={secondaryBlueTextStyles}>
                      {vehicle.vehicleType}
                    </span>
                  ) : null
                }
              />
            </ListItem>
          </Grid>

          <Grid md={6} xs={12}>
            <ListItem>
              <ListItemAvatar>
                <Avatar className={largeBlueAvatarStyles}>
                  <Event fontSize="large" />
                </Avatar>
              </ListItemAvatar>
              <ListItemText
                className={upperDivSpaceStyles}
                primary="License Expiry Date"
                secondary={
                  vehicle ? (
                    <span className={secondaryBlueTextStyles}>
                      {vehicle
                        ? moment(vehicle.licenseExpires).format('DD-MM-YYYY')
                        : null}
                    </span>
                  ) : null
                }
              />
            </ListItem>
          </Grid>

          <Grid md={6} xs={12}>
            <ListItem>
              <ListItemAvatar>
                <Avatar className={largeBlueAvatarStyles}>
                  <Room fontSize="large" />
                </Avatar>
              </ListItemAvatar>
              <ListItemText
                className={upperDivSpaceStyles}
                primary="Registration Region"
                secondary={
                  vehicle ? (
                    <span className={secondaryBlueTextStyles}>
                      {vehicle.registrationRegion}
                    </span>
                  ) : null
                }
              />
            </ListItem>
          </Grid>

          <Grid md={6} xs={12}>
            <ListItem>
              <ListItemAvatar>
                <Avatar className={largeBlueAvatarStyles}>
                  <Description fontSize="large" />
                </Avatar>
              </ListItemAvatar>
              <ListItemText
                className={upperDivSpaceStyles}
                primary="Vehicle Registration Number"
                secondary={
                  vehicle ? (
                    <span className={secondaryBlueTextStyles}>
                      {vehicle.registerNumber}
                    </span>
                  ) : null
                }
              />
            </ListItem>
          </Grid>

          <Grid md={6} xs={12}>
            <ListItem>
              <ListItemAvatar>
                <Avatar className={largeBlueAvatarStyles}>
                  <Payment fontSize="large" />
                </Avatar>
              </ListItemAvatar>
              <ListItemText
                className={upperDivSpaceStyles}
                primary="Renewal Fee"
                secondary={
                  vehicle ? (
                    <span className={secondaryBlueTextStyles}>
                      {vehicle.renewalFeeInCents &&
                        formatCurrency(vehicle.renewalFeeInCents)}
                    </span>
                  ) : null
                }
              />
            </ListItem>
          </Grid>

          <Grid md={6} xs={12}>
            <ListItem>
              <ListItemAvatar>
                <Avatar className={largeBlueAvatarStyles}>
                  <BsPersonDashFill fontSize="large" />
                </Avatar>
              </ListItemAvatar>
              <ListItemText
                className={upperDivSpaceStyles}
                primary="User first name"
                secondary={
                  vehicle ? (
                    <span className={secondaryBlueTextStyles}>
                      {user.firstName}
                    </span>
                  ) : null
                }
              />
            </ListItem>
          </Grid>

          <Grid md={6} xs={12}>
            <ListItem>
              <ListItemAvatar>
                <Avatar className={largeBlueAvatarStyles}>
                  <BsFillPersonLinesFill fontSize="large" />
                </Avatar>
              </ListItemAvatar>
              <ListItemText
                className={upperDivSpaceStyles}
                primary="User last name"
                secondary={
                  vehicle ? (
                    <span className={secondaryBlueTextStyles}>
                      {user.lastName}
                    </span>
                  ) : null
                }
              />
            </ListItem>
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
};

export default Vehicle;
