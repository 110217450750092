import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react-lite';
import { useLocation, useParams } from 'react-router-dom';

import { CircularProgress } from '@material-ui/core';
import { API_URL } from 'logic/stores/root';
import { useStore } from 'logic/store';
import StartRenewal, { IRenewalResult, Steps } from '.';
import useStyles from './styles';

const RenewalResult = observer((props) => {
  // TODO: clear the params so okes aren't sneaky
  const loc = useLocation();

  const store = useStore();

  const classes = useStyles();

  const [isLoading, setLoading] = useState(false);
  const [result, setResult] = useState<IRenewalResult | undefined>(undefined);

  useEffect(() => {
    async function updateResult() {
      setLoading(true);
      try {
        const result = await store.payments.updatePaymentResult(loc.search);

        if (result.success) {
          setResult({
            result: result.data.result,
            reason: result.data.description,
          });
          setLoading(false);
        } else {
          throw new Error(result.message);
        }
      } catch (e) {
        console.log(`Error updating result`);
        console.log(e);
        setLoading(false);
        setResult({
          result: 'failed',
          reason: (e as Error).message,
        });
      }
    }

    updateResult();
  }, []);

  return isLoading ? (
    <CircularProgress className={classes.progress} />
  ) : (
    <StartRenewal step={Steps.FinalConfirmation} result={result} />
  );
});

export default RenewalResult;
