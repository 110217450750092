import { PAYMENT_HOST } from 'logic/config';
import { useStore } from 'logic/store';
import { IUser } from 'logic/stores/users/validation';
import React, { FC, useState } from 'react';
import useStyles from './styles';
import './Pay.css';

const WHITELABEL_IFRAME_NAME = 'sortedWhiteLabel';

interface UpdateStatusInput {
  step: number;
  status: string;
}

interface PaymentFormProps {
  amountInCents: number;
  user: IUser;
  vehicleId: number;
  description: string;
  reference: string;
}

const PaymentForm: FC<PaymentFormProps> = ({
  amountInCents,
  user,
  vehicleId,
  description,
  reference,
}: PaymentFormProps) => {
  const store = useStore();

  const userId = user.id;

  const redirectUrl = `${window.location.origin}/start-renewal/result/${vehicleId}`;

  const [checkoutId, setCheckoutId] = useState<string>();
  const [hasPaymentScript, setPaymentScript] = useState<boolean>(false);
  const form = React.createRef<any>();

  const classes = useStyles({});

  React.useEffect(() => {
    async function loadForm() {
      setCheckoutId(undefined);
      window.localStorage.removeItem('checkoutId');

      try {
        const response = await store.payments.getPaymentUrl({
          amount: amountInCents,
          userId,
          vehicleId,
          reference,
          description,
        });

        if (response.success) {
          setCheckoutId(response.data.checkoutId);
          window.localStorage.setItem('checkoutId', response.data.checkoutId);
        } else {
          throw new Error(response.message);
        }
      } catch (e) {
        console.log('Error Getting form');
        console.log(e);
        setCheckoutId('');
      }
    }

    loadForm();

    return () => {
      setCheckoutId(undefined);
    };
  }, []);

  React.useEffect(() => {
    if (checkoutId && !hasPaymentScript) {
      // TODO: Prepopulate the billing address using the Google Maps response
      // Also save a billing address for a customer for future payments
      const options: Record<string, any> = {
        billingAddress: {
          country: 'ZA',
        },
        mandatoryBillingFields: {
          city: true,
          country: true,
          street1: true,
          postcode: true,
        },
      };

      if (store.whitelabel) {
        // Only add target settings when inside the iframe for whitelabeling
        // Due to how much of the page should be rerendered
        options.paymentTarget = WHITELABEL_IFRAME_NAME;
        options.shopperResultTarget = WHITELABEL_IFRAME_NAME;
      }

      const scriptVar = document.createElement('script');
      scriptVar.setAttribute('type', 'text/javascript');
      const wpwlOptions = `var wpwlOptions=${JSON.stringify(options)}`;

      const inlineScript = document.createTextNode(wpwlOptions);
      scriptVar.appendChild(inlineScript);
      document.head.appendChild(scriptVar);

      const script = document.createElement('script');
      script.src = `${PAYMENT_HOST}v1/paymentWidgets.js?checkoutId=${checkoutId}`;
      document.head.appendChild(script);
      setPaymentScript(true);
    }
  }, [checkoutId]);

  return (
    <main className={classes.iframe}>
      <div className="content">
        {checkoutId === undefined ? (
          <div>
            <div className="text-center">
              <div className="loading loading-lg" />
              <p>Setting up payment form...</p>
            </div>
          </div>
        ) : null}

        <div ref={form}>
          {checkoutId !== undefined ? (
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  width: '40em',
                  maxWidth: '90vw',
                  justifyContent: 'center',
                  alignItems: 'space-between',
                }}
              >
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}
                >
                  <img
                    style={{
                      width: 'auto',
                      height: '75px',
                    }}
                    alt="Peach Payments"
                    src="https://sorted-space.fra1.digitaloceanspaces.com/assets/general/Secured%20by%20Peach%20_%20Banner%20Simple%20_%20Color.png"
                  />
                </div>
              </div>
              <div
                style={{
                  marginTop: '18px',
                  justifyContent: 'center',
                  alignItems: 'center',
                  display: 'flex',
                  flexDirection: 'row',
                  width: '40em',
                  maxWidth: '90vw',
                }}
              >
                <form
                  className="paymentWidgets"
                  action={redirectUrl}
                  data-brands="VISA MASTER AMEX"
                />
              </div>
            </div>
          ) : null}
        </div>
      </div>
    </main>
  );
};

export default PaymentForm;
