import React from 'react';
import { toast } from 'react-toastify';
import { useDropzone, DropEvent, FileRejection } from 'react-dropzone';
import swal from '@sweetalert/with-react';
import { observer } from 'mobx-react-lite';
// @material-ui
import {
  Typography,
  Grid,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  RootRef,
  Paper,
  LinearProgress,
  CircularProgress,
  useMediaQuery,
} from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import { useTheme } from '@material-ui/core/styles';
// @icons
import { CloudUpload as UploadIcon } from '@material-ui/icons';
// @logic
import { useStore } from 'logic/store';
// @local
import { IOCRResults } from 'logic/stores/document/validation';
import useStyles from './styles';

interface TemplateProps {
  file: File | null;
  setOpenDialog: React.Dispatch<React.SetStateAction<boolean>>;
  setFile: React.Dispatch<React.SetStateAction<File | null>>;
  openDialog: boolean;
  handleClickOpen: () => void;
  updateOCRForm: (update: IOCRResults) => void;
}

const LicenseUpload = observer((props: TemplateProps) => {
  const {
    file,
    setOpenDialog,
    setFile,
    openDialog,
    handleClickOpen,
    updateOCRForm,
  } = props;
  const store = useStore();
  const theme = useTheme();
  const classes = useStyles();
  const uploadCenterStyles = [classes.uploadSpaceTop, classes.gridCenter].join(
    ' ',
  );

  const [uploadFile, setUploadFile] = React.useState<
    string | ArrayBuffer | null
  >(null);

  const handleClose = () => {
    setOpenDialog(false);
  };

  const [documentId, setDocumentId] = React.useState<number | null>(null);
  const [loadingOCR, setLoadingOCR] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));

  const [progress, setProgress] = React.useState(0);

  // set if we have received the ocr data
  const [gotOCR, setGotOCR] = React.useState(false);

  const progressUpdate = (update: number) => {
    setProgress(update);
  };

  const [seconds, setSeconds] = React.useState(10);

  React.useEffect(() => {
    const fetchOCRDetails = async () => {
      if (documentId != null) {
        setLoadingOCR(true);
        const details = await store.documents.fetchLicenseOCR(documentId);
        if (details && details.success) {
          updateOCRForm(details.data);
          setGotOCR(true);
          // stop the timer if data is fetched
          setSeconds(0);
        } else {
          // display retry message on error
          displayRetry();
        }
        setLoadingOCR(false);
      }
    };
    fetchOCRDetails();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [documentId]);

  React.useEffect(() => {
    if (loadingOCR || (gotOCR && seconds === 0)) {
      // if we either waiting or we have received the ocr info
      displayExtractingDetails();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loadingOCR, gotOCR, seconds]);

  React.useEffect(() => {
    // run ocr upload after file is uploaded
    if (file !== null) {
      uploadDocument();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(file)]);

  React.useEffect(() => {
    // set a 10 second timer to ensure we have enough time to capture ocr
    if (seconds !== 0 && !gotOCR) {
      setTimeout(() => setSeconds(seconds - 1), 1000);
    }
  }, [seconds, gotOCR]);

  const uploadConfig = {
    accept: 'image/jpeg, image/jpg, image/png, application/pdf',
    maxSize: 5 * 1000000,
    onDropRejected: (fileRejections: FileRejection[], event: DropEvent) => {
      fileRejections.forEach((element: FileRejection) => {
        element.errors.forEach((errors: { code: string; message: string }) => {
          swal(
            `${errors.code} ${errors.message.replace(
              '5000000 bytes',
              '5 MegaBytes',
            )}`,
          );
        });
      });
    },
    onDropAccepted: (selectedFile: File[]) => {
      const reader = new FileReader();
      const newFile = selectedFile[0];

      reader.onloadend = () => {
        const binaryStr = reader.result;
        setUploadFile(binaryStr);
        setFile(newFile);
      };

      reader.readAsDataURL(newFile);
    },
  };

  const { getRootProps, getInputProps, isDragActive } =
    useDropzone(uploadConfig);

  const { ref, ...rootProps } = getRootProps();

  const uploadDocument = async () => {
    if (file != null) {
      setLoading(true);
      const result = await store.documents.uploadLicenceDisk(file);
      setLoading(false);
      if (result && result.success) {
        setDocumentId(result.document.id);
      }
    } else {
      toast.error('No file has been specified');
    }
  };

  const displayExtractingDetails = () => {
    swal({
      title: gotOCR
        ? 'Extracted! We can proceed now.'
        : 'Uploaded, now extracting vehicle details from image.',
      content: (
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <div className={classes.centerProgress}>
              {loadingOCR && gotOCR === false ? (
                <CircularProgress className={classes.progress} />
              ) : (
                <>
                  <Alert severity="info" className={classes.alertSpaceBottom}>
                    Please make sure to validate and double check the extracted
                    vehicle details from image to confirm accuracy of vehicle
                    details.
                  </Alert>
                  <Alert severity="success">
                    Click Ok to double check your details and proceed to next
                    step!
                  </Alert>
                </>
              )}
            </div>
          </Grid>
        </Grid>
      ),
      icon: 'success',
    });
    handleClose();
  };

  const displayRetry = () => {
    swal({
      title: 'Vehicle details extraction failed',
      content: (
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <Alert severity="info">
              Please attempt to upload the image one more time, before
              proceeding with entering the details manually.
            </Alert>
          </Grid>
        </Grid>
      ),
      icon: 'info',
    });
    handleClose();
  };

  return (
    <>
      <Dialog
        fullWidth
        maxWidth="sm"
        fullScreen={fullScreen}
        open={openDialog}
        onClose={handleClose}
      >
        <DialogTitle>Upload Licence Disc</DialogTitle>
        <DialogContent>
          {file != null ? (
            <Button
              onClick={uploadDocument}
              variant="contained"
              disabled={loading}
              color="primary"
              fullWidth
            >
              Click to scan details
            </Button>
          ) : null}
          <RootRef rootRef={ref}>
            <Paper elevation={0} {...rootProps}>
              <Grid container alignItems="center" justify="center">
                <Grid item md={1}>
                  <UploadIcon
                    color="primary"
                    className={classes.uploadFont60}
                  />
                </Grid>
                <Grid
                  item
                  xs={12}
                  md={12}
                  sm={12}
                  className={classes.gridCenter}
                >
                  <input {...getInputProps()} />
                  {isDragActive ? (
                    <Typography variant="overline" align="center">
                      Drop the files here ...
                    </Typography>
                  ) : (
                    <Typography variant="overline" align="center">
                      Drag and drop a copy of your licence disc and we will be
                      able to grab all your vehicle details
                    </Typography>
                  )}
                </Grid>
                <Grid
                  item
                  xs={12}
                  md={12}
                  sm={12}
                  className={classes.fullWidth}
                >
                  <Button
                    onClick={handleClickOpen}
                    color="primary"
                    variant="outlined"
                    fullWidth
                  >
                    Select Licence Disc Image
                  </Button>
                </Grid>
              </Grid>

              {uploadFile != null ? (
                <Grid item md={12} xs={12} className={uploadCenterStyles}>
                  {(uploadFile as string).includes('application/pdf') &&
                  typeof uploadFile === 'string' ? (
                    <>
                      <iframe
                        frameBorder="0"
                        scrolling="auto"
                        height="400px"
                        width="400px"
                        title="Licence Upload"
                        src={uploadFile}
                      />
                    </>
                  ) : (
                    <img
                      className={classes.img100}
                      src={uploadFile as string}
                      alt="Licence Upload"
                    />
                  )}
                </Grid>
              ) : null}
            </Paper>
          </RootRef>
          {progress > 0 ? (
            <LinearProgress variant="determinate" value={progress} />
          ) : null}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
});

export default LicenseUpload;
