import React from 'react';
import moment from 'moment';
import { observer } from 'mobx-react-lite';

// @material-ui
import {
  Table,
  TableHead,
  TableCell,
  TableBody,
  TableFooter,
  TablePagination,
  TableRow,
  Paper,
  Chip,
  AppBar,
  Toolbar,
  Grid,
  Hidden,
  TextField,
  Typography,
  InputLabel,
  NativeSelect,
  TableSortLabel,
  FormControl,
  CircularProgress,
  TableContainer,
} from '@material-ui/core';
// @logic
import { useStore } from 'logic/store';
// @components
import { formatCurrency } from 'utilities/handleCurrency';
import useAfterMount from 'components/utilities/UseAfterMount';
// @local
import { IPayment } from 'logic/stores/payments/validation';
import { API_URL } from 'logic/stores/root';
import useStyles from './styles';

type Order = 'asc' | 'desc';

function descendingComparator<T>(a: T, b: T, orderBy: keyof T) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator<Key extends keyof any>(
  order: Order,
  orderBy: Key,
): (a: { [key in Key]: any }, b: { [key in Key]: any }) => number {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort<T>(array: T[], comparator: (a: T, b: T) => number) {
  const stabilizedThis = array.map((el, index) => [el, index] as [T, number]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

const PaymentsController = observer(() => {
  // @logic
  const store = useStore();
  const { payments } = store.payments;
  const { permissions: permissionNames } = store.auth;
  const classes = useStyles({});
  const [fromDate, setFromDate] = React.useState<string>('');
  const [toDate, setToDate] = React.useState<string>('');
  const [statusSelect, setSetStatusSelect] = React.useState('');
  const [textSearch, setTextSearch] = React.useState('');
  const [order, setOrder] = React.useState<Order>('desc');
  const [orderBy, setOrderBy] = React.useState<'createdAt' | 'amountInCents'>(
    'createdAt',
  );
  const [searchTimeout, setSearchTimeout] =
    React.useState<NodeJS.Timeout | null>(null);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  const [page, setPage] = React.useState(0);
  // const searchQuery: Partial<IQuery<IPayment>> = {};

  const [loadingPaymentId, setLoadingPaymentId] = React.useState<number>();

  const onRefreshPaymentClick = React.useCallback((id, event) => {
    async function updatePayment() {
      setLoadingPaymentId(id);
      try {
        const url = new URL(`${API_URL}/payments/request-payment-update`);
        url.searchParams.set(`payment_id`, id);
        const repsonse = await fetch(url.toString());

        await fetchPayments();
        setLoadingPaymentId(undefined);
      } catch (e) {
        console.log(`Error updating result`);
        console.log(e);
        setLoadingPaymentId(undefined);
      }
    }

    updatePayment();
  }, []);

  React.useEffect(() => {
    fetchPayments();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  React.useEffect(() => {
    fetchPayments();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rowsPerPage, page, orderBy, order]);

  useAfterMount(() => {
    if (searchTimeout != null) {
      clearTimeout(searchTimeout);
    }
    setSearchTimeout(
      // @ts-ignore To-Do Fix Type
      setTimeout(() => {
        if (page > 0) {
          setPage(0);
        } else {
          fetchPayments();
        }
      }, 300),
    );
  }, [fromDate, toDate, statusSelect, textSearch]);

  const fetchPayments = async () => {
    await store.payments.find({
      from: fromDate ? stringToDate(fromDate) : undefined,
      to: toDate ? stringToDate(toDate) : undefined,
      status: statusSelect,
      search: textSearch,
      skip: page * rowsPerPage,
      take: rowsPerPage,
      order,
      orderBy,
    });
  };

  const stringToDate = (date: string) => {
    const newDate = new Date(date);
    return newDate;
  };

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleDateChange = (dateEvent: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = dateEvent.target;
    name === 'fromDate' ? setFromDate(value) : setToDate(value);
  };

  const handleSort = (name: any) => (event: React.MouseEvent) => {
    const isDesc = orderBy === name && order === 'desc';
    setOrder(isDesc ? 'asc' : 'desc');
    setOrderBy(name);
  };

  const handleStatusSelect = (
    statusEvent: React.ChangeEvent<{ value: string }>,
  ) => {
    setSetStatusSelect(statusEvent.target.value);
  };

  const handleSearchText = (textEvent: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = textEvent.target;
    setTextSearch(value);
  };

  const emptyRows =
    rowsPerPage - Math.min(rowsPerPage, payments.total - page * rowsPerPage);

  const rowStyles = 49 * emptyRows;
  const heightWithRow = [{ height: rowStyles }].join(' ');

  return (
    <>
      <Typography variant="h4" align="center">
        Manage Payments
      </Typography>
      <AppBar position="static" color="default" elevation={0}>
        <Toolbar>
          <Grid
            container
            justify="space-around"
            alignItems="center"
            direction="row"
          >
            <Grid item xs={12} md={7} className={classes.searchBar}>
              <TextField
                color="secondary"
                onChange={handleSearchText}
                value={textSearch}
                placeholder="Search by description or reference"
                InputProps={{ disableUnderline: true }}
              />
            </Grid>
            <Grid item xs={12} md={5}>
              <Grid container spacing={2} direction="row">
                <Grid item md={4} xs={12}>
                  <FormControl>
                    <InputLabel shrink htmlFor="status-selector">
                      Status
                    </InputLabel>
                    <NativeSelect
                      value={statusSelect}
                      onChange={handleStatusSelect}
                      inputProps={{
                        id: 'status-selector',
                      }}
                    >
                      <option value="" aria-label="All Statuses">
                        All
                      </option>
                      <option value="processing">Processing</option>
                      <option value="success">Success</option>
                      <option value="cancelled">Cancelled</option>
                      <option value="failed">Failed</option>
                    </NativeSelect>
                  </FormControl>
                </Grid>
                <Grid item md={4} xs={12}>
                  <TextField
                    color="secondary"
                    type="date"
                    label="From Date"
                    name="fromDate"
                    onChange={handleDateChange}
                    value={fromDate}
                    InputLabelProps={{ shrink: true }}
                    inputProps={{ max: toDate }}
                  />
                </Grid>
                <Grid item md={4} xs={12}>
                  <TextField
                    color="secondary"
                    type="date"
                    label="To Date"
                    name="toDate"
                    onChange={handleDateChange}
                    value={toDate}
                    InputLabelProps={{ shrink: true }}
                    inputProps={{ min: fromDate }}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Toolbar>
      </AppBar>
      <div className={classes.contentWrapper}>
        {payments.total < 1 ? (
          <Typography color="textSecondary" align="center">
            No payments found...
          </Typography>
        ) : store.payments.loading === true ? (
          <div className={classes.centerProgress}>
            Fetching Payments.....
            <CircularProgress className={classes.progress} />
          </div>
        ) : (
          <TableContainer component={Paper} elevation={0}>
            <Table>
              <TableHead className={classes.tableHeader}>
                <TableRow>
                  <TableCell>Payment ID</TableCell>
                  {permissionNames?.includes('isAdmin') ? (
                    <TableCell>User ID</TableCell>
                  ) : (
                    ''
                  )}
                  <TableCell
                    className={classes.sortCell}
                    onClick={handleSort('createdAt')}
                  >
                    Payment Date
                    <TableSortLabel
                      active={orderBy === 'createdAt'}
                      direction={
                        orderBy === 'createdAt'
                          ? order === 'desc'
                            ? 'desc'
                            : 'asc'
                          : 'desc'
                      }
                    />
                  </TableCell>
                  <TableCell>Description</TableCell>
                  <TableCell
                    onClick={handleSort('amountInCents')}
                    className={classes.sortCell}
                  >
                    Amount (R)
                    <TableSortLabel
                      active={orderBy === 'amountInCents'}
                      direction={
                        orderBy === 'amountInCents'
                          ? order === 'desc'
                            ? 'desc'
                            : 'asc'
                          : 'desc'
                      }
                    />
                  </TableCell>
                  <TableCell>Reference</TableCell>
                  <TableCell>Status</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {stableSort(payments.results, getComparator(order, orderBy))
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((payment: IPayment) => (
                    <TableRow hover key={payment.id}>
                      <TableCell>#{payment.id}</TableCell>
                      {permissionNames?.includes('isAdmin') ? (
                        <TableCell>#{payment.userId}</TableCell>
                      ) : (
                        ''
                      )}
                      <TableCell>
                        {moment(payment.createdAt).format('D/MM/YYYY')}
                      </TableCell>
                      <TableCell>
                        {payment.status !== 'successful'
                          ? payment.failureReason || 'Not Provided'
                          : 'Success'}
                      </TableCell>
                      <TableCell>
                        {formatCurrency(payment.amountInCents)}
                      </TableCell>
                      <TableCell>#{payment.reference}</TableCell>
                      <TableCell>
                        {loadingPaymentId === payment.id ? (
                          <CircularProgress className={classes.progress} />
                        ) : payment.status === 'processing' ? (
                          <Chip
                            className={`${classes.chip}${payment.status}`}
                            label={payment.status}
                            variant="outlined"
                            onClick={(e) =>
                              onRefreshPaymentClick(payment.id, e)
                            }
                          />
                        ) : (
                          <Chip
                            className={`${classes.chip}${payment.status}`}
                            label={payment.status}
                            variant="outlined"
                          />
                        )}
                      </TableCell>
                    </TableRow>
                  ))}
                <Hidden smDown>
                  {emptyRows > 0 && (
                    <TableRow className={heightWithRow}>
                      <TableCell colSpan={12} />
                    </TableRow>
                  )}
                </Hidden>
              </TableBody>
            </Table>
            <TableFooter className={classes.tableFooter}>
              <TableRow>
                <TablePagination
                  rowsPerPageOptions={[5, 10, 25]}
                  count={payments.total}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  backIconButtonProps={{
                    'aria-label': 'Previous Page',
                  }}
                  nextIconButtonProps={{
                    'aria-label': 'Next Page',
                  }}
                  classes={{
                    toolbar: classes.paginationToolbar,
                    actions: classes.paginationActions,
                  }}
                  onChangePage={handleChangePage}
                  onChangeRowsPerPage={handleChangeRowsPerPage}
                />
              </TableRow>
            </TableFooter>
          </TableContainer>
        )}
      </div>
    </>
  );
});

export default PaymentsController;
