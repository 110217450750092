import {
  Dialog,
  DialogContent,
  Typography,
  DialogActions,
  Button,
} from '@material-ui/core';
import React, { FC } from 'react';
import useStyles from './styles';

export type NotAcceptingRenewalsDialogProps = {
  open: boolean;
  onClose?: () => void;
};

export const NotAcceptingRenewalsDialog: FC<NotAcceptingRenewalsDialogProps> =
  ({ open, onClose }) => {
    const classes = useStyles();

    return (
      <Dialog open={open} keepMounted onClose={onClose}>
        <DialogContent>
          <Typography
            variant="body1"
            align="center"
            className={classes.bannerText}
          >
            Hi loyal customers. Sorted&apos;s services are temporarily
            unavailable but we will be back up and running soon. Thanks for your
            continued support!
          </Typography>
        </DialogContent>
        <DialogActions>
          {onClose ? <Button onClick={onClose}>Close</Button> : null}
        </DialogActions>
      </Dialog>
    );
  };
