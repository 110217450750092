export const GOOGLE_MAPS_API_KEY = process.env.REACT_APP_GOOGLE_API_KEY;

export const DEFAULT_LOCATION: [number, number] = [
  Number(process.env.REACT_APP_DEFAULT_LAT || 0),
  Number(process.env.REACT_APP_DEFAULT_LNG || 0),
];

export const DEFAULT_LOCATION_STRING = `298 Witch-Hazel Ave, Eco-Park Estate,
Centurion, 0046`;

export const APP_NAME = process.env.REACT_APP_APP_NAME;
export const APP_LOGO = process.env.REACT_APP_LOGO;

export const SOCKET_PATH = process.env.REACT_APP_SOCKET_PATH;

export const GOOGLE_ANALYTICS_ID = process.env.REACT_APP_GA_ID;
export const FB_PIXEL_ID = process.env.REACT_APP_FB_PIXEL_ID;
export const PAYMENT_HOST = process.env.REACT_APP_PAYMENT_HOST;
