import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react-lite';
import moment from 'moment';
import Numeral from 'numeral';
import * as R from 'ramda';
import { useLocation, Link, useHistory } from 'react-router-dom';
import swal from '@sweetalert/with-react';
// @material-ui
import {
  AppBar,
  TableFooter,
  Hidden,
  TablePagination,
  TableRow,
  TableHead,
  TableCell,
  Table,
  TableBody,
  TextField,
  Grid,
  Paper,
  TableSortLabel,
  CircularProgress,
  Toolbar,
  Typography,
  Chip,
  Button,
  IconButton,
  Menu,
  MenuItem,
} from '@material-ui/core';
// @icons
import {
  Search as SearchIcon,
  ArrowForwardIos,
  MoreVert,
} from '@material-ui/icons';
// @logic
import { useStore } from 'logic/store';
// @components
import { formatCurrency } from 'utilities/handleCurrency';
import useCertaintyButtonStyles from 'components/Layout/certaintyButtonStyles';
// @local
import { IVehicle } from 'logic/stores/vehicles/validation';
import { DefaultViewProps } from 'components/Layout/WithLayout';
import EditVehicle from './EditVehicle';
import useStyles from './styles';
import ViewRenewalButton from './ViewRenewalButton';

type Order = 'asc' | 'desc';

function descendingComparator<T>(a: T, b: T, orderBy: keyof T) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator<Key extends keyof any>(
  order: Order,
  orderBy: Key,
): (a: { [key in Key]: any }, b: { [key in Key]: any }) => number {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort<T>(array: T[], comparator: (a: T, b: T) => number) {
  const stabilizedThis = array.map((el, index) => [el, index] as [T, number]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

const ManageVehicles = observer<DefaultViewProps>(({ view }) => {
  const history = useHistory();
  const classes = useStyles();
  const certaintyButtonStyle = useCertaintyButtonStyles({});

  const mobileLabelStyles = { label: classes.mobileLabel };
  const location = useLocation();

  const store = useStore();
  const { permissions: permissionTypes } = store.auth;

  const [loading, setLoading] = useState(false);
  const [vehicle, setVehicle] = useState<IVehicle>();
  const [vehicles, setVehicles] = useState<IVehicle[]>([]);

  const [totalVehicles, setTotalVehicles] = useState(0);

  const [searchText, setSearchText] = useState('');
  const [typingTimeout, setTypeTimeout] = useState<NodeJS.Timeout | null>(null);

  const [order, setOrder] = useState<Order>('asc');
  const [orderBy, setOrderBy] = useState<
    'updatedAt' | 'make' | 'weightKg' | 'licenseExpires'
  >('licenseExpires');

  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [page, setPage] = useState(0);

  const [renewNow, setRenewNow] = useState<null | string>(null);
  const [editId, setEditId] = useState<number | null>(null);
  const [viewEditDialog, setViewEditDialog] = useState(false);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const isAdmin = permissionTypes?.includes('isAdmin') ?? false;

  const manageAdminsVehicles = location.pathname.includes(
    '/manage-my-vehicles',
  );
  const adminPath = location.pathname.includes('admin');

  const handleVehicleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    switch (event.currentTarget.name) {
      case 'expireSoon':
        startRenewalClick(event.currentTarget.id);
        break;
      case 'wantRenew':
        renewNowClick(event.currentTarget.id);
        break;
      case 'editRegion':
        setEditId(event.currentTarget.id as any);
        edit();
        break;
      default:
    }
  };

  const startRenewalClick = (vehicleId: string) => {
    if (vehicleId) {
      history.push(`/start-renewal/collection/${vehicleId}`);
    }
  };

  const renewNowClick = (vehicleId: string) => {
    if (vehicleId) {
      setRenewNow(vehicleId);
    }
  };

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
    setEditId(event.currentTarget.id as any);
  };

  useEffect(() => {
    if (!editId) {
      return;
    }
    const v = vehicles.find((v) => v.id == editId);
    setVehicle(v);
  }, [editId]);

  useEffect(() => {
    fetchVehicles();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rowsPerPage, page, orderBy, order]);

  const fetchVehicles = async (searchTerm?: string) => {
    setLoading(true);
    const search = searchTerm ?? searchText;

    if ((view === 'admin' || adminPath) && !manageAdminsVehicles) {
      const result = await store.admin.getVehicles({
        skip: page * rowsPerPage,
        take: rowsPerPage,
        search,
      });
      if (result.success) {
        setTotalVehicles(result.data.count);
        setVehicles(result.data.entries);
      }
    } else {
      const result = await store.vehicles.getVehicles({
        skip: page * rowsPerPage,
        take: rowsPerPage,
      });
      if (result.success) {
        setTotalVehicles(result.data.count);
        setVehicles(result.data.entries);
      }
    }
    setLoading(false);
  };

  const searchFor = (searchEvent: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = searchEvent.target;
    if (typingTimeout != null) {
      clearTimeout(typingTimeout);
    }
    setSearchText(value);
    setTypeTimeout(setTimeout(() => fetchVehicles(value), 300));
  };

  const removeVehicle = async (vehicleId: number) => {
    swal({
      title: 'Are you sure?',
      text: 'Once deleted, you will not be able to recover this Vehicle!',
      icon: 'warning',
      buttons: {
        cancel: true,
        catch: {
          text: 'Yes, I want to delete this.',
          value: 'confirm',
          className: certaintyButtonStyle.dangerRed,
        },
      },
      dangerMode: true,
    }).then(async (willDelete: string) => {
      if (willDelete === 'confirm') {
        try {
          const result = await store.vehicles.remove(vehicleId);
          if (!result.success) {
            throw new Error(result.message);
          }
          fetchVehicles();
          swal('Selected Vehicle has been removed', {
            icon: 'success',
          });
        } catch (error) {
          swal(
            'Error!',
            'Unable to remove vehicle. Please email info@sorted.co.za for help',
            'error',
          );
        }
      }
    });
  };

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleSort = (name: any) => (event: React.MouseEvent) => {
    const isDesc = orderBy === name && order === 'desc';
    setOrder(isDesc ? 'asc' : 'desc');
    setOrderBy(name);
  };

  const dateDiff = (date: Date) => {
    return moment(date).diff(moment(), 'days');
  };

  const getLicenseStatus = (licenseExpires: Date) => {
    const daysBetween = moment(licenseExpires).diff(moment(), 'days');
    if (daysBetween >= 31) {
      return 'sorted';
    }
    if (daysBetween < 31 && daysBetween > 0) {
      return 'expiring soon';
    }
    return 'expired';
  };

  const emptyRows = rowsPerPage - vehicles.length; // - Math.min(rowsPerPage, vehicles.length - page * rowsPerPage); // not needed when paginating correctly

  const rowStyles = 49 * emptyRows;
  const heightWithRow = [{ height: rowStyles }].join(' ');

  const remove = () => {
    setAnchorEl(null);
    if (editId) {
      removeVehicle(editId);
    }
  };

  const edit = () => {
    setViewEditDialog(true);
    setAnchorEl(null);
  };

  const redirect = () => {
    if (isAdmin) {
      history.push(`/admin/view-vehicle/${editId}`);
    } else {
      history.push(`/view-vehicle/${editId}`);
    }
  };

  const VehicleStatus = ({ vehicle }: { vehicle: IVehicle }) => {
    const licenseStatus = getLicenseStatus(vehicle.licenseExpires);

    return vehicle.inRenewal ? (
      <Chip
        variant="outlined"
        label="In Progress"
        className={[classes.status, classes.inProgress].join(' ')}
      />
    ) : licenseStatus === 'expired' ? (
      <Chip
        variant="outlined"
        label="expired"
        className={[classes.status, classes.statusExpired].join(' ')}
      />
    ) : licenseStatus === 'expiring soon' ? (
      <Chip
        variant="outlined"
        label="Expiring Soon"
        className={[classes.status, classes.statusExpiringSoon].join(' ')}
      />
    ) : licenseStatus === 'sorted' ? (
      <Chip
        variant="outlined"
        label="Sorted"
        className={[classes.status, classes.statusSorted].join(' ')}
      />
    ) : null;
  };

  const RenewalAction = ({ vehicle }: { vehicle: IVehicle }) => {
    return vehicle.inRenewal ? (
      <Typography variant="overline">
        <ViewRenewalButton vehicle={vehicle} />
      </Typography>
    ) : vehicle.registrationRegion === 'unknown' ? (
      <Button
        variant="contained"
        id={`${vehicle.id}`}
        name="editRegion"
        onClick={handleVehicleClick}
        color="secondary"
        classes={mobileLabelStyles}
        endIcon={<ArrowForwardIos />}
      >
        <b>Update Province</b>
      </Button>
    ) : dateDiff(vehicle.licenseExpires) < 31 ||
      renewNow === `${vehicle.id}` ? (
      <Button
        variant="contained"
        id={`${vehicle.id}`}
        name="expireSoon"
        onClick={handleVehicleClick}
        className={classes.greenButton}
        classes={mobileLabelStyles}
        endIcon={<ArrowForwardIos />}
      >
        <b>Click to Renew</b>
      </Button>
    ) : (
      <Button
        id={`${vehicle.id}`}
        name="wantRenew"
        onClick={handleVehicleClick}
      >
        Expires in: {` `}
        {dateDiff(vehicle.licenseExpires)} days
      </Button>
    );
  };

  const closeEditDialog = (vehicle: IVehicle) => {
    fetchVehicles(searchText);
    setViewEditDialog(false);
    setVehicle(vehicle);
  };

  return (
    <Paper className={classes.paper} elevation={0}>
      <Menu
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={() => setAnchorEl(null)}
      >
        <MenuItem onClick={remove}>Delete Vehicle</MenuItem>
        <MenuItem onClick={edit}>Edit Vehicle</MenuItem>
        <MenuItem onClick={redirect}>View Vehicle</MenuItem>
        <MenuItem onClick={redirect}>Upload Custom Documents</MenuItem>
      </Menu>
      {editId != null && vehicle ? (
        <EditVehicle
          handleClose={closeEditDialog}
          editId={editId}
          vehicle={vehicle}
          open={viewEditDialog}
        />
      ) : null}
      <Typography variant="h4" align="center">
        Manage Vehicles
      </Typography>
      <AppBar
        className={classes.searchBar}
        position="static"
        color="default"
        elevation={0}
      >
        <Toolbar>
          <Grid container spacing={2} alignItems="center">
            <Hidden smDown>
              <Grid item>
                <SearchIcon className={classes.block} color="inherit" />
              </Grid>
            </Hidden>
            <Grid item xs>
              <TextField
                fullWidth
                color="secondary"
                placeholder="Search by make or license number"
                onChange={searchFor}
                value={searchText}
                InputProps={{
                  disableUnderline: true,
                  className: classes.searchInput,
                }}
              />
            </Grid>
          </Grid>
        </Toolbar>
      </AppBar>
      <div className={classes.contentWrapper}>
        {vehicles.length < 1 ? (
          <div>
            <Typography color="textSecondary" align="center">
              No vehicle found...
            </Typography>
            <Button
              component={Link}
              color="primary"
              variant="contained"
              size="large"
              to="/add-vehicle"
              className={classes.addVehicleSpace}
            >
              Click here to Add Vehicles
            </Button>
          </div>
        ) : loading === true ? (
          <div className={classes.centerProgress}>
            Fetching Vehicles...
            <CircularProgress className={classes.progress} />
          </div>
        ) : (
          <div className={classes.scrollX}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>
                    <b>Vehicle</b>
                  </TableCell>
                  {isAdmin ? (
                    <TableCell>
                      <b>User</b>
                    </TableCell>
                  ) : null}
                  <TableCell onClick={handleSort('licenseExpires')}>
                    <b>Expires</b>
                    <TableSortLabel
                      active={orderBy === 'licenseExpires'}
                      direction={
                        orderBy === 'licenseExpires'
                          ? order === 'desc'
                            ? 'desc'
                            : 'asc'
                          : 'desc'
                      }
                    />
                  </TableCell>
                  <TableCell>
                    <b>Renewal Fee</b>
                  </TableCell>
                  <TableCell align="center">
                    <b>Status</b>
                  </TableCell>
                  <TableCell />
                  <TableCell />
                </TableRow>
              </TableHead>
              <TableBody>
                {stableSort(vehicles, getComparator(order, orderBy))
                  // .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage) // Not needed when paginating correctly...
                  .map((vehicle) => {
                    const firstName = vehicle.user?.firstName;
                    const lastName = vehicle.user?.lastName;

                    // TODO: Show the model here too, but have to adjust the headers?
                    // eslint-disable-next-line no-lone-blocks
                    return (
                      <TableRow key={vehicle.id}>
                        <TableCell className={classes.uppercase}>
                          {vehicle.model
                            ? `${vehicle.make} - ${vehicle.model}`
                            : vehicle.make}
                          <br />
                          {vehicle.licenseNumber}
                          <br />
                          {vehicle.registerNumber}
                          <br />
                          {Numeral(vehicle.weightKg).format('0,0')} kg
                        </TableCell>
                        {isAdmin ? (
                          <TableCell>
                            {firstName} <br />
                            {lastName}
                          </TableCell>
                        ) : null}
                        <TableCell>
                          {moment(vehicle.licenseExpires).format(
                            'Do MMMM, YYYY',
                          )}
                        </TableCell>
                        <TableCell>
                          <b>
                            {vehicle.renewalFeeInCents &&
                              formatCurrency(vehicle.renewalFeeInCents)}
                          </b>
                        </TableCell>
                        <TableCell align="center">
                          <VehicleStatus vehicle={vehicle} />
                        </TableCell>
                        <TableCell>
                          <RenewalAction vehicle={vehicle} />
                        </TableCell>

                        <TableCell>
                          <IconButton
                            id={`${vehicle.id}`}
                            onClick={handleClick}
                          >
                            <MoreVert />
                          </IconButton>
                        </TableCell>
                      </TableRow>
                    );
                  })}
                <Hidden smDown>
                  {emptyRows > 0 && (
                    <TableRow className={heightWithRow}>
                      <TableCell colSpan={6} />
                    </TableRow>
                  )}
                </Hidden>
              </TableBody>
              <TableFooter>
                <TableRow>
                  <TablePagination
                    rowsPerPageOptions={[5, 10, 25]}
                    count={totalVehicles}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    backIconButtonProps={{
                      'aria-label': 'Previous Page',
                    }}
                    nextIconButtonProps={{
                      'aria-label': 'Next Page',
                    }}
                    classes={{
                      toolbar: classes.paginationToolbar,
                      actions: classes.paginationActions,
                    }}
                    onChangePage={handleChangePage}
                    onChangeRowsPerPage={handleChangeRowsPerPage}
                  />
                </TableRow>
              </TableFooter>
            </Table>
          </div>
        )}
      </div>
    </Paper>
  );
});

export default ManageVehicles;
