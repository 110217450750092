import { makeStyles, createStyles } from '@material-ui/core/styles';
import { Theme } from '@material-ui/core/styles/createMuiTheme';
import { ICompany } from 'logic/stores/company/validation';

interface Props {
  company: ICompany;
}

export default makeStyles<Theme, Props>((theme: Theme) =>
  createStyles({
    appbar: {
      padding: '11px',
      boxShadow: 'none',
      background: 'transparent',
    },
    selected: {
      '& $buttonActive': {
        fontWeight: 800,
      },
    },
    text: {
      color: (props) => props.company.textColor,
    },
    buttonActive: {
      color: 'black',
    },
    profileMenu: {
      marginTop: '28px',
    },
    button: {
      color: (props) => props.company.textColor,
      [theme.breakpoints.down('sm')]: {
        marginLeft: '5px',
      },
    },
    siteNoticeHead: {
      background: 'transparent',
    },
    siteLink: {
      color: theme.palette.secondary.contrastText,
      paddingBottom: '0',
      textDecoration: 'none',
    },
    siteNotice: {
      background: theme.palette.secondary.main,
      color: theme.palette.secondary.contrastText,
      textAlign: 'center',
      padding: '10px',
      position: 'fixed',
      width: '100%',
      top: '0',
      zIndex: 20,
      [theme.breakpoints.down('sm')]: {
        position: 'relative !important',
      },
    },
    header: {
      position: 'absolute',
      top: 0,
      width: '100%',
      zIndex: 21,
    },
    marginRight: {
      marginRight: theme.spacing(1),
    },
    grow: {
      flexGrow: 1,
    },
    icon: {
      color: '#777',
    },
    logo: {
      '&:hover': {
        cursor: 'pointer',
      },
      width: '236px',
      heigh: '100%',
    },
    buttonLink: {
      'text-decoration': 'none',
    },
    link: {
      'text-decoration': 'none',
      color: (props) => props.company.textColor,
      'font-weight': 100,
      'font-family': '"Roboto", "Helvetica", "Arial", sans-serif',
      '&:after': {
        content: '""',
        'border-bottom': `solid 2px ${theme.palette.secondary.main}`,
      },
    },
    logoLink: {
      'text-decoration': 'none',
    },
    linkType2: {
      'text-decoration': 'none',
      color: theme.palette.common.black,
      'font-weight': 400,
      'font-family': '"Roboto", "Helvetica", "Arial", sans-serif',
      '&:after': {
        content: '""',
        'border-bottom': `solid 2px ${theme.palette.primary.main}`,
      },
    },
    noHorizontalPadding: {
      paddingLeft: '0px !important',
      paddingRight: '0px !important',
      width: '100%',
    },
    sectionDesktop: {
      display: 'none',
      [theme.breakpoints.up('md')]: {
        display: 'flex',
        justifyContent: 'flex-end',
        alignItems: 'center',
        flex: 1,
      },
    },
    sectionMobile: {
      display: 'flex',
      [theme.breakpoints.up('md')]: {
        display: 'none',
      },
    },
    toolbar: {
      paddingLeft: '0px',
      paddingRight: '0px',
      [theme.breakpoints.down('sm')]: {
        justifyContent: 'space-between',
        display: 'flex',
        paddingLeft: '15px',
        paddingRight: '15px',
      },
    },
  }),
);
