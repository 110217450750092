import Joi from 'joi';
import { IRenewal, RenewalSchema } from '../renewals/validation';
import { IUser, UserSchema } from '../users/validation';

export type IDelivery = {
  id: number;
  status: string;
  deliveryId?: string;
  waybillNumber?: string;
  wumdropReference?: string;
  priceInCents: number;
  currency: string;
  confirmationImageUrl?: string;
  createdAt: Date;
  updatedAt: Date;
  userId?: number;
  user?: IUser;
  renewalId?: number;
  renewal?: IRenewal;
};

export const DeliverySchema = Joi.object<IDelivery>({
  id: Joi.number().required(),
  status: Joi.string().required(),
  deliveryId: Joi.string().allow(null),
  waybillNumber: Joi.string().allow(null),
  wumdropReference: Joi.string().allow(null),
  priceInCents: Joi.number().required(),
  currency: Joi.string().required(),
  confirmationImageUrl: Joi.string().allow(null),
  createdAt: Joi.date().required(),
  updatedAt: Joi.date().required(),
  userId: Joi.number().allow(null),
  user: UserSchema.allow(null),
  renewalId: Joi.number().allow(null),
  renewal: RenewalSchema.allow(null),
});
