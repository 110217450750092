import React from 'react';
import { toast } from 'react-toastify';
import { observer } from 'mobx-react-lite';
// @material-ui
import { Grid, LinearProgress } from '@material-ui/core';
// @logic
import { useStore } from 'logic/store';
// @components
import IdHelp from 'components/UploadDetails/IdDialog';
import Upload from 'components/UploadDetails/Upload';

interface Props {
  next?: () => void;
}

const SetIDDocumentSelect = observer(({ next }: Props) => {
  const store = useStore();
  const { userId } = store.auth;
  const [id, setId] = React.useState<File | null>(null);
  const [idProgress, setIdProgress] = React.useState(0);

  const uploadIDDocument = async () => {
    if (userId != null && id != null) {
      const result = await store.users.uploadId(id);
      if (result.success) {
        if (next) {
          next();
        }
      } else {
        toast.error('Your ID document could not be uploaded');
      }
    } else {
      toast.error('Unable to identify the user and ID document');
    }
  };

  return (
    <Grid item xs={12} md={12}>
      <Upload
        file={id}
        setFile={setId}
        message="Copy of your ID"
        HelpDialog={IdHelp}
        next={uploadIDDocument}
      />
      {idProgress > 0 ? (
        <LinearProgress variant="determinate" value={idProgress} />
      ) : null}
    </Grid>
  );
});

export default SetIDDocumentSelect;
