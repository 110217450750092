import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';

export default makeStyles((theme: Theme) =>
  createStyles({
    contentContainer: {
      background: 'transparent',
      height: '100%',
      overflowY: 'hidden',
    },
    container: {
      paddingBottom: '20px',
      maxWidth: '900px !important',
      width: '100%',
      padding: '0 10px',
      background: 'transparent',
      margin: '0 auto',
      [theme.breakpoints.down('sm')]: {
        // spacing above profile page
        marginTop: '5px',
      },
    },
    profileHeadingSpace: {
      marginBottom: '20px',
    },
    textTopMargin: {
      marginTop: '10px',
    },
    Paper: {
      marginTop: theme.spacing(3),
      width: '100%',
    },
    tabs: {
      marginTop: theme.spacing(2),
    },
    bigAvatar: {
      margin: 10,
      width: 100,
      height: 100,
      marginBottom: '5%',
    },
    forms: {
      marginTop: '5%',
    },
    inputs: {
      width: '90%',
    },
    fab: {
      margin: theme.spacing(1),
    },
    icon: {
      margin: theme.spacing(1),
    },
    infomation: {},
    margin: {
      margin: theme.spacing(1),
    },
    textField: {
      display: 'flex',
      width: '100%',
      marginBottom: '5%',
    },
    lastChild: {
      marginBottom: '2%',
    },
  }),
);
