import Joi from 'joi';

export interface IPermission {
  id: number;
  name: string;
  permission: string;
  risk: number;
  role: string;
  createdAt: Date;
  updatedAt: Date;
}

export const PermissionSchema = Joi.object<IPermission>({
  id: Joi.number().required(),
  name: Joi.string().required(),
  permission: Joi.string().required(),
  risk: Joi.number().required(),
  role: Joi.string().required(),
  createdAt: Joi.date().required(),
  updatedAt: Joi.date().required(),
});
