import { Theme } from '@material-ui/core/styles/createMuiTheme';
import { makeStyles, createStyles } from '@material-ui/core/styles';

import { ICompany } from 'logic/stores/company/validation';

interface IProps {
  whitelabel: boolean;
  company: ICompany;
}

export default makeStyles<Theme, IProps>((theme: Theme) =>
  createStyles({
    container: {
      textAlign: 'center',
      background: 'transparent',
      height: `calc(100vh - ${theme.mixins.toolbar.minHeight}px)`,
    },
    homeOverlay: {
      height: '100%',
      width: '100%',
      opacity: 2,
    },
    homeLanding: {
      width: '100%',
      textAlign: 'center',
      overflow: 'hidden',
      position: 'relative',
    },
    homeLandingImg: {
      maxWidth: '100%',
      '&:hover': {
        cursor: 'pointer',
      },
    },
    heroImageBack: {
      position: 'absolute',
      zIndex: 0,
    },
    heroImageBackRight: {
      marginLeft: '10%',
    },
    heroImagBbackLeft: {
      marginLeft: '-10%',
    },
    heroImage: {
      maxWidth: '67%',
      display: 'inline-block',
      position: 'relative',
    },
    heroP2: {
      fontSize: '1.6rem',
      lineHeight: '140%',
      margin: '0.82rem 0 0.656rem 0',
      fontWeight: 300,
    },
    cover: {
      width: '100%',
      textAlign: 'center',
      backgroundPosition: 'center',
      overflow: 'hidden',
    },
    width200: {
      width: '200px',
    },
    padding10: {
      padding: '10px',
    },
    darkBanner: {
      textAlign: 'center',
      backgroundPosition: (props) => `50% ${props.whitelabel ? '75%' : '50%'}`,
      backgroundImage: (props) => `url(${props.company.backgroundUrl})`, // removed the background color of #fff here
      backgroundRepeat: 'no-repeat',
      position: 'relative',
      padding: '10px 0px 87px 0px',
      minHeight: 'calc(88vh)',
      backgroundAttachment: 'fixed',
    },
    titleP: {
      color: 'white',
      position: 'relative',
    },
    btnDark: {
      color: 'rgba(0, 0, 0, 0.87)', // 'black',
    },
    homeMid: {
      fontSize: '2.1rem',
      lineHeight: '130%',
      margin: '1.46rem 0 1.68rem 0',
      color: 'black',
    },
    homeHead: {
      fontSize: '3.2rem',
      lineHeight: '110%',
      margin: '1.78rem 0 1.424rem 0',
    },
    homeText: {
      fontSize: '1.6rem',
      lineHeight: '140%',
      margin: '1.78rem 0 1.424rem 0',
    },
    gridMaxCenterMore: {
      width: '500px',
      margin: '0 auto',
    },
    gridMaxCenter: {
      width: '100%',
      maxWidth: '850px',
      margin: '0 auto',
    },
    bottomRemove: {
      margin: '0px !important',
    },
    infoGrid: {
      maxWidth: '650px',
      margin: '0 auto',
    },
    infoGridDiv: {
      maxWidth: '700px',
      margin: '0 auto',
    },
    padding7: {
      padding: '7px',
    },
    margin0: {
      margin: '0',
    },
    featureText: {
      fontWeight: 300,
      fontSize: '1.15rem',
      letterSpacing: '0.02rem',
      lineHeight: '1.72rem',
      margin: '0px 0px 1.72rem 0px',
      color: 'rgba(0,0,0,0.95)',
    },
    textSecond: {
      maxWidth: '37rem',
      marginLeft: 'auto',
      marginRight: 'auto',
    },
    btnLight: {
      color: '#000',
    },
    homeFaqText: {
      marginBottom: '1.2rem',
      color: '#000',
    },
    infoLight: {
      opacity: '0.87',
      fontWeight: 400,
      letterSpacing: '0.02rem',
      fontSize: '1.42rem',
      lineHeight: '2.14rem',
      margin: '1.5rem 0px 1.4rem 0px',
    },
    typoText: {
      opacity: 0.64,
      fontWeight: 300,
      fontSize: '1.15rem',
      letterSpacing: '0.02rem',
      lineHeight: '1.72rem',
      margin: '0px 0px 1.72rem 0px',
      color: 'rgba(0,0,0,0.95)',
    },
    typoHead: {
      fontSize: '2.1rem',
      lineHeight: '130%',
      margin: '1.46rem 0 1.168rem 0',
    },
    btnFaq: {
      width: '140px',
      color: '#fff',
      background: '#81bcff !important',
    },
    footerSpace: {
      marginBottom: '7em',
    },
    btnBlue: {
      background: theme.palette.primary.main,
      color: theme.palette.primary.contrastText,
      padding: '1.5em',
      margin: '15px',
      borderRadius: '3px',
      fontSize: '1.0em',
      width: '9em',
    },
    modal: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    paper: {
      backgroundColor: theme.palette.background.paper,
      border: '2px solid #000',
      boxShadow: theme.shadows[5],
      padding: theme.spacing(2, 4, 3),
    },
    infoHome: {
      fontSize: '1.2em',
      color: 'rgb(123, 127, 133)',
    },
    contentMid: {
      background: '#F5F5F5',
      padding: '50px',
      textAlign: 'center',
      paddingTop: '5rem',
      paddingBottom: '5rem',
      marginTop: '1px',
    },
    contentMidWhite: {
      background: '#FFFFFF',
      padding: '60px',
      textAlign: 'center',
      [theme.breakpoints.down('sm')]: {
        padding: '10px',
      },
    },
    contentFaq: {
      padding: '1.5rem',
      textAlign: 'center',
    },
    icon: {
      fontSize: '3.5rem',
    },
    iconText: {
      maxWidth: '300px',
      margin: '0 auto',
    },
    faqFooterSection: {
      margin: '2rem',
    },
    footer: {
      magin: '0 auto',
      background: '#eee',
      display: 'flex',
      justify: 'center',
      justifyContent: 'space-between',
      width: '500px',
    },
    footerInner: {
      width: '500px',
      margin: '0 auto',
      padding: '20px',
    },
    iconSpace: {
      padding: '2px',
    },
    footerText: {},
    footerLink: {
      marginLeft: '8px',
      color: 'black',
    },
    footerBackground: {
      background: '#eee',
      width: '100% !important',
    },
  }),
);
