import React from 'react';
import { observer } from 'mobx-react-lite';
import { Link, useLocation } from 'react-router-dom';
// @material-ui
import {
  Typography,
  Grid,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Button,
} from '@material-ui/core';
import { Alert } from '@material-ui/lab';
// @icons
import { ArrowForwardIos } from '@material-ui/icons';
// @logic
import { useStore } from 'logic/store';
// @components
import useStyles from 'components/UploadDetails/styles';
// @local
import { IDocument } from 'logic/stores/document/validation';
import SetIDDocumentSelect from './SetIDDocumentSelect';
import SetPassportDocumentSelect from './SetPassportDocumentSelect';
import SetBusinessDocumentSelect from './SetBusinessDocumentSelect';

interface Props {
  next?: () => void;
  documents?: IDocument[];
}

const SelectDocuments = observer(({ next, documents }: Props) => {
  const classes = useStyles({});

  const location = useLocation();
  const alertMarginStyles = {
    root: classes.alertSpaceBottom,
  };
  const selectStyles = { select: classes.select };

  const [display, setDisplay] = React.useState('');
  const [registerRole, setRegisteRole] = React.useState('');

  const store = useStore();
  const { user } = store.auth;

  React.useEffect(() => {
    if (user != null) {
      setRegisteRole(user.userType);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(user)]);

  const handleMenuDocument = (event: React.ChangeEvent<{ value: unknown }>) => {
    const selected = event.target.value as string;
    // handle select for owner to upload documents
    setDisplay(selected);
  };

  const [idIncluded, setIdIncluded] = React.useState(false);
  const [passportIncluded, setPassportIncluded] = React.useState(false);
  const [businessIncluded, setBusinessIncluded] = React.useState(false);

  React.useEffect(() => {
    if (documents != null) {
      documents.forEach((document) => {
        if (document.filename.includes('ID')) {
          setIdIncluded(true);
        } else if (document.filename.includes('Passport')) {
          setPassportIncluded(true);
        } else if (document.filename.includes('Business')) {
          setBusinessIncluded(true);
        }
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(documents)]);

  const allowedUsers = ['individual', 'business'];
  const checkUploadAccessRole = allowedUsers.includes(registerRole);
  const { pathname } = location;

  return (
    <Grid container spacing={4}>
      <Grid item md={12} xs={12}>
        <Typography variant="h6" className={classes.lightText}>
          Please provide the following documentation
        </Typography>
        {!checkUploadAccessRole ? (
          <Typography variant="body1">
            Only individuals or buinesses can upload critical documents
          </Typography>
        ) : null}
      </Grid>

      <Grid item xs={12}>
        {registerRole === 'individual' ? (
          <>
            {display === 'id' || display === 'passport' ? null : (
              <Alert severity="success" classes={alertMarginStyles}>
                {idIncluded || passportIncluded || businessIncluded ? (
                  <>
                    <Typography gutterBottom>
                      Note, Critical Documents (ID, Passport and Business
                      Documents) will be limitied to 1 Document per Critical
                      Document. You will not be allowed to upload multiple
                      Critical Documents. Alternatively, you can see which
                      documents you are allowed to upload below.
                    </Typography>
                    {!pathname.includes('view-documents') ? (
                      <Button onClick={next} fullWidth>
                        <b>Proceed to next step</b>
                      </Button>
                    ) : null}
                  </>
                ) : (
                  <Typography>
                    Great, Please select which document you are uploading
                  </Typography>
                )}
              </Alert>
            )}
            {idIncluded && passportIncluded ? (
              <Button
                variant="outlined"
                component={Link}
                color="primary"
                to="/view-documents"
                endIcon={<ArrowForwardIos />}
                className={classes.btnSpace}
              >
                View Documents
              </Button>
            ) : (
              <FormControl variant="outlined">
                <InputLabel>Document Type</InputLabel>
                <Select
                  value={display}
                  onChange={handleMenuDocument}
                  label="Document Type"
                  classes={selectStyles}
                >
                  <MenuItem disabled value="">
                    <em>Select a document</em>
                  </MenuItem>
                  <MenuItem value="id" disabled={idIncluded}>
                    ID
                  </MenuItem>
                  <MenuItem value="passport" disabled={passportIncluded}>
                    Passport (if you are a non-SA resident)
                  </MenuItem>
                </Select>
              </FormControl>
            )}
          </>
        ) : null}
      </Grid>

      <Grid item container spacing={1} md={12} xs={12} sm={12}>
        {display === 'id' ? <SetIDDocumentSelect next={next} /> : null}

        {display === 'passport' ? (
          <SetPassportDocumentSelect next={next} />
        ) : null}

        {registerRole === 'business' ? (
          <SetBusinessDocumentSelect next={next} />
        ) : null}
      </Grid>
    </Grid>
  );
});

export default SelectDocuments;
