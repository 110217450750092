import React from 'react';
import { Link } from 'react-router-dom';
// @material-ui
import { Button, Typography, Grid } from '@material-ui/core';
// @components
import Content from 'components/Layout/Content';
// @local
import { useStore } from 'logic/store';
import useStyles from './styles';
import BaseFooterTopStyles from '../Layout/BaseFooterTopStyles';

const Contact = () => {
  const store = useStore();
  const classes = useStyles({
    company: store.companies.company,
    whitelabel: store.whitelabel,
  });
  const footerStyles = BaseFooterTopStyles({});
  const centerHeadTopStyles = [classes.centerDiv, footerStyles.headTop].join(
    ' ',
  );

  const typoTextStyles = [
    classes.typoText,
    classes.padding10,
    classes.infoSpace,
  ].join(' ');

  return (
    <div className={classes.cover}>
      <Grid container className={classes.darkBanner}>
        <Grid item xs="auto" className={classes.gridContent}>
          <div className={centerHeadTopStyles}>
            <Typography
              variant="h3"
              align="center"
              className={`${classes.btnDark} ${classes.padding10}`}
            >
              Contact us
            </Typography>

            <Typography
              variant="body1"
              align="center"
              className={typoTextStyles}
            >
              <strong>Tel:</strong> 061 588 1711
            </Typography>

            <Typography
              variant="body1"
              align="center"
              className={typoTextStyles}
            >
              <strong>Address:</strong> The Workspace, 1 Maxwell Drive, Sandton,
              2191, Gauteng, South Africa.
            </Typography>

            <Typography
              variant="body1"
              align="center"
              className={typoTextStyles}
            >
              <strong>Email:</strong> info@sorted.co.za
            </Typography>
          </div>
        </Grid>
      </Grid>
      <Content
        className={` ${footerStyles.headTop}`}
        containerClass={`${classes.containerClass} ${footerStyles.contentMidWhite}`}
      >
        <Grid item sm={12}>
          <Typography
            variant="h4"
            align="center"
            className={`${classes.typoHead} ${classes.padding10}`}
          >
            Ready to get Sorted?
          </Typography>
          <div>
            <Button component={Link} to="/signup" className={classes.btnBlue}>
              Sign Up
            </Button>
          </div>
        </Grid>
      </Content>
    </div>
  );
};

export default Contact;
