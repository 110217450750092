import React, { useState } from 'react';
import moment from 'moment';
import { observer } from 'mobx-react-lite';
// @material-ui
import { Typography } from '@material-ui/core';
import {
  Alert,
  Timeline,
  TimelineItem,
  TimelineSeparator,
  TimelineDot,
  TimelineConnector,
  TimelineContent,
  TimelineOppositeContent,
} from '@material-ui/lab';
// @icons
import {
  Person,
  Commute,
  NoteAdd,
  LocalShipping,
  FiberManualRecord,
  Autorenew,
  SupervisorAccount,
  PlaylistAdd,
  Cancel,
  Done,
  Event,
  Warning,
} from '@material-ui/icons';
import { FaTruckLoading, FaPaperPlane } from 'react-icons/fa';
import { RiRefund2Line } from 'react-icons/ri';
import { GiReturnArrow } from 'react-icons/gi';
import { FiPackage } from 'react-icons/fi';
import { AiOutlineFileDone, AiOutlineSchedule } from 'react-icons/ai';
// @local
import { EventStatus, IEvent } from 'logic/stores/events/validation';
import useStyles from './styles';

interface TemplateProps {
  events: IEvent[];
}

const Events = observer(({ events }: TemplateProps) => {
  const classes = useStyles({});

  const GetEventData = (event: IEvent) => {
    switch (event.status) {
      case 'user joined': {
        return {
          Icon: Person,
          time: moment(event.createdAt).format('MMMM Do, YYYY, HH:mm'),
          description: event.description,
        };
      }
      case 'vehicle created': {
        return {
          Icon: Commute,
          time: moment(event.createdAt).format('MMMM Do, YYYY, HH:mm'),
          description: event.description,
        };
      }
      case 'submitted by user': {
        return {
          Icon: NoteAdd,
          time: moment(event.createdAt).format('MMMM Do, YYYY, HH:mm'),
          description: event.description,
        };
      }
      case 'processing': {
        return {
          Icon: Autorenew,
          time: moment(event.createdAt).format('MMMM Do, YYYY, HH:mm'),
          description: event.description,
        };
      }
      case 'ready to collect': {
        return {
          Icon: FiPackage,
          time: moment(event.createdAt).format('MMMM Do, YYYY, HH:mm'),
          description: event.description,
        };
      }
      case 'delivery requested': {
        return {
          Icon: FaPaperPlane,
          time: moment(event.createdAt).format('MMMM Do, YYYY, HH:mm'),
          description: event.description,
        };
      }
      case 'delivered': {
        return {
          Icon: AiOutlineFileDone,
          time: moment(event.createdAt).format('MMMM Do, YYYY, HH:mm'),
          description: event.description,
        };
      }
      case 'license updated': {
        return {
          Icon: Done,
          time: moment(event.createdAt).format('MMMM Do, YYYY, HH:mm'),
          description: event.description,
        };
      }
      case 'admin to action': {
        return {
          Icon: SupervisorAccount,
          time: moment(event.createdAt).format('MMMM Do, YYYY, HH:mm'),
          description: event.description,
        };
      }
      case 'user to action': {
        return {
          Icon: PlaylistAdd,
          time: moment(event.createdAt).format('MMMM Do, YYYY, HH:mm'),
          description: event.description,
        };
      }
      case 'cancelled': {
        return {
          Icon: Cancel,
          time: moment(event.createdAt).format('MMMM Do, YYYY, HH:mm'),
          description: event.description,
        };
      }
      case 'ready': {
        return {
          Icon: LocalShipping,
          time: moment(event.createdAt).format('MMMM Do, YYYY, HH:mm'),
          description: 'Delivery request accepted',
        };
      }
      case 'collected': {
        return {
          Icon: FaTruckLoading,
          time: moment(event.createdAt).format('MMMM Do, YYYY, HH:mm'),
          description: event.description,
        };
      }
      case 'pick-up exception': {
        return {
          Icon: Warning,
          time: moment(event.createdAt).format('MMMM Do, YYYY, HH:mm'),
          description: event.description,
        };
      }
      case 'drop-off exception': {
        return {
          Icon: Warning,
          time: moment(event.createdAt).format('MMMM Do, YYYY, HH:mm'),
          description: event.description,
        };
      }
      case 'returned to sender': {
        return {
          Icon: GiReturnArrow,
          time: moment(event.createdAt).format('MMMM Do, YYYY, HH:mm'),
          description: event.description,
        };
      }
      case 'cancelled with fee': {
        return {
          Icon: Cancel,
          time: moment(event.createdAt).format('MMMM Do, YYYY, HH:mm'),
          description: event.description,
        };
      }
      case 'refunded': {
        return {
          Icon: RiRefund2Line,
          time: moment(event.createdAt).format('MMMM Do, YYYY, HH:mm'),
          description: event.description,
        };
      }
      case 'scheduled': {
        return {
          Icon: AiOutlineSchedule,
          time: moment(event.createdAt).format('MMMM Do, YYYY, HH:mm'),
          description: event.description,
        };
      }
      default: {
        return {
          Icon: Event,
          time: moment(event.createdAt).format('MMMM Do, YYYY, HH:mm'),
          description: event.description,
        };
      }
    }
  };

  const TimeLineEvent = ({
    Icon,
    time,
    description,
    last = false,
  }: {
    Icon: any;
    time: string;
    description: string;
    last?: boolean;
  }) => (
    <TimelineItem>
      <TimelineOppositeContent>
        <Typography variant="body2" color="textSecondary">
          {time}
        </Typography>
      </TimelineOppositeContent>
      <TimelineSeparator className={classes.seperateHeight}>
        <TimelineDot color="primary" variant="outlined">
          <Icon className={classes.eventIcon} />
        </TimelineDot>
        {last ? null : <TimelineConnector className={classes.secondaryTail} />}
      </TimelineSeparator>
      <TimelineContent>
        <Typography variant="body2" className={classes.eventDescription}>
          {description}
        </Typography>
      </TimelineContent>
    </TimelineItem>
  );

  const recorededEvents = [
    'user joined',
    'vehicle created',
    'submitted by user',
    'user to action',
    'admin to action',
    'processing',
    'ready to collect',
    'delivery requested',
    'ready',
    'delivered',
    'cancelled',
    'drop-off exception',
    'pick-up exception',
    'license updated',
    'collected',
    'cancelled with fee',
    'returned to sender',
    'refunded',
  ] as EventStatus[];

  return (
    <div>
      {events && events.length < 1 ? (
        <Alert severity="info">
          There are currently no events recorded for this renewal
        </Alert>
      ) : (
        <Timeline align="alternate">
          {events &&
            events
              .sort(
                (a, b) =>
                  new Date(b.createdAt).getTime() -
                  new Date(a.createdAt).getTime(),
              )
              .filter((n) => recorededEvents.includes(n.status))
              .map((event: IEvent) => {
                const { Icon, time, description } = GetEventData(event);
                return (
                  <TimeLineEvent
                    key={event.id}
                    Icon={Icon}
                    time={time}
                    description={description || ''}
                  />
                );
              })}

          <TimeLineEvent Icon={FiberManualRecord} time="" description="" last />
        </Timeline>
      )}
    </div>
  );
});

export default Events;
