import * as React from 'react';
import { observer } from 'mobx-react-lite';
// @material-ui
import CircularProgress from '@material-ui/core/CircularProgress';
// @logic
import { useStore } from 'logic/store';
// @local
import useStyles from './styles';

export const Loader: React.FC = () => {
  const classes = useStyles({});
  return (
    <div className={classes.loader}>
      <CircularProgress />
    </div>
  );
};

const Rehydrater = observer((props) => {
  const store = useStore();
  const [state, setState] = React.useState('');

  const rehydrateUser = async () => {
    setState('loading');
    await store.auth.rehydrate();
    setState('done');
  };

  React.useEffect(() => {
    rehydrateUser();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      {state === 'loading' ? (
        <Loader />
      ) : state === 'done' ? (
        props.children
      ) : (
        <div />
      )}
    </>
  );
});

export default Rehydrater;
