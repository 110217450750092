import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';

export default makeStyles((theme: Theme) =>
  createStyles({
    blueAvatar: {
      background: '#64abfb',
    },
    large: {
      width: theme.spacing(7),
      height: theme.spacing(7),
    },
    divSpace: {
      padding: '10px',
    },
    secondaryItem: {
      [theme.breakpoints.down('sm')]: {
        paddingRight: '163px',
      },
    },
    uppercase: {
      textTransform: 'uppercase',
    },
  }),
);
