import { makeObservable, observable, runInAction } from 'mobx';
import uniqBy from 'lodash/uniqBy';
import { BaseStore, RequestConfig } from '../util/baseStore';
import {
  GetPaymentUrlOutputSchema,
  IPayment,
  PaginatedVehiclesSchema,
  PaymentSchema,
  PaymentUpdateOutputSchema,
} from './validation';
import { IPaginated } from '../vehicles/vehicles';
import { MessageSchema } from '../validation';

export type IQuery = {
  skip?: number;
  take?: number;
  order?: 'desc' | 'asc';
  orderBy?: 'createdAt' | 'amountInCents';
  search?: string;
  status?: string;
  from?: Date;
  to?: Date;
};

class V2PaymentsStore extends BaseStore {
  constructor(config: RequestConfig) {
    super(config);
    makeObservable(this, {
      payments: observable,
    });
  }

  loading = false;
  payments: IPaginated<IPayment> = {
    total: 0,
    results: [],
    skip: 0,
    take: 0,
  };

  // TODO: admin only
  async find(query: IQuery) {
    this.setLoading(true);

    const result = await this.sendRequest({
      method: 'GET',
      path: 'payments/admin',
      params: query,
      validation: PaginatedVehiclesSchema,
    });

    runInAction(() => {
      this.loading = false;
      if (result.success) {
        this.payments = {
          total: result.data.count,
          results: uniqBy(
            [...this.payments.results, ...result.data.entries],
            (v) => v.id,
          ),
          skip: query.skip ?? 0,
          take: query.take ?? 10,
        };
      }
    });

    return result;
  }

  async getPaymentUrl(input: GetPaymentUrlInput) {
    const result = await this.withState(
      this.sendRequest({
        method: 'GET',
        path: `payments/payment-key`,
        params: input,
        validation: GetPaymentUrlOutputSchema,
      }),
    );

    return result;
  }

  async updatePaymentResult(search: string) {
    return this.withState(
      this.sendRequest({
        method: 'GET',
        path: `payments/result${search}`,
        validation: PaymentUpdateOutputSchema,
      }),
    );
  }
}

export interface IRenewalResult {
  result: string;
  reason: string;
}

export type GetPaymentUrlInput = {
  userId: number;
  vehicleId: number;
  amount: number; // in cents
  description: string;
  reference: string;
};

export default V2PaymentsStore;
