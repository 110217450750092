import React, { useState } from 'react';
import { toast } from 'react-toastify';
import swal from '@sweetalert/with-react';
import { observer } from 'mobx-react-lite';
import { useHistory } from 'react-router-dom';
// @material-ui
import {
  Button,
  Grid,
  TextField,
  Paper,
  MenuItem,
  Typography,
} from '@material-ui/core';
// @form
import { useForm, Controller } from 'react-hook-form';
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
// @logic
import { useStore } from 'logic/store';
import { emailValidation } from 'logic/validation';
// @components
import Content from 'components/Layout/Content';
// @local
import { UserType } from 'logic/stores/auth/auth';
import useStyles from './styles';

interface FormValues {
  email: string;
  password: string;
  passwordConfirm: string;
  firstName: string;
  lastName: string;
  contactNumber: string;
  userType: UserType;
}

const Signup = observer(() => {
  const history = useHistory();
  const store = useStore();
  const classes = useStyles({});

  const [loading, setLoading] = useState(false);

  const validationSchema = Yup.object().shape({
    userType: Yup.string().label('User Type').default('individual').required(),
    password: Yup.string().label('Password').required().min(7),
    passwordConfirm: Yup.string()
      .label('Password Confirm')
      .required()
      .oneOf([Yup.ref('password')], 'Passwords must match'),
    email: emailValidation.required(),
    firstName: Yup.string().label('First Name').required(),
    lastName: Yup.string().required().label('Last Name'),
    contactNumber: Yup.string()
      .label('Number')
      .transform((value: string, original: string) => {
        const transformed = original
          .replaceAll(' ', '')
          .replace(/^(\+27|27)/, '0');
        return transformed;
      })
      .matches(
        new RegExp(/^((\+27|27)|0)(\d{2})-?(\d{3})-?(\d{4})$/),
        'Not a valid phone Number',
      )
      .required(),
  });

  const { handleSubmit, errors, control } = useForm<FormValues>({
    resolver: yupResolver(validationSchema),
    defaultValues: {
      userType: 'individual',
    },
  });

  const createAccount = async (userData: FormValues) => {
    setLoading(true);
    const res = await store.auth.register({
      firstName: userData.firstName,
      lastName: userData.lastName,
      email: userData.email,
      password: userData.password,
      contactNumber: userData.contactNumber,
      userType: userData.userType,
      companyId: store.companies.company.id,
    });

    setLoading(false);
    if (res.success === true) {
      const { user } = res;
      if (user) {
        const permissionNames = user.permissions.map((n) => n.permission);
        if (permissionNames.includes('isAdmin')) {
          history.push('/admin');
        } else if (user.verified === true) {
          if (!permissionNames.includes('isActivatedUser')) {
            history.push('/account-setup');
          } else {
            history.push('/manage-vehicles');
          }
        } else if (!user.verified) {
          toast.success(
            'You has been registered successfully, please check email to verify account',
          );
          history.push('/verify-account');
        }
      }

      return;
    }

    if (res.code === 409) {
      swal(
        'Error!',
        'Email has already been registered. Please Log In',
        'error',
      );
    } else {
      swal(
        'Error!',
        'There was an issue creating your account, please email info@sorted.co.za for help getting set up',
        'error',
      );
    }
  };

  return (
    <div className={classes.container}>
      <Grid className={classes.signUpHead}>
        <p className={classes.headFirst}>Let&apos;s get you sorted</p>
        <p className={classes.headSecond}>
          We&apos;ll need a few details to get you going.
        </p>
      </Grid>
      <Content
        className={classes.container}
        containerClass={classes.contentContainer}
      >
        <Grid item md={12} xs={12} className={classes.removePadding}>
          <Paper
            className={`${classes.formStyle} ${classes.padding20}`}
            elevation={2}
          >
            <form onSubmit={handleSubmit(createAccount)} autoComplete="off">
              <Grid container spacing={2}>
                {!store.whitelabel && (
                  <Grid item xs={12}>
                    <Typography color="primary" variant="h5" paragraph>
                      Are you registering as an individual or a business? Please
                      select an option below.
                    </Typography>
                    <Controller
                      as={
                        <TextField
                          select
                          name="userType"
                          id="userType"
                          variant="outlined"
                          label="select an option"
                          color="secondary"
                          error={Boolean(errors.userType)}
                          helperText={errors.userType && errors.userType}
                          SelectProps={{
                            MenuProps: {
                              anchorOrigin: {
                                vertical: 'bottom',
                                horizontal: 'left',
                              },
                              getContentAnchorEl: null,
                            },
                          }}
                        >
                          <MenuItem value="individual">Individual</MenuItem>
                          <MenuItem value="business">Business</MenuItem>
                        </TextField>
                      }
                      name="userType"
                      control={control}
                    />
                  </Grid>
                )}
                <Grid item xs={12} md={6}>
                  <Controller
                    as={
                      <TextField
                        fullWidth
                        placeholder="First Name"
                        color="secondary"
                        label="First Name"
                        InputProps={{
                          className: classes.textField,
                        }}
                        error={Boolean(errors.firstName)}
                        className={classes.textField}
                        helperText={
                          errors.firstName && errors.firstName.message
                        }
                      />
                    }
                    name="firstName"
                    control={control}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <Controller
                    as={
                      <TextField
                        fullWidth
                        color="secondary"
                        placeholder="Last Name"
                        label="Last Name"
                        InputProps={{
                          className: classes.textField,
                        }}
                        error={Boolean(errors.lastName)}
                        helperText={errors.lastName && errors.lastName.message}
                      />
                    }
                    name="lastName"
                    control={control}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <Controller
                    as={
                      <TextField
                        fullWidth
                        color="secondary"
                        placeholder="Email"
                        label="Email"
                        InputProps={{
                          className: classes.textField,
                        }}
                        type="email"
                        error={Boolean(errors.email)}
                        helperText={errors.email && errors.email.message}
                      />
                    }
                    name="email"
                    control={control}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  {/* // TODO: replace below with phone number input  */}
                  <Controller
                    as={
                      <TextField
                        fullWidth
                        color="secondary"
                        placeholder="Contact Number (+ 27)"
                        label="Contact Number"
                        InputProps={{
                          className: classes.textField,
                        }}
                        error={Boolean(errors.contactNumber)}
                        helperText={
                          errors.contactNumber && errors.contactNumber.message
                        }
                      />
                    }
                    name="contactNumber"
                    control={control}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <Controller
                    as={
                      <TextField
                        fullWidth
                        color="secondary"
                        placeholder="Password"
                        type="password"
                        label="Password"
                        InputProps={{
                          className: classes.textField,
                        }}
                        error={Boolean(errors.password)}
                        helperText={errors.password && errors.password.message}
                      />
                    }
                    name="password"
                    control={control}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <Controller
                    as={
                      <TextField
                        fullWidth
                        color="secondary"
                        placeholder="Password Confirm"
                        type="password"
                        label="Password Confirm"
                        InputProps={{
                          className: classes.textField,
                        }}
                        error={Boolean(errors.passwordConfirm)}
                        helperText={
                          errors.passwordConfirm &&
                          errors.passwordConfirm.message
                        }
                      />
                    }
                    name="passwordConfirm"
                    control={control}
                  />
                </Grid>
              </Grid>
              <div className={classes.submitContainer}>
                <Button
                  fullWidth
                  disabled={loading}
                  className={classes.btnBlue}
                  size="large"
                  type="submit"
                >
                  Let&apos;s Go
                </Button>
              </div>
              <br />
            </form>
          </Paper>
        </Grid>
      </Content>
    </div>
  );
});

export default Signup;
