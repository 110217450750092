import React from 'react';

const useAfterMount = (callback: () => void, deps: any[]) => {
  const hasMount = React.useRef(false);
  React.useEffect(() => {
    if (hasMount.current) {
      callback();
    } else {
      hasMount.current = true;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, deps);
};
export default useAfterMount;
