import React from 'react';
import { observer } from 'mobx-react-lite';
import { Link, useHistory, useLocation } from 'react-router-dom';
// @material-ui
import {
  AppBar,
  Toolbar,
  Drawer,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  useMediaQuery,
  IconButton,
  Hidden,
  Menu,
  MenuItem,
  ListSubheader,
  Collapse,
} from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';
// @icons
import {
  Menu as MenuIcon,
  DriveEta,
  Sync,
  AccountCircle,
  ArrowDropDown,
  PeopleAlt,
  Commute,
  Payment,
  ExpandLess,
  ExpandMore,
  Settings,
  Check,
} from '@material-ui/icons';
// @logic
import { useStore } from 'logic/store';
// @local
import { IUser } from 'logic/stores/users/validation';
import useStyles from './styles';

interface Props {
  children: React.ReactNode;
}

const AdminDashMenu = observer(({ children }: Props) => {
  const store = useStore();
  const classes = useStyles();
  const history = useHistory();

  const { userId, user } = store.auth;

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const location = useLocation();

  const [drawerOpen, setDrawerOpen] = React.useState(false);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [name, setName] = React.useState('');
  const [openItems, setOpenItems] = React.useState<string[]>([]);

  const storeUserName = (storeUser: IUser) => {
    setName(storeUser.firstName);
  };

  // Grab the User from User Actions
  React.useEffect(() => {
    if (userId != null) {
      store.users.getById(userId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userId]);

  React.useEffect(() => {
    if (user != null) {
      // grab the user and set the state
      storeUserName(user);
    }
  }, [user]);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleDrawerToggle = () => {
    setDrawerOpen(!drawerOpen);
  };

  const logout = () => {
    setAnchorEl(null);
    store.auth.logout();
    history.replace('/');
  };

  const handleOpenMenuItems = (menuItem: string) => (event: any) => {
    const current = openItems.find((n) => n === menuItem);
    if (current) {
      setOpenItems(openItems.filter((n) => n !== menuItem));
    } else {
      setOpenItems([...openItems, menuItem]);
    }
  };

  const contains = (paths: string[]) => {
    let doesContain = false;
    paths.forEach((path) => {
      if (location.pathname.includes(path)) {
        doesContain = true;
      }
    });

    return doesContain;
  };

  return (
    <div>
      <Hidden mdUp>
        <AppBar className={classes.whiteBackground} position="static">
          <Toolbar>
            <div className={classes.mobileAppbar}>
              <IconButton className={classes.blackIcon}>
                <MenuIcon onClick={handleDrawerToggle} />
              </IconButton>
              <Link to="/">
                <img
                  src={store.companies.company.logoUrl}
                  className={classes.appbarLogoMobile}
                  alt="APP_LOGO"
                />
              </Link>
              <IconButton onClick={handleClick}>
                <AccountCircle fontSize="large" />
              </IconButton>
            </div>
            <Menu
              anchorEl={anchorEl}
              keepMounted
              open={Boolean(anchorEl)}
              onClose={handleClose}
            >
              <MenuItem>
                <Link to="profile" className={classes.linkMenu}>
                  Profile
                </Link>
              </MenuItem>
              <MenuItem>
                <Link to="/faq" className={classes.linkMenu}>
                  FAQ
                </Link>
              </MenuItem>
              <ListItem button onClick={logout}>
                <ListItemText
                  primaryTypographyProps={{
                    className: classes.linkMenu,
                  }}
                  primary="Log out"
                />
              </ListItem>
            </Menu>
          </Toolbar>
        </AppBar>
      </Hidden>

      <Hidden smDown>
        <div className={` ${classes.contentContainer}`}>
          <div className={classes.navigationHeadParent}>
            <div className={classes.navigationHead}>
              <AppBar
                className={`${classes.whiteBackground} ${classes.menuMaxWidth}`}
                position="static"
              >
                <Toolbar className={`${classes.alignRight}`}>
                  <div className={classes.mobileAppbar}>
                    <div className={classes.iconBox}>
                      <IconButton
                        disableTouchRipple
                        className={classes.removeHover}
                        onClick={handleClick}
                      >
                        <AccountCircle
                          fontSize="large"
                          classes={{
                            root: classes.iconSpace,
                          }}
                        />
                        <h6 className={classes.profileText}>{name}</h6>
                        <ArrowDropDown
                          fontSize="small"
                          classes={{
                            root: classes.iconSpace,
                          }}
                        />
                      </IconButton>
                    </div>
                    <Menu
                      anchorEl={anchorEl}
                      keepMounted
                      open={Boolean(anchorEl)}
                      onClose={handleClose}
                      elevation={0}
                      PaperProps={{
                        style: {
                          paddingBottom: 'none !important',
                        },
                      }}
                      getContentAnchorEl={null}
                      anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'center',
                      }}
                      transformOrigin={{
                        vertical: 'top',
                        horizontal: 'center',
                      }}
                    >
                      <div className={classes.menuShadow}>
                        <MenuItem className={classes.styledMenuItem}>
                          <Link to="profile" className={classes.linkMenu}>
                            Profile
                          </Link>
                        </MenuItem>

                        <MenuItem className={classes.styledMenuItem}>
                          <Link to="/faq" className={classes.linkMenu}>
                            FAQ
                          </Link>
                        </MenuItem>
                        <ListItem button onClick={logout}>
                          <ListItemText
                            primaryTypographyProps={{
                              className: classes.linkMenu,
                            }}
                            primary="Log out"
                          />
                        </ListItem>
                      </div>
                    </Menu>
                  </div>
                </Toolbar>
              </AppBar>
            </div>
          </div>
        </div>
      </Hidden>

      <Drawer
        variant={isMobile ? 'temporary' : 'permanent'}
        open={drawerOpen}
        classes={{
          paper: classes.drawerPaper,
        }}
        onClose={handleDrawerToggle}
        ModalProps={{
          keepMounted: true, // Better open performance on mobile.
        }}
      >
        <div className={classes.toolbar}>
          <div className={classes.menuItems}>
            <Link to="/" className={classes.linkStyles}>
              <span className={classes.spanFlex}>
                <img
                  src={store.companies.company.logoUrl}
                  className={classes.appLogo}
                  alt="APP_LOGO"
                />
              </span>
            </Link>
            <div className={classes.menuItem}>
              <IconButton onClick={handleDrawerToggle}>
                <MenuIcon className={classes.whiteText} />
              </IconButton>
            </div>
          </div>
        </div>
        <List
          className={classes.listOne}
          subheader={<ListSubheader component="div">Admin area</ListSubheader>}
        >
          <ListItem
            component={Link}
            to="/admin/manage-users"
            button
            selected={
              contains(['/manage-users']) || location.pathname === '/admin'
            }
            className={`${classes.padding10}`}
          >
            <ListItemIcon>
              <PeopleAlt fontSize="large" className={classes.icon} />
            </ListItemIcon>
            <ListItemText
              primaryTypographyProps={{
                className: classes.boldText,
              }}
              primary="Users"
            />
          </ListItem>

          <ListItem
            component={Link}
            to="/admin/manage-vehicles"
            button
            selected={location.pathname.includes('/manage-vehicles')}
            className={`${classes.padding10}`}
          >
            <ListItemIcon>
              <Commute fontSize="large" className={classes.icon} />
            </ListItemIcon>
            <ListItemText
              primaryTypographyProps={{
                className: classes.boldText,
              }}
              primary="User Vehicles"
            />
          </ListItem>

          <ListItem
            component={Link}
            to="/admin/user-renewals"
            button
            selected={location.pathname.includes('/admin/user-renewals')}
            className={`${classes.padding10}`}
          >
            <ListItemIcon>
              <Sync fontSize="large" className={classes.icon} />
            </ListItemIcon>
            <ListItemText
              primaryTypographyProps={{
                className: classes.boldText,
              }}
              primary="User renewals"
            />
          </ListItem>

          <ListItem
            component={Link}
            to="/admin/user-active-renewals"
            button
            selected={contains([
              '/admin/user-active-renewals',
              'admin/view-renewal',
            ])}
            className={`${classes.padding10}`}
          >
            <ListItemIcon>
              <Check fontSize="large" className={classes.icon} />
            </ListItemIcon>
            <ListItemText
              primaryTypographyProps={{
                className: classes.boldText,
              }}
              primary="Active Renewals"
            />
          </ListItem>

          <ListItem
            component={Link}
            to="/admin/payments"
            button
            selected={location.pathname.includes('/admin/payments')}
            className={`${classes.padding10}`}
          >
            <ListItemIcon>
              <Payment fontSize="large" className={classes.icon} />
            </ListItemIcon>
            <ListItemText
              primaryTypographyProps={{
                className: classes.boldText,
              }}
              primary="Payments"
            />
          </ListItem>

          {/* <ListItem
						component={Link}
						to="/history"
						button
						selected={location.pathname.includes('/history')}
						className={`${classes.padding10}`}
					>
						<ListItemIcon>
							<History
								fontSize="large"
								className={classes.icon}
							/>
						</ListItemIcon>
						<ListItemText
							primaryTypographyProps={{
								className: classes.boldText,
							}}
							primary="History"
						/>
					</ListItem> */}
          <ListItem
            button
            onClick={handleOpenMenuItems('manage')}
            className={classes.padding10}
          >
            <ListItemIcon>
              <Settings fontSize="large" className={classes.icon} />
            </ListItemIcon>
            <ListItemText
              primaryTypographyProps={{
                className: classes.boldText,
              }}
              primary="Manage"
            />
            {openItems.includes('manage') ? <ExpandLess /> : <ExpandMore />}
          </ListItem>
          <Collapse
            in={openItems.includes('manage')}
            timeout="auto"
            unmountOnExit
          >
            <List component="div" disablePadding>
              <ListItem
                component={Link}
                to="/admin/tare-pricing"
                button
                selected={location.pathname.includes('/admin/tare-pricing')}
              >
                <ListItemText
                  className={classes.nestedText}
                  primary="Tare Pricing"
                />
              </ListItem>
              <ListItem
                component={Link}
                to="/admin/manage-variables"
                button
                selected={location.pathname.includes('/admin/manage-variables')}
              >
                <ListItemText
                  className={classes.nestedText}
                  primary="Variables"
                />
              </ListItem>
            </List>
          </Collapse>
        </List>
        <List
          className={classes.listOne}
          subheader={
            <ListSubheader component="div">Personal area</ListSubheader>
          }
        >
          <ListItem
            component={Link}
            to="/admin/profile"
            button
            className={`${classes.padding10}`}
            selected={location.pathname.includes('admin/profile')}
          >
            <ListItemIcon>
              <AccountCircle fontSize="large" className={classes.icon} />
            </ListItemIcon>
            <ListItemText
              primaryTypographyProps={{
                className: classes.boldText,
              }}
              primary="My Profile"
            />
          </ListItem>
          <ListItem
            button
            onClick={handleOpenMenuItems('vehicles')}
            selected={openItems.includes('vehicles')}
            className={`${classes.padding10}`}
          >
            <ListItemIcon>
              <DriveEta fontSize="large" className={classes.icon} />
            </ListItemIcon>
            <ListItemText
              primaryTypographyProps={{
                className: classes.boldText,
              }}
              primary="My Vehicles"
            />
            {openItems.includes('vehicles') ? <ExpandLess /> : <ExpandMore />}
          </ListItem>
          <Collapse
            in={openItems.includes('vehicles')}
            timeout="auto"
            unmountOnExit
          >
            <List component="div" disablePadding>
              <ListItem
                component={Link}
                to="/admin/manage-my-vehicles"
                button
                selected={location.pathname.includes(
                  'admin/manage-my-vehicles',
                )}
              >
                <ListItemText
                  className={classes.nestedText}
                  primary="All Vehicles"
                />
              </ListItem>
              <ListItem
                component={Link}
                to="/admin/add-vehicle"
                button
                selected={location.pathname.includes('admin/add-vehicle')}
              >
                <ListItemText
                  className={classes.nestedText}
                  primary="Add new"
                />
              </ListItem>
            </List>
          </Collapse>
        </List>

        <List className={classes.listTwo}>
          <ListItem
            component={Link}
            to="/admin/manage-my-renewals"
            button
            className={`${classes.padding10}`}
          >
            <ListItemIcon>
              <Sync fontSize="large" className={classes.icon} />
            </ListItemIcon>
            <ListItemText
              primaryTypographyProps={{
                className: classes.boldText,
              }}
              primary="My Renewals"
            />
          </ListItem>
        </List>
      </Drawer>
      <main className={classes.content}>{children}</main>
    </div>
  );
});

export default AdminDashMenu;
