import React from 'react';

interface IframeProps {
  address: string;
  title: string;
  height?: string;
}

const MapIframe = ({ title, address, height = '300' }: IframeProps) => {
  return (
    <iframe
      title={title}
      width="100%"
      height={height}
      frameBorder="0"
      style={{ border: 0 }}
      allowFullScreen
      src={`https://maps.google.com/maps?f=q&source=s_q&hl=en&geocode=&q=${address}&z=14&output=embed`}
    />
  );
};

export default MapIframe;
