import React from 'react';
// @material-ui
import {
  IconButton,
  DialogTitle,
  DialogContent,
  Grid,
  Dialog,
  Typography,
} from '@material-ui/core';
// @icons
import { Help } from '@material-ui/icons';
// @components
import useStyles from './styles';

const IdDialog = () => {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div>
      <IconButton onClick={handleClickOpen}>
        <Help />
      </IconButton>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>
          <b>Copy of ID or Passport</b>
        </DialogTitle>
        <DialogContent>
          <Grid container spacing={3} className={classes.gridPaddingBottom}>
            <Grid item>
              <Typography>
                Please upload a copy of a valid identity document.
              </Typography>
            </Grid>
            <Grid item>
              <Typography>
                Accepted formats are PNG, JPEG, JPG and PDF files. Proof of
                identification can be a scan/copy of one of the following (does
                not need to be certified):
              </Typography>
            </Grid>
            <Grid item className={classes.gridWidthBackground}>
              <ul>
                <li>
                  <Typography>
                    South African green bar-coded ID book, or
                  </Typography>
                </li>
                <li>
                  <Typography>South African ID smart-card, or</Typography>
                </li>
                <li>
                  <Typography>South African passport</Typography>
                </li>
              </ul>
            </Grid>
          </Grid>
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default IdDialog;
