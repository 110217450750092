import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';

export default makeStyles((theme: Theme) =>
  createStyles({
    container: {
      marginTop: '10px',
      padingLeft: '10px',
      paddingRight: '10px',
    },
    img100: {
      height: '500px',
      width: '500px',
    },
    selectedTopCenter: {
      textAlign: 'center',
      marginTop: '15px',
    },
    fullWidth: {
      marginTop: '10px',
      maxWidth: '100%',
    },
    gridFab: {
      display: 'flex',
      justifyContent: 'flex-end',
    },
    searchInput: {
      fontSize: '16px',
      width: '200px',
    },
    blueText: {
      color: '#64ABFC',
    },
    blueAvatar: {
      background: '#64abfb',
    },
    blackAvatar: {
      background: '#000',
    },
  }),
);
