import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';

export default makeStyles((theme: Theme) =>
  createStyles({
    contentContainer: {
      background: 'transparent',
      height: '100%',
    },
    container: {
      paddingBottom: '20px',
      maxWidth: '1200px !important',
      width: '100%',
      padding: '0 10px',
      background: 'transparent',
      margin: '0 auto',
    },
    gridLessPadding: {
      padding: '20px !important',
    },
    centerDocuments: {
      margin: '0 auto',
    },
    userSecondaryText: {
      color: theme.palette.text.secondary,
    },
    alignRight: {
      textAlign: 'right',
      [theme.breakpoints.down('sm')]: {
        textAlign: 'left',
      },
    },
    headerMargin: {
      marginBottom: '1%',
    },
    blueText: {
      color: '#81BCFF',
    },
    redText: {
      color: '#FF0000',
      display: 'inline-block',
      paddingLeft: '24px',
    },
    blueAvatar: {
      background: '#64abfb',
    },
    large: {
      width: theme.spacing(7),
      height: theme.spacing(7),
    },
    margin10: {
      margin: '10px',
    },
  }),
);
