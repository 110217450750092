import React, { useState } from 'react';
import * as R from 'ramda';
import { observer } from 'mobx-react-lite';
// @material-ui
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  TextField,
  DialogTitle,
  IconButton,
  FormGroup,
  FormControlLabel,
  Checkbox,
  Typography,
} from '@material-ui/core';
// @form
import { useForm, Controller } from 'react-hook-form';
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
// @icons
import { Close as CloseIcon } from '@material-ui/icons';
// @logic
import { useStore } from 'logic/store';
import { emailValidation } from 'logic/validation';
// @components
import PhoneInput, {
  formatNumberOut,
  transformForValidation,
} from 'components/utilities/PhoneInput';
import Confirmation from 'components/utilities/Confirm';
// @local
import { IPermission } from 'logic/stores/permissions/validation';
import { IUser } from 'logic/stores/users/validation';
import useStyles from '../styles';

interface FormValues {
  email: string;
  firstName: string;
  lastName: string;
  contactNumber: string;
  permissions: number[];
}

interface CheckBoxState {
  [id: number]: boolean;
}

const phoneRegExp = /^((\+27|27))(\d{2})-?(\d{3})-?(\d{4})$/;

const validationSchema = Yup.object().shape({
  firstName: Yup.string().label('First name').required(),
  lastName: Yup.string().required().label('Last name'),
  email: emailValidation.required(),
  contactNumber: Yup.string()
    .transform((value, originalValue) => transformForValidation(value))
    .matches(phoneRegExp, 'Please enter a valid format, e.g. 27824567890')
    .label('Contact number')
    .required(),
});

interface Props {
  userId: number;
  closeModal: () => void;
}

const UpdateUserForm = observer(({ userId, closeModal }: Props) => {
  const store = useStore();
  const classes = useStyles();

  const [user, setUser] = useState<IUser>();
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [viewConfirm, setViewConfirm] = useState(false);
  const [checkboxState, setState] = useState<CheckBoxState>({});
  const [permissions, setPermissions] = useState<IPermission[]>(
    R.pathOr([], ['permissions'], user),
  );
  const [testUser, setTestUser] = useState(false);

  const { handleSubmit, errors, control, reset, setValue, getValues } =
    useForm<FormValues>({
      resolver: yupResolver(validationSchema),
      defaultValues: user
        ? {
            ...user,
            contactNumber: user.contactNumber,
            permissions: user.permissions.map((n) => n.id),
          }
        : undefined,
    });

  const resetForm = (newUser: IUser) => {
    reset({
      ...newUser,
      permissions: newUser.permissions.map((n) => n.id),
    });
    fetchPermissions();
    setTestUser(newUser.testUser);
    setValue('contactNumber', newUser.contactNumber);
  };

  React.useEffect(() => {
    if (user != null) {
      resetForm(user);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  const fetchPermissions = async () => {
    try {
      const userPerms = R.pathOr<IPermission[]>([], ['permissions'], user);
      const userPermIds = userPerms.map((n: any) => n.id);
      const response = await store.permissions.load();
      if (response.success && response.data.length > 0) {
        const newState = response.data.reduce((acc, val) => {
          acc[val.id] = userPermIds.includes(val.id);
          return acc;
        }, {} as CheckBoxState);
        setState(newState);
        setPermissions(response.data);
      }
    } catch (err) {
      // eslint-disable-next-line no-console
      console.log('Could not fetch permissions', err);
    }
  };

  React.useEffect(() => {
    const loadUser = async (id: number) => {
      const result = await store.users.getById(id);
      if (result.success) {
        setUser(result.data);
      } else {
        console.log(`Error updating user: ${result.message}`);
      }
    };

    if (userId != null) {
      setOpen(true);
      loadUser(userId);
    } else {
      setOpen(false);
    }
  }, [userId]);

  const handleChange =
    (id: number) => (event: React.ChangeEvent<HTMLInputElement>) => {
      const newState = { ...checkboxState, [id]: event.target.checked };
      setState(newState);
    };

  const updateUser = async (data: FormValues) => {
    const { permissions, contactNumber, ...rest } = data;
    const cleanedContactNumber = formatNumberOut(contactNumber);

    if (userId != null) {
      setLoading(true);
      const permissionIds = Object.entries(checkboxState)
        .filter(([permId, checked]) => {
          return checked === true;
        })
        .map((n) => n[0]);

      const res = await store.users.update(
        userId,
        {
          ...rest,
          contactNumber: cleanedContactNumber,
          testUser,
        },
        permissions,
      );
      setLoading(false);

      if (res && res.success) {
        setUser(res.data);
        resetForm(res.data);
        closeModal();
      }
    }
  };

  const confirm = async () => {
    if (userId != null) {
      const res = await store.users.remove(userId);
      if (res.success === true) {
        setViewConfirm(false);
        closeModal();
      }
    }
  };

  const toggleTestUser = (e: any, checked: boolean) => {
    setTestUser(checked);
  };

  return (
    <>
      <Confirmation
        title={`Remove ${user != null ? user.firstName : ''} ${
          user != null ? user.lastName : ''
        }`}
        confirm={confirm}
        close={() => setViewConfirm(false)}
        description="Click okay to remove the selected user"
        open={viewConfirm}
      />
      <Dialog fullWidth maxWidth="sm" open={open} onClose={closeModal}>
        <DialogTitle>
          <b>Update User</b>
          <IconButton
            aria-label="close"
            onClick={closeModal}
            className={classes.positionIcon}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <form>
            <br />
            <Typography>
              <b>General Details</b>
            </Typography>
            <Controller
              as={
                <TextField
                  fullWidth
                  margin="dense"
                  variant="outlined"
                  placeholder="First Name"
                  error={Boolean(errors.firstName)}
                  helperText={errors.firstName && errors.firstName.message}
                />
              }
              name="firstName"
              control={control}
            />

            <Controller
              as={
                <TextField
                  fullWidth
                  color="secondary"
                  margin="dense"
                  variant="outlined"
                  placeholder="Last Name"
                  error={Boolean(errors.lastName)}
                  helperText={errors.lastName && errors.lastName.message}
                />
              }
              name="lastName"
              control={control}
            />

            <Controller
              as={
                <TextField
                  fullWidth
                  color="secondary"
                  margin="dense"
                  variant="outlined"
                  placeholder="Email address"
                  type="email"
                  error={Boolean(errors.email)}
                  helperText={errors.email && errors.email.message}
                />
              }
              name="email"
              control={control}
            />
            <Controller
              name="contactNumber"
              control={control}
              defaultValue=""
              render={({ onChange, value, ...props }) => {
                console.log(`all available props`);
                console.log(props);
                return (
                  <PhoneInput
                    error={Boolean(errors.contactNumber)}
                    helperText={
                      errors.contactNumber && errors.contactNumber.message
                    }
                    value={value}
                    onChange={onChange}
                    {...props}
                  />
                );
              }}
            />

            <br />
            <Typography>
              <b>User Permissions</b>
            </Typography>
            <FormGroup row>
              {permissions
                .sort((a, b) => a.risk - b.risk)
                .map((n) => {
                  return (
                    <FormControlLabel
                      key={n.id}
                      control={
                        <Checkbox
                          checked={Boolean(checkboxState[n.id])}
                          onChange={handleChange(n.id)}
                          value={n.id}
                        />
                      }
                      label={n.name}
                    />
                  );
                })}
              <FormControlLabel
                control={
                  <Checkbox
                    checked={testUser}
                    onChange={toggleTestUser}
                    value={testUser}
                  />
                }
                label="Test User"
              />
            </FormGroup>
          </form>
        </DialogContent>
        <DialogActions className={classes.dialogBorder}>
          <Button
            onClick={() => setViewConfirm(true)}
            variant="contained"
            color="default"
          >
            Delete
          </Button>
          <div className={classes.flexGrow} />
          <Button onClick={closeModal} color="default">
            Cancel
          </Button>
          <Button
            disabled={loading}
            variant="contained"
            color="primary"
            onClick={handleSubmit(updateUser)}
          >
            Save
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
});

export default UpdateUserForm;
