import React, { useState } from 'react';
import { observer } from 'mobx-react-lite';
import { toast } from 'react-toastify';
// @material-ui
import { Grid, Button, TextField, Typography } from '@material-ui/core';
// @form
import { useForm, Controller } from 'react-hook-form';
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
// @logic
import { useStore } from 'logic/store';
// @components
import Content from 'components/Layout/Content';
import PhoneInput, {
  formatNumberOut,
  transformForValidation,
} from 'components/utilities/PhoneInput';
import GooglePlaces, { PlacesResult } from 'components/utilities/Google/Places';
// @local
import { emailValidation } from 'logic/validation';
import { IUser } from 'logic/stores/users/validation';
import useStyles from './styles';

interface UserForm {
  firstName: string;
  lastName: string;
  contactNumber: string;
  email: string;
  deliveryAddress: string;
  deliveryCoordinates: [number, number];
  deliveryAddressAdditional: string | null;
}

interface TemplateProps {
  userId: string;
}

const phoneRegExp = /^((\+27|27)|0)(\d{2})-?(\d{3})-?(\d{4})$/;

const validationSchema = Yup.object().shape({
  email: emailValidation.required(),
  firstName: Yup.string().label('First Name').required(),
  lastName: Yup.string().label('Last Name').required(),
  contactNumber: Yup.string()
    .transform((value, originalValue) => transformForValidation(value))
    .matches(phoneRegExp, 'Phone number is not valid, i.e. 27824567890')
    .label('Contact Number')
    .required(),
  deliveryAddress: Yup.string().label('delivery address').required(),
  deliveryAddressAdditional: Yup.string()
    .label('Additional Address Information')
    .nullable(true),
});

const Profile = observer((props: TemplateProps) => {
  const store = useStore();
  const classes = useStyles({});
  const { userId, user } = store.auth;

  const defaultCoords = [-33.9248685, 18.4240553] as [number, number];

  const [loading, setLoading] = useState(false);
  const [address, setAddress] = React.useState('');
  const [, setAddressCoords] = React.useState(defaultCoords);

  const { handleSubmit, errors, control, reset, setValue, register } =
    useForm<UserForm>({
      resolver: yupResolver(validationSchema),
      defaultValues: user || {
        contactNumber: '+27',
        deliveryAddressAdditional: null,
      },
    });

  const getSelectedLocation = (location: PlacesResult) => {
    if (location.formatted_address) {
      setAddress(location.formatted_address);
      setValue('deliveryAddress', location.formatted_address);
    }
    setAddressCoords(location.coordinates);
    setValue('deliveryCoordinates', location.coordinates);
  };

  React.useEffect(() => {
    if (userId != null) {
      store.users.getById(userId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userId]);

  React.useEffect(() => {
    if (user != null) {
      if (user.deliveryCoordinates) {
        setAddressCoords(user.deliveryCoordinates);
      }
      if (user.deliveryAddress) {
        setAddress(user.deliveryAddress);
      }

      reset({
        email: user.email,
        firstName: user.firstName,
        lastName: user.lastName,
        contactNumber: user.contactNumber || '+27',
        deliveryAddress: user.deliveryAddress,
        deliveryCoordinates: user.deliveryCoordinates,
        deliveryAddressAdditional: user.deliveryAddressAdditional,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(user)]);

  const updateUser = async (payload: UserForm) => {
    if (!userId) {
      return;
    }
    setLoading(true);

    const { contactNumber, ...rest } = payload;

    const result = await store.users.update(userId, {
      ...rest,
      contactNumber: formatNumberOut(contactNumber),
    } as Partial<IUser>);
    setLoading(false);
    if (result.success) {
      toast.success('Profile has been updated');
      store.auth.set(result.data);
    }
  };

  React.useEffect(() => {
    register({ name: 'deliveryAddress' });
    register({ name: 'deliveryCoordinates' });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [register]);

  return (
    <Content
      className={classes.container}
      containerClass={classes.contentContainer}
    >
      <Grid container justify="center" className={classes.container}>
        <Grid item md={11} xs={12}>
          <Typography
            variant="h4"
            align="center"
            className={classes.profileHeadingSpace}
          >
            Manage Profile
          </Typography>
          <form onSubmit={handleSubmit(updateUser)}>
            <Typography variant="h5" align="center" color="primary">
              User Details
            </Typography>
            <Controller
              as={
                <TextField
                  color="secondary"
                  fullWidth
                  margin="normal"
                  variant="outlined"
                  placeholder="Email"
                  type="email"
                  error={Boolean(errors.email)}
                  helperText={errors.email && errors.email.message}
                />
              }
              name="email"
              control={control}
            />

            <Controller
              as={
                <TextField
                  fullWidth
                  color="secondary"
                  margin="normal"
                  variant="outlined"
                  placeholder="First Name"
                  error={Boolean(errors.firstName)}
                  helperText={errors.firstName && errors.firstName.message}
                />
              }
              name="firstName"
              control={control}
            />

            <Controller
              as={
                <TextField
                  fullWidth
                  color="secondary"
                  margin="normal"
                  variant="outlined"
                  placeholder="Last Name"
                  error={Boolean(errors.lastName)}
                  helperText={errors.lastName && errors.lastName.message}
                />
              }
              name="lastName"
              control={control}
            />

            <Controller
              render={({ onChange, value, ...props }) => (
                <PhoneInput
                  margin="normal"
                  error={Boolean(errors.contactNumber)}
                  helperText={
                    errors.contactNumber && errors.contactNumber.message
                  }
                  value={value}
                  onChange={onChange}
                  {...props}
                />
              )}
              defaultValue=""
              name="contactNumber"
              control={control}
            />
            <div>
              <Typography
                variant="h5"
                align="center"
                className={classes.textTopMargin}
                color="primary"
              >
                Delivery Address
              </Typography>
              {/* Delivery or collection options */}
              <GooglePlaces
                getSelectedLocation={getSelectedLocation}
                textfieldProps={{
                  placeholder: address,
                  variant: 'outlined',
                  label: 'Updated Location',
                  margin: 'normal',
                  fullWidth: true,
                }}
              />
              {address && (
                <Controller
                  as={
                    <TextField
                      fullWidth
                      color="secondary"
                      margin="normal"
                      variant="outlined"
                      label="Complex / Building (Optional)"
                      placeholder="Complex or Building Name, unit number or floor"
                      error={Boolean(errors.deliveryAddressAdditional)}
                      helperText={
                        errors.deliveryAddressAdditional &&
                        errors.deliveryAddressAdditional.message
                      }
                    />
                  }
                  name="deliveryAddressAdditional"
                  control={control}
                />
              )}
            </div>

            <div>
              <Button
                fullWidth
                variant="contained"
                size="large"
                color="primary"
                disabled={loading}
                type="submit"
              >
                Update Details
              </Button>
            </div>
          </form>
        </Grid>
      </Grid>
    </Content>
  );
});

export default Profile;
