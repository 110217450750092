import discMake from 'assets/img/disc-make.png';
import discLicense from 'assets/img/disc-licence.png';
import discXP from 'assets/img/disc-expiry.png';

export enum ShowMoreInfo {
  makeInfo,
  modelInfo,
  yearInfo,
  licenseNumberInfo,
  expiryDateInfo,
  vehicleRegisterNumber,
  renewalNotifications,
  provinceInfo,
  weightInfo,
  vehicleType,
}

export interface IImageDialog {
  id: number;
  image: any;
  text: string;
  description: string;
  imageText: string;
  lowerText: any;
}

const imageDialog: Record<ShowMoreInfo, IImageDialog> = {
  [ShowMoreInfo.makeInfo]: {
    id: 0,
    image: discMake,
    text: 'Vehicle make',
    description:
      "Enter the make of the vehicle as it appears on your licence disc/certificate. For example: 'Opel', 'BMW' or 'Yamaha'. Once you start typing some options should appear to help you choose.",
    imageText: 'Vehicle make on licence disc',
    lowerText: null,
  },
  [ShowMoreInfo.licenseNumberInfo]: {
    id: 1,
    image: discLicense,
    text: 'License Number',
    description:
      "Enter the vehicle's licence number as it appears on your licence disc/certificate. This is the same as your vehicle's number plate.",
    imageText: 'Licence number on licence disc',
    lowerText: null,
  },
  [ShowMoreInfo.expiryDateInfo]: {
    id: 2,
    image: discXP,
    text: 'Licence expiry',
    description:
      'Select the expiry date of your licence as it appears on your licence disc/certificate.',
    imageText: 'licence expiry on licence disc',
    lowerText: null,
  },
  [ShowMoreInfo.vehicleRegisterNumber]: {
    id: 3,
    image: null,
    text: 'Vehicle Register Number',
    description:
      'Enter your vehicle register number as it appears on your licence disc/certificate.',
    imageText: 'Vehicle register number on licence disc',
    lowerText: null,
  },
  [ShowMoreInfo.renewalNotifications]: {
    id: 4,
    image: null,
    text: 'Renewal notifications',
    description:
      'Make sure that this option is checked if you would like to receive automatic emails to remind you well before your licence expires so that you can renew it in good time.',
    imageText:
      'If this option is selected, an automatic email will also be sent to you should your licence expire',
    lowerText: 'You can change this setting later.',
  },
  [ShowMoreInfo.provinceInfo]: {
    id: 5,
    image: null,
    text: 'Vehicle Region',
    description:
      'Make sure that you have a license number that matches a valid province/region in SA',
    imageText: 'Province Selection',
    lowerText: null,
  },
  [ShowMoreInfo.weightInfo]: {
    id: 6,
    image: null,
    text: 'Vehicle Weight',
    description: 'Make sure that you have the vehicle weight details in KGs',
    imageText: 'Weight KG',
    lowerText: null,
  },
  [ShowMoreInfo.vehicleType]: {
    id: 7,
    image: null,
    text: 'Vehicle Type',
    description: 'Make sure you select the proper vehicle type',
    imageText: 'Vehicle Type',
    lowerText: null,
  },
  [ShowMoreInfo.modelInfo]: {
    id: 8,
    image: null,
    text: 'Vehicle Model',
    description: `Enter the model of the vehicle. For example: 'Polo', 'Getz' or 'Corolla'.`,
    imageText: 'Vehicle Model',
    lowerText: null,
  },
  [ShowMoreInfo.yearInfo]: {
    id: 9,
    image: null,
    text: 'Vehicle Year',
    description: `Enter the year that the vehicle was manufactured.`,
    imageText: 'Vehicle Year',
    lowerText: null,
  },
};

export default imageDialog;
