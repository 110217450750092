import React, { useState } from 'react';
import { observer } from 'mobx-react-lite';
import { useHistory, useLocation } from 'react-router-dom';
import Numeral from 'numeral';
import moment from 'moment';
import * as R from 'ramda';
// @material-ui
import {
  AppBar,
  TableFooter,
  Hidden,
  TablePagination,
  TableRow,
  TableHead,
  TableCell,
  Table,
  TableBody,
  TextField,
  Grid,
  Paper,
  CircularProgress,
  Toolbar,
  Typography,
  Chip,
  IconButton,
  Menu,
  MenuItem,
  Collapse,
  Box,
} from '@material-ui/core';
// @icons
import {
  Search as SearchIcon,
  MoreVert,
  KeyboardArrowDown,
} from '@material-ui/icons';
// @logic
import { useStore } from 'logic/store';
// @components
import { formatCurrency } from 'utilities/handleCurrency';
// @local
import { IRenewal } from 'logic/stores/renewals/validation';
import { delay } from 'bluebird';
import { StatusDict } from 'logic/stores/renewals/types';
import useStyles from './styles';
import { DefaultViewProps } from '../Layout/WithLayout';

type Order = 'asc' | 'desc';

function stableSort<T>(array: T[], comparator: (a: T, b: T) => number) {
  const stabilizedThis = (array || []).map(
    (el, index) => [el, index] as [T, number],
  );
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

function getComparator<Key extends keyof any>(
  order: Order,
  orderBy: Key,
): (a: { [key in Key]: any }, b: { [key in Key]: any }) => number {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function descendingComparator<T>(a: T, b: T, orderBy: keyof T) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}
const Renewals = observer<DefaultViewProps>(({ view }) => {
  const location = useLocation();
  const history = useHistory();
  const store = useStore();
  const { userId } = store.auth;
  const classes = useStyles();

  const [total, setTotal] = useState(0);
  const [renewals, setRenewals] = useState<IRenewal[]>([]);

  const [searchText, setSearchText] = React.useState('');
  const [typingTimeout, setTypeTimeout] = React.useState<NodeJS.Timeout | null>(
    null,
  );
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [selectedRenewal, setSelectedRenewal] = React.useState<IRenewal | null>(
    null,
  );
  const [order] = React.useState<Order>('desc');
  const [orderBy] = React.useState<'updatedAt'>('updatedAt');

  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  const [page, setPage] = React.useState(0);

  const [renewalIds, setRenewalIds] = React.useState<number[]>([]);

  const [loading, setLoading] = React.useState(false);

  const manageMyRenewals =
    location.pathname.includes('/manage-my-renewals') ||
    location.pathname.includes('/manage-renewals');
  const viewActiveRenewals = location.pathname.includes(
    '/user-active-renewals',
  );
  const viewAllRenewals = location.pathname.includes('/user-renewals');
  const viewRenewal = (event: any) => {
    const { id } = event.currentTarget;
    if (manageMyRenewals === true) {
      history.push(`/view-renewal/${id}`);
    } else {
      history.push(`/admin/view-renewal/${id}`);
    }
  };

  const viewVehicle = (event: any) => {
    const { id } = event.currentTarget;

    if (manageMyRenewals) {
      history.push(`/view-vehicle/${id}`);
    } else {
      history.push(`/admin/view-vehicle/${id}`);
    }
  };

  React.useEffect(() => {
    // store.renewals.clear();
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [viewActiveRenewals, viewAllRenewals, manageMyRenewals]);

  React.useEffect(() => {
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rowsPerPage, page, orderBy, order]);

  const fetchData = async (search?: string) => {
    setLoading(true);
    setRenewals([]);
    if (search) {
      const result = await store.admin.getRenewals(
        {
          search,
          skip: 0,
          take: 10,
        },
        viewActiveRenewals,
      );
      if (result.success) {
        await delay(500);
        setTotal(result.data.count);
        setRenewals(result.data.entries);
      }
    } else if (manageMyRenewals) {
      const result = await store.renewals.getRenewals({
        userId,
        skip: page * rowsPerPage,
        take: rowsPerPage,
      });
      if (result.success) {
        await delay(500);
        setTotal(result.data.count);
        setRenewals(result.data.entries);
      }
    } else {
      const result = await store.admin.getRenewals(
        {
          skip: page * rowsPerPage,
          take: rowsPerPage,
        },
        viewActiveRenewals,
      );
      if (result.success) {
        await delay(500);
        setTotal(result.data.count);
        setRenewals(result.data.entries);
      }
    }
    setLoading(false);
  };

  const toggleRow = (id: number) => {
    const alreadyOpen = renewalIds.includes(id);
    if (alreadyOpen) {
      const updatedIds = renewalIds.filter((n) => String(n) !== String(id));
      setRenewalIds(updatedIds);
    } else {
      setRenewalIds([...renewalIds, id]);
    }
  };

  const searchFor = (searchEvent: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = searchEvent.target;
    if (typingTimeout != null) {
      clearTimeout(typingTimeout);
    }
    setSearchText(value);
    setTypeTimeout(
      // @ts-ignore To-Do Fix Type
      setTimeout(() => fetchData(value), 300),
    );
  };

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const viewMenu = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
    const renewal = renewals.find(
      (r) => r.id === parseInt(event.currentTarget.id, 10),
    );
    setSelectedRenewal(renewal ?? null);
  };

  const closeMenu = () => {
    setAnchorEl(null);
  };

  const emptyRows =
    rowsPerPage - Math.min(rowsPerPage, renewals.length - page * rowsPerPage);
  const rowStyles = 49 * emptyRows;
  const heightWithRow = [{ height: rowStyles }].join(' ');

  const RenewalState = ({ status }: { status: string }) => {
    return (
      <Chip
        variant="outlined"
        label={status}
        className={[classes.status].join(' ')}
      />
    );
  };

  const getVehicleDetails = (renewal: IRenewal) => {
    const licenseNumber = R.pathOr('', ['vehicle', 'licenseNumber'], renewal);
    const make = R.pathOr('', ['vehicle', 'make'], renewal);
    const model = R.pathOr('', ['vehicle', 'model'], renewal);
    const year = R.pathOr('', ['vehicle', 'year'], renewal);
    const weightKg = R.pathOr('', ['vehicle', 'weightKg'], renewal);
    const vehicleId = R.pathOr('', ['vehicle', 'id'], renewal);
    const licenseExpires = R.path(
      ['vehicle', 'licenseExpires'],
      renewal,
    ) as null | Date;

    return {
      licenseNumber,
      make,
      model,
      year,
      weightKg,
      vehicleId,
      licenseExpires,
    };
  };

  return (
    <Paper className={classes.paper} elevation={0}>
      <Typography variant="h4" align="center">
        Manage Renewals
      </Typography>
      <AppBar
        className={classes.searchBar}
        position="static"
        color="default"
        elevation={0}
      >
        {selectedRenewal ? (
          <Menu
            anchorEl={anchorEl}
            open={Boolean(anchorEl)}
            onClose={closeMenu}
          >
            {selectedRenewal.vehicleId == null ? (
              <MenuItem
                onClick={viewVehicle}
                id={selectedRenewal.vehicleId}
                disabled={selectedRenewal.vehicleId == null}
              >
                Vehicle Removed
              </MenuItem>
            ) : (
              <MenuItem
                onClick={viewVehicle}
                id={`${selectedRenewal.vehicleId}`}
                disabled={selectedRenewal.vehicleId == null}
              >
                View Vehicle
              </MenuItem>
            )}
            <MenuItem id={`${selectedRenewal.id}`} onClick={viewRenewal}>
              View Renewal
            </MenuItem>
          </Menu>
        ) : null}

        <Toolbar>
          <Grid container spacing={2} alignItems="center">
            <Hidden smDown>
              <Grid item>
                <SearchIcon className={classes.block} color="inherit" />
              </Grid>
            </Hidden>
            <Grid item xs>
              <TextField
                fullWidth
                color="secondary"
                placeholder="Search by Vehicle reference"
                onChange={searchFor}
                value={searchText}
                InputProps={{
                  disableUnderline: true,
                  className: classes.searchInput,
                }}
              />
            </Grid>
          </Grid>
        </Toolbar>
      </AppBar>

      <div className={classes.contentWrapper}>
        {renewals.length < 1 ? (
          <Typography color="textSecondary" align="center">
            No renewals found...
          </Typography>
        ) : loading === true ? (
          <div className={classes.centerProgress}>
            Fetching renewals...
            <CircularProgress className={classes.progress} />
          </div>
        ) : (
          <div className={classes.tableScroll}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell />
                  <TableCell>Date Ordered</TableCell>
                  <TableCell>Expiry Date</TableCell>
                  {viewActiveRenewals || viewAllRenewals ? (
                    <TableCell>User</TableCell>
                  ) : null}
                  <TableCell>Payment Reference</TableCell>
                  <TableCell>Payment Total</TableCell>
                  <TableCell align="center">Status</TableCell>
                  <TableCell />
                </TableRow>
              </TableHead>
              <TableBody>
                {stableSort(renewals, getComparator(order, orderBy)).map(
                  (renewal) => {
                    const {
                      make,
                      licenseExpires,
                      licenseNumber,
                      weightKg,
                      model,
                    } = getVehicleDetails(renewal); // TODO: add model and year to display?
                    return (
                      <>
                        <TableRow key={renewal.id} hover>
                          <TableCell>
                            <IconButton
                              aria-label="Renewals info"
                              size="small"
                              className={classes.blueIcon}
                              onClick={() => toggleRow(renewal.id)}
                            >
                              <KeyboardArrowDown
                                className={
                                  renewalIds.includes(renewal.id)
                                    ? classes.chevronRotate
                                    : classes.chevron
                                }
                              />
                            </IconButton>
                          </TableCell>
                          <TableCell>
                            {renewal.dateOrdered
                              ? moment(renewal.dateOrdered).format('DD-MM-YYYY')
                              : null}
                          </TableCell>
                          <TableCell>
                            {renewal.originalExpiry
                              ? moment(renewal.originalExpiry).format(
                                  'DD-MM-YYYY',
                                )
                              : null}
                          </TableCell>
                          {viewActiveRenewals || viewAllRenewals ? (
                            <TableCell>
                              {renewal.user
                                ? `${renewal.user.firstName} ${renewal.user.lastName}`
                                : null}
                            </TableCell>
                          ) : null}
                          <TableCell>{renewal.paymentReference}</TableCell>
                          <TableCell>
                            {formatCurrency(
                              parseInt(renewal.paymentInCents, 10),
                            )}
                          </TableCell>
                          <TableCell align="center">
                            <RenewalState status={StatusDict[renewal.status]} />
                          </TableCell>
                          <TableCell>
                            <IconButton id={`${renewal.id}`} onClick={viewMenu}>
                              <MoreVert />
                            </IconButton>
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell
                            className={classes.removeCellSpacing}
                            colSpan={7}
                          >
                            <Collapse
                              in={renewalIds.includes(renewal.id)}
                              timeout="auto"
                            >
                              <Box
                                margin={1}
                                className={classes.collapsePadding}
                              >
                                <Typography
                                  variant="h6"
                                  gutterBottom
                                  component="div"
                                  className={classes.blueIcon}
                                >
                                  Vehicle details
                                </Typography>
                                <Table
                                  size="small"
                                  aria-label="Renewals Additional Details"
                                >
                                  <TableHead>
                                    <TableRow>
                                      <TableCell
                                        className={classes.renewalHead}
                                      >
                                        License Number
                                      </TableCell>
                                      <TableCell
                                        className={classes.renewalHead}
                                      >
                                        Make &amp; Model
                                      </TableCell>
                                      <TableCell
                                        align="right"
                                        className={classes.renewalHead}
                                      >
                                        Weight (kg)
                                      </TableCell>
                                      <TableCell
                                        align="right"
                                        className={classes.renewalHead}
                                      >
                                        License Expiry Date
                                      </TableCell>
                                    </TableRow>
                                  </TableHead>
                                  <TableBody>
                                    <TableRow>
                                      <TableCell className={classes.uppercase}>
                                        {licenseNumber === ''
                                          ? 'Vehicle has been removed'
                                          : licenseNumber}
                                      </TableCell>
                                      <TableCell className={classes.uppercase}>
                                        {make} {model}
                                      </TableCell>
                                      <TableCell
                                        align="right"
                                        className={classes.uppercase}
                                      >
                                        {Numeral(weightKg).format('0,0.00')}
                                      </TableCell>
                                      <TableCell
                                        align="right"
                                        className={classes.uppercase}
                                      >
                                        {licenseExpires
                                          ? moment(licenseExpires).format(
                                              'DD-MM-YYYY',
                                            )
                                          : null}
                                      </TableCell>
                                    </TableRow>
                                  </TableBody>
                                </Table>
                              </Box>
                            </Collapse>
                          </TableCell>
                        </TableRow>
                      </>
                    );
                  },
                )}
                <Hidden smDown>
                  {emptyRows > 0 && (
                    <TableRow className={heightWithRow}>
                      <TableCell colSpan={6} />
                    </TableRow>
                  )}
                </Hidden>
              </TableBody>
              <TableFooter>
                <TableRow>
                  <TablePagination
                    rowsPerPageOptions={[5, 10, 25]}
                    count={total}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    backIconButtonProps={{
                      'aria-label': 'Previous Page',
                    }}
                    nextIconButtonProps={{
                      'aria-label': 'Next Page',
                    }}
                    classes={{
                      toolbar: classes.paginationToolbar,
                      actions: classes.paginationActions,
                    }}
                    onChangePage={handleChangePage}
                    onChangeRowsPerPage={handleChangeRowsPerPage}
                  />
                </TableRow>
              </TableFooter>
            </Table>
          </div>
        )}
      </div>
    </Paper>
  );
});

export default Renewals;
