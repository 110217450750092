import React from 'react';
import swal from '@sweetalert/with-react';
import { observer } from 'mobx-react-lite';
import { useHistory } from 'react-router-dom';
// @material-ui
import { Alert } from '@material-ui/lab';
import { Typography, Button, CircularProgress } from '@material-ui/core';
// @icons
import { Done, Clear } from '@material-ui/icons';
import { useStore } from 'logic/store';
// @local
import { IRenewal } from 'logic/stores/renewals/validation';
import { IUser } from 'logic/stores/users/validation';
import { RenewalStatus, StatusDict } from 'logic/stores/renewals/types';
import UserActionRequiredForm from '../UserActionRequiredForm';
import useStyles from '../styles';

interface TemplateProps {
  renewal: IRenewal | null;
  user: IUser | null;
  onAction: (renewalId: number) => void;
}

const RenewalActionsContainer = observer(
  ({ renewal, user, onAction }: TemplateProps) => {
    const classes = useStyles({});
    const store = useStore();
    const history = useHistory();

    const transitionAction = ({ status }: { status: RenewalStatus }) => {
      if (status === 'user to action') {
        UserActionRequired({ status });
      } else {
        standardAction({ status });
      }
    };

    const { permissions: permissionTypes } = store.auth;
    const isAdmin = permissionTypes?.includes('isAdmin');

    const viewVehicle = () => {
      if (renewal != null) {
        if (isAdmin) {
          history.push(`/admin/view-vehicle/${renewal.vehicleId}`);
        } else {
          history.push(`/view-vehicle/${renewal.vehicleId}`);
        }
      }
    };

    const onRenewVehicleAgain = () => {
      history.push(`/start-renewal/collection/${renewal?.vehicleId}`);
    };

    const standardAction = ({ status }: { status: RenewalStatus }) => {
      swal({
        title: 'Are you sure?',
        text: 'This will update the renewal status',
        content: (
          <Typography>
            This will update the renewal status to{' '}
            <strong>{StatusDict[status]}</strong>.
          </Typography>
        ),
        icon: 'warning',
        buttons: {
          cancel: true,
          catch: {
            text: 'Yes, I want to update state',
            value: 'confirm',
          },
        },
        dangerMode: true,
      }).then(async (action: string) => {
        if (action !== 'confirm') {
          return;
        }
        try {
          if (status === 'delivery requested' && user) {
            await startDelivery();
          } else {
            await actionToPerform({ status });
          }
          if (renewal) {
            onAction(renewal.id);
          }
        } catch (error) {
          swal('Error', (error as any).msg, 'error');
        }
      });
    };

    const UserActionRequired = ({ status }: { status: RenewalStatus }) => {
      swal({
        title: 'Are you sure?',
        text: 'Please attach a note to describe what action needs to take place.',
        content: <UserActionRequiredForm renewal={renewal} />,
        icon: 'warning',
        buttons: [true],
        dangerMode: true,
      });
    };

    const actionToPerform = async ({ status }: { status: RenewalStatus }) => {
      if (renewal != null) {
        const result = await store.renewals.update(renewal.id, { status });
        if (result.success === true && result) {
          store.events.load({ renewalId: renewal.id });
          swal('Sorted!', 'status has been updated', 'success');
        } else {
          swal('Error', result.message, 'error');
        }
      }
    };

    const startDelivery = async () => {
      if (!renewal || !user) {
        return;
      }

      // create delivery
      swal({
        text: 'Connecting to delivery service....',
        content: <CircularProgress />,
        buttons: false,
      });

      const result = await store.deliveries.create({
        renewalId: renewal.id,
        userId: renewal.userId,
        destinationAddress: user.deliveryAddress,
        destinationContactName: `${user.firstName} ${user.lastName}`,
        destinationPhoneNumber: user.contactNumber,
        registrationRegion: renewal.vehicle?.registrationRegion,
      });

      swal.close();

      if (result.success === true && result) {
        const res = await store.renewals.update(renewal.id, {
          status: 'delivery requested',
        });
        if (res.success === true && res) {
          swal(
            'Sorted!',
            'status has been moved to delivery requested',
            'success',
          );
        }
      } else {
        swal.close();
        swal('Error', result.message, 'error');
      }
    };

    // Renewal State
    const RenewalState = ({ status }: { status: RenewalStatus }) => {
      switch (status) {
        case 'submitted by user':
          return <RenewalSubmittedButtons />;
        case 'scheduled':
          return <RenewalScheduledButtons />;
        case 'processing':
          return <ProcessingRenewalButtons />;
        case 'ready to collect':
          return <ReadyToCollectButtons />;
        case 'delivery requested':
          return <InDeliveryButtons />;
        case 'delivered':
          return <DeliveredButtons />;
        case 'user to action':
          return <UserActionRequiredButtons />;
        case 'admin to action':
          return <AdminToActionButtons />;
        case 'license updated':
          return <RenewalComplete />;
        default:
          return null;
      }
    };
    // Buttons
    const PositiveActionButton = (config: {
      label: string;
      action: (renewal: IRenewal) => void;
      renewal: IRenewal;
    }) => {
      const { label, action } = config;

      const onClickAction = () => {
        if (renewal) {
          action(renewal);
        }
      };

      return (
        <Button
          variant="contained"
          color="primary"
          fullWidth
          onClick={onClickAction}
          className={[
            classes.status,
            classes.full100,
            classes.buttonMarginBottom,
            classes.success,
          ].join(' ')}
          endIcon={<Done />}
        >
          {label}
        </Button>
      );
    };

    const NegativeActionButton = (config: {
      label: string;
      action: (renewal: IRenewal) => void;
      renewal: IRenewal;
    }) => {
      const { label, action, renewal: selectedsRenewal } = config;
      const onClickAction = () => {
        if (selectedsRenewal) {
          action(selectedsRenewal);
        }
      };
      return (
        <Button
          variant="contained"
          color="primary"
          fullWidth
          onClick={onClickAction}
          className={[
            classes.status,
            classes.full100,
            classes.buttonMarginBottom,
            classes.danger,
          ].join(' ')}
          endIcon={<Clear />}
        >
          {label}
        </Button>
      );
    };
    const RenewalSubmittedButtons = () => {
      return (
        <div className={classes.actionMargin}>
          {renewal ? (
            <>
              <PositiveActionButton
                label="Add to Processing"
                action={() =>
                  transitionAction({
                    status: 'processing',
                  })
                }
                renewal={renewal}
              />
            </>
          ) : null}
        </div>
      );
    };

    const RenewalComplete = () => {
      return (
        <div className={classes.actionMargin}>
          <Alert severity="success">Renewal is complete</Alert>
          <br />
          <Alert severity="info">
            Please ensure the licence expiry date has been updated.
          </Alert>
        </div>
      );
    };

    const UserActionRequiredButtons = () => {
      return (
        <div className={classes.actionMargin}>
          {renewal ? (
            <>
              <PositiveActionButton
                label="Resolve"
                action={() =>
                  transitionAction({
                    status: 'admin to action',
                  })
                }
                renewal={renewal}
              />
              <NegativeActionButton
                label="Cancel"
                action={() =>
                  transitionAction({
                    status: 'cancelled',
                  })
                }
                renewal={renewal}
              />
            </>
          ) : null}
        </div>
      );
    };

    const AdminToActionButtons = () => {
      return (
        <div className={classes.actionMargin}>
          {renewal ? (
            <>
              <PositiveActionButton
                label="Approve"
                action={() =>
                  transitionAction({
                    status: 'processing',
                  })
                }
                renewal={renewal}
              />
              <PositiveActionButton
                label="Resume Delivery"
                action={() =>
                  transitionAction({
                    status: 'delivery requested',
                  })
                }
                renewal={renewal}
              />
              <PositiveActionButton
                label="Require Action"
                action={() =>
                  transitionAction({
                    status: 'user to action',
                  })
                }
                renewal={renewal}
              />
              <NegativeActionButton
                label="Cancel"
                action={() =>
                  transitionAction({
                    status: 'cancelled',
                  })
                }
                renewal={renewal}
              />
            </>
          ) : null}
        </div>
      );
    };

    const ProcessingRenewalButtons = () => {
      return (
        <div className={classes.actionMargin}>
          {renewal ? (
            <>
              <PositiveActionButton
                label="Ready for Delivery"
                action={() =>
                  transitionAction({
                    status: 'delivery requested',
                  })
                }
                renewal={renewal}
              />
            </>
          ) : null}
        </div>
      );
    };

    const RenewalScheduledButtons = () => {
      return (
        <div className={classes.actionMargin}>
          {renewal ? (
            <>
              <NegativeActionButton
                label="Remove from schedule"
                action={() =>
                  transitionAction({
                    status: 'submitted by user',
                  })
                }
                renewal={renewal}
              />
            </>
          ) : null}
        </div>
      );
    };

    const ReadyToCollectButtons = () => {
      return (
        <div className={classes.actionMargin}>
          {renewal ? (
            <PositiveActionButton
              label="Request Delivery"
              action={() =>
                transitionAction({
                  status: 'delivery requested',
                })
              }
              renewal={renewal}
            />
          ) : null}
        </div>
      );
    };

    const InDeliveryButtons = () => {
      return (
        <div className={classes.actionMargin}>
          {renewal ? (
            <>
              <PositiveActionButton
                label="Done"
                action={() =>
                  transitionAction({
                    status: 'delivered',
                  })
                }
                renewal={renewal}
              />
              <NegativeActionButton
                label="Fail"
                action={() =>
                  transitionAction({
                    status: 'admin to action',
                  })
                }
                renewal={renewal}
              />
            </>
          ) : null}
        </div>
      );
    };

    const DeliveredButtons = () => {
      return (
        <div className={classes.actionMargin}>
          {renewal ? (
            <PositiveActionButton
              label="Update License"
              action={() =>
                transitionAction({
                  status: 'license updated',
                })
              }
              renewal={renewal}
            />
          ) : null}
        </div>
      );
    };

    return (
      <>
        <Typography
          align="center"
          className={`${classes.upperCase} ${classes.renewalText}`}
        >
          Renewal Actions
        </Typography>
        {renewal ? <RenewalState status={renewal.status} /> : null}
        <Button
          onClick={viewVehicle}
          variant="contained"
          color="primary"
          fullWidth
          className={[
            classes.status,
            classes.full100,
            classes.buttonMarginBottom,
          ].join(' ')}
        >
          View Vehicle
        </Button>
        {renewal?.status === 'license updated' ? (
          <Button
            onClick={onRenewVehicleAgain}
            variant="contained"
            color="secondary"
            fullWidth
            className={[
              classes.status,
              classes.full100,
              classes.buttonMarginBottom,
            ].join(' ')}
          >
            Renew Vehicle Again
          </Button>
        ) : null}
      </>
    );
  },
);

export default RenewalActionsContainer;
