import { Theme } from '@material-ui/core/styles/createMuiTheme';
import { makeStyles, createStyles } from '@material-ui/core/styles';

import { ICompany } from 'logic/stores/company/validation';

interface IProps {
  whitelabel: boolean;
  company: ICompany;
}

export default makeStyles<Theme, IProps>((theme: Theme) =>
  createStyles({
    container: {
      textAlign: 'center',
      background: 'transparent',
      height: `calc(100vh - ${theme.mixins.toolbar.minHeight}px)`,
    },
    termsMargin: {
      marginBottom: '5em',
    },
    content: {
      marginTop: theme.spacing(3),
      paddingBottom: theme.spacing(3),
    },
    cover: {
      width: '100%',
      textAlign: 'center',
      backgroundPosition: 'center',
      overflow: 'hidden',
    },
    padding10: {
      padding: '10px',
    },
    darkBanner: {
      textAlign: 'center',
      backgroundPosition: (props) => `50% ${props.whitelabel ? '75%' : '50%'}`,
      backgroundImage: (props) => `url(${props.company.backgroundUrl})`,
      backgroundRepeat: 'no-repeat',
      position: 'relative',
      padding: '10px 0px 0px',
      minHeight: 'calc(88vh)',
      backgroundAttachment: 'fixed',
      zIndex: 5,
    },
    containerClass: {
      margin: '1px',
    },
    btnDark: {
      color: 'black',
    },
    btnBlue: {
      background: theme.palette.primary.main,
      color: theme.palette.primary.contrastText,
      padding: '1.5em',
      margin: '15px',
      borderRadius: '3px',
      fontSize: '1.0em',
      width: '9em',
    },
    btnLight: {
      color: '#000',
    },
    typoText: {
      opacity: 0.64,
      fontWeight: 300,
      fontSize: '1.15rem',
      letterSpacing: '0.02rem',
      lineHeight: '1.72rem',
      margin: '0px 0px 1.72rem 0px',
      color: 'rgba(0,0,0,0.95)',
    },
    typoHead: {
      fontSize: '2.1rem',
      lineHeight: '130%',
      margin: '1.46rem 0 1.168rem 0',
    },
    headTop: {
      zIndex: 'auto',
      color: 'black',
      margin: '1.5rem',
      marginTop: '0',
      // decrease spacing around this section
      padding: '0.5em',
    },
    contentMidWhite: {
      background: '#FFFFFF',
      // add some breathing space for text inside this box
      padding: '10px',
      textAlign: 'center',
      [theme.breakpoints.down('sm')]: {
        padding: '10px',
      },
    },
    contentFaq: {
      padding: '30px !important',
      textAlign: 'center',
    },
    gridContent: {
      width: '100%',
      maxWidth: '850px',
      margin: '0 auto',
      display: 'flex',
      alignItems: 'center',
    },
  }),
);
