import React, { useState } from 'react';
import { observer } from 'mobx-react-lite';
import { useHistory } from 'react-router-dom';
// @material-ui
import {
  AppBar,
  TableFooter,
  Hidden,
  TablePagination,
  TableRow,
  TableHead,
  TableCell,
  Table,
  TableBody,
  TextField,
  Grid,
  Paper,
  CircularProgress,
  Toolbar,
  Typography,
  Button,
} from '@material-ui/core';
// @icons
import { Search as SearchIcon, ArrowForwardIos } from '@material-ui/icons';
// @logic
import { useStore } from 'logic/store';
// @local
import { IUser } from 'logic/stores/users/validation';
import { PaginatedResponse } from 'logic/stores/vehicles/validation';
import useStyles from './styles';
import AddUser from './AddUser';
import UpdateUser from './UpdateUser';

type Order = 'asc' | 'desc';

function descendingComparator<T>(a: T, b: T, orderBy: keyof T) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator<Key extends keyof any>(
  order: Order,
  orderBy: Key,
): (a: { [key in Key]: any }, b: { [key in Key]: any }) => number {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort<T>(array: T[], comparator: (a: T, b: T) => number) {
  const stabilizedThis = array.map((el, index) => [el, index] as [T, number]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

const Users = observer(() => {
  const store = useStore();

  // All users, admin only...

  const history = useHistory();
  const classes = useStyles();
  const [searchText, setSearchText] = React.useState('');
  const [userId, setUserId] = React.useState<number | null>(null);
  const [typingTimeout, setTypeTimeout] = React.useState<NodeJS.Timeout | null>(
    null,
  );

  const [users, setUsers] = useState<PaginatedResponse<IUser>>({
    entries: [],
    count: 0,
  });

  const [order] = React.useState<Order>('asc');
  const [orderBy] = React.useState<'updatedAt' | 'firstName'>('firstName');
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  const [page, setPage] = React.useState(0);

  React.useEffect(() => {
    fetchUsers();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  React.useEffect(() => {
    fetchUsers();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rowsPerPage, page, orderBy, order]);

  const fetchUsers = async () => {
    const result = await store.admin.getUsers({
      skip: page * rowsPerPage,
      take: rowsPerPage,
      order,
      orderBy: 'createdAt',
    });
    if (result.success) {
      setUsers(result.data);
    }
  };

  const searchFor = (searchEvent: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = searchEvent.target;
    if (typingTimeout != null) {
      clearTimeout(typingTimeout);
    }
    setSearchText(value);
    setTypeTimeout(
      // @ts-ignore To-Do Fix Type
      setTimeout(async () => {
        const result = await store.admin.getUsers({ search: value });
        if (result.success) {
          setUsers(result.data);
        }
      }, 300),
    );
  };

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const clearUser = async () => {
    setUserId(null);
    console.log('Reloading...');
    const result = await store.admin.getUsers({
      search: searchText,
      skip: page * rowsPerPage,
      take: rowsPerPage,
      order,
      orderBy: 'createdAt',
    });
    if (result.success) {
      setUsers(result.data);
    }
  };

  const viewUser = (id: number | void) => () => {
    if (id != null) {
      setUserId(id);
    }
  };

  const emptyRows =
    rowsPerPage - Math.min(rowsPerPage, users.count - page * rowsPerPage);

  const rowStyles = 49 * emptyRows;
  const heightWithRow = [{ height: rowStyles }].join(' ');

  return (
    <Paper className={classes.paper} elevation={0}>
      <Typography variant="h4" align="center">
        Manage Users
      </Typography>
      <AppBar
        className={classes.searchBar}
        position="static"
        color="default"
        elevation={0}
      >
        <Toolbar>
          <Grid container spacing={2} alignItems="center">
            <Hidden smDown>
              <Grid item>
                <SearchIcon className={classes.block} color="inherit" />
              </Grid>
            </Hidden>
            <Grid item xs>
              <TextField
                fullWidth
                color="secondary"
                placeholder="Search by user name or email address"
                onChange={searchFor}
                value={searchText}
                InputProps={{
                  disableUnderline: true,
                  className: classes.searchInput,
                }}
              />
            </Grid>
            <Grid item>
              <AddUser onClose={clearUser} />
              {userId != null ? (
                <UpdateUser userId={userId} closeModal={clearUser} />
              ) : null}
            </Grid>
          </Grid>
        </Toolbar>
      </AppBar>
      <div className={classes.contentWrapper}>
        {users.count < 1 ? (
          <Typography color="textSecondary" align="center">
            No users found...
          </Typography>
        ) : store.users.loading === true ? (
          <div className={classes.centerProgress}>
            Fetching Users...
            <CircularProgress className={classes.progress} />
          </div>
        ) : (
          <div className={classes.scrollTable}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Name</TableCell>
                  <TableCell>Email</TableCell>
                  <TableCell>Contact number</TableCell>
                  <TableCell />
                </TableRow>
              </TableHead>
              <TableBody>
                {stableSort(users.entries, getComparator(order, orderBy))
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((user: IUser) => (
                    <TableRow
                      onClick={viewUser(user.id)}
                      key={user.id}
                      hover
                      role="checkbox"
                      classes={{
                        hover: classes.rowHover,
                      }}
                    >
                      <TableCell>
                        {`${user.firstName} ${user.lastName} ${
                          user.testUser ? '- test user' : ''
                        }`}
                      </TableCell>
                      <TableCell>{user.email}</TableCell>
                      <TableCell>{user.contactNumber}</TableCell>
                      <TableCell>
                        <Button
                          variant="contained"
                          onClick={() =>
                            history.push(`/admin/view-user/${user.id}`)
                          }
                          className={classes.greenButton}
                          endIcon={<ArrowForwardIos />}
                        >
                          <b>View User</b>
                        </Button>
                      </TableCell>
                    </TableRow>
                  ))}
                <Hidden smDown>
                  {emptyRows > 0 && (
                    <TableRow className={heightWithRow}>
                      <TableCell colSpan={6} />
                    </TableRow>
                  )}
                </Hidden>
              </TableBody>
              <TableFooter>
                <TableRow>
                  <TablePagination
                    rowsPerPageOptions={[5, 10, 25]}
                    count={users.count}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    backIconButtonProps={{
                      'aria-label': 'Previous Page',
                    }}
                    nextIconButtonProps={{
                      'aria-label': 'Next Page',
                    }}
                    classes={{
                      toolbar: classes.paginationToolbar,
                      actions: classes.paginationActions,
                    }}
                    onChangePage={handleChangePage}
                    onChangeRowsPerPage={handleChangeRowsPerPage}
                  />
                </TableRow>
              </TableFooter>
            </Table>
          </div>
        )}
      </div>
    </Paper>
  );
});

export default Users;
