import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    headTop: {
      zIndex: 'auto',
      color: 'black !important',
      margin: '1.5rem',
      padding: '1em',
      marginBottom: '70px',
      [theme.breakpoints.down('sm')]: {
        marginBottom: '10em',
      },
    },
    contentMidWhite: {
      background: '#FFFFFF',
      padding: '60px',
      paddingTop: '0',
      textAlign: 'center',
      [theme.breakpoints.down('sm')]: {
        padding: '10px',
      },
    },
  }),
);

export default useStyles;
