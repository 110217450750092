import React from 'react';
import { observer } from 'mobx-react-lite';
import { useHistory } from 'react-router-dom';
// @logic
import { useStore } from 'logic/store';
// @material-ui
import {
  Grid,
  Button,
  Stepper,
  Step,
  StepLabel,
  StepContent,
} from '@material-ui/core';
// @components
import Content from 'components/Layout/Content';
import UploadDocuments from 'components/UploadDocuments';
import DeliveryAddress from 'components/DeliveryAddress';
// @local
import useStyles from './styles';

enum FormSteps {
  UploadDocs = 0,
  AddAddress = 1,
}

const AccountSetup = observer(() => {
  const history = useHistory();
  const classes = useStyles();
  const [activeStep, setActiveStep] = React.useState(0);
  const store = useStore();
  const { userId } = store.auth;

  const { documents } = store.documents;

  React.useEffect(() => {
    if (userId != null) {
      store.documents.load({ userId });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userId]);

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const steps = ['Document Uploads', 'Delivery Address'];

  const addVehiclesPage = () => {
    history.push('/add-vehicle');
  };

  return (
    <Content>
      <Grid item md={12} xs={12}>
        <Stepper
          activeStep={activeStep}
          orientation="vertical"
          className={classes.stepperPaddingBelow}
        >
          {steps.map((label, index) => (
            <Step key={label}>
              <StepLabel>{label}</StepLabel>
              <StepContent>
                {activeStep === FormSteps.UploadDocs ? (
                  <UploadDocuments next={handleNext} documents={documents} />
                ) : activeStep === FormSteps.AddAddress ? (
                  <DeliveryAddress next={addVehiclesPage} />
                ) : null}
                {activeStep !== FormSteps.UploadDocs && (
                  <div className={classes.actionsContainer}>
                    <div>
                      <Button onClick={handleBack} className={classes.button}>
                        Back
                      </Button>
                    </div>
                  </div>
                )}
              </StepContent>
            </Step>
          ))}
        </Stepper>
      </Grid>
    </Content>
  );
});

export default AccountSetup;
