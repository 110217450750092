import React from 'react';
import { Link } from 'react-router-dom';
// @material-ui
import { Grid, Paper, Typography } from '@material-ui/core';
// @local
import { useStore } from 'logic/store';
import useStyles from './styles';
import Content from '../../components/Layout/Content';

const NotFound = () => {
  const store = useStore();
  const classes = useStyles({});
  return (
    <Content
      className={classes.container}
      containerClass={classes.contentContainer}
    >
      <Grid item md={12} xs={12}>
        <Paper elevation={2} className={classes.paperPadding}>
          <div className={classes.logoLink}>
            <Link className={classes.logoLink} to="/">
              <img
                src={store.companies.company.logoUrl}
                className={classes.logo}
                alt="logo"
              />
            </Link>
          </div>
          <Typography variant="h5" align="center">
            <strong>Page </strong>
          </Typography>
        </Paper>
      </Grid>
    </Content>
  );
};

export default NotFound;
