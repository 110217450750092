import Joi from 'joi';
import { IUser, UserSchema } from '../users/validation';
import { RenewalStatus } from '../renewals/types';
import { Region, VehicleType } from './types';

export interface IVehicleRenewal {
  id: number;
  dateOrdered: Date;
  originalExpiry: Date;
  paymentReference: string;
  paymentInCents: string;
  currency: string;
  status: RenewalStatus;
  webhookSuccessful?: boolean;
  webhookResponse?: string;
  createdAt: Date;
  updatedAt: Date;
  user?: IUser;
  userId?: number;
  vehicle?: IVehicle;
  vehicleId?: number;
}

export const VehicleRenewalSchema = Joi.object<IVehicleRenewal>({
  id: Joi.number().required(),
  dateOrdered: Joi.date().required(),
  originalExpiry: Joi.date().required(),
  paymentReference: Joi.string().required(),
  paymentInCents: Joi.any().required(),
  currency: Joi.string().required(),
  status: Joi.string().required(),
  webhookSuccessful: Joi.boolean().allow(null),
  webhookResponse: Joi.string().allow(null),
  createdAt: Joi.date().required(),
  updatedAt: Joi.date().required(),
  user: UserSchema.allow(null),
  userId: Joi.number().allow(null),
  vehicle: Joi.any().allow(null),
  vehicleId: Joi.number().allow(null),
});

export interface IVehicle {
  id: number;
  make: string;
  licenseNumber: string;
  vehicleType: VehicleType;
  weightKg: number;
  licenseExpires: Date;
  registrationRegion: Region;
  lastSortedDate?: Date;
  description?: string;
  renewalFeeInCents?: number;
  renewalFeeCurrency?: string;
  inRenewal: boolean;
  reminderSent: boolean;
  archived: boolean;
  registerNumber?: string;
  userId?: number;
  model?: string;
  year?: number;
  createdAt: Date;
  updatedAt: Date;
  user?: IUser;
  renewals?: IVehicleRenewal[];
}

export const VehicleSchema = Joi.object<IVehicle>({
  id: Joi.number().required(),
  make: Joi.string().required(),
  licenseNumber: Joi.string().required(),
  vehicleType: Joi.string().required(),
  weightKg: Joi.number().required(),
  licenseExpires: Joi.date().required(),
  registrationRegion: Joi.string().required(),
  lastSortedDate: Joi.date().allow(null),
  description: Joi.string().allow(null),
  renewalFeeInCents: Joi.number().allow(null),
  renewalFeeCurrency: Joi.string().allow(null),
  inRenewal: Joi.boolean().required(),
  reminderSent: Joi.boolean().required(),
  archived: Joi.boolean().required(),
  registerNumber: Joi.string().allow(null),
  userId: Joi.number().allow(null),
  model: Joi.string().allow(null),
  year: Joi.number().allow(null),
  createdAt: Joi.date().required(),
  updatedAt: Joi.date().required(),
  user: UserSchema.allow(null),
  renewals: Joi.array<IVehicleRenewal[]>()
    .items(VehicleRenewalSchema)
    .allow(null),
});

export const VehiclesSchema = Joi.array<IVehicle[]>().items(VehicleSchema);

export const LoadVehiclesSchema = Joi.object<{
  count: number;
  entries: IVehicle[];
}>({
  count: Joi.number().required(),
  entries: Joi.array<IVehicle>().items(VehicleSchema),
});

export type PaginatedInput = {
  skip: number;
  take: number;
  vehicleId?: number;
  userId?: number;
  renewalId?: number;
};

export type PaginatedResponse<Data> = {
  entries: Data[];
  count: number;
};

export const PaginatedVehiclesSchema = Joi.object<PaginatedResponse<IVehicle>>({
  count: Joi.number().required(),
  entries: VehiclesSchema,
});
