import { makeObservable, observable, runInAction } from 'mobx';
import Joi from 'joi';
import {
  BaseStore,
  RequestConfig,
  UploadFileResponse,
} from '../util/baseStore';
import {
  DocumentSchema,
  IDocument,
  IOCRResults,
  IOCRResultsSchema,
} from './validation';

export type LoadDocumentsInput = {
  userId?: number;
  vehicleId?: number;
  search?: string;
  skip?: number;
  take?: number;
};

class V2DocumentStore extends BaseStore {
  constructor(config: RequestConfig) {
    super(config);
    makeObservable(this, {
      documents: observable,
    });
  }

  // State
  loading = false;
  documents: IDocument[] = [];

  reset() {
    this.documents = [];
  }

  // Actions
  async uploadLicenceDisk(
    file: File,
    userId?: number,
  ): Promise<UploadFileResponse> {
    if (userId) {
      return { success: false, error: new Error('Admin mode unimplemented') };
    }
    return this.uploadFile('uploads/license-disk', file);
  }

  async fetchLicenseOCR(documentId: number) {
    return this.withState(
      this.sendRequest({
        path: `documents/license-ocr/${documentId}`,
        method: 'GET',
        validation: IOCRResultsSchema,
      }),
    );
  }

  async remove(id: number) {
    return this.withState(
      this.sendRequest({
        method: 'DELETE',
        path: 'documents',
        params: { id },
        validation: DocumentSchema,
      }),
    );
  }

  getById(id: number) {
    return this.documents.find((d) => d.id === id);
  }

  async load(input: LoadDocumentsInput) {
    this.setLoading(true);

    const result = await this.sendRequest({
      method: 'GET',
      path: 'documents',
      params: input,
      validation: Joi.array<IDocument[]>().items(DocumentSchema),
    });

    runInAction(() => {
      this.loading = false;
      if (result.success) {
        this.documents = result.data;
      }
    });

    return result;
  }
}

export default V2DocumentStore;
