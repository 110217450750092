import React from 'react';
import { toast } from 'react-toastify';
import { observer } from 'mobx-react-lite';
// @material-ui
import { Grid, LinearProgress, Typography } from '@material-ui/core';
// @logic
import { useStore } from 'logic/store';
// @components
import Upload from 'components/UploadDetails/Upload';
import IdHelp from 'components/UploadDetails/IdDialog';

interface Props {
  next?: () => void;
}

const SetPassportDocumentSelect = observer(({ next }: Props) => {
  const store = useStore();
  const { userId } = store.auth;

  const [passport, setPassport] = React.useState<File | null>(null);

  const [passportProgress, setPassportProgress] = React.useState(0);

  const uploadPassportDocs = async () => {
    if (userId != null && passport != null) {
      const result = await store.users.uploadPassport(passport, userId);
      // progressUpdatePassport: setPassportProgress,

      if (result.success) {
        if (next) {
          next();
        }
      } else {
        toast.error('Passport documents could not be uploaded');
      }
    } else {
      toast.error(
        'Unable to identify the user and the documents intended for upload',
      );
    }
  };

  return (
    <>
      <Grid item xs={12} md={12}>
        <Upload
          file={passport}
          setFile={setPassport}
          message="Copy of your Passport"
          next={uploadPassportDocs}
          HelpDialog={IdHelp}
        />
      </Grid>
    </>
  );
});

export default SetPassportDocumentSelect;
