import Joi from 'joi';
import { IUser, UserSchema } from '../users/validation';
import { PaginatedResponse } from '../vehicles/validation';

export type IPayment = {
  id: number;
  checkoutId: string;
  amountInCents: number;
  currency: string;
  description: string;
  status: string;
  reference: string;
  failureReason?: string;
  rawResponse?: string;
  externalReference?: string;
  rawNotification?: string;
  merchantReference?: string;
  createdAt: Date;
  updatedAt: Date;
  userId?: number;
  user?: IUser;
};

export const PaymentSchema = Joi.object<IPayment>({
  id: Joi.number().required(),
  checkoutId: Joi.string().required(),
  amountInCents: Joi.number().required(),
  currency: Joi.string().required(),
  description: Joi.string().required(),
  status: Joi.string().required(),
  reference: Joi.string().required(),
  failureReason: Joi.string().allow(null),
  rawResponse: Joi.string().allow(null),
  externalReference: Joi.string().allow(null),
  rawNotification: Joi.string().allow(null),
  merchantReference: Joi.string().allow(null),
  createdAt: Joi.date().required(),
  updatedAt: Joi.date().required(),
  userId: Joi.number().allow(null),
  user: UserSchema.allow(null),
});

export const PaginatedVehiclesSchema = Joi.object<PaginatedResponse<IPayment>>({
  count: Joi.number().required(),
  entries: Joi.array<IPayment[]>().items(PaymentSchema),
});

export type GetPaymentUrlOutput = {
  paymentUrl: string;
  checkoutId: string;
};

export const GetPaymentUrlOutputSchema = Joi.object<GetPaymentUrlOutput>({
  paymentUrl: Joi.string().required(),
  checkoutId: Joi.string().required(),
});

export type PaymentUpdatOutput = {
  result: string;
  description: string;
};

export const PaymentUpdateOutputSchema = Joi.object<PaymentUpdatOutput>({
  result: Joi.string().required(),
  description: Joi.string().required(),
});
