import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter, useHistory } from 'react-router-dom';
import * as Sentry from '@sentry/browser';
import { toast } from 'react-toastify';
import ReactGA from 'react-ga'; // Google Analytics
import ReactPixel from 'react-facebook-pixel';
import MomentUtils from '@date-io/moment';
import moment from 'moment';
import 'react-toastify/dist/ReactToastify.css';
// @material-ui
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
// @logic
import { createStore, Provider } from 'logic/store';
// @components
import App from 'components/App';
import Rehydrater from 'components/utilities/Rehydrater';
// @local
import Whitelabel from 'components/utilities/Whitelabel';
import { GOOGLE_ANALYTICS_ID, FB_PIXEL_ID } from './logic/config';
import * as serviceWorker from './serviceWorker';

Sentry.init({ dsn: process.env.REACT_APP_SENTRY });

toast.configure({
  position: 'top-right',
  autoClose: 4000,
});

if (GOOGLE_ANALYTICS_ID) {
  // eslint-disable-next-line no-console
  ReactGA.initialize(GOOGLE_ANALYTICS_ID);
} else {
  // eslint-disable-next-line no-console
  console.log('No Google Analytics ID was found');
}

const Analytics = () => {
  const history = useHistory();

  history.listen((location: any) => {
    if (GOOGLE_ANALYTICS_ID && location) {
      // eslint-disable-next-line no-console
      console.log('GA LOGGED LOCATION', location.pathname);
      ReactGA.set({ page: location.pathname }); // Update the user's current page
      ReactGA.pageview(location.pathname); // Record a pageview for the given page
    }

    if (FB_PIXEL_ID) {
      ReactPixel.init(FB_PIXEL_ID);
      ReactPixel.pageView();
    } else {
      // eslint-disable-next-line no-console
      console.log('No Facebook Pixel ID was found');
    }
  });

  return <></>;
};

const AuthenticatedApp = () => {
  const [locale] = React.useState('en');
  const store = createStore();

  console.log(`API_URL ${process.env.REACT_APP_API_URL}`);

  return (
    <BrowserRouter>
      <MuiPickersUtilsProvider
        utils={MomentUtils}
        libInstance={moment}
        locale={locale}
      >
        <Provider value={store}>
          <Analytics />
          <Rehydrater>
            <Whitelabel Component={App} />
          </Rehydrater>
        </Provider>
      </MuiPickersUtilsProvider>
    </BrowserRouter>
  );
};

ReactDOM.render(<AuthenticatedApp />, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA

if (process.env.NODE_ENV === 'production') {
  serviceWorker.unregister();
} else {
  serviceWorker.unregister();
}
