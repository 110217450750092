/* eslint-disable no-else-return */
import * as React from 'react';
import { observer } from 'mobx-react-lite';
import { pathToRegexp } from 'path-to-regexp';
import { useHistory, Redirect } from 'react-router-dom';
// @logic
import { useStore } from 'logic/store';
import ManageVehicles from 'components/ManageVehicles';
import AccountSetup from 'components/AccountSetup';
import AccountCreated from 'components/Auth/AccountCreated';
import Signin from 'components/Auth/Signin';

const safeRoutes = [
  '/',
  '/login',
  '/login/:nextRoute*',
  '/about',
  '/faq',
  '/terms',
  '/contact',
  '/signup',
  '/recover-account',
  '/resend-verification',
  '/verify-account/:token?',
  '/password-reset/:token*',
].map((path) => pathToRegexp(path));

const adminRoutes = ['/admin', '/admin/:nextRoute*'].map((path) =>
  pathToRegexp(path),
);

const unactivatedUserRoutes = ['/account-setup'].map((path) =>
  pathToRegexp(path),
);

const PrivateRoute = observer(({ children }: any) => {
  const history = useHistory();
  const currentRoute = history.location.pathname;

  // @logic
  const store = useStore();
  const {
    userId,
    user,
    accessToken: token,
    permissions: permissionNames,
  } = store.auth;

  React.useEffect(() => {
    fetchUser(userId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userId]);

  const fetchUser = async (id?: number | null) => {
    if (id) {
      const loadedUser = await store.users.getById(id);
      if (loadedUser.success) {
        store.auth.set(loadedUser.data);
      }
    }
  };

  // Order of the route arrays matters
  const hasAccess = () => {
    if (safeRoutes.some((checkRoute) => checkRoute.test(currentRoute))) {
      // If user is verified send them to view vehicles
      if (
        user &&
        user.verified === true &&
        currentRoute.includes('verify-account')
      ) {
        history.push('/manage-vehicles');
        return {
          canAccess: false,
          path: '/manage-vehicles',
          content: <ManageVehicles />,
        };
      }

      return { canAccess: true };
    }

    if (adminRoutes.some((checkRoute) => checkRoute.test(currentRoute))) {
      if (permissionNames?.includes('isAdmin')) {
        return { canAccess: true };
      }
    }

    if (!safeRoutes.some((checkRoute) => checkRoute.test(currentRoute))) {
      if (userId != null && token != null) {
        if (permissionNames?.includes('isAdmin')) {
          return {
            canAccess: true,
          };
        }

        if (user && user.verified === true) {
          if (permissionNames?.includes('isActivatedUser')) {
            return {
              canAccess: true,
            };
          }

          if (
            unactivatedUserRoutes.some((checkRoute) =>
              checkRoute.test(currentRoute),
            )
          ) {
            return { canAccess: true };
          }
          return {
            canAccess: false,
            path: '/account-setup',
            content: <AccountSetup />,
          };
        } else {
          if (store.users.loading === false) {
            return {
              canAccess: false,
              path: `/verify-account`,
              content: <AccountCreated />,
            };
          }

          return {
            canAccess: true,
          };
        }
      }
    }
    return {
      canAccess: false,
      path: `/login${currentRoute || '/'}`,
      content: <Signin />,
    };
  };

  const { canAccess, content, path } = hasAccess();

  return canAccess ? children : content;
});

export default PrivateRoute;
