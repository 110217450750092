import { BaseStore } from '../util/baseStore';
import { MessageSchema } from '../validation';

interface SendMailInput {
  email: string;
  message: string;
  contactNumber: string;
  firstName: string;
  lastName: string;
}

class V2MailStore extends BaseStore {
  // State
  loading = false;

  // Actions
  async sendMail(input: SendMailInput) {
    return this.withState(
      this.sendRequest({
        path: 'mailer/contact',
        method: 'POST',
        validation: MessageSchema,
        body: input,
      }),
    );
  }
}

export default V2MailStore;
