import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  button: {
    marginTop: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
  bannerText: {
    fontSize: '1.4rem',
    lineHeight: '140%',
    margin: '16px',
  },
  startRenewalDiv: {
    padding: '24px',
    paddingTop: '0px',
  },
  actionsContainer: {
    width: '100%',
    paddingBottom: '20px',
  },
  actions: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  headingSpace: {
    marginBottom: '20px',
  },
  resetContainer: {
    padding: theme.spacing(3),
  },
  cardMargin: {
    margin: 20,
  },
  progress: {
    padding: theme.spacing(1),
  },
}));

export default useStyles;
